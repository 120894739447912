import { Button, Table, Tooltip } from "antd";
import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { areaCodeOptions, MAX_SETTINGS_ALLOWED, popoverContent } from "../constants";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import AreaCodeSelection from "../AreaCodeSelection";
import { getOrdinalNumber } from "../utils";
import "./BlockSettings.scss";
import HeaderWithPopover from "../../common/AntTableColumnHeaderWithPopover";

const BlockSettings = forwardRef((props, ref) => {
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});
  const { handlePriorityChange } = props;

  const tableColumns = [
    {
      title: <HeaderWithPopover title={"Priority"} popoverTitle={"Priority"} popoverText={popoverContent} />,
      dataIndex: "priority",
      key: "priority",
      width: 30,
      className:"block-priority"
    },
    {
      title: "Area code",
      dataIndex: "area_code",
      key: "area_code",
      className: "block-area-code",
      width: 60,
      render: (value, record) => {
        return (
          <AreaCodeSelection
            id={record.id}
            handleAreaSelection={handleAreaSelection}
            options={filterOptions()}
            value={settings[record.id].value}
            showError={errors[record.id]}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      className: "block-action",
      width: 10,
      render: (value, record) => {
        return (
          Object.values(settings).length > 1 ?
          (<CloseOutlined
            onClick={() => handleDeleteSetting(record.id)} 
          />) : null);
      },
    },
  ];

  const handleDeleteSetting = (id) => {
    const tempSettings = Object.values({...settings})
      .filter((setting) => setting.id !== id)
      .map((setting, index) => {
        setting.priority = `${getOrdinalNumber(index)} Priority`;
        return setting
    }).reduce((acc, setting) => {
      acc[setting.id] = setting;
      return acc;
    },{});
    setSettings(tempSettings);
  };

  const handleAreaSelection = (id, value) => {
    const tempSettings = {...settings};
    tempSettings[id].value = value;
    setSettings(tempSettings)
    updateErrorState(tempSettings);
  };

  const filterOptions = () => {
    const settingsOpted = Object.values({ ...settings });
    const chosenAreaCodes = settingsOpted.reduce(
      (acc, setting) => {
        if(setting.value){
          acc.push(setting.value);
        }
        return acc;
      },[]
    );
    return areaCodeOptions.filter(
      (option) => !chosenAreaCodes.includes(option.value)
    );
  }

  const addDefaultSetting = () => {
    const initialId = uuidv4();
    const priority = getOrdinalNumber(Object.values(settings).length);
    const defaultSetting = {
      id: initialId,
      priority: `${priority} Priority`,
      value: null
    };
    setSettings((prev) => ({...prev, [initialId]: defaultSetting}));
  };

  const validatePreferences = () => {
    let isValid = true;
    Object.values(settings).forEach((setting) => {
      if(!setting.value){
        isValid = false;
      }
    })
    return isValid;
  }

  const validateAndAddNewSettings = () => {
    if(validatePreferences()){
      addDefaultSetting();
    }else{
      updateErrorState();
    }
  }

  useImperativeHandle(ref, () => {
    return {
      validatePreferences,
      updateErrorState
    }
  })

  const updateErrorState = (settingsOpted = settings) => {
    const tempSettings = {...settingsOpted};
    const unFilledSettings = Object.values(tempSettings).reduce((acc, val) => {
      acc[val.id] = !val.value;
      return acc;
    }, {});
    setErrors(unFilledSettings);
  }

  useEffect(() => {
    addDefaultSetting();
  }, []);

  useEffect(() => {
    const dataToPost = Object.values({ ...settings }).reduce(
      (acc, setting) => {
        acc[setting.priority] = setting.value;
        return acc;
      },{}
    );
    handlePriorityChange(dataToPost);
  }, [settings]);

  const TooltipForDisableButton = ({children, disabled}) => {
    return (
      <>
        {disabled ? 
          <Tooltip
            title="Max 5 area codes can be added in priority"
            overlayClassName="disabled-button-tooltip"
          >{children}</Tooltip> : <>{children}</>}
      </>
    )
  }

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={Object.values(settings)}
        pagination={{ position: ["none"] }}
        rowKey={(record) => record.id}
        className="block-settings-table"
      />
      <TooltipForDisableButton disabled={Object.values(settings).length >= MAX_SETTINGS_ALLOWED}>
        <Button
          onClick={validateAndAddNewSettings}
          disabled={Object.values(settings).length >= MAX_SETTINGS_ALLOWED}
          className="add-priority"
        >
          <PlusCircleOutlined />
          Add priority
        </Button>
      </TooltipForDisableButton>
    </>
  );
});

export default BlockSettings;
