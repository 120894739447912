export const MAX_SETTINGS_ALLOWED = 5;

export const areaCodeOptions = [
  { value: "201", label: "201" },
  { value: "202", label: "202" },
  { value: "203", label: "203" },
  { value: "205", label: "205" },
  { value: "206", label: "206" },
  { value: "207", label: "207" },
  { value: "208", label: "208" },
  { value: "209", label: "209" },
  { value: "210", label: "210" },
  { value: "212", label: "212" },
  { value: "213", label: "213" },
  { value: "214", label: "214" },
  { value: "215", label: "215" },
  { value: "216", label: "216" },
  { value: "217", label: "217" },
  { value: "218", label: "218" },
  { value: "219", label: "219" },
  { value: "220", label: "220" },
  { value: "224", label: "224" },
  { value: "225", label: "225" },
  { value: "228", label: "228" },
  { value: "229", label: "229" },
  { value: "231", label: "231" },
  { value: "234", label: "234" },
  { value: "239", label: "239" },
  { value: "240", label: "240" },
  { value: "248", label: "248" },
  { value: "251", label: "251" },
  { value: "252", label: "252" },
  { value: "253", label: "253" },
  { value: "254", label: "254" },
  { value: "256", label: "256" },
  { value: "260", label: "260" },
  { value: "262", label: "262" },
  { value: "267", label: "267" },
  { value: "269", label: "269" },
  { value: "270", label: "270" },
  { value: "272", label: "272" },
  { value: "276", label: "276" },
  { value: "281", label: "281" },
  { value: "283", label: "283" },
  { value: "301", label: "301" },
  { value: "302", label: "302" },
  { value: "303", label: "303" },
  { value: "304", label: "304" },
  { value: "305", label: "305" },
  { value: "307", label: "307" },
  { value: "308", label: "308" },
  { value: "309", label: "309" },
  { value: "310", label: "310" },
  { value: "312", label: "312" },
  { value: "313", label: "313" },
  { value: "314", label: "314" },
  { value: "315", label: "315" },
  { value: "316", label: "316" },
  { value: "317", label: "317" },
  { value: "318", label: "318" },
  { value: "319", label: "319" },
  { value: "320", label: "320" },
  { value: "321", label: "321" },
  { value: "323", label: "323" },
  { value: "325", label: "325" },
  { value: "326", label: "326" },
  { value: "330", label: "330" },
  { value: "331", label: "331" },
  { value: "334", label: "334" },
  { value: "336", label: "336" },
  { value: "337", label: "337" },
  { value: "339", label: "339" },
  { value: "346", label: "346" },
  { value: "347", label: "347" },
  { value: "351", label: "351" },
  { value: "352", label: "352" },
  { value: "360", label: "360" },
  { value: "361", label: "361" },
  { value: "364", label: "364" },
  { value: "380", label: "380" },
  { value: "385", label: "385" },
  { value: "386", label: "386" },
  { value: "401", label: "401" },
  { value: "402", label: "402" },
  { value: "404", label: "404" },
  { value: "405", label: "405" },
  { value: "406", label: "406" },
  { value: "407", label: "407" },
  { value: "408", label: "408" },
  { value: "409", label: "409" },
  { value: "410", label: "410" },
  { value: "412", label: "412" },
  { value: "413", label: "413" },
  { value: "414", label: "414" },
  { value: "415", label: "415" },
  { value: "417", label: "417" },
  { value: "419", label: "419" },
  { value: "423", label: "423" },
  { value: "424", label: "424" },
  { value: "425", label: "425" },
  { value: "430", label: "430" },
  { value: "432", label: "432" },
  { value: "434", label: "434" },
  { value: "435", label: "435" },
  { value: "440", label: "440" },
  { value: "442", label: "442" },
  { value: "443", label: "443" },
  { value: "445", label: "445" },
  { value: "458", label: "458" },
  { value: "463", label: "463" },
  { value: "464", label: "464" },
  { value: "469", label: "469" },
  { value: "470", label: "470" },
  { value: "475", label: "475" },
  { value: "478", label: "478" },
  { value: "479", label: "479" },
  { value: "480", label: "480" },
  { value: "484", label: "484" },
  { value: "501", label: "501" },
  { value: "502", label: "502" },
  { value: "503", label: "503" },
  { value: "504", label: "504" },
  { value: "505", label: "505" },
  { value: "507", label: "507" },
  { value: "508", label: "508" },
  { value: "509", label: "509" },
  { value: "510", label: "510" },
  { value: "512", label: "512" },
  { value: "513", label: "513" },
  { value: "515", label: "515" },
  { value: "516", label: "516" },
  { value: "517", label: "517" },
  { value: "518", label: "518" },
  { value: "520", label: "520" },
  { value: "530", label: "530" },
  { value: "531", label: "531" },
  { value: "534", label: "534" },
  { value: "539", label: "539" },
  { value: "540", label: "540" },
  { value: "541", label: "541" },
  { value: "551", label: "551" },
  { value: "559", label: "559" },
  { value: "561", label: "561" },
  { value: "562", label: "562" },
  { value: "563", label: "563" },
  { value: "564", label: "564" },
  { value: "567", label: "567" },
  { value: "570", label: "570" },
  { value: "571", label: "571" },
  { value: "573", label: "573" },
  { value: "574", label: "574" },
  { value: "575", label: "575" },
  { value: "580", label: "580" },
  { value: "585", label: "585" },
  { value: "586", label: "586" },
  { value: "601", label: "601" },
  { value: "602", label: "602" },
  { value: "603", label: "603" },
  { value: "605", label: "605" },
  { value: "606", label: "606" },
  { value: "607", label: "607" },
  { value: "608", label: "608" },
  { value: "609", label: "609" },
  { value: "610", label: "610" },
  { value: "612", label: "612" },
  { value: "614", label: "614" },
  { value: "615", label: "615" },
  { value: "616", label: "616" },
  { value: "617", label: "617" },
  { value: "618", label: "618" },
  { value: "619", label: "619" },
  { value: "620", label: "620" },
  { value: "623", label: "623" },
  { value: "626", label: "626" },
  { value: "628", label: "628" },
  { value: "629", label: "629" },
  { value: "630", label: "630" },
  { value: "631", label: "631" },
  { value: "636", label: "636" },
  { value: "640", label: "640" },
  { value: "641", label: "641" },
  { value: "646", label: "646" },
  { value: "650", label: "650" },
  { value: "651", label: "651" },
  { value: "657", label: "657" },
  { value: "660", label: "660" },
  { value: "661", label: "661" },
  { value: "662", label: "662" },
  { value: "667", label: "667" },
  { value: "669", label: "669" },
  { value: "678", label: "678" },
  { value: "681", label: "681" },
  { value: "682", label: "682" },
  { value: "701", label: "701" },
  { value: "702", label: "702" },
  { value: "703", label: "703" },
  { value: "704", label: "704" },
  { value: "706", label: "706" },
  { value: "707", label: "707" },
  { value: "708", label: "708" },
  { value: "712", label: "712" },
  { value: "713", label: "713" },
  { value: "714", label: "714" },
  { value: "715", label: "715" },
  { value: "716", label: "716" },
  { value: "717", label: "717" },
  { value: "718", label: "718" },
  { value: "719", label: "719" },
  { value: "720", label: "720" },
  { value: "724", label: "724" },
  { value: "725", label: "725" },
  { value: "726", label: "726" },
  { value: "727", label: "727" },
  { value: "730", label: "730" },
  { value: "731", label: "731" },
  { value: "732", label: "732" },
  { value: "734", label: "734" },
  { value: "737", label: "737" },
  { value: "740", label: "740" },
  { value: "743", label: "743" },
  { value: "747", label: "747" },
  { value: "752", label: "752" },
  { value: "754", label: "754" },
  { value: "757", label: "757" },
  { value: "760", label: "760" },
  { value: "762", label: "762" },
  { value: "763", label: "763" },
  { value: "764", label: "764" },
  { value: "765", label: "765" },
  { value: "769", label: "769" },
  { value: "770", label: "770" },
  { value: "772", label: "772" },
  { value: "773", label: "773" },
  { value: "774", label: "774" },
  { value: "775", label: "775" },
  { value: "779", label: "779" },
  { value: "781", label: "781" },
  { value: "785", label: "785" },
  { value: "786", label: "786" },
  { value: "801", label: "801" },
  { value: "802", label: "802" },
  { value: "803", label: "803" },
  { value: "804", label: "804" },
  { value: "805", label: "805" },
  { value: "806", label: "806" },
  { value: "808", label: "808" },
  { value: "810", label: "810" },
  { value: "812", label: "812" },
  { value: "813", label: "813" },
  { value: "814", label: "814" },
  { value: "815", label: "815" },
  { value: "816", label: "816" },
  { value: "817", label: "817" },
  { value: "818", label: "818" },
  { value: "828", label: "828" },
  { value: "830", label: "830" },
  { value: "831", label: "831" },
  { value: "832", label: "832" },
  { value: "835", label: "835" },
  { value: "843", label: "843" },
  { value: "845", label: "845" },
  { value: "847", label: "847" },
  { value: "848", label: "848" },
  { value: "850", label: "850" },
  { value: "854", label: "854" },
  { value: "856", label: "856" },
  { value: "857", label: "857" },
  { value: "858", label: "858" },
  { value: "859", label: "859" },
  { value: "860", label: "860" },
  { value: "862", label: "862" },
  { value: "863", label: "863" },
  { value: "864", label: "864" },
  { value: "865", label: "865" },
  { value: "870", label: "870" },
  { value: "872", label: "872" },
  { value: "878", label: "878" },
  { value: "901", label: "901" },
  { value: "903", label: "903" },
  { value: "904", label: "904" },
  { value: "906", label: "906" },
  { value: "907", label: "907" },
  { value: "908", label: "908" },
  { value: "909", label: "909" },
  { value: "910", label: "910" },
  { value: "912", label: "912" },
  { value: "913", label: "913" },
  { value: "914", label: "914" },
  { value: "915", label: "915" },
  { value: "916", label: "916" },
  { value: "917", label: "917" },
  { value: "918", label: "918" },
  { value: "919", label: "919" },
  { value: "920", label: "920" },
  { value: "925", label: "925" },
  { value: "928", label: "928" },
  { value: "929", label: "929" },
  { value: "930", label: "930" },
  { value: "931", label: "931" },
  { value: "934", label: "934" },
  { value: "936", label: "936" },
  { value: "937", label: "937" },
  { value: "940", label: "940" },
  { value: "941", label: "941" },
  { value: "945", label: "945" },
  { value: "947", label: "947" },
  { value: "949", label: "949" },
  { value: "951", label: "951" },
  { value: "952", label: "952" },
  { value: "954", label: "954" },
  { value: "956", label: "956" },
  { value: "957", label: "957" },
  { value: "959", label: "959" },
  { value: "970", label: "970" },
  { value: "971", label: "971" },
  { value: "972", label: "972" },
  { value: "973", label: "973" },
  { value: "975", label: "975" },
  { value: "978", label: "978" },
  { value: "979", label: "979" },
  { value: "980", label: "980" },
  { value: "984", label: "984" },
  { value: "985", label: "985" },
  { value: "989", label: "989" },
];

export const popoverContent = "Our system will start renting numbers from your 1st priority area code. When that set is insufficient, we add numbers from your 2nd priority and so on."

export const segmentEvents = {
  creation: 'proactive_number_series_created',
  enabled: 'proactive_number_series_enabled',
}