import React, { useEffect, useState } from "react";
import DownArrow from "../../../../assets/svg/DownArrow";
import UpArrowIcon from "../../../../assets/svg/UpArrowIcon";

import './CustomSelect.scss';

const CustomSelect = ({ sort_order_select_id, customSelectClass, optionList, selectedOptionBgColor, jqueryComponentData }) => {

    const [isDropDownToggled, setIsDropDownToggled] = useState(false)
    const [selectedOption, setSelectedOption] = useState(1)
    const [selectedText, setSelectedText] = useState(optionList[0])

    const { selectedSortOrder = 1, action = 'add' } = jqueryComponentData


    const dropDownHandler = () => {
        if(action == 'edit') {
            setIsDropDownToggled(false)
            return;
        }
        setIsDropDownToggled(!isDropDownToggled)
    }

    const selectOptionHandler = (event) => {
        const text = event.target.innerText
        const value = event.target.value
        setSelectedOption(value);
        setSelectedText(text)
        setIsDropDownToggled(!isDropDownToggled)        
    }

    useEffect(() => {
        if(parseInt(selectedSortOrder) == -1) {
            setSelectedOption(1)
            setSelectedText(optionList[0])
            return;
        } 
        setSelectedOption(selectedSortOrder)
        setSelectedText(optionList[parseInt(selectedSortOrder)-1])
    }, [])

    return (
        <div className={`custom-select-main-div ${(action == 'edit') ? 'custom-select-margin-left' : ''}`} onClick = {dropDownHandler}>
            <div className={`custom-select-input-div ${(action == 'edit') ? 'custom-select-input-div-background' : ''}`}>
                <div 
                    id={sort_order_select_id} 
                    className={`${customSelectClass} `} 
                    value={selectedOption}
                >
                    {selectedText}
                </div>

                <span className={`${(action == 'edit') ?' custom-select-dropdown-icon' : ''}`}>
                    {isDropDownToggled 
                        ? 
                            <DownArrow width = {11} height={8}/>
                        : 
                            <UpArrowIcon width = {11} height={8}/> 
                    }
                </span>   
            </div>
            {
                isDropDownToggled &&
                    <ul className="custom-select-option-list">
                        {
                            optionList.map((option, index) => {
                                return (
                                    <li 
                                        onClick={selectOptionHandler} 
                                        key={index+1} 
                                        value={index+1}
                                        className={`custom-select-list-item ${selectedText === option ? selectedOptionBgColor : ''}`}
                                    > 
                                    {option}
                                    </li>
                                )
                            })
                        }
                    </ul>
            }
            
            

        </div>
        
        
    )
}

export default CustomSelect