import React, { useState, useEffect } from "react";
import NoteIcon from '../../../../assets/svg/NoteIcon.js'
import { trackSegementEvents } from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';
// Importing Components
import _ from "lodash";

//css
import "../commonStyle.scss";
import "./contactTracking.scss";

function ConfirmationScreen(props){

    useEffect(() => {
        if(props.segmentUrlType === '') return;
        setTimeout(() => {
            trackSegementEvents('visited-url-short-link-confirmation-page',{
                'product_category': props.eventData.productCategory,
                'url_type': props.segmentUrlType,
                'domain_name': props.selectedDomain,
                'customer_type' : props.segmentCustomerType,
                'industry': props.eventData.industry
            });
        });
    },[props.segmentUrlType])

    return (
		<div>	
            <div className="fs16 fw600 pb30">You won't be able to edit or delete your shortened URL once you've completed this step. Are you sure you'd like to create this short URL?</div>		
            <div className="originalURL">
                <div>Long URL</div>
                <div>{props.longURL}</div>
            </div>
            
            <div className="shortURLPreview">Shortened URL preview: <span>{props.shortURLPreivew}</span></div>
            { 
                props.isContactTrackingEnabled ? 
                    <div className="note">
                        <div>
                            <NoteIcon size={16}/>
                            <span>NOTE</span>
                        </div>
                        <div className="secondaryParagraph">The above shortened URL is only for illustration purpose. A unique link will be assigned to each contact in the contact list. In these URLs, only last characters after the slash (/) will be modified.</div>
                    </div>
                : 
                null
            }
            <div className="errorColor">{props.errorMessage}</div>
		</div>
	);
};

export default ConfirmationScreen;
