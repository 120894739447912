
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
// segment events for upgrade plan 
export const segmentEventNamesForUpgradePlans = {
  "lite" : "chose-payg",
  "lite plan": "chose-payg",
  "essentials": "chose-essentials",
  "essentials plan": "chose-essentials",
  "scale": "chose-scale",
  "scale plan": "chose-scale",
  "enterprise": "setup-call-with-sales",
  "enterprise plan": "setup-call-with-sales",
};

export const segmentEventNamesForBoughtUpgradePlans = {
  "essentials": "bought-essentials",
  "essentials plan": "bought-essentials",
  "scale": "bought-scale",
  "scale plan": "bought-scale",
  "enterprise": "bought-enterprise",
  "enterprise plan": "bought-enterprise",
};

export const segmentEventNamesForAddons = {
  2 : "clicked-sb-in-store",
  3 : "clicked-ur-in-store",
};

export const segmentEventNamesForBuyAddons = {
  2 : "clicked-sb-buy-button",
  3 : "clicked-ur-buy-button",
};

export const segmentEventNamesForAddonsBought = {
  1: "bought-dialer-from-store",
  2 : "bought-sb-add-on",
  3 : "bought-ur-add-on",
};

export const segmentEventNamesForDowngradePlan = {
  "lite" : "clicked-on-downgrade-to-lite-plan",
  "lite plan" : "clicked-on-downgrade-to-lite-plan",
  "essentials": "clicked-on-downgrade-to-essentials-plan",
  "essentials plan": "clicked-on-downgrade-to-essentials-plan",
  "scale": "clicked-on-downgrade-to-scale-plan",
  "scale plan": "clicked-on-downgrade-to-scale-plan",
};

export const segmentEventNamesForDowngradedPlan = {
  "lite" : "downgraded-to-lite-plan",
  "lite plan" : "downgraded-to-lite-plan",
  "essentials": "downgraded-to-essentials-plan",
  "essentials plan": "downgraded-to-essentials-plan",
  "scale": "downgraded-to-scale-plan",
  "scale plan": "downgraded-to-scale-plan",
};

export const segmentEventNamesForDowngradeAddons = {
  1 : "clicked-on-unsubscribe-for-dialer-addon",
  2 : "clicked-on-unsubscribe-for-sub-accounts-addon",
  3 : "clicked-on-unsubscribe-for-user-roles-addon",
};

function getSegmentUserAccountData(segmentTrait){
  try {
    if(user_details && user_details.user_id_str){
        let industry = user_details.organisation
        let account_id = user_details.account_id
        let account_name = user_details.account_name
        let organisation_name = user_details.company_name
        let user_role = user_details.user_role
        let SegmentUserAccountData = {
          ...segmentTrait,
          'account_id':account_id,
          'account_name':account_name,
          'industry':industry,
          'organisation_name':organisation_name,
          'user_role':user_role,
        }
        return SegmentUserAccountData
    }
  } catch (error) {
      console.log(error)
      try {
        let SegmentUserAccountData = {
          ...segmentTrait,
          ...account_data_segment
        }
        return SegmentUserAccountData
      } 
      catch (error) {
        return {}
      }
      
  }
  
  return {}
}

export const trackSegementEvents = (segmentEvent, activityName) => {

  //jquery pages doesnot get analytics_APP value
  const analytics_APP_from_sessionstorage = sessionStorage.getItem('analytics_APP'); 
  
  if(typeof activityName === 'object'){
      let segmentTrait = getSegmentUserAccountData(activityName)
      activityName = { 
        ...activityName,
        ...segmentTrait,
      }
  }

  if(analytics_key){
    const analytics = Analytics({
      app: analytics_APP_from_sessionstorage ? analytics_APP_from_sessionstorage : analytics_APP,
      plugins: [
        segmentPlugin({
          writeKey: analytics_key,
        }),
      ],
    });
    if (analytics) {
      analytics.track(segmentEvent, activityName);
    }
    return true
  }
};

export const identifySegment = (username, traits) => {

  const analytics_APP_from_sessionstorage = sessionStorage.getItem('analytics_APP'); 

  if(analytics_key){
    const analytics = Analytics({
      app: analytics_APP_from_sessionstorage ? analytics_APP_from_sessionstorage : analytics_APP,
      plugins: [
        segmentPlugin({
          writeKey: analytics_key,
        }),
      ],
    });
    if (analytics) {
      analytics.identify(username, traits);
    }
    return true
  }

}