import React, { useState, useEffect } from 'react';
import './detailsContent.scss';
import moment from 'moment-timezone';

const DetailsContent = (props) => {
    const [createdData, handleCreatedData] = useState("")
    useEffect(() => {
        if(props.shortUrlDetails.created_date){
            let date = props.shortUrlDetails.created_date.split("T")[0] || "";
            handleCreatedData(date);
        }
    },[props.shortUrlDetails.created_date])
    return(
        <>
            <div className="detailsContent">
                <div className="detailHeading">type</div>
                <div className="pb20 detailTitle">{props.shortUrlDetails.title}</div>
                <div className="detailHeading">Long URL</div>
                <div className="pb20 detailTargetURL">{props.shortUrlDetails.target_url}</div>
                <div className="detailHeading pb20 detailsPreivew">
                    Short URL: <span className="blueFontColor textTransformInitial short-url">{props.shortUrlDetails.preview}</span>
                </div>
                <div className={`detailsButtons ${props.addToScriptButton !== undefined ? 'url-duplicate-btn' : ''}`}>
                    {props.addToScriptButton === undefined  && <button className="btn btn-primary add-script-buttton" onClick={() => props.addToScript(props.shortUrlDetails.title, props.shortUrlDetails.pk_str, props.shortUrlDetails.preview)}>Add to Script</button>}
                    <button className={`btn btn-primary duplicate-button`} onClick={() => props.duplicateShortenUrl("", props.shortUrlDetails.target_url)}>Duplicate</button>
                </div>
                <div className="detailHeading pb20">Contact Tracking: <span className="fontWeightRegular">{props.shortUrlDetails.type === 1 ? 'Disabled' : 'Enabled'}</span></div>

                <div className="detailHeading pb20">Created on: <span className="fontWeightRegular">{moment(createdData).format('DD MMM, YYYY')}</span></div>
                    <div className="detailsTable">
                        <div className="statsContainer">
                            <div className='statsHeading'><span>CTR</span> <span className="bigDetailsHeading">{props.click_data.click_through_rate ? props.click_data.click_through_rate.toFixed(2) : 0}<span className='campaign-percentage'>%</span></span></div>
                            <div className='statsHeading'>Total clicks <span className="bigDetailsHeading">{props.click_data.total_clicks}</span></div>
                            <div className='statsHeading'>Unique clicks <span className="bigDetailsHeading">{props.click_data.unique_clicks}</span></div>
                        </div>
                        <div>
                            <div className="detailHeading">Campaigns using the URL</div>
                            <div>
                                {
                                    props.campaign_names.length > 0 ? 
                                        props.campaign_names.map((item, index) => 
                                            <div key={index} className='campaign-list'>{item}</div>
                                        )
                                    :
                                    "No campaigns associated with this URL."
                                }
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default DetailsContent;
