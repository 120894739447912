import React from "react";

export const SmartInsightStarIcon = (props) =>{

    return(
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1807_77286)">
            <path
                d="M6.84 15.2L4.7025 9.975L0 7.6L4.7025 5.225L6.84 0L8.9775 5.225L13.68 7.6L8.9775 9.975L6.84 15.2ZM14.58 18L13.5225 15.375L11.16 14.2L13.5225 13L14.58 10.4L15.66 13L18 14.2L15.66 15.375L14.58 18Z"
                fill="#444444"
            />
            </g>
            <defs>
            <clipPath id="clip0_1807_77286">
                <rect width={18} height={18} fill="white" />
            </clipPath>
            </defs>
        </svg>
    )
}