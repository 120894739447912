import * as React from "react"

const DialInIcon = (props) => (
  <svg
    width={73}
    height={72}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.071} width={72} height={72} rx={8} fill="#096DD9" />
    <g clipPath="url(#a)">
      <path
        d="M27.156 34.619A23.338 23.338 0 0 0 37.31 44.77l3.389-3.39a1.532 1.532 0 0 1 1.571-.369c1.726.57 3.59.878 5.5.878.847 0 1.54.693 1.54 1.54v5.377c0 .847-.693 1.54-1.54 1.54-14.466 0-26.19-11.723-26.19-26.189 0-.847.694-1.54 1.541-1.54h5.392c.847 0 1.54.693 1.54 1.54a17.5 17.5 0 0 0 .879 5.5c.17.54.046 1.14-.386 1.572l-3.389 3.389Zm20.86-10.938-1.094-1.079-9.937 9.706v-6.609h-1.54v9.243h9.243v-1.54h-6.394l9.721-9.721Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(18 18)" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default DialInIcon

