import React, {useEffect, useState} from 'react';
import CommonSideModal from '../common/SideModalWrapper'
import EditUTMParamsContent from './editUtmParamsContent'
import "../common/SideModalWrapper.scss";
import _ from 'lodash'
import UTMParameterIcon from '../../../../assets/svg/UTMParameterIcon';


function EditUTMParams(props){
    const { utmParamsObj, setUtmParams } = props;
    const[ utmFieldList, setUtmFieldList ] = useState([]); 
    const [errorUTMSourceStatus, setUTMSourceErrorStatus] = useState('')
    const [errorUTMMediumStatus, setUTMMediumErrorStatus] = useState('') 
    
    let utmParamsStr = "";
    const utmFields = [
        {
            name : "utm_source",
            desc : "*Campaign Source (required)",
            value : utmParamsObj.utm_source || "",
            placeholder : "eg: google, facebook, newsletter...",
        },
        {
            name : "utm_medium",
            desc : "*Campaign Medium (required)",
            value : utmParamsObj.utm_medium || "",
            placeholder : "eg: banner, email, sms...",
        },
        {
            name : "utm_campaign",
            desc : "Campaign Name",
            value : utmParamsObj.utm_campaign || "",
            placeholder : "eg: name, slogan, product...",
        },
        {
            name : "utm_term",
            desc : "Campaign Term",
            value : utmParamsObj.utm_term || "",
            placeholder : "eg: running+equipment...",
        },
        {
            name : "utm_content",
            desc : "Campaign Content",
            value : utmParamsObj.utm_content || "",
            placeholder : "eg: textlink, logolink...",
        },
    ];
    const createUTMFields = () => {
        setUtmFieldList(utmFields);
    }
    const setUtmParamsInLongUrl = (utm_name, value) => {
        let utmUpdateParam = utmFieldList;
        utmFieldList.map((utmName, index) => {
            if(utmName['name'] === utm_name){
                utmUpdateParam[index]['value'] = value;
                return;
            }
        })
        setUtmFieldList(utmUpdateParam);
        for(let i = 0; i < utmFieldList.length;i++){
            if(utmFieldList[i].value !== "" && utmFieldList[i].name === utm_name){
                utmParamsStr += ('&' + utmFieldList[i].name + "=" + utmFieldList[i].value)
                props.setUtmString(utmParamsStr);
                return;
            }

        }
    }

    const AddUtmParamsToURL = () => {
        let utmParams = "";
        let i = 0;
        for(let key in utmParamsObj){
            if(utmParamsObj[key]){
                if(i === 0){
                    utmParams = `${key}=${utmParamsObj[key]}`;
                }else{
                    utmParams += `&${key}=${utmParamsObj[key]}`;
                }
                i++;
            }
        }
        props.setUtmString(utmParamsObj);
    }

    const handleAddParams = () => {

        if(document.querySelector("#utm_source").value === '' || document.querySelector("#utm_medium").value === '') {

            if (document.querySelector("#utm_source").value === '') {
                setUTMSourceErrorStatus('utm_source is required')
            } 
            if (document.querySelector("#utm_medium").value === '') {
                setUTMMediumErrorStatus('utm_medium is required')
            } 
        }  else {
            setUTMSourceErrorStatus('')
            setUTMMediumErrorStatus('')
            AddUtmParamsToURL();
            props.closeEditUTMParamsModal();
        }
      
    }
    useEffect(() => {
        createUTMFields();
    }, [])
    return (
        <CommonSideModal
            noOverlay = {true}
            id="edit_UTM_params_modal"
            modalClass={`sideModal animate-right utm-modal ` + (props.isModalClosed ? "hide" : "show")}
            modalHeader={
                <div className="side-modal-header panel-modal-header-title panel-modal-header-title-padding">
                    {
                        <>
                            <div className="heading-icon"><UTMParameterIcon/></div>
                                <div className="heading-text">
                                    <h3>UTM Parameters</h3>
                                    <h5>See which social strategies are most effective and most cost-effective</h5>
                                </div>
                        </>
                    }
                </div>
            }
            modalBodyId="side-modal-body"
            activeModalScreen = {
                <EditUTMParamsContent 
                    longURL={props.longURL} 
                    setUtmParamsInLongUrl={setUtmParamsInLongUrl} 
                    utmFieldList={utmFieldList} 
                    utmTags={utmParamsObj}
                    setUTMTags={setUtmParams}
                    errorUTMSourceStatus={errorUTMSourceStatus}
                    setUTMSourceErrorStatus={setUTMSourceErrorStatus} 
                    errorUTMMediumStatus={errorUTMMediumStatus}
                    setUTMMediumErrorStatus={setUTMMediumErrorStatus}
                />
            }
            footerClassName={"panel-modal-footer m-0 p-0 side-modal-footer"}
            footerContent = {
                <>
                    <a className="btn btn-default mr-5 modal-button-padding common-modal-button-margin"
                    data-dismiss="modal" onClick={props.closeEditUTMParamsModal}>Cancel</a>
                    <a id="modal-discard-btn"
                    className={`btn common-modal-button-margin mr-20 w146 addParamBtn`} onClick={handleAddParams}>Add Parameters</a>
                    
                </>
            }
        />
    )
}

export default EditUTMParams;
