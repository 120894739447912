import React from 'react';
import '../../../screens/store/AddonCommonStyles.scss';
import DOMPurify from 'dompurify';

const RenderMarkup = (props) => {
    
    const {cssClass, __html} = props.data;

    return (
        <div className={cssClass} dangerouslySetInnerHTML={{__html : DOMPurify.sanitize(__html)}}></div>
    )

}

export default RenderMarkup;