import React from 'react';
import "./TrailGiftContainer.scss";
import { Form, Radio } from 'antd';
import {UserTypes} from "./KycConstants"
import "./UserTypeKYC.scss";
import ManagerIcon from '../../../../assets/svg/ManagerIcon';
import VolunteerIcon from '../../../../assets/svg/VolunteerIcon';

const UserType = ({handleContinue, getBrandName}) => {
    const {Item} = Form;
    let [form] = Form.useForm();
    return(
        <div className="first-slide">
            <div className="slide_one_heading">Welcome! Are you a campaign manager or agent? </div>
            <div className="mobile-slide_one_heading">
                <p className="heading">Welcome!<br/>Are you a campaign<br/>manager or agent?</p>
                <p className='sub-heading'>Managers create campaigns while volunteers/agents join campaigns to call or text</p>
            </div>
            <div className='user-type-form'>
                <Form
                    layout="vertical" 
                    className="user_type_form"
                    requiredMark="optional"
                    form={form}
                    name="kyc-form-1"
                    scrollToFirstError={true}
                >
                    <Item
                        required={true}
                        rules={[{required:true,message:"Select one option"}]}
                        name="UserType"
                    >
                        <Radio.Group className='kyc-user-type-list-radioGroup'>
                            <Radio.Button value={'Manager'}>
                                <div className="type_container manager">
                                    <ManagerIcon/>
                                    <div className="content">
                                        <p className='heading'>{UserTypes.manager.heading}</p>
                                        <p className='des'>{UserTypes.manager.description}</p>
                                    </div>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                            <Radio.Button  value={'Agent'} >
                                <div className="type_container">
                                    <VolunteerIcon/>
                                    <div className="content">
                                        <p className='heading'>{UserTypes.agent.heading}</p>
                                        <p className='des'>{UserTypes.agent.description}</p>
                                    </div>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                        </Radio.Group>

                    </Item>
                    <Item>
                        <button className='kyc-continue-button' type="submit" onClick={()=>{handleContinue(1 , form.getFieldValue(), form)}}>Next</button>
                    </Item>
                </Form>
            </div>
        </div>
    )
}

export default UserType;