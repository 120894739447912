import axios from 'axios';
import { apiGetCall, apiPostCall } from "./utilities";
import {earlyAccessRequestUrl} from "../components/common/GuidePageComponents/config";

export const getEarlyAccess = async(params)=>{
    try{
        const early_access_url = earlyAccessRequestUrl();
        const host = window.location.hostname;
        const response = await apiGetCall(`https://${host}/${early_access_url}`, '',params);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const postEarlyAccess = async(data)=>{
    try{
        const early_access_url = earlyAccessRequestUrl();
        const host = window.location.hostname;
        const response = await apiPostCall(`https://${host}/${early_access_url}`, data);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}