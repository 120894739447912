import React from 'react';

const TransferCreditsIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "16"}
      height={props.height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      {...props} // for additional props like className, style, etc.
    >
      <path
        d="M13.2489 9.25H2.37541C2.30666 9.25 2.25041 9.30625 2.25041 9.375V10.3125C2.25041 10.3812 2.30666 10.4375 2.37541 10.4375H11.8317L9.57698 13.2969C9.51291 13.3781 9.57073 13.5 9.67541 13.5H10.8082C10.8848 13.5 10.9567 13.4656 11.0051 13.4047L13.6426 10.0594C13.9004 9.73125 13.6676 9.25 13.2489 9.25ZM13.6254 5.5625H4.16916L6.42385 2.70312C6.48791 2.62188 6.4301 2.5 6.32541 2.5H5.1926C5.11604 2.5 5.04416 2.53438 4.99573 2.59531L2.35823 5.94063C2.10041 6.26875 2.33323 6.75 2.75041 6.75H13.6254C13.6942 6.75 13.7504 6.69375 13.7504 6.625V5.6875C13.7504 5.61875 13.6942 5.5625 13.6254 5.5625Z"
        fill={props.fill || "black"}
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default TransferCreditsIcon;
