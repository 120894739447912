import React, {useState, useEffect, useRef} from "react";
import "./WelcomeMessageStyles.scss";
import { ButtonAnt } from "../common/CustomAntDesignComponents/ButtonAnt/ButtonAnt";
import AudioFileContainer from "./InboundAudioFileContainer";
import { useRecoilState } from "recoil";
import { inboundCallingDataState, inboundTextErrorState, stepperCurrentState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";
import InboundTextContainer from "./InboundTextContainer";
import InboundTabsContainer from "./InboundTabsContainer";
import CheckBox from 'antd/lib/checkbox';
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntCheckbox.scss";

function WelcomeMessage({componentData, inboundTextError, setInboundTextError}) {
    const [current, setCurrent] = useRecoilState(stepperCurrentState);
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState);
    

    const handleInboundTextError = (value) => {
        setInboundTextError(value)
    }

    const onChangeHandler = (e) => {
        console.log('e.target.checked')
        setInboundCallingData({...inboundCallingData, "OutsideCampaignSubscriberAllowed" : e.target.checked})
        sessionStorage.removeItem('inboundCallingData')
    }

    const handleNext = () => {
        if(inboundCallingData.welcomeAudioSelected) {
            setCurrent(current + 1);
        } else if ((!inboundTextError) && (!inboundCallingData.welcomeAudioSelected)) {
            setCurrent(current + 1);
        }
      
        let segmentTrait = {}
        if(inboundCallingData.welcomeAudioSelected){
            segmentTrait['message_type'] = 'audio'
            segmentTrait['audio_type'] = 'custom'
            if(!inboundCallingData.welcomeAudioFileName || inboundCallingData.welcomeAudioFileName == 'sample-welcome-message-inbound-calling.mp3'){
                segmentTrait['audio_type'] = 'default'
            }  
        }
        else{
            segmentTrait['message_type'] = 'text-to-speech'
        }
        handleSegmentTrackEvent('cc-added-welcome-message-inbound-calling',segmentTrait)
    }
    

    
    return (
        <div id="welcome-message-container">
            <div>Greets the contact and let’s them know they are on hold and will be connected soon.</div>
            <InboundTabsContainer componentData={componentData} componentType={"welcomeMessage"} handleInboundTextError={handleInboundTextError}/>
            <CheckBox onChange={onChangeHandler} checked = {inboundCallingData.OutsideCampaignSubscriberAllowed} className = 'outside-campaign-subscriber-checkbox'>Allow contacts outside of this campaign to call back</CheckBox>
            <div className="outside-campaign-subscriber-info-text">If a contact not part of this campaign calls back to the Caller ID selected, route them to agents in this campaign</div>
            <ButtonAnt className="inbound-next-button" text="Next" type="primary" onClick={handleNext}/>
        </div>
    )
}

export default WelcomeMessage;