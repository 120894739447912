import React, { useEffect, useState } from "react";
import Pie from '@ant-design/plots/es/components/pie';
import { useRecoilValue } from "recoil";
import {CallSentimentDataAtom} from '../../../../RecoilSharedState/CcAiPowed/CcAiPowerdState';
import PieChartEmpty from '../../../../RelationalOrganisation/components/PieChart/PieChartEmpty';
import './OverAllSentiment.scss';

const OverAllSentimentChart = ()=>{
  const sentimentChartData = useRecoilValue(CallSentimentDataAtom);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const handleChartColor = ()=>{
    let color = [];

    if(sentimentChartData.length){
      for(let i= 0; i < sentimentChartData.length; i++){
        switch(sentimentChartData[i].type){
          case 'Negative':
            color.push('#FF7875');
            break;
          case 'Positive':
            color.push('#58D5A2');
            break;
          case 'Neutral':
            color.push('#FFC069');
            break;
          default:
            break;
        }
      }
    }
    return color;
  }
  
  const config = {
    height: 230,
    width: 230,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}%',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 12,
        },
        content: `Total calls\n${totalCount}`,
      },
    },
    color: handleChartColor(),
    legend: false,
    tooltip: {
      formatter: (datum) => {
        return {
          name: datum.type,
          value: `${datum.value}%`,
        };
      },
    },
  };

  useEffect(()=>{
    const totalValue = sentimentChartData.reduce((sum, item) => sum + item.value, 0);

    const updatedData = sentimentChartData.map(item => ({
      ...item,
      value: parseFloat(((item.value / totalValue) * 100).toFixed(1))
    }));
    setTotalCount(totalValue);
    setData(updatedData);
  },[sentimentChartData])

  return(
      <div className='sentiment-chart'>
          {data.length ? <Pie {...config} /> : <PieChartEmpty/>}
      </div>
  )
}

export default OverAllSentimentChart;