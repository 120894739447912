import React from "react";

export const benefits = [
    {
        id: 1,
        text: (amount) => (
            <>
                Pay-as-you-go campaigns with a one-time account setup fee of{" "}
                <span className="amount">{amount}</span>
            </>
        ),
    },
    { id: 2, text: () => "Unmatched data insights & analytics" },
    { id: 3, text: () => "Smoothly integrates with your CRM" },
    { id: 4, text: () => "Best-in-class customer support" },
];