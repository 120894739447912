export const MULTI_CHOICE = 1 
export const TEXT_SECTION = 2
export const DIGIT_SECTION = 3
export const NOTES_SECTION = 4
export const NB_EVENT = 5
export const DISPOSITION = 6
export const SUPPORTER = 7
export const AN_EVENT = 8
export const OSDI_EVENT = 9
export const VAN_QUESTION = 10
export const VAN_EVENT = 11
export const SCRIPT = 12
export const BLACKBAUD_EVENT = 13
export const SALESFORCE_FIELD = 14

export const SPECIFIC_SECTION = "-1";
export const END = "0";