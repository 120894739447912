import React from 'react';

import Plus from './../../../assets/svg/Plus';

function AddButton(props) {
    return(
        <div className={`add-button ${props.classStyle}`} onClick={ () => props.handleAction() }>
            <div>
                <Plus size={16} />
            </div>
            <div>
                {props.btnName}
            </div>
        </div>
    )
}

export default AddButton;