import React, { useEffect, useRef, useState } from 'react';
// import giftImage from "../../../../assets/images/gift-image.png";
import "./NewKycForm.scss";
import "./TrailGiftContainer.scss";
import { postCustomerKycDetails, postKycStatus } from '../../../utilities/subscriptionUtilities';
import {InterstedProducts} from "./InterstedProducts"
import {ChooseContactList} from "./ChooseContactList";
import {Congratulations} from './CongratulationsKyc';
import {OrganizationDetailKyc} from './OrganizationDetailKyc';
// imported antd styles
import "../AntDesignCustomStyles/AntForm.scss";
import "../AntDesignCustomStyles/AntInput.scss";
import "../AntDesignCustomStyles/AntCheckbox.scss";
import "../AntDesignCustomStyles/AntRadio.scss";
import "../AntDesignCustomStyles/AntMessage.scss";
import { CONTACT_LIST_RANGE, INTERESTED_PRODUCT, ORGANISATION_MAPPING, PREMIUM_CONTACT_LIST_STARTS_FROM } from './KycConstants';
import ExpertiseLevel from './ExpertiseLevel';
import UserType from './UserTypeKYC';
import { trackSegementEvents } from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';


export function NewKycForm(props) {
    let [allData , setAllData] = useState({});
    const [form, setForm] = useState({})
    
    let {
        handleShowNudgeOverNav,
        user_details,
        handleCustomerHasKyc,
        handleCustomerSkippedKyc,
        tokenForApi,
        customerKycInfo,
        firstName=null,
        country = 'US',
        isTrial,
        fullName='',
        organisation=-1,
        removeModalFormDOM,
        getBrandName,
        isBrandCallHub
    } = props;
    const {is_whitelabel} = user_details || {}
    let [slide, setSlide] = useState(is_whitelabel ? 2 : 1);
    let handleContinue = (slideNumber, formData, _form={}) => {
        console.log('formData', _form.getFieldsError(['fullname', 'industry', 'companyName']))
        let has_error = false
        _form.getFieldsError(['fullname', 'industry', 'companyName']).map((error) => {
            if(error.errors.length > 0) {
                has_error = true;
                return;
            }
        })
        if ((slideNumber === 1) && has_error ) return;
        let key_in_formData = Object.keys(formData)[0];
        if(key_in_formData){
            let nextSlide = slideNumber + 1
            if (key_in_formData == 'productsInterersted'){
                if(formData[key_in_formData].length >0){
                    setAllData({...allData , ...formData })
                    setSlide(nextSlide)
                }
            }else{
                setAllData({...allData , ...formData })
                if(nextSlide != 6){
                    setSlide(nextSlide)
                }
            }
        }
        setForm(_form)
    }

    const get_error = (error) => {
        const _errors = {}
        switch (error.error_code) {
            case 'KYC402':
            case 'KYC401':
                _errors.name = 'fullname'
                setSlide(4);
                break;
            case 'KYC403':
                _errors.name = ['industry']
                setSlide(4);
                break;

        }
        _errors.errors = [error.error_message]
        return _errors
    }

    let sendingData = async () => {
        let res = await postCustomerKycDetails(allData , tokenForApi)
        if(!res.error){
            const availableProductsMap = Object.values(INTERESTED_PRODUCT).reduce((acc, curr) => {
                acc[curr] = false;
                return acc;
            },{})
            const segment_traits = allData.productsInterersted.reduce((acc,curr) => {
                acc[INTERESTED_PRODUCT[curr]] = true;
                return acc;
            },availableProductsMap);
            try {
                if(isBrandCallHub){
                    const userTraits = {
                        contactList: CONTACT_LIST_RANGE[allData.contactListRange], 
                        organization: allData.companyName, 
                        industry: ORGANISATION_MAPPING[allData.industry],
                        fullName: allData.fullname || fullName,
                        expertiseLevel: allData.ExpertiseLevel,
                        leadType: allData.UserType,
                        ...segment_traits
                    }
                    if(window.dataLayer){
                        window.dataLayer.push({
                            event : "signup_kyc_submitted", 
                            ...userTraits
                        });
                    }
                    trackSegementEvents("signup_kyc_submitted", userTraits);
                }
                await handleKycSkip();
            }catch(error) {
                console.log('error', error)
            }
            
        }else{
            const errors = get_error(res.error)
            console.log('errors', errors)
            form.setFields([errors])
            console.log("here is error")
        }
    }

    let handleKycSkip = async() => {
        if(!customerKycInfo.customer_skipped_kyc){
            let kycStatusRes = await postKycStatus({"customer_skipped_kyc":true},tokenForApi)
        }
        handleCustomerHasKyc(true)
        handleCustomerSkippedKyc(true)
        handleShowNudgeOverNav(true)
    }
    useEffect(() => {
        if(removeModalFormDOM){
            removeModalFormDOM();
        }
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'scroll';
        };
    },[]);
    
    useEffect(() => {
        if(allData['ExpertiseLevel']){
            sendingData();
        }
    },[allData])
    return(
        <div className="newKycContainer">
            <div className="container_data">
            <p className="kyc-steps">
                Step {is_whitelabel ? slide - 1 : slide} of {is_whitelabel ? 4 : 5}
            </p>

                
                {!is_whitelabel && slide === 1 && <UserType handleContinue={handleContinue} />}
                {slide == 2 && 
                    <OrganizationDetailKyc 
                        handleContinue = {handleContinue}
                        firstName = {firstName}
                        user_details = {user_details}
                        country = {country}
                        organisation={organisation}
                        fullName={fullName}
                        getBrandName={getBrandName}
                        is_whitelabel={is_whitelabel}
                    >
                    </OrganizationDetailKyc>
                }
                {slide == 3 &&
                    <InterstedProducts handleContinue = {handleContinue} getBrandName={getBrandName} ></InterstedProducts>
                }

                {slide == 4 &&
                    <ChooseContactList handleContinue = {handleContinue} getBrandName={getBrandName} ></ChooseContactList>
                }
                {slide == 5 &&
                    <ExpertiseLevel handleContinue = {handleContinue} getBrandName={getBrandName} ></ExpertiseLevel>
                }
            </div>
        </div>
    )
}