import React, { useEffect, useState } from "react";
import MobileAppPromoImage from "../../../../assets/images/mobile-app-promo.png";
import CancelIcon from "../../../../assets/svg/CancelIcon";
import CalendlyTextPopUp from "../../../screens/react_library_for_jquery/common/CalendlyTextPopUp";
import { getMobileAppPromoBannerStatus, postMobileAppPromoBanerStatus } from "../../../utilities/mobileAppPromoUtilities";
import "./MobileAppPromoBanner.scss";

function MobileAppPromoBanner(props) {
    const [buttonDisable, setButtonDisable] = useState(false);
    const {apiToken, type} = props.componentData;
    const [promoStatus, setPromoStatus] = useState("UNINTERESTED");

    const getMobileAppPromoStatus = async () => {
        setButtonDisable(true);
        const response = await getMobileAppPromoBannerStatus(apiToken);
        setButtonDisable(false);
        setPromoStatus(response.mobile_promo_choice)
    }

    useEffect(() => {
        getMobileAppPromoStatus();
    }, [])

    const handleJoin = async (user_choice) => {
        const data = {mobile_promo_choice: user_choice}
        const response = await postMobileAppPromoBanerStatus(data, apiToken);
        getMobileAppPromoStatus();
    }
    return (
        (promoStatus === 'CLOSED' || promoStatus === 'UNINTERESTED') ? <div></div> : <div className={type == "new_call_center_campaign" ? "mobile-app-banner-container" : "mobile-app-promo-banner-container"}>
            <div>
                <img src={MobileAppPromoImage} width="100%"/>
            </div>
            <div className={type == "new_call_center_campaign" ? "mobile-app-banner-heading-wrapper" : "mobile-app-promo-banner-heading-wrapper"}>
                { promoStatus === "NOT_SEEN_YET" ? <> <div>
                    We invite you to join CallHub mobile app
                </div>
                <div><button className={buttonDisable ? "mobile-app-banner-join-waitlist-button mobile-app-disable-opacity" : "mobile-app-banner-join-waitlist-button"} onClick={() => handleJoin("INTERESTED")} id="join-waitlist" disabled={buttonDisable} >Join waitlist</button>
                <div className="mobile-app-banner-8-top-margin"><a  className= {type === "new_call_center_campaign" ? "mobile-app-banner-a-link mobile-app-banner-mg-left-5px" : "mobile-app-banner-a-link"}  onClick={() => handleJoin("UNINTERESTED")} id="not-interested">Not interested</a></div></div> </> : promoStatus === "INTERESTED" ? <div id={type == "new_call_center_campaign" ? "mobile-app-banner-heading" : "mobile-app-promo-baner-heading"}>We’ve received your response to join CallHub mobile app</div> : null}
            </div>
            { promoStatus === "NOT_SEEN_YET" ? type == "new_call_center_campaign" ? <div className="mobile-app-banner-info-container">
                <div>CallHub mobile app is releasing on February 2022 and we’re thrilled to invite you to be in the closed list of users who get to access it first. (Invite closes on 10 Feb)</div>
                <div>The calling app has been reimagined for call center agents so you can cut down operational costs, and training & retention effort.</div>
                <div id="request-app-demo"><span>For a detailed app walkthrough, </span> <CalendlyTextPopUp url="https://calendly.com/discuss-with-product/mobile-app-demo" text="request a demo" className="mobile-app-banner-a-link mobile-app-promo-calendly-button"/> <span style={{marginLeft: "96px"}}>or you can <a className="mobile-app-banner-a-link" id="learn-more-about-app" href="https://callhub.io/ios-android-app/" target="_blank">learn more here.</a></span> </div>
            </div> : <div className="mobile-app-promo-banner-info-container">
                <div>CallHub mobile app is releasing on February 2022! We invite you to apply (Invite closes on 10 Feb). The calling app is reimagined for call center agents so you cut down operational costs, and training & retention effort. </div>
                <div id="request-app-demo"><span>For a detailed app walkthrough, </span> <CalendlyTextPopUp url="https://calendly.com/discuss-with-product/mobile-app-demo" text="request a demo" className="mobile-app-banner-a-link mobile-app-promo-calendly-button"/> <span style={{marginLeft: "96px"}}>or you can visit our <a className="mobile-app-banner-a-link" id="learn-more-about-app" href="https://callhub.io/ios-android-app/" target="_blank">Callhub app page.</a></span></div>
            </div> : promoStatus === "INTERESTED" ? type == "new_call_center_campaign" ? <>
            <div className="mobile-app-banner-info-wrapper">
                <div>Thanks for taking time to join our waitlist.</div>
                <div>Places are limited. If you’re selected, we’ll send you an email and our team will reach out for your onboarding.</div>
                <div id="request-app-demo"><span>For a detailed app walkthrough, </span> <CalendlyTextPopUp url="https://calendly.com/discuss-with-product/mobile-app-demo" text="request a demo" className="mobile-app-banner-a-link mobile-app-promo-calendly-button"/> <span style={{marginLeft: "96px"}}> or you can visit our <a className="mobile-app-banner-a-link" id="learn-more-about-app" href="https://callhub.io/ios-android-app/" target="_blank">learn more here.</a></span></div>
            </div>
            <div className="mobile-app-banner-close-icon-wrapper" onClick={() => handleJoin("CLOSED")} id="banner-closed">
                <CancelIcon />
            </div>
             </> : <>
                <div className="mobile-app-promo-banner-info-wrapper">
                    <div>Thanks for taking time to join our waitlist. Places are limited. If you are selected, we’ll send you an email and CallHub team will contact you.</div>
                    <div id="request-app-demo"><span>For a detailed app walkthrough, </span> <CalendlyTextPopUp url="https://calendly.com/discuss-with-product/mobile-app-demo" text="request a demo" className="mobile-app-banner-a-link mobile-app-promo-calendly-button"/> <span style={{marginLeft: "96px"}}> or you can visit our <a className="mobile-app-banner-a-link" id="learn-more-about-app" href="https://callhub.io/ios-android-app/" target="_blank">Callhub app page.</a></span></div>
                </div>
                <div className="mobile-app-promo-banner-close-icon-wrapper" onClick={() => handleJoin("CLOSED")} id="banner-closed">
                    <CancelIcon />
                </div>
              </> : null}
        </div>
    )
}

export default MobileAppPromoBanner;