import React, { useEffect, useRef, useState } from 'react';
import "./NewKycForm.scss";
import "./TrailGiftContainer.scss";
import { isEmpty } from 'lodash';

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import { Select, Checkbox } from 'antd'
// imported antd styles
import "../AntDesignCustomStyles/AntForm.scss";
import "../AntDesignCustomStyles/AntInput.scss";
import "../AntDesignCustomStyles/AntCheckbox.scss";
import "../AntDesignCustomStyles/AntRadio.scss";
import "../AntDesignCustomStyles/AntMessage.scss";

export function OrganizationDetailKyc(props){

  const { Option } = Select;

    const [form] = Form.useForm();
    const [showIndustryConsent, setShowIndustryConsent] = useState(false);
    const [disableButton , setDisableButton] = useState(false);
    const [isConsentChecked, setIsConsentChecked] = useState(false);
    const { is_whitelabel } = props;
    const industryConsentChange = (e)=>{
      const checked = e.target.checked;
      setDisableButton(!checked);
      setIsConsentChecked(checked);
    }

    const handleIndustryChange = (value)=>{
      if( props.country == 'US'){
        const shouldShowIndustryConsent = value === "1" || value === "18" || value === "19" || value==="2";
        setShowIndustryConsent(shouldShowIndustryConsent);
        setIsConsentChecked(shouldShowIndustryConsent && isConsentChecked);
        setDisableButton(shouldShowIndustryConsent && !isConsentChecked);
      }
    }

    console.log(form.getFieldError('fullname'))
    return(
        <div className="first_slide">
            <div className="welcome_note">Tell us about yourself...</div>
            <div className='mobile-welcome-note'>
              <p className='heading'>Tell us about yourself...</p>
              <p className='sub-heading'>Ready to campaign? Help us set up your account </p>
            </div>
            <div className='form-container'>
                <Form
                    layout="vertical" 
                    requiredMark="optional"
                    form={form}
                    name="kyc-form-1"
                    scrollToFirstError={true}
                    autoComplete="off"
                    onFinish={() => props.handleContinue(2 , form.getFieldValue(), form)}
                >
                  {(isEmpty(props.fullName.trim()) || !(props.fullName.trim()) || props.fullName === '') &&
                    <Form.Item
                      name = 'fullname'
                      label = "Your name"
                      required={true}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if(!value?.trim()){
                              throw new Error('Please provide your name');
                            }
                            if (value?.trim() && value?.trim().includes(".")) {
                              throw new Error('Please remove full stop symbol (.) from name')
                            }
                          }
                        },
                      ]}
                      className="flex-flow-column"
                    >
                      <div className='kyc-name-input-container'>
                        <Input
                            placeholder="Enter your name"
                            maxLength={100}
                            className="kyc-input kyc-name-input"
                        />
                      </div>
                    </Form.Item>
                  }


                  <div className='industry-layout'>
                    <Form.Item
                        name = 'companyName'
                        label = "Your organization's name"
                        required={true}
                        rules={[
                          {
                            validator: async (_, value) => {
                              if(!value?.trim()){
                                throw new Error('Please provide an organization name')
                              }
                            }
                          }
                        ]}
                        className="flex-flow-column"
                    >
                        <Input
                            placeholder="Enter your organization name"
                            maxLength={89}
                            className="kyc-input kyc-organisation-input"
                        />
                    </Form.Item>
                    {(props.organisation === -1) && 
                    <div id='industry-select-container'>
                      <Form.Item
                        name = 'industry'
                        label = "Industry"
                        required={true}
                        rules={[{required: true, message:"Please select industry"}]}
                        className="flex-flow-column industry-select"
                      >
                        <Select
                          // showSearch
                          style={{ width: 200 }}
                          placeholder="Select Industry"
                          optionFilterProp="children"
                          onChange={handleIndustryChange}
                          // onFocus={onFocus}
                          // onBlur={onBlur}
                          // onSearch={onSearch}
                          dropdownClassName='kyc-industry-dropdown'
                          getPopupContainer = {() => document.getElementById('industry-select-container')}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {!is_whitelabel && <Option value="1">Political</Option>}
                          {
                            props.country === 'US' ?
                                <>
                                  {!is_whitelabel &&<Option value="18">Advocacy - 501 (c)(3)</Option>}
                                  {!is_whitelabel &&<Option value="19">Advocacy - 501 (c)(4)</Option>}
                                  <Option value="16">Non Profit - 501 (c)(3)</Option>
                                  <Option value="17">Non Profit - 501 (c)(4)</Option>
                                </>
                              :
                                <>
                                   {!is_whitelabel && <Option value="2">Advocacy</Option>}
                                   <Option value="3">Non Profit</Option>
                                </>

                          }
                          <Option value="6">Union</Option>
                          <Option value="7">Education</Option>
                          <Option value="8">Financial Services</Option>
                          <Option value="9">Real Estate</Option>
                          <Option value="10">Ecommerce</Option>
                          <Option value="11">Healthcare</Option>
                          <Option value="12">Energy/Utilities</Option>
                          <Option value="13">Market Research</Option>
                          <Option value="14">Telecom</Option>
                          <Option value="15">Services</Option>
                          <Option value="20">Debt Collection</Option>
                          <Option value="5">Other</Option>
                        </Select>
                      </Form.Item>
                      </div>
                    }
                  </div>
                    {showIndustryConsent && 
                      <div className='industry-consent-section'>
                        <Checkbox className="industry-consent-checkbox" checked={isConsentChecked} onChange={industryConsentChange}></Checkbox>
                        <p className='industry-consent-text'>I understand that {props.getBrandName} exclusively onboards Progressive and Democratic clients in the US and is unable to engage with clients of other political affiliations due to potential conflicts of interest.</p>
                      </div>
                    }
                    <Form.Item>
                        <button className= {`kyc-continue-button ${disableButton ? 'disable-continue-button' : ''}`} type="submit">Next</button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}