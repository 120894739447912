import * as React from "react"

const NotificationCloseIcon = (props) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.04 7 13.314.712A.16.16 0 0 0 13.19.45h-1.603a.327.327 0 0 0-.247.115L6.991 5.75 2.643.565A.321.321 0 0 0 2.395.45H.792A.16.16 0 0 0 .67.713L5.943 7 .67 13.285a.16.16 0 0 0 .122.263h1.603a.327.327 0 0 0 .247-.114l4.35-5.185 4.349 5.185c.06.072.15.114.247.114h1.603a.16.16 0 0 0 .123-.263L8.04 7Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
)

export default NotificationCloseIcon
