import { Select } from "antd";
import React from "react";
import "./AreaCodeSelection.scss";

const AreaCodeSelection = ({
  id,  
  options,
  handleAreaSelection,
  value,
  showError
}) => {

  const chooseAreaCode = (value) => {
      handleAreaSelection(id, value);
  }

  return (
    <div className={`area-container`}>
      <Select 
        options={options} 
        value={value} 
        onSelect={chooseAreaCode} 
        style={{width: "100%"}} 
        status={showError ? "error" : ''}
        placeholder="Area code"
        className="area-code-select"
      />
      {showError && <p className="error-text">Please select area code</p>}
    </div>
  );
};

export default AreaCodeSelection;
