import React from 'react'

function UTMParameterIcon(props) {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 22 14"
            width={20}
            height={18}
            {...props}
        >
            <path
            vectorEffect="non-scaling-stroke"
            d="M15 1l2.29 2.29-4.88 4.88-4-4L1 11.59 2.41 13l6-6 4 4 6.3-6.29L21 7V1z"
            fill="#3690E6"
            fillRule="nonzero"
            />
      </svg>
    )
}

export default UTMParameterIcon