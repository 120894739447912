import React from 'react'

const ShortenURLIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    version="1.2" 
    overflow="visible" 
    preserveAspectRatio="none" 
    viewBox="0 0 24 24" 
    width="24" 
    height="24">
      <g>
        <path 
          className="short-url-icon"
          xmlns default="http://www.w3.org/2000/svg" 
          id="link" 
          d="M18.5,16.01c0,0.26-0.11,0.51-0.29,0.7l-1.53,1.52c-0.19,0.18-0.45,0.28-0.71,0.27c-0.26,0-0.52-0.1-0.71-0.28  l-2.14-2.16c-0.19-0.19-0.29-0.44-0.29-0.71c0-0.29,0.12-0.57,0.34-0.76l0.19,0.2l0.22,0.22l0.2,0.16c0.08,0.06,0.17,0.11,0.27,0.14  c0.1,0.01,0.19,0.01,0.29,0c0.55,0,1-0.45,1-1c0.01-0.1,0.01-0.19,0-0.29c-0.03-0.1-0.08-0.19-0.14-0.27L15,13.59l-0.22-0.22  l-0.2-0.19c0.19-0.21,0.46-0.33,0.75-0.33c0.27,0,0.52,0.1,0.71,0.29l2.17,2.17c0.19,0.19,0.29,0.44,0.29,0.71l0,0V16.01z   M11.18,8.67c0,0.29-0.12,0.57-0.34,0.76l-0.19-0.2l-0.23-0.22l-0.2-0.16C10.15,8.79,10.08,8.74,10,8.71c-0.1-0.01-0.19-0.01-0.29,0  c-0.55,0-1,0.45-1,1C8.7,9.8,8.7,9.9,8.71,10c0.03,0.1,0.08,0.19,0.14,0.27L9,10.43l0.22,0.22l0.2,0.19  c-0.19,0.21-0.47,0.32-0.75,0.32c-0.25,0-0.49-0.1-0.67-0.27L5.79,8.72C5.6,8.53,5.5,8.27,5.5,8.01c0-0.26,0.11-0.51,0.29-0.7  l1.54-1.52C7.51,5.61,7.75,5.51,8,5.51c0.27,0,0.52,0.1,0.71,0.29l2.14,2.16c0.19,0.19,0.29,0.44,0.29,0.71l0,0H11.18z M20.5,16.01  c0.01-0.8-0.3-1.56-0.87-2.12l-2.17-2.17c-1.16-1.18-3.06-1.21-4.24-0.05c-0.02,0.02-0.03,0.03-0.05,0.05l-0.92-0.92  c0.6-0.57,0.93-1.36,0.92-2.18c0.02-0.79-0.29-1.56-0.86-2.11L10.16,4.4C9.6,3.81,8.81,3.48,8,3.51C7.21,3.49,6.44,3.8,5.89,4.37  L4.39,5.9C3.81,6.44,3.49,7.21,3.5,8.01c-0.01,0.8,0.3,1.56,0.87,2.12l2.17,2.17c0.56,0.57,1.32,0.88,2.12,0.87  c0.82,0.01,1.61-0.32,2.17-0.92l0.92,0.92c-0.6,0.57-0.93,1.36-0.92,2.18c-0.02,0.79,0.29,1.56,0.86,2.11l2.14,2.16  c1.17,1.17,3.07,1.17,4.24,0l1.53-1.52C20.18,17.56,20.5,16.8,20.5,16.01L20.5,16.01z" 
          fill= 'rgb(153, 153, 153)'
          vectorEffect="non-scaling-stroke"/>
      </g>
    </svg>
)

export default ShortenURLIcon;