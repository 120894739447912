import React,{useEffect, useState, useRef} from 'react';
import "./BranchingStyles.scss";
import { BranchingScriptQuestion } from './BranchingScriptQuestion';
import { EmptyPreview } from './EmptyPreview';

export const BranchingScript = (props)=>{
    const [dataToPreview,setDataToPreview] = useState(null);
    const [questionsContainer,setQuestionsContainer] = useState(null);
    const [questionToDisplay,setQuestionToDisplay] = useState(null);
    const [selected,setSelected] = useState({});
    const scrollRef = useRef(null);

    useEffect(()=>{
        SetIntialData();
    },[]);
    
    useEffect(()=>{
        if(dataToPreview && questionsContainer){
            setQuestionToDisplay([questionsContainer[0]]);
        }
    },[questionsContainer]);
    
    useEffect(() => {
        trackClicks();           
        return ()=>{
            document.getElementById("builderTab").removeEventListener("click",builderEventController);
        }
    },[]);


    const ModifyContainer = (currentId, nextId)=>{
        let index;
        questionToDisplay.map((e,i)=>{
            if(e.id === currentId){
                index = i;
            }
        });
        let aftermodify = questionToDisplay.slice(0, index+1);
        setQuestionToDisplay(aftermodify);
        setTimeout(()=>{
            if(nextId != "0" && nextId){
                DisplayNext(nextId,aftermodify);
            }
        },50)
    }    

    const DisplayNext = (id,aftermodify=null)=>{
        if(aftermodify){
            setQuestionToDisplay([...aftermodify,dataToPreview[id]]);
        }
        else{
            setQuestionToDisplay([...questionToDisplay,dataToPreview[id]]);  
        }
        setTimeout(()=>{
            scrollRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              });
        },50);
    }

    const SetIntialData = ()=>{
        let data;
        if(props.componentData.campaign_id){
            data = JSON.parse(localStorage.getItem(props.componentData.campaign_id));
        }
        else if(props.componentData.script_graph && Object.keys(props.componentData.script_graph).length > 0){
            data = props.componentData.script_graph;
        }else{
            data = JSON.parse(localStorage.getItem('scriptQuestions'));
        }
        setDataToPreview(data);
        let dataAfterSorted = [];
        if(data){
            for(let key in data){
                dataAfterSorted.push(data[key]);
            }
            dataAfterSorted.sort((a,b)=>a.order_number.toString().localeCompare(b.order_number.toString()));
        }
        setQuestionsContainer(dataAfterSorted);
    }

    const trackClicks = ()=>{
        if(!window.location.href.split('/').includes('settings')){
            document.getElementById("builderTab").addEventListener("click",builderEventController)
        }
    }

    const builderEventController = ()=>{
        props.unMountTheComponent(false);
    };

    const storeSelected = (QuestionId, choiceId) => {
        setSelected({...selected,[QuestionId] : choiceId});
    }

    return (
        <>
            {questionToDisplay ? questionToDisplay.length>0 && questionToDisplay.map((question)=>{
                return (<div key={question.id} className="SingleQuestion" ref={scrollRef}>
                    <BranchingScriptQuestion DisplayNext={DisplayNext} question={question} ModifyContainer={ModifyContainer} dataToPreview={dataToPreview} storeSelected={storeSelected} selected={selected} has_question_name_permission={props.componentData.has_question_name_permission} />
                </div>)
            }) : <EmptyPreview/>}
        </>
    )
}