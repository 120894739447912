import React from 'react';
import {Emoji} from "./Emoji"

export const ProgressbarSlider = ({status,color})=>{
    let completedDivs = Math.floor(status/25);
    let extraPortionDiv = status%25;
    return(
        <div className="progressbarSlider">
            <div className='progressslider'>
                <div><div style={{background:color,width : completedDivs >= 1 ? "100%" : extraPortionDiv*4+"%"}}>{completedDivs < 1 ? <span><span style={{background: color }}></span><span><Emoji symbol={'0x1F61E'}/><br/>{status}%</span></span> : null}</div></div>
                <div><div style={{background:color,width : completedDivs >= 2 ? "100%" : completedDivs >= 1 ? extraPortionDiv*4+"%" : 0}}>{(completedDivs >= 1 && completedDivs < 2) ? <span><span style={{background: color }}></span><span><Emoji symbol={'0x1F641'}/><br/>{status}%</span></span> : null}</div></div>
                <div><div style={{background:color,width : completedDivs >= 3 ? "100%" : completedDivs >= 2 ? extraPortionDiv*4+"%" : 0}}>{(completedDivs >= 2 && completedDivs < 3) ? <span><span style={{background: color }}></span><span><Emoji symbol={'0x1F60A'}/><br/>{status}%</span></span> : null}</div></div>
                <div><div style={{background:color,width : completedDivs == 4 ? "100%" : completedDivs >= 3 ? extraPortionDiv*4+"%" : 0}}>{completedDivs >= 3 ? <span><span style={{background: color }}></span><span><Emoji symbol={'0x1F604'}/><br/>{status}%</span></span> : null}</div></div>
            </div>
            <div className='progress-number-labels'>
                <p className="numberlabel">0</p>
                <p className="numberlabel">25</p>
                <p className="numberlabel">50</p>
                <p className="numberlabel">75</p>
                <p className="numberlabel">100</p>
            </div>
            <div className='progress-labels'>
                <p>Very low</p>
                <p>Low</p>
                <p>Good</p>
                <p>Great</p>
            </div>
        </div>
    )
    
}