import React from 'react'

const AgentProfanitySheildIcon = props => (
    <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#EBEBEB" />
    <path
      d="M22.9316 9.31836L16.2949 7.05664C16.2148 7.0293 16.1074 7.01562 16 7.01562C15.8926 7.01562 15.7852 7.0293 15.7051 7.05664L9.06836 9.31836C8.90625 9.37305 8.77344 9.56055 8.77344 9.73242V19.1543C8.77344 19.3262 8.88477 19.5527 9.01953 19.6602L15.752 24.9062C15.8203 24.959 15.9082 24.9863 15.998 24.9863C16.0879 24.9863 16.1777 24.959 16.2441 24.9062L22.9766 19.6602C23.1113 19.5547 23.2227 19.3281 23.2227 19.1543V9.73242C23.2266 9.56055 23.0938 9.375 22.9316 9.31836ZM19.5645 12.6543L15.4121 18.3711C15.3829 18.411 15.3447 18.4436 15.3006 18.466C15.2565 18.4884 15.2077 18.5 15.1582 18.5C15.1087 18.5 15.0599 18.4884 15.0158 18.466C14.9717 18.4436 14.9335 18.411 14.9043 18.3711L12.4355 14.9727C12.3613 14.8691 12.4355 14.7246 12.5625 14.7246H13.6406C13.7402 14.7246 13.8359 14.7734 13.8945 14.8535L15.1582 16.5918L18.1055 12.5332C18.1641 12.4531 18.2578 12.4043 18.3594 12.4043H19.4375C19.5645 12.4062 19.6387 12.5508 19.5645 12.6543Z"
      fill="#3690E6"
    />
  </svg>
)

export default AgentProfanitySheildIcon;