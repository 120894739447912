import React, {useEffect, useCallback, useState} from "react";
import "./NudgeOverNav.scss";
import _ from 'lodash';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import CalendlyTextPopUp from "../common/CalendlyTextPopUp";
import { QuestionCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";

const screenSize = {
    xxl: 1072,
    xl: 998,
    l: 826,
    md: 795,
    sm: 576,
    xs: 564,
    xxs: 374
}

function NudgeOverNav(props) {
    const {
        setShowContent,
        showContent,
        setShowAllPlansModal,
        setUpgradeButtonClicked,
        nudgeDetails,
        productTrainingDate,
        productTrainingTime,
        localTimeZone,
        isEventTimePassed,
        isError,
        isDataloaded,
        isRenderingOnReact,
        isTrial,
        handleCustomerSkippedKyc,
        customerHasKyc,
        customerSkippedKyc,
        country
    } = props;

    
    const openShowAllPlansModal = () => {
        
        setUpgradeButtonClicked(true)
        setShowAllPlansModal(true)
    }

    const handleSeeFeature = () => {
        setShowContent(prevState => true)
    }

    const addMarginForLeftNavAndMain = (options, timeout) => {
        setTimeout(() => {
            if(document.getElementById("left-panel")){
                document.getElementById("left-panel").style.top = options.leftPanelTop;
            }
            if(document.querySelector(".main-container-section")){
                document.querySelector(".main-container-section").style.marginTop = options.mainTopMargin;
            }
        }, timeout);
    }

    const checkScreenWidth = (timeout) => {
        const screenWidth = window.innerWidth;
        let navNudgeHeight = document.querySelector(".trial-nudge-container")?.offsetHeight
        if(screenWidth > screenSize.xxl){    
            if(navNudgeHeight>65 ){
                addMarginForLeftNavAndMain({leftPanelTop: "71px", mainTopMargin: "71px"}, timeout)
            }
            else if (navNudgeHeight>30){
                addMarginForLeftNavAndMain({leftPanelTop: "40px", mainTopMargin: "40px"}, timeout) 
            }
        }
        else if (screenWidth > screenSize.xl)
            addMarginForLeftNavAndMain({leftPanelTop: "100px", mainTopMargin: "100px"}, timeout);
        else if (screenWidth > screenSize.l)
            addMarginForLeftNavAndMain({leftPanelTop: "50px", mainTopMargin: "50px"}, timeout);
        else if (screenWidth > screenSize.md)
            addMarginForLeftNavAndMain({leftPanelTop: "60px", mainTopMargin: "100px"}, timeout);
        else if (screenWidth > screenSize.sm)
            addMarginForLeftNavAndMain({leftPanelTop: "90px", mainTopMargin: "100px"}, timeout);
        else if (screenWidth > screenSize.xs)
            addMarginForLeftNavAndMain({leftPanelTop: "98px", mainTopMargin: "100px"}, timeout);
        else {
            if(navNudgeHeight>100 ){
                addMarginForLeftNavAndMain({leftPanelTop: "101px", mainTopMargin: "101px"}, timeout)
            }
            else if (navNudgeHeight>80){
                addMarginForLeftNavAndMain({leftPanelTop: "81px", mainTopMargin: "81px"}, timeout) 
            }
        }
    }

    const delayedScreenCheck = useCallback(_.debounce(() => checkScreenWidth(200), 500), []);

    useEffect(() => {
        window.addEventListener('resize', delayedScreenCheck);
            
        checkScreenWidth(1000);
        setTimeout(() => checkScreenWidth(1000), 2000);
        return () => {
            window.removeEventListener('resize', delayedScreenCheck);
        }
    }, []);

    useEffect(()=>{
        if(document.getElementById("container") && document.getElementById("container") !== null){
            if(customerHasKyc === false){
                document.getElementById("container").style.height = "calc(100vh - 41px)";
            }
            else{
                document.getElementById("container").style.height = "calc(100vh - 41px)";
            }
        }
    },[customerHasKyc])
    let KycAllowedCountries = {
        "US":1,
        "GB":2,
        "AU":3,
        "CA":4,
        "FR":5,
    }
   
    const showNudgeContent = () => {
        if(isTrial && location.pathname == '/guide/'){
            return <div className="nudge-container">
                <div className="nav-nudge-body">
                    <div className="font-14"> 
                        <QuestionCircleFilled className="NudgeQuestionCircleFilled"  /> {nudgeDetails.daysLeft} days left in your {nudgeDetails.currentPlanName} plan trial. {" "}
                        <span onClick={openShowAllPlansModal} className="color-sky text-underline cursor-pointer"  >Upgrade now</span>. {" "}
                        {!(productTrainingDate && productTrainingTime && localTimeZone) && (!isError) && <span> Stuck somewhere?
                        <CalendlyTextPopUp url="https://calendly.com/d/2t8-fpb-ymb" className="nudge-book-event-button" text=" Book free 30-min training "/></span>}
                        { (productTrainingDate && productTrainingTime && localTimeZone) && (!isEventTimePassed) && <span className="nudge-event-time">{`Your free training is scheduled for ${productTrainingDate} at ${productTrainingTime} ${localTimeZone}`}</span>}
                    </div>
                </div>
            </div>
        }
        return <></>
    }
    
    return ( isDataloaded && 
            <div>
                {showNudgeContent()}
            </div>
    )
}

export default NudgeOverNav;