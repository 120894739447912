
import { NumberRotationUrl } from "../configuration/NumberRotationConfig";
import { apiPostCall, apiGetCall } from "./utilities";
import React from "react";

export const createNewProactiveBlock = async (data) => {
    try{
        const url = NumberRotationUrl();
        const response = await apiPostCall(url, data);
        return response;
    }
    catch(error){
        throw error;
    }
}

export const getAvailableProactiveBlocks = async(block_id) => {
    try{
        const url = NumberRotationUrl(block_id);
        const response = await apiGetCall(url);
        return response;
    }
    catch(error){
        throw error;
    }
}

export const getSessionKey = (campaign_id) => {
    return `rotationBlockSelected${campaign_id ? '-'+campaign_id : ''}`;
}

export const proactivePopoverContent = (showHelpLinks) => {
    return (
      <div>
        <p>Replace numbers even before they are flagged as Spam/Spam Likely. </p>  
        <p>Note: This will exponentially increase the total numbers rented for your account. Please remember to unrent unused numbers.</p>  
        {showHelpLinks && (<p>Learn how proactive swapping with Spam Label Shield works <a href="https://support.callhub.io/hc/en-us/articles/36173224786329-Proactive-Number-Rotation-for-Spam-Shield">here</a>.</p>)}
      </div>
    )
  }