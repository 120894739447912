import React, { useEffect, useState } from 'react';
import Transfer from 'antd/es/transfer';
import './SingleTransfer.scss';
import Spin from 'antd/es/spin';
import Empty from 'antd/es/empty';

const SingleTransfer = (props) => {
  const [selectedData, setSelectedData] = useState([]);

  const renderFooter = () => {
    return (
      <button className='ch-transfer-footer' onClick={props.onClickHandler}>Apply</button>
    );
  }

  const renderEmpty = () => {
    return (
      props.loading ? <Spin size={'small'}/> : <Empty />
    )
  }

  const caseInsensitiveFilter =(inputValue, option)=> {
    return option.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  }
  
  useEffect(()=>{
    setTimeout(() => {
      setSelectedData(props.selectedValues)
    });
    
  },[props.data, props.selectedValues])

  return (
    <div className='ch-single-transfer'>
      <Transfer
        dataSource={props.data}
        selectedKeys={selectedData}
        showSearch
        targetKeys={[]}
        onSearch={props.handleSearch}
        showSelectAll
        selectAllLabels={[() => <p className='ch-all-option-selected'> All</p>]}
        render={(item) => item.title}
        onSelectChange={props.handleSelectChange}
        onScroll={props.handleScroll}
        footer={props.removeFooter ? false : renderFooter}
        locale={{ notFoundContent: renderEmpty() }}
        filterOption={caseInsensitiveFilter}
    />
    </div>

  )
}

export default SingleTransfer