import React, { useEffect, useState } from 'react';
import ModalV2 from "../common/ModalV2";
import AllSubscriptionPlans from "../common/AllSubscriptionPlans";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import Loading from "../../../../assets/svg/Loading";
import { getSubscriptionPlans, getMinCallAndTextCost, manipulatePlansData, getAllPlansButtonStates, getBillingRankings, getBillingVerificationStatus } from "../../../utilities/subscriptionUtilities";
import "./PostTrialAllPlansContainer.scss";
import { ExperimentContext } from '../../../context/subscriptionContext';
import { OfferTag } from '../common/OfferTag';

function PostTrialAllPlansContainer(props) {

    const {
        handleClose,
        modalHeading,
        showCloseButton,
        currentPlanBillingPeriod,
        currentPlanData,
        trialData,
        handleDowngrade,
        upgradeButtonClicked,
        apiToken,
        daysLeft
    } = props;

    const [planTabData, setPlanTabData] = useState([]);
    const [planCardData, setPlanCardData] = useState([]);
    const [buttonStates, setButtonStates] = useState([]);
    const [billingRankings, setbillingRankings] = useState([]);
    const [minTextCallCost, setMinTextCallCost] = useState({})
    const [loading, setLoading] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [experiment, setExperiment] = useState(null)
    const [offerTagEligibility,setOfferTagEligibility] = useState(false)
    const [offerTagTitle,setOfferTagTitle] = useState("")
    useEffect(() => {
        getAllPlansData();
    }, [])

    const getAllPlansData = async () => {
        try {
            // get Api
            let apiToken;
            if(props.componentData && props.componentData.token) {
                apiToken = props.componentData.token;
            }else {
                apiToken = token
            }

            const [subscriptionsPlansRes, minCallAndTextCostRes] = await Promise.all([getSubscriptionPlans(apiToken), getMinCallAndTextCost(apiToken)]);

            let planData = subscriptionsPlansRes;
            let minTextAndCallCost = minCallAndTextCostRes;

            let {plans, savings, experiment,referral } = planData;
            if(referral){
                setOfferTagEligibility(referral.eligible)
                setOfferTagTitle(referral.comment)
            }
            setExperiment(experiment)

            // data manipulator
            let plansData = manipulatePlansData(plans);
            
            // setting all plans button states
            let allPlansButtonStates = getAllPlansButtonStates(currentPlanData, planData.plans);
            
            // getting billings rankings
            let billingRankings = getBillingRankings(savings); // ["monthly", "quarterly", "half yearly", "yearly"];
            
            setMinTextCallCost(minTextAndCallCost);
            setbillingRankings(billingRankings);
            setPlanTabData(savings);
            setPlanCardData(plansData);
            setButtonStates(allPlansButtonStates);
            setLoading(false);

            const billingVerificationStatusResponse = await getBillingVerificationStatus(apiToken);
            if(!billingVerificationStatusResponse.error){
                setIsEmailVerified(billingVerificationStatusResponse.is_email_verified);
            }
        } catch (error) {
            console.log("Error in Plans Modal", error);
        }
    }

    return (
      <ModalV2
        className="posttrial-allplans-modal"
        handleClose={() => handleClose()}
        padding={30}
        footerHeight={70}
        dimensions={{ minWidth: 500, minHeight: 200 }}
      >
        {showCloseButton && (
          <div onClick={handleClose} className="allplans-close-icon">
            <CloseIcon className="cursor-pointer" size={20} />
          </div>
        )}
        <div className="modalHeadingCont" style={{ paddingTop: 30 }}>
          <div>
            <p className="text-size-20 lh-32px font-weight-600 text-222222 mb-20px text-center">
              {modalHeading}
            </p>
          </div>
          {offerTagEligibility ? (
            <div className="offerTag">
              <OfferTag TagTitle={offerTagTitle}></OfferTag>
            </div>
          ) : null}
        </div>
        {loading ? (
          <div className="disp-flex justify-center mt-21px">
            <Loading size={100} />
          </div>
        ) : (
          <div style={{ paddingBottom: 30 }}>
            <ExperimentContext.Provider
              value={{
                user_bucket: "Variant",
                experiment_id: "Subscription_Decision_Modal",
              }}
            >
              <AllSubscriptionPlans
                allPlanTabData={planTabData}
                allPlancardData={planCardData}
                direction="column"
                tabWidth={126}
                buttonStates={buttonStates}
                currentPlanBillingPeriod={currentPlanBillingPeriod}
                currentPlanName={currentPlanData.product.name}
                billingRankings={billingRankings}
                trialData={trialData}
                handleDowngrade={handleDowngrade}
                minTextCallCost={minTextCallCost}
                apiToken={apiToken}
                daysLeft={daysLeft}
                isEmailVerified={isEmailVerified}
                upgradeButtonClicked={upgradeButtonClicked}
              />
            </ExperimentContext.Provider>
          </div>
        )}
      </ModalV2>
    );
}

export default PostTrialAllPlansContainer
