import React, { useEffect } from "react";
import "./NotificationSettings.scss";

import CampaignAssignedCalling from "../../../../assets/svg/CampaignAssignedCalling";
import CampaignAssignedTexting from "../../../../assets/svg/CampaignAssignedTexting";
import CampaignOperational from "../../../../assets/svg/CampaignOperational";
import ReplyNotification from "../../../../assets/svg/ReplyNotification";
import NotificationSwitch from "./NotificationSwitch";
import BlueExclamationCircle from "../../../../assets/svg/BlueExclamationCircle";

const NotificationSettings = ({ componentData }) => {
  const { campaignType, notification_settings, provider, brandName, showHelpLinks } = componentData;

  const notificationList = [
    {
      id: 1,
      name: "assigned_notification",
      title: "New campaign is assigned to agent",
      trigger_frequency: "Once",
      preview: {
        cc: <CampaignAssignedCalling />,
        p2p: <CampaignAssignedTexting />,
      },
      campaignList: ["cc", "p2p"],
      warningMessage:
        "Are you sure you don’t want to notify your agents when you assign them a new campaign?",
    },
    {
      id: 2,
      name: "operational_notification",
      title: "Campaign daily operational time starts",
      trigger_frequency: "Everyday",
      preview: {
        cc: <CampaignOperational />,
        p2p: <CampaignOperational />,
      },
      campaignList: ["cc", "p2p"],
      warningMessage:
        "Are you sure you don’t want to notify your agents when the campaign daily operational time starts?",
    },
    {
      id: 3,
      name: "contact_replies_notification",
      title: "Contact replies",
      trigger_frequency: "Once",
      preview: {
        p2p: <ReplyNotification />,
      },
      campaignList: ["p2p"],
      warningMessage:
        "Are you sure you don’t want to notify your agents when the contact replies?",
    },
  ];

  const renderNotificationItem = ({
    id,
    name,
    title,
    trigger_frequency,
    preview,
    warningMessage,
  }) => {
    const jqueryComponentData = {
      defaultValue: notification_settings[name] ?? true,
    };
    const previewImage = preview[campaignType];
    return (
      <div className="notification-item-container" key={id}>
        <NotificationSwitch
          switchId={`${name}Id`}
          inputFieldName={name}
          jqueryComponentData={jqueryComponentData}
          warningMessage={warningMessage}
        />
        <span className="notification-item">{title}</span>
        <span className="notification-item">{trigger_frequency}</span>
        <div className="notification-item notification-preview">
          <span>Preview </span>
          <div className="notification-preview-image-container">
            {previewImage}
          </div>
          <div className="preview-arrow-left" />
        </div>
      </div>
    );
  };
  return (
    <div>
      <p
        className={`notification-title ${
          campaignType == "p2p" ? "p2p-notification-title" : ""
        }`}
      >
        Notify agents on mobile app
      </p>
      <p className="notification-subtitle">
        Send push notifications to increase agent engagement and retention in
        campaign.
      </p>

      {provider == "plivo" && (
        <div className="plivo-notification-info">
          <BlueExclamationCircle size={14} />
          <span>To notify agents, gain access to {brandName} mobile app. {showHelpLinks == "False" ? "Contact Support" : ""}</span>
          {showHelpLinks == "True" && <a
            className="notification-support-link"
            href="https://support.callhub.io/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Support
          </a>}
        </div>
      )}

      <div
        className={`notification-list-container ${
          provider == "plivo"
            ? "notification-disabled-overlay"
            : ""
        }`}
      >
        <div className="notification-item-container notification-list-header">
          <span className="notification-list-header-item" />
          <span className="notification-list-header-item">
            Notification type
          </span>
          <span className="notification-list-header-item">
            Trigger frequency{" "}
          </span>
          <span className="notification-list-header-item" />
        </div>
        {notificationList
          .filter(({ campaignList }) => campaignList.includes(campaignType))
          .map((item) => {
            return renderNotificationItem(item);
          })}
      </div>
    </div>
  );
};

export default NotificationSettings;
