import React from 'react';

export const Exclamation = () => {

    return (
        <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="45.4037" cy="45.4037" r="43.4037" stroke="white" strokeWidth="4"/>
            <path d="M48.328 21.5632L47.8664 55.5405H42.5113L42.0496 21.5632H48.328ZM45.1888 69.2053C44.0501 69.2053 43.073 68.7975 42.2574 67.9819C41.4418 67.1663 41.034 66.1892 41.034 65.0504C41.034 63.9117 41.4418 62.9345 42.2574 62.119C43.073 61.3034 44.0501 60.8956 45.1888 60.8956C46.3276 60.8956 47.3047 61.3034 48.1203 62.119C48.9359 62.9345 49.3437 63.9117 49.3437 65.0504C49.3437 65.8045 49.1513 66.4969 48.7666 67.1278C48.3973 67.7588 47.8972 68.2666 47.2663 68.6513C46.6507 69.0206 45.9582 69.2053 45.1888 69.2053Z" fill="white"/>
        </svg>
    );
}
