import React from "react"
const CancelIcon = (props) => (
    <svg
    width={10}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.763 5.094 9.864.204A.124.124 0 0 0 9.77 0H8.522a.255.255 0 0 0-.192.09L4.947 4.121 1.564.089A.25.25 0 0 0 1.372 0H.125A.124.124 0 0 0 .03.205L4.13 5.094.03 9.983a.124.124 0 0 0 .095.204h1.247a.254.254 0 0 0 .192-.089l3.383-4.032 3.383 4.032a.25.25 0 0 0 .192.09h1.247a.124.124 0 0 0 .095-.205l-4.101-4.89Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
)
export default CancelIcon;
