import React from "react";

const NoteIcon = props => {
    return (
        <svg
        overflow="visible"
        preserveAspectRatio="none"
        viewBox="0 0 24 24"
        width={props.size}
        height={props.size}
        {...props}
        >
        <path
            className="note-icon"
            d="M15 15a.99.99 0 01.71-.29H20V5c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v14c0 .55.45 1 1 1h9.66v-4.34c.02-.26.15-.49.34-.66zm1 1v4c.52-.08.99-.32 1.37-.68l1.92-1.92c.38-.38.63-.87.71-1.4h-4z"
            vectorEffect="non-scaling-stroke"
            fill="#3f89de"
        />
        </svg>
  )
}

export default NoteIcon; 