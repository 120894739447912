import {
    brandDisplayUrl,
    brandRegistrationUrl,
    brandStatusUrl,
    CampaignVerifyTokenUrl,
    credits_availableUrl,
    getuseCaseNumbersUrl,
    media_url,
    updateBrandDetailsUrl,
    updateUsecaseDetailsUrl,
    usecaseDataUrl,
    usecaseDetailsUrl,
    usecaseDisplayUrl,
    usecaseEligiblityUrl,
    usecaseStatusUrl,
    usecaseSummaryUrl,
    getUseCaseNumberChangeUrl,
    deleteUsecaseUrl
} from "../configuration/ComplianceConfig";
import { apiGetCall, apiPostCall, apiPutCall, apiDeleteCall } from "./utilities";

export const postBrandRegistrationData = async (formData, isDraft) =>{
    try{
        const brandRegistrationURL = brandRegistrationUrl();
        formData.is_draft = isDraft;
        const response = await apiPostCall(brandRegistrationURL,formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getBrandRegistrationData = async (id) => {
    try {
        const brandRegistrationURL = brandRegistrationUrl(id);
        const response = await apiGetCall(brandRegistrationURL);
        return response.data;
    } catch (error) {
        return {
            error:error
        }
    }
}

export const updateBrandRegistrationData = async (formData, id, isDraft=false) => {
    try {
        const updateBrandDetailsURL = updateBrandDetailsUrl(id);
        formData.is_draft = isDraft;
        const response = await apiPutCall(updateBrandDetailsURL,formData);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const getEligibleUseCaseData = async (id) => {
    try {
        const usecaseEligiblityURL = usecaseEligiblityUrl(id);
        const response = await apiGetCall(usecaseEligiblityURL);
        return response.data;
    } catch (error) {
        return {
            error:  error.response.data,
            status: error.response.status
        };
    }
}

export const getUseCaseData = async () => {
    try {
        const usecaseDetailsURL = usecaseDetailsUrl();
        const response = await apiGetCall(usecaseDetailsURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const postUsecaseRegistrationData = async (formData,isDraft=false) =>{
    try{
        const usecaseDetailsURL = usecaseDetailsUrl();
        formData.is_draft = isDraft;
        const response = await apiPostCall(usecaseDetailsURL,formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getUseCaseDetails = async (id) => {
    try {
        const useCaseDataURL = usecaseDataUrl(id);
        const response = await apiGetCall(useCaseDataURL);
        return response.data;
    }
    catch(error) {
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}
export const updateUsecaseRegistrationData = async (id,formData,isDraft=false) => {
    try {
        const perticularUseCaseURL = updateUsecaseDetailsUrl(id);
        formData.is_draft = isDraft;
        const response = await apiPutCall(perticularUseCaseURL,formData);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const deactivateUsecase = async (id) => {
    try {
        const deleteUseCaseURL = updateUsecaseDetailsUrl(id);
        const response = await apiDeleteCall(deleteUseCaseURL);
        return response.data;
    } catch (error) {
        return {
            error: error,
            // status: error.response.status
        };
    } 
}

export const getAvailableCredits = async () => {
    try {
        const credits_availableURL = credits_availableUrl();
        const response = await apiGetCall(credits_availableURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const validateCampaignVerifyToken = async (formData) => {
    try {
        const CampaignVerifyTokenURL = CampaignVerifyTokenUrl();
        const response = await apiPostCall(CampaignVerifyTokenURL,formData);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const getBrandDisplayData = async () => {
    try {
        const brandDisplayURL = brandDisplayUrl();
        const response = await apiGetCall(brandDisplayURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const getUsecaseDisplayData = async (id) => {
    try {
        const usecaseDisplayURL = usecaseDisplayUrl(id);
        const response = await apiGetCall(usecaseDisplayURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const getBrandStatusData = async () => {
    try {
        const brandStatusURL = brandStatusUrl()
        const response = await apiGetCall(brandStatusURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const getUsecaseStatusData = async (id) => {
    try {
        const usecaseStatusURL = usecaseStatusUrl(id)
        const response = await apiGetCall(usecaseStatusURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const postCloseBrandNotification = async (formData) => {
    try {
        const brandStatusURL = brandStatusUrl()
        const response = await apiPostCall(brandStatusURL,formData);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const postCloseUsecaseNotification = async (id,formData) => {
    try {
        const usecaseStatusURL = usecaseStatusUrl(id)
        const response = await apiPostCall(usecaseStatusURL,formData);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const getUsecaseSummaryData = async (limit,offset) => {
    try {
        const usecaseSummaryURL = usecaseSummaryUrl(limit,offset)
        const response = await apiGetCall(usecaseSummaryURL);
        return response.data;
    } catch (error) {
        return {
            error: error.response.data,
            status: error.response.status
        };
    }
}

export const handleUsecaseMediaUpload = async (options) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append('file', file);

    try{
        const response = await apiPostCall(media_url(),formData);
        onSuccess(response.data.data, file);
    }
    catch (error) {
        onError(error);
    }
    
}

export const getUsecaseNumbersOptions = async () =>{
    try {
        const usecaseNumbersURL = getuseCaseNumbersUrl()
        const response = await apiGetCall(usecaseNumbersURL);
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const postUsecaseNumberChange = async (payload) =>{
    try{
        const url = getUseCaseNumberChangeUrl()
        const response = await apiPostCall(url,payload)
        return response.data;
    }catch(error){
        console.log(error)
    }
}
export const deleteUsecase = async (id) => {
    try{
        const url = deleteUsecaseUrl(id)
        const response = await apiDeleteCall(url)
    return response.data;
  }
    catch(error){
       console.log(error)
    }
}