import React from 'react';
import DOMPurify from 'dompurify';
import {END,SPECIFIC_SECTION, VAN_EVENT} from "../../constants/SectionTypes"

export const BranchingScriptQuestion = ({question,DisplayNext,ModifyContainer,dataToPreview,storeSelected,selected,has_question_name_permission})=>{

    const question_name = (question.question_name && has_question_name_permission && question.type != 12) ? `<span class="question-name"> (Question name: ${question.question_name})</span>`: '';

    const selectChoice = (question,choice)=>{
        if(question.type == VAN_EVENT && (question.nextEvent && question.nextQuestion)){
            storeSelected(question.id,choice.id);
            ModifyContainer(question.id, question.nextEvent);
        }
        else if(question.type == VAN_EVENT && question.nextQuestion == null){
            let nextId;
            if(dataToPreview[question.nextEvent].nextQuestion == SPECIFIC_SECTION){
                dataToPreview[question.nextEvent].choices.forEach((choice)=>{
                    if(selected[question.nextEvent]==choice.id){
                        nextId = choice.nextQuestion;
                    }
                })
            }else{
                nextId = dataToPreview[question.nextEvent].nextQuestion;
            }
            ModifyContainer(question.id, nextId);
        }else{
            if(question.nextQuestion == SPECIFIC_SECTION){
                ModifyContainer(question.id, choice.nextQuestion);
            }else{
                ModifyContainer(question.id, question.nextQuestion);
            }
        }
    }
    
    return (
        <div>
            <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question.question + question_name)}} className= {question.choices ? "noBorder" : "border"}></p>
            {question.choices ? <div className="choicesContainer">
                {question.choices.map((choice)=>{
                    return(<div key={choice.id}>
                        <input type={(question.type == 17 || question.type == 18) ? 'checkbox': 'radio'} name={question.id} id={choice.id} onChange={()=>selectChoice(question,choice)} />
                        <p>{choice.answer}</p>
                    </div>)})}
            </div> : question.nextQuestion && question.nextQuestion != END ? <button onClick={()=>DisplayNext(question.nextQuestion)} className="next">Next</button> : null} 
            {question.skipQuestion && question.skipQuestion != END && <button className='skipButton' onClick={()=>ModifyContainer(question.id,question.skipQuestion)}>Skip</button>}                       
        </div>
    )
}