import React from 'react'

function NudgeConvoIcon(props) {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 48 44"
            width={props.width} // {48}
            height={props.height} // {44}
            {...props}
            >
            <path
                d="M48 5.755v13.48c0 3.192-2.543 5.782-5.715 5.782H26.852c-.302 0-.55-.001-.758-.006a6.277 6.277 0 00-.559.005c-.1.066-.344.291-.579.51-.093.086-.199.18-.312.285l-4.367 4.005c-.348.32-.864.403-1.295.211-.431-.191-.734-.62-.734-1.095V16.235H5.78a3.42 3.42 0 00-3.4 3.404v13.48c0 1.871 1.54 3.342 3.4 3.342h17.24c.297 0 .574.137.793.339l3.558 3.3V28.719c0-.662.533-1.198 1.19-1.198.657 0 1.19.536 1.19 1.198v14.097c0 .475-.27.898-.701 1.09-.154.068-.31.095-.472.095-.291 0-.579-.12-.802-.326l-5.218-4.818H5.781C2.61 38.856 0 36.311 0 33.12v-13.48c0-3.192 2.61-5.8 5.781-5.8h12.467V5.755c0-3.191 2.63-5.755 5.801-5.755h18.236C45.457 0 48 2.564 48 5.755z"
                vectorEffect="non-scaling-stroke"
                fill="#e67540"
            />
        </svg>
    )
}

export default NudgeConvoIcon
