import React from 'react'
import "./ModalV2.scss";
import CloseIcon from "../../../../assets/svg/CloseIcon";
import Button from "./Button";
import styled from 'styled-components';
import { OfferTag } from './OfferTag';

function ModalV2({children,fadeOutAnimation = false, ...restProps}) {
    const {
        handleSecondaryBtn,
        handlePrimaryBtn,
        handleClose,
        staticText,
        dimensions,
        padding,
        footerHeight,
        buttonCustomStyles,
        buttonLightStyles,
        buttonPrimaryStyles,
        className,
        areButtonsLoading,
        showOfferTag,
        OfferTagStyles,
        OfferTagTitle,
        CloseIconSize=18
    } = restProps;

   let showOffer=showOfferTag||false
    return (
        <div className={`modal-v2-overlay ${className ? className : ""}`}>
            <div className={`modal-v2-container modal-fade-in ${fadeOutAnimation ? 'modal-fade-out' :"" }`} style={{...dimensions}}>
                <div className="modal-v2-header" style={{padding: `${padding}px ${padding}px 8px ${padding}px`}}>
                    <div className={`${OfferTagStyles ? OfferTagStyles : ""}`} >
                        <div><p className="modal-v2-title">{staticText && staticText.title ? staticText.title : ""}</p></div>
                      {showOffer?<OfferTag TagTitle={OfferTagTitle}/>:null} 
                    </div>
                    <div className="modal-v2-close">
                        <CloseIcon onClick={handleClose ? handleClose : null} size={CloseIconSize} />
                    </div>
                </div>
                <div className="modal-v2-content">
                    <div className="modal-v2-body" style={{padding: `0 ${padding}px`}}>
                        {children}
                    </div>
                </div>
                <div className="modal-v2-footer" style={{height: footerHeight}}>
                        <div className="modal-v2-btn modal-v2-secondary">
                            <Button 
                                handleClick={handleSecondaryBtn ? handleSecondaryBtn : null}
                                type="light"
                                name={staticText && staticText.secondaryBtnName ? staticText.secondaryBtnName : ""}
                                customStyles={{...buttonCustomStyles, ...buttonLightStyles ? buttonLightStyles : ""}}
                                isLoading={areButtonsLoading}
                            />
                        </div>
                        <div className="modal-v2-btn modal-v2-primary">
                            <Button 
                                handleClick={handlePrimaryBtn ? handlePrimaryBtn : null}
                                type="primary"
                                name={staticText && staticText.primaryBtnName ? staticText.primaryBtnName : ""}
                                customStyles={{...buttonCustomStyles, ...buttonPrimaryStyles ? buttonPrimaryStyles : ""}}
                                isLoading={areButtonsLoading}
                            />
                        </div>
                </div>  
            </div>
        </div>
    )
}

export default ModalV2
