import React from 'react';
import Heading from './Header';
import UrlShortenerStatisticsTable from './UrlShortenerStatisticsTable';
import statsIcon from '../../../assets/images/stats-icon.png'

import './UrlShortenerStatisticsTableLayout.scss';
import {getPowerCampaignUrlStatsData, getUrlStatsData} from "./UrlShortenerUtilities";

const UrlShortenerStatisticsTableLayout = (props) => {
    const fetchData = (props.componentData.campaignType === 'POWER_CAMPAIGN')
        ? getPowerCampaignUrlStatsData
        : getUrlStatsData;

    return (
            <>
                {
                    props.componentData.renderUrlStatsTable === 'True' ? 
                    <>
                        <div className={`row-fluid ${props.componentData.smsBroadcastCampaign ? 'display-none' : ''}`}>
                            <div className="span12">
                                <hr className="cb-no-margin hr-line m-t-20"/>
                            </div>
                        </div>
                        <div className={`${props.componentData.smsBroadcastCampaign ? 'margin-top-bottom' : ''}`}>
                            <Heading 
                                linkText={props.componentData.linkText}
                                headingText={props.componentData.headingText}
                                redirectLink={props.componentData.redirectLink}
                                icon={statsIcon}
                                className={props.componentData.className}
                                campaignId={props.componentData.campaign_id}
                            />

                            <UrlShortenerStatisticsTable 
                                campaignId={props.componentData.campaign_id}
                                apiToken={props.componentData.apiToken}
                                className={props.componentData.className}
                                fetchData={fetchData}
                            />
                        </div> 
                    </>   
                    : null
                }
            </>
    )
}

export default UrlShortenerStatisticsTableLayout