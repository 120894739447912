import React, { useState, useEffect } from "react";
import ModalV2 from "../../react_library_for_jquery/common/ModalV2";
import Button from "../../react_library_for_jquery/common/Button";
import CloseIcon from "../../../../assets/svg/CloseIcon";

import "./UpSellModal.scss";
import PostTrialAllPlansContainer from "../../react_library_for_jquery/SubscriptionTrial/PostTrialAllPlansContainer";
import { getCurrentPlanData } from "../../../utilities/subscriptionUtilities";
import { UpSellContext } from "../../../context/subscriptionContext";
import RenderMarkup from "../../../components/Store/AddonDescription/RenderMarkup";

const GenericUpsellModal = ({
  showUpgradeButton = false,
  apiToken = "",
  unMountTheComponent,
  ...restProps
}) => {
  const { closeUpSellModal, product, icon_url, upsellData } = restProps;

  const {
    title_text,
    display_text,
    plan_name,
    width,
    height,
    features,
    included_in,
    legacy_title_text,
    legacy_width,
    legacy_height,
  } = upsellData ?? {};

  const [showPlanModal, setShowPlanModal] = useState(false);

  const [currentPlan, setCurrentPlan] = useState(null);

  const [fadeOutAnimation, setFadeOutAnimation] = useState(false) 

  const handleClose = () => {
    setFadeOutAnimation(prev => true)

    setTimeout(
      () => {closeUpSellModal(); unMountTheComponent && unMountTheComponent(false)}, 
      500
    );
    
  };

  const { id, cost } = product;

  const handleButtonClick = (type) => {
    if (type === "addon") {
      closeUpSellModal();

      const buyAddon = {
        type: "addon",
        base_product_id: id,
      };
      sessionStorage.setItem("billing", JSON.stringify(buyAddon));
      window.location = "/payment";
    }

    setShowPlanModal((prev) => true);
  };

  const getCurrentPlanBillingPeriod = () => {
    const isTrial = currentPlan.is_trial;
    const currentPlanBillingPeriod = !isTrial
      ? currentPlan.product.billing_period
      : "";
    return currentPlanBillingPeriod.replace("-", " ");
  };

  const getPlanDetails = async () => {
    const [currPlanDataRes] = await Promise.all([getCurrentPlanData(apiToken)]);
    setCurrentPlan((prev) => currPlanDataRes);
  };

  useEffect(() => {
    getPlanDetails();
    return () => {
      
    }
  }, []);

  const handleDowngradePlanClick = (cardData, billingPeriod) => {};

  return (
    <>
      <ModalV2
        className="upsell-modal-container"
        dimensions={ showUpgradeButton ? { width: width, height: height } : { width: legacy_width, height: legacy_height }}
        footerHeight={0}
        fadeOutAnimation={fadeOutAnimation}
      >
        <div onClick={handleClose} className="upsell-close-icon">
          <CloseIcon className="cursor-pointer" size={20} />
        </div>
        <div className="upsell-container">
          <div className="icon-container">
            <img src={icon_url} alt="icon" />
          </div>
          <div className="text-container">
            <p className="upsell-text-title">{showUpgradeButton ? title_text : legacy_title_text }</p>
            {showUpgradeButton && <div className="upsell-text-content"> <RenderMarkup data = {{ __html: display_text, cssClass : '' }} /> </div>}

            <div className="btn-grp">
              {showUpgradeButton && (
                <Button
                  name={`Upgrade to ${plan_name} plan`}
                  type="primary"
                  customStyles={{
                    height: 30,
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                  handleClick={() => handleButtonClick("plan")}
                />
              )}

              <Button
                name={`Buy add-on for $${cost}`}
                type={showUpgradeButton ? "light" : "primary"}
                customStyles={{
                  height: 30,
                  fontSize: 14,
                }}
                handleClick={() => handleButtonClick("addon")}
              />
            </div>
          </div>
        </div>
      </ModalV2>

      {showPlanModal && currentPlan && (
        <UpSellContext.Provider value={{ plan_name, features, included_in }}>
          <PostTrialAllPlansContainer
            handleClose={() => setShowPlanModal(false)}
            modalHeading={"Choose a plan that fits your need"}
            showCloseButton={true}
            currentPlanBillingPeriod={getCurrentPlanBillingPeriod()}
            currentPlanData={currentPlan}
            trialData={{
              isTrial: currentPlan.is_trial,
              daysLeft: currentPlan.is_trial
                ? currentPlan.remaining_days
                : null,
              isAllPlansPostTrial: currentPlan.show_all_plans_modal,
            }}
            handleDowngrade={handleDowngradePlanClick}
            daysLeft={currentPlan.is_trial ? currentPlan.remaining_days : null}
            apiToken={apiToken}
            componentData={{
              token: apiToken,
            }}
          />
        </UpSellContext.Provider>
      )}
    </>
  );
};

export default GenericUpsellModal;
