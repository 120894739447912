import React from "react";
import './Button.scss';

const DiscoverabilityButton = ({ text, buttonClasses }) => {
    return (

        <div>
            <button className={`discoverability-button ${buttonClasses}`}>
                {text}
            </button>
        </div>

    )
}

export default DiscoverabilityButton