import React, { useEffect, useState } from "react";
import { Button, Modal } from 'antd';
import { WarningFilled } from "@ant-design/icons";
import './LowCreditError.scss';

const LowCreditError = (props)=>{

    const [isModalOpen, setIsModalOpen] = useState(true);
    const handleOk = () => {
        setIsModalOpen(false);
        window.location.href = '/billing/recharge/'
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <div>
            <Modal open={isModalOpen} onOk={handleOk} okText={'Add Credits'} onCancel={handleCancel} className = 'low-credit-error-modal' maskClosable={false} centered={true}>
                <WarningFilled style={{ fontSize: '48px', color: '#F65656'}} />
                <p className='low-credit-error-title'>Low Credits</p>
                <p className='low-credit-error-subtitle'>You don’t have enough credits to start this campaign</p>
            </Modal>
        </div>
    )
}

export default LowCreditError;