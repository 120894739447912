import { apiGetCall, apiPostCall, apiPutCall } from '../../../../../src/utilities/utilities';
// import {getAgentList , getSmartFilterDataUrl, goalCreationUrl} from './CcAiPowedConfig';
import {getAgentsDataUrl, assignAgentUrl} from './AssignAgentsPopoverConfig';


export const getCampaignAgents = async(page, searchText)=>{

    try{
        const agent_list_url = getAgentsDataUrl(page, searchText);
        const host = window.location.hostname;
        const response = await apiGetCall(`https://${host}/${agent_list_url}`);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const assignNewAgent = async (team_id, data) => {
    try {
      const assign_agent_url = assignAgentUrl(team_id);
      const host = window.location.hostname;
      const response = await apiPutCall(
        `https://${host}/${assign_agent_url}`, {"agent_ids": data}
      );
      return response;

    } catch (error) {
      return {
        error: error.response.data,
        status: error.response.status,
      };
    }
};