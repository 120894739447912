import React, { useEffect, useRef, useState } from 'react';
import Checkbox from 'antd/lib/checkbox'
import UncheckedKycIcon from '../../../../assets/svg/UncheckedKycIcon';
import CheckedKycIcon from '../../../../assets/svg/CheckedKycIcon';

export function ProductContainer(props) {

    let [checkedProduct , setCheckedProduct] = useState(false);

    const handleClick = ()=>{
        setCheckedProduct(!checkedProduct);
    }

    return(
        <Checkbox value={props.product.productValue} onClick={handleClick}>
            <div className="product_container">
                <div className="product_checked">{checkedProduct ? <CheckedKycIcon/> : <UncheckedKycIcon/>}</div>
                <div className="product-details">
                    <div><props.product.icon></props.product.icon></div>
                    <div className="product-info">
                        <div className="product_name">{props.product.productName}</div>
                        <div className="product_text">{props.product.statement}</div>
                    </div>
                </div>
            </div>
        </Checkbox>
    )
}