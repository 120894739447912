import React from "react"

const UpArrowIcon = (props) => (
  <svg
    width={props.width}
    height={props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.894.571H9.889a.215.215 0 0 0-.172.089L5.912 5.904 2.107.66A.215.215 0 0 0 1.934.57H.93c-.087 0-.138.1-.087.17l4.722 6.51a.427.427 0 0 0 .692 0L10.98.742a.107.107 0 0 0-.086-.17Z"
      fill="#000"
      fillOpacity={0.25}
    />
  </svg>
)

export default UpArrowIcon
