import React, {useEffect, useState} from 'react';
import axios from 'axios';

import './table.scss';
import UrlShortenerTableRow from './UrlShortenerTableRow'
import {getUrlStatsData} from '../UrlShortenerUtilities'
import Loading from '../../../../assets/svg/Loading';

const UrlShortenerStatisticsTable = ({campaignId, apiToken, className, fetchData}) => {
    const[tableData, setTableDate] = useState({});
    const [totalContacts, setTotalContacts] = useState(0)
    const[error, setError]= useState(false)
    const [loading, setLoading] = useState(true)

    const urlShortenerTableHeaders = ['URL', 'Sent', 'Total Clicks', 'Unique Clicks']

    useEffect(() => {
        const urlStatsDetails = async () => {
            const urlStatsDetails = await fetchData(apiToken, campaignId);
            setTotalContacts(urlStatsDetails.totalcontacts)
            setTableDate(urlStatsDetails);
            setError(false);
            setLoading(false)
        }
        
        urlStatsDetails();

    }, [])

    const tooltipContentForTableHeader = (data) => {
        switch(data) {
            case 'Sent':
                return "Messages that were sent to contact's handset."
            case 'Total Clicks':
                return <div><p>Shows the total number of times various contacts visited the URL.</p> <p><strong>Note:</strong> These are not unique clicks, if a contact clicks on it again, it counts as two clicks.</p></div>
            case 'Unique Clicks':
                return "Shows the number of unique contacts who clicked on the URL."
            default:
                return
        }
    }

    return (
        <table className={`url-stats-table ${className ? className : ''}`}>
            <thead className='url-stats-table-header'>
                <tr className = 'url-stats-table-header-row'>
                    {
                        urlShortenerTableHeaders.map((data, index) => (
                            <th key={index} className={`table-title table-title-${index}`}>
                                <div className="table-header-tooltip">
                                    {data.toUpperCase()}
                                    <span className="table-header-tooltiptext">
                                        <h4 className='tooltip-heading'>{data}</h4>
                                       {tooltipContentForTableHeader(data)}
                                    </span>
                                </div>
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    tableData.base_url_objects_list ?
                        tableData.base_url_objects_list.length ?
                            tableData.base_url_objects_list.map((data, index) => {
                                return <UrlShortenerTableRow
                                            tablerowdata = {data}
                                            key = {index}
                                            index={index}
                                            totalContacts = {totalContacts}
                                        />
                            })
                        :
                            <tr><td align="center" colSpan="12">
                                {
                                    loading ? 
                                        <Loading size={40} /> 
                                    :
                                        <div>No data</div>
                                }
                            </td></tr>
                    :
                        error ?
                            <tr><td align="center" colSpan="12"><div>No data</div></td></tr>
                        :
                            <>
                                <tr><td align="center" colSpan="12"><div className="text-line"></div></td></tr>
                                <tr><td align="center" colSpan="12"><div className="text-line"></div></td></tr>
                            </>
                }
            </tbody>
        </table>
    )
}

export default UrlShortenerStatisticsTable