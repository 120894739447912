import { 
    getWorkflowDataUrl,
    getWorkflowListUrl,
    postWorkflowDataUrl,
    workflowStatusUpdaterUrl,
    getWorkflowStatusUrl,
    getWorkflowStatsUrl,
    getNodeStatusUrl,
    duplicateWorkflowUrl,
    getAvailableConfigurationsUrl,
    nodeCreateUrl,
    deleteWorkflowUrl,
    getWorkflowReportsUrl,
    getNodeReportsUrl,
    formatNodeDataUrl,
    getAvailableFiltersUrl,
    getWorkflowSettingsUrl,
    getUserMergeTagsUrl,
    getWebhookTestUrl,
    getWebhookStatsUrl,
    unpublishedWorkflowsMessageUrl
} from '../configs/urlConfig';

import { apiGetCall, apiPostCall, apiPutCall, apiDeleteCall, apiPatchCall } from '../../utilities/callApi';

class WorkflowService {
    constructor(apiGetCall, apiPostCall, apiPutCall, apiDeleteCall, apiPatchCall) {
        this.apiGetCall = apiGetCall;
        this.apiPostCall = apiPostCall;
        this.apiPutCall = apiPutCall;
        this.apiDeleteCall = apiDeleteCall;
        this.apiPatchCall = apiPatchCall;
    }

    getWorkflowLists = async(limit, offset, query, filterByTrigger, filterByStatus, sortBy, orderBy) => {
        const url = getWorkflowListUrl(limit, offset, query, filterByTrigger, filterByStatus, sortBy, orderBy);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }
    createWorkflow = async (data) => {
        const url = postWorkflowDataUrl();
        const response = await this.apiPostCall(url,data);

        if(response.data){
            return response;
        }else if(response.error){
            return response;
        }else{

        }
    }
    getWorkflowData = async(workflow_id) => {
        const url = getWorkflowDataUrl(workflow_id);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }
    editWorkflow = async (workflow_id, data) => {
        const url = postWorkflowDataUrl(workflow_id);
        const response = await this.apiPutCall(url,data);

        if(response.data){
            return response;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getWorkflowStatus = async (workflow_id) => {
        const url = getWorkflowStatusUrl(workflow_id);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    updateWorkflowStatus = async (workflow_status, data) => {
        const url = workflowStatusUpdaterUrl(workflow_status);
        const response = await this.apiPostCall(url, data);

        if(response.data){
            return response;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getWorkflowStats = async (workflow_id) => {
        const url = getWorkflowStatsUrl(workflow_id);
        const response = await this.apiGetCall(url);
    
        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{
    
        }
    }

    getNodeStats = async (workflow_id,node_id) => {
        const url = getNodeStatusUrl(workflow_id,node_id);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{
    
        }
    }

    duplicateWorkflow = async(workflow_id, data) => {
        const url = duplicateWorkflowUrl(workflow_id);
        const response = await this.apiPostCall(url, data);

        if(response.data){
            return response;
        }else if(response.error){
            return response;
        }else{
    
        }
    }

    getAvailableConfigurations = async() => {
        const url = getAvailableConfigurationsUrl();
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    createNodeData = async (object_type, campaign_type=null, search=null, selected=null) => {
        const url = nodeCreateUrl(object_type, campaign_type=campaign_type, search=search, selected=selected);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    createNodeDataByPost = async (data) => {
        const url = nodeCreateUrl();
        const response = await this.apiPostCall(url, data);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    deleteWorkflow = async (workflow_id) => {
        const url = deleteWorkflowUrl(workflow_id);
        const response = await this.apiDeleteCall(url);
        if(response.data){
            return response;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getWorkflowReports = async (workflow_id, limit, offset, query, sortBy, orderBy) => {
        const url = getWorkflowReportsUrl(workflow_id, limit, offset, query, sortBy, orderBy);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getNodeReports = async (workflow_id, node_id, limit, offset, query, sortBy, orderBy) => {
        const url = getNodeReportsUrl(workflow_id, node_id, limit, offset, query, sortBy, orderBy);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    formatNodeData = async (data) => {
        const url = formatNodeDataUrl();
        const response = await this.apiPostCall(url, data);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getAvailableFilters = async () => {
        const url = getAvailableFiltersUrl();
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getWorkflowSettings = async () => {
        const url = getWorkflowSettingsUrl();
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getUserMergeTags = async () => {
        const url = getUserMergeTagsUrl();
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    testWebhook = async (payload) => {
        const url = getWebhookTestUrl();
        const response = await this.apiPostCall(url, payload);

        if(response.status == 200){
            return response.data;
        }else {
            return response;
        }
    }

    getWebhookStats = async (workflow_id, node_id) => {
        const url = getWebhookStatsUrl(workflow_id, node_id);
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    getUnpublishedMessage = async () => {
        const url = unpublishedWorkflowsMessageUrl();
        const response = await this.apiGetCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }

    postUnpublishedMessageRemoval = async () => {
        const url = unpublishedWorkflowsMessageUrl();
        const response = await this.apiPostCall(url);

        if(response.data){
            return response.data;
        }else if(response.error){
            return response;
        }else{

        }
    }
}

const workflowService = new WorkflowService(
    apiGetCall, 
    apiPostCall, 
    apiPutCall, 
    apiDeleteCall,
    apiPatchCall,
);

export default workflowService;