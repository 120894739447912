import {sendOtpUrl,verifyOtpUrl} from "../configuration/contactCreationConfig";
import {apiPostCall} from "./utilities";

export const postCreateContactSendOtp = async (userId, data) =>{
    try{
        const url = sendOtpUrl();
        return await apiPostCall(url, data);
    }
    catch(error){
        throw error;
    }
}
export const postVerifyOtp = async (userId, data) =>{
    try{
        const url = verifyOtpUrl();
        return await apiPostCall(url, data);
    }
    catch(error){
        throw error;
    }
}