const prefix = "/v1/"
const prefix2 = "/v2/"

export const brandRegistrationUrl = (id) => {
    if(id){
        return `/compliance/brands/${id}/`;
    }
    else{
        return `/compliance/brands/`
    }
}

export const updateBrandDetailsUrl = (id) => {
    return `/compliance/brands/${id}/`;
}

export const usecaseEligiblityUrl = (id) => {
    return `/compliance/eligibility/${id}/`;
}
export const usecaseDetailsUrl = () => {
    return `/compliance/usecases/`;
}

export const updateUsecaseDetailsUrl = (id) => {
    return `/compliance/usecases/${id}/`
}

export const usecaseDataUrl = (id) => {
    return `/compliance/usecases/${id}/`
}

export const credits_availableUrl = () => {
    return  `/v1/credits_available/`
}

export const CampaignVerifyTokenUrl = () => {
    return  `/compliance/verify_token/`
}
export const brandDisplayUrl = () => {
    return  `/compliance/brand_summary/`
}
export const usecaseDisplayUrl = (id) => {
    return  `/compliance/usecase_summary/${id}/`
}
export const brandStatusUrl = () => {
    return `/compliance/brand_status/`
}
export const usecaseStatusUrl = (id) => {
    return `/compliance/usecase_status/${id}/`
}
export const usecaseSummaryUrl = (limit,offset) => {
    return  `/compliance/usecase_summary/?limit=${limit}&offset=${offset}`
}
export const media_url = () => {
    return `/v2/media/`
}
export const getuseCaseNumbersUrl = () => {
    return '/v2/usecases/'
}
export const getUseCaseNumberChangeUrl = ()=>{
    return '/v2/sms_campaign/set_usecase_phonenumber/'
}
export const deleteUsecaseUrl = (id) => {
    return `/compliance/usecases/${id}/`
}