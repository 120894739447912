export const CALL_SENTIMENTS_HEADING = 'Call Sentiments';
export const CALL_SENTIMENTS_SUBHEADING = 'AI-powered analysis to evaluates the sentiment of the conversations with your campaign goal.';
export const GENERATE_CAMPAIGN_DISCRIPTION = 'Our AI will fetch a campaign goal based on your call script.';
export const GENERATE_CAMPAIGN_GOAL = 'Generate campaign goal';
export const SAVE_GOAL = 'Save goal';
export const CONTACT_CATEGORIZATION = 'Contact Categorization';
export const CATEGORIZATION_SUBHEADING = 'Auto-categorize contacts into selected categories based on their conversations to discover meaningful patterns.';
export const SUGGESTIONS_TITLE = 'Suggested categories';
export const REVISE_CATEGORIE = 'Revise categories';
export const SUGGESTIONS_CATEGORIE_POPOVER = 'We identify new categories based on campaign script and goal you’ve added.';
export const CONTACT_CATEGORIE = 'Contact categories';
export const ENABLE_RECORDING_TITLE = '‘Record all calls’ setting has not been enabled';
export const ENABLE_RECORDING_SUBHEADING = 'Call recordings are essential for AI to generate  campaign insights.';
export const ENABLE_RECORDING_BUTTON = "Enable ‘Record all calls’";
export const CONTACT_CATEGORIZATION_POPOVER = 'Based on the chosen categories, contacts will be automatically categorized in the campaign.';
export const CALL_SENTIMENT_POPOVER = 'Based on campaign goal you set, the sentiment in conversation is analyzed and calls are segregated to positive, negative and neutral sentiment categories.';
export const SMART_INSIGHT_BETA_POPOVER = 'This feature is currently in the beta phase, so results may be preliminary, not very precise.';
