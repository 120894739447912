import React from "react";
import { useRecoilState } from "recoil";
import ExlamationIcon from "../../../assets/svg/ExlametionIcon";
import { showInboundFormState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";
import "./CallerIdNudgeStyles.scss";
import Button from "antd/lib/button"
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntButton.scss";



function CallerIdNudge({componentData}) {
    const [showinboundForm, setShowInboundForm] = useRecoilState(showInboundFormState)
    const handleCancel = () => {
        sessionStorage.removeItem("isInboundSelected")
        sessionStorage.setItem('isInboundEnabled', JSON.stringify(false))
        setShowInboundForm(false)
    }

    const handleCallerIdSection = () => {
        // console.log("hello")
        // $('#myWizard').wizard('previous');
        // $('#wizard-next').html('Next');
        // $('#wizard-next').attr('disabled', false);
        componentData.inboundData.revertInboundForValidatedNumber();
        sessionStorage.removeItem("isInboundSelected")
        sessionStorage.setItem('isInboundEnabled', JSON.stringify(false))
        setShowInboundForm(false)
    }

    return (
        <div id="caller-id-nudge-container">
            <div>
                <div className="nudge-message-container">
                    <div><ExlamationIcon /></div>
                    <div>
                        <div>Change Caller ID setting to use this feature</div>
                        <div>To use Inbound Calling you need to use a rented number as the Caller ID. This lets us route incoming calls to the right callers.</div>
                    </div>
                </div>
                <div className="caller-id-nudge-button-wrapper">
                    
                    {/* <button onClick={handleCancel}>Cancel</button>
                    <button onClick={handleCallerIdSection}>Go to Caller ID section</button> */}
                    <Button className="inbound-caller-id-nudge-cancel-button" onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" className="inbound-caller-id-nudge-go-back-button" onClick={handleCallerIdSection}>Go to Caller ID section</Button>
                </div>
            </div>
        </div>
    )
}


export default CallerIdNudge;