import React, {useEffect, useState, useRef} from "react";
import { useRecoilState } from "recoil";
import { inboundCallingDataState, inboundTextErrorState,  } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";
import Input from "antd/lib/input";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntInput.scss";
import "./InboundTextContainerStyles.scss";

function InboundTextContainer({componentType, handleInboundTextError, setThankyouMessageTextError}) {
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState);
    const { TextArea } = Input;
    const [textError, setTextError] = useState("");
    

    const messagetype = {
        "welcomeMessage": "welcomeText",
        "thankyouMessage": "ThankyouMessageText"
    }

    useEffect(() => {

        if(inboundCallingData[messagetype[componentType]] == "") {
            setTextError("Enter text to proceed.");
            if(componentType == "welcomeMessage") {
                handleInboundTextError(true);
            } else if (componentType == "thankyouMessage") {
                setThankyouMessageTextError(true);
            }   
        } else {
            setTextError("");
            if(componentType == "welcomeMessage") {
                handleInboundTextError(false);
            } else if (componentType == "thankyouMessage") {
                setThankyouMessageTextError(false);
            }
        }
    }, [])

    
    const handleMessageChange = ({ target: { value } }) => {
        setInboundCallingData({...inboundCallingData, [messagetype[componentType]] : value})
        sessionStorage.removeItem('inboundCallingData')
        if(value == "") {
            setTextError("Enter text to proceed.");
            if(componentType == "welcomeMessage") {
                handleInboundTextError(true);
            } else if (componentType == "thankyouMessage") {
                setThankyouMessageTextError(true);
            }   
        } else {
            setTextError("");
            if(componentType == "welcomeMessage") {
                handleInboundTextError(false);
            } else if (componentType == "thankyouMessage") {
                setThankyouMessageTextError(false);
            }
        }
    }

    
    return (
        <div id="inbound-text-container">
            <TextArea
                value={inboundCallingData[messagetype[componentType]]}
                onChange={handleMessageChange}
                autoSize={{ minRows: 4, maxRows: 4 }}
                className={textError !== "" ? "inbound-textarea-error-border" : ""}
            />
            {textError !== "" && <div className="inbound-textarea-error">{textError}</div>}
        </div>
    )
}

export default InboundTextContainer;