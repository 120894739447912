import React, { useEffect } from "react";

export const AGENT_PROFANITY_TITLE = 'Profanity filteration';
export const PROFANITY_NOT_ENABLED_TITLE = 'Profanity filteration is not enabled';
export const PROFANITY_NOT_ENABLED_SUBHEADING = 'Your agents are at the risk of receiving explicit or vulgar messages from any contact. To guard agents and block such messages, enable profanity filtration.';
export const ENABLE_PROFANITY_BUTTON =  'Enable profanity filtration';
export const PROFANVE_MESSAGE_TITLE = 'Profane messages blocked';
export const PROFANVE_MESSAGE_DETAILS = '54 agents have been protected against 1082 profane messages';
export const CONTACTS_SENDING_PROFANE = 'Unique contacts sending profane messages';
export const CONTACTS_SENDING_PROFANE_DETAILS = 'Total unique contacts in the campaign who have sent profane messages to the agents.';
export const PROFANITY_MODAL_TITLE = 'Are you sure you want to enable agent profanity guard?';
export const PROFANITY_MODAL_SUBHEADING = 'Once enabled, the agents will no longer receive explicit or vulgar messages from the contacts.';
export const ENABLE_PROFANITY_MODAL_BUTTON = 'Enable profanity filtration';
export const PROFANITY_ENABLED_SUCCESSFULLY = 'Agent profanity guard was enabled successfully';
