import * as React from "react"

const OpenArrowIcon = (props) => (
  <svg
    width={props.width}
    height={props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.086.5h-1.025a.22.22 0 0 0-.177.09L6 5.944 2.116.59A.22.22 0 0 0 1.94.5H.914a.11.11 0 0 0-.089.174L5.646 7.32c.175.24.533.24.707 0l4.82-6.646A.109.109 0 0 0 11.087.5Z"
      fill={props.fill || "#000"}
      fillOpacity={props.fillOpacity}
    />
  </svg>
)

export default OpenArrowIcon
