import * as React from "react"

function DetailsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 22 12"
      width={20}
      height={10}
      {...props}
    >
      <path
        d="M16 1h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H6C4.35 9 3 7.65 3 6s1.35-3 3-3h4V1H6C3.24 1 1 3.24 1 6s2.24 5 5 5h4V9zM7 5h8v2H7V5z"
        vectorEffect="non-scaling-stroke"
        fill="#3F89DE"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default DetailsIcon