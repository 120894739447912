import * as React from "react";
const KYCVeteranIcon = (props) => (
    <svg
    width={113}
    height={80}
    viewBox="0 0 113 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={56.5} cy={42.9839} r={33.5} fill="#EFF0F4" />
    <path
      d="M46.0538 28.9353L23.7205 48.0267C24.585 54.3665 28.8835 61.4747 30.9248 64.2364C40.1463 74.3224 51.5771 76.3636 56.1398 76.1234C71.7011 74.9708 79.6738 67.7184 81.715 64.2364L46.0538 28.9353Z"
      fill="#DDEEED"
      stroke="#B2F7F9"
      strokeWidth={1.08064}
    />
    <path
      d="M72.1092 13.3425C78.4573 16.6855 83.5645 21.9781 86.6789 28.4414C89.7934 34.9046 90.7506 42.1971 89.4096 49.2451C88.0687 56.2932 84.5004 62.7246 79.23 67.5926C73.9597 72.4605 67.2657 75.5079 60.1335 76.2861C53.0014 77.0643 45.8077 75.5321 39.6116 71.9153C33.4155 68.2984 28.5443 62.7878 25.715 56.1947C22.8856 49.6017 22.2477 42.2744 23.8951 35.2916C25.5425 28.3088 29.3881 22.0393 34.866 17.4061"
      stroke="#484848"
      strokeWidth={1.29677}
    />
    <path
      d="M23 48.0267L46.0537 28.2148L82.0752 63.5159"
      stroke="#484848"
      strokeWidth={1.29677}
    />
    <path d="M46.0538 32.5376V35.0591" stroke="#484848" strokeWidth={1.08064} />
    <path d="M46.0538 37.5808V40.1023" stroke="#484848" strokeWidth={1.08064} />
    <path d="M46.0538 42.6235V45.145" stroke="#484848" strokeWidth={1.08064} />
    <path
      d="M61.1828 43.7043L71.4797 34.699L88.5591 51.2689"
      stroke="#484848"
      strokeWidth={1.29677}
    />
    <path d="M46.0538 28.5753V3" stroke="#484848" strokeWidth={1.29677} />
    <rect x={46.7742} y={3} width={16.5699} height={11.5269} fill="#FF8935" />
    <path
      d="M75.2311 64.2366C71.6349 68.0419 68.7508 69.2253 63.5483 70.5463C58.3458 71.8673 52.8508 71.7226 47.7334 70.1298C42.6161 68.5371 38.0976 65.5651 34.7292 61.5763C31.3607 57.5876 30.0083 54.5556 29.4838 49.4678"
      stroke="#484848"
      strokeWidth={1.29677}
      strokeLinecap="round"
    />
  </svg>
);
export default KYCVeteranIcon;
