import React, { useState, useEffect } from "react";
import upsellFixtures from "../../../constants/upsellFixtures";
import GenericUpsellModal from "./GenericUpsellModal";

const UpSellWrapperForJquery = ({ componentData, unMountTheComponent, ...restProps }) => {
  const [showUpsellModal, setShowUpsellModal] = useState(true);

  const { product, apiToken, showUpgradeButton } = componentData;

  useEffect(() => {
    showUpsellModal === false
  }, [showUpsellModal]);


  return (
    <>
      {showUpsellModal && (
        <GenericUpsellModal
          upsellData={upsellFixtures[product.unique_name] ?? null}
          closeUpSellModal={() => setShowUpsellModal((prev) => false)}
          showUpgradeButton={showUpgradeButton ?? false }
          product={{
            id: product.id,
            cost: product.cost ?? 0,
          }}
          icon_url={product.screenshot_url + product.icon}
          apiToken={apiToken}
          unMountTheComponent={unMountTheComponent}
        />
      )}
    </>
  );
};

export default UpSellWrapperForJquery;
