import React from "react"
const IStoreIcon = ()=>{
    return (
        <svg width="102" height="34" viewBox="0 0 102 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5756_73147)">
                <path d="M93.615 -0.000133641H8.1049C7.79321 -0.000133641 7.48525 -0.000133641 7.17438 0.00156636C6.91416 0.00326636 6.656 0.00820486 6.39328 0.0123614C5.82251 0.0190779 5.25309 0.0692912 4.68996 0.162565C4.12762 0.257869 3.5829 0.437545 3.07421 0.695515C2.56615 0.955667 2.10192 1.29371 1.69836 1.69738C1.29268 2.09991 0.954521 2.56518 0.696875 3.0753C0.438517 3.58441 0.259372 4.12996 0.165625 4.69312C0.0709865 5.25557 0.0200609 5.82451 0.0133047 6.39482C0.00541675 6.65543 0.00458374 6.91691 0.000427246 7.17756V26.8247C0.00458374 27.0886 0.00541675 27.3443 0.0133047 27.6083C0.0200629 28.1786 0.0709886 28.7475 0.165625 29.3099C0.259113 29.8734 0.438269 30.4193 0.696875 30.9286C0.954404 31.437 1.29261 31.9004 1.69836 32.3007C2.10039 32.7062 2.56492 33.0444 3.07421 33.3026C3.58289 33.5613 4.12756 33.742 4.68996 33.8388C5.25319 33.9313 5.82255 33.9816 6.39328 33.9891C6.656 33.9949 6.91416 33.9982 7.17438 33.9982C7.48524 33.9999 7.79323 33.9999 8.1049 33.9999H93.615C93.9204 33.9999 94.2309 33.9999 94.5364 33.9982C94.7954 33.9982 95.061 33.9949 95.32 33.9891C95.8896 33.982 96.4579 33.9317 97.02 33.8388C97.5842 33.7413 98.1308 33.5606 98.6419 33.3026C99.1508 33.0443 99.6149 32.706 100.017 32.3007C100.421 31.8989 100.76 31.4358 101.021 30.9286C101.278 30.4189 101.455 29.8731 101.547 29.3099C101.642 28.7474 101.695 28.1786 101.705 27.6083C101.708 27.3443 101.708 27.0886 101.708 26.8247C101.715 26.5159 101.715 26.2088 101.715 25.8949V8.10558C101.715 7.7943 101.715 7.48551 101.708 7.17756C101.708 6.91691 101.708 6.65543 101.705 6.39479C101.695 5.82443 101.642 5.25562 101.547 4.69309C101.455 4.13026 101.277 3.58474 101.021 3.07527C100.496 2.05278 99.6642 1.22049 98.6419 0.695438C98.1308 0.438099 97.5841 0.258471 97.02 0.162488C96.458 0.0688037 95.8897 0.018573 95.32 0.0122424C95.061 0.00809436 94.7954 0.00311336 94.5364 0.00145586C94.2309 -0.000244141 93.9204 -0.000244141 93.615 -0.000244141V-0.000133641Z" fill="#A6A6A6"/>
                <path d="M7.17878 33.2563C6.9198 33.2563 6.66708 33.253 6.41014 33.2472C5.87785 33.2403 5.34681 33.194 4.82137 33.1086C4.33142 33.0242 3.85679 32.8673 3.41314 32.6429C2.97355 32.4204 2.57262 32.1286 2.22569 31.7788C1.87374 31.4331 1.58072 31.0321 1.35826 30.5918C1.13334 30.1486 0.977679 29.6735 0.896706 29.1831C0.809262 28.6562 0.761951 28.1235 0.755181 27.5894C0.749792 27.4101 0.742737 26.8133 0.742737 26.8133V7.17777C0.742737 7.17777 0.750251 6.59008 0.755223 6.41742C0.761705 5.8842 0.808741 5.35224 0.895924 4.82616C0.977047 4.33445 1.13282 3.85802 1.35786 3.41337C1.57951 2.97334 1.8709 2.57207 2.22073 2.22511C2.57017 1.87486 2.97239 1.5816 3.41272 1.35601C3.85536 1.13237 4.32909 0.976508 4.81804 0.893665C5.34521 0.807446 5.8781 0.760832 6.41223 0.754214L7.1792 0.743835H94.5325L95.3086 0.75463C95.8379 0.76092 96.3659 0.807118 96.8882 0.892832C97.3821 0.976714 97.8608 1.13366 98.3085 1.3585C99.1906 1.81312 99.9084 2.53237 100.361 3.41545C100.583 3.85703 100.736 4.32957 100.816 4.81703C100.904 5.34743 100.954 5.88356 100.964 6.42116C100.966 6.66188 100.966 6.92045 100.966 7.17777C100.973 7.49652 100.973 7.79992 100.973 8.1058V25.8952C100.973 26.204 100.973 26.5053 100.966 26.8091C100.966 27.0855 100.966 27.3387 100.963 27.5993C100.953 28.1273 100.905 28.6539 100.818 29.1748C100.738 29.6687 100.584 30.1475 100.359 30.5943C100.135 31.0299 99.8434 31.4274 99.4955 31.7722C99.1483 32.1239 98.7466 32.4173 98.306 32.6413C97.8596 32.8674 97.3816 33.0249 96.8882 33.1086C96.3628 33.1944 95.8317 33.2408 95.2994 33.2472C95.0505 33.253 94.7898 33.2563 94.5366 33.2563L93.6152 33.258L7.17878 33.2563Z" fill="black"/>
                <path d="M21.0544 17.2555C21.0635 16.5461 21.252 15.8505 21.6022 15.2334C21.9524 14.6163 22.4531 14.0979 23.0575 13.7263C22.6735 13.1779 22.1669 12.7266 21.578 12.4082C20.9891 12.0899 20.3341 11.9132 19.665 11.8923C18.2376 11.7424 16.8539 12.7464 16.1265 12.7464C15.3851 12.7464 14.2652 11.9071 13.0593 11.9319C12.2793 11.9571 11.5191 12.184 10.8528 12.5903C10.1865 12.9967 9.63682 13.5687 9.25732 14.2506C7.61342 17.0968 8.83962 21.2797 10.4143 23.5803C11.2022 24.7068 12.123 25.9652 13.3278 25.9206C14.5068 25.8717 14.9472 25.1688 16.3703 25.1688C17.7803 25.1688 18.1934 25.9206 19.4227 25.8922C20.6878 25.8717 21.4849 24.7607 22.2451 23.6235C22.8112 22.8208 23.2468 21.9336 23.5358 20.9948C22.8008 20.684 22.1735 20.1636 21.7323 19.4987C21.291 18.8337 21.0553 18.0536 21.0544 17.2555Z" fill="white"/>
                <path d="M18.733 10.3794C19.4228 9.5513 19.7627 8.48695 19.6804 7.41235C18.6265 7.52304 17.653 8.02672 16.9539 8.82302C16.6121 9.21204 16.3503 9.66461 16.1835 10.1549C16.0167 10.6451 15.9481 11.1634 15.9818 11.6802C16.5089 11.6856 17.0304 11.5714 17.5069 11.3461C17.9835 11.1207 18.4027 10.7902 18.733 10.3794Z" fill="white"/>
                <path d="M35.958 23.0691H31.9346L30.9684 25.9221H29.2642L33.0751 15.3668H34.8457L38.6565 25.9221H36.9233L35.958 23.0691ZM32.3513 21.7526H35.5405L33.9683 17.1225H33.9243L32.3513 21.7526Z" fill="white"/>
                <path d="M46.8869 22.0749C46.8869 24.4664 45.6069 26.0028 43.6753 26.0028C43.186 26.0284 42.6994 25.9157 42.2712 25.6776C41.843 25.4395 41.4904 25.0856 41.254 24.6564H41.2175V28.4682H39.6378V18.2267H41.1668V19.5066H41.1959C41.4432 19.0795 41.8017 18.7275 42.2332 18.4879C42.6647 18.2483 43.153 18.1302 43.6463 18.1461C45.5994 18.1461 46.8869 19.6901 46.8869 22.0749ZM45.2633 22.0749C45.2633 20.5169 44.4581 19.4925 43.2296 19.4925C42.0227 19.4925 41.2108 20.5384 41.2108 22.0749C41.2108 23.6255 42.0227 24.6639 43.2296 24.6639C44.4581 24.6639 45.2633 23.6471 45.2633 22.0749Z" fill="white"/>
                <path d="M55.3561 22.0749C55.3561 24.4664 54.0761 26.0028 52.1445 26.0028C51.6552 26.0284 51.1686 25.9157 50.7404 25.6776C50.3121 25.4395 49.9596 25.0856 49.7232 24.6564H49.6866V28.4681H48.107V18.2266H49.636V19.5066H49.665C49.9124 19.0795 50.2708 18.7275 50.7023 18.4879C51.1338 18.2483 51.6222 18.1302 52.1154 18.1461C54.0686 18.1461 55.3561 19.6901 55.3561 22.0749ZM53.7325 22.0749C53.7325 20.5168 52.9273 19.4925 51.6988 19.4925C50.4918 19.4925 49.68 20.5384 49.68 22.0749C49.68 23.6255 50.4918 24.6639 51.6988 24.6639C52.9273 24.6639 53.7325 23.6471 53.7325 22.0749H53.7325Z" fill="white"/>
                <path d="M60.9538 22.9811C61.0709 24.0279 62.0877 24.7151 63.4772 24.7151C64.8087 24.7151 65.7666 24.0278 65.7666 23.084C65.7666 22.2647 65.1889 21.7742 63.8209 21.438L62.4529 21.1084C60.5147 20.6403 59.6149 19.7338 59.6149 18.2629C59.6149 16.4417 61.202 15.1908 63.4557 15.1908C65.6861 15.1908 67.2151 16.4417 67.2666 18.2629H65.672C65.5765 17.2095 64.7057 16.5737 63.4332 16.5737C62.1607 16.5737 61.29 17.217 61.29 18.1534C61.29 18.8996 61.8461 19.3387 63.2066 19.6749L64.3696 19.9604C66.5352 20.4726 67.435 21.3425 67.435 22.8864C67.435 24.8612 65.862 26.098 63.3602 26.098C61.0194 26.098 59.4389 24.8902 59.3368 22.981L60.9538 22.9811Z" fill="white"/>
                <path d="M70.8464 16.4051V18.2263H72.3098V19.4772H70.8464V23.7197C70.8464 24.3788 71.1394 24.6859 71.7827 24.6859C71.9564 24.6829 72.1299 24.6707 72.3023 24.6494V25.8929C72.0131 25.9469 71.7191 25.9714 71.4249 25.9659C69.8669 25.9659 69.2593 25.3807 69.2593 23.8882V19.4772H68.1403V18.2263H69.2592V16.4051H70.8464Z" fill="white"/>
                <path d="M73.1564 22.0745C73.1564 19.6532 74.5824 18.1317 76.8062 18.1317C79.0375 18.1317 80.4569 19.6532 80.4569 22.0745C80.4569 24.5025 79.045 26.0174 76.8062 26.0174C74.5683 26.0174 73.1564 24.5025 73.1564 22.0745ZM78.8474 22.0745C78.8474 20.4135 78.0862 19.4332 76.8062 19.4332C75.5262 19.4332 74.7659 20.421 74.7659 22.0745C74.7659 23.7421 75.5262 24.715 76.8062 24.715C78.0862 24.715 78.8474 23.7421 78.8474 22.0745H78.8474Z" fill="white"/>
                <path d="M81.7584 18.2266H83.265V19.5364H83.3015C83.4034 19.1273 83.6431 18.7658 83.9802 18.5126C84.3173 18.2594 84.7313 18.13 85.1526 18.1461C85.3346 18.1454 85.5161 18.1652 85.6938 18.205V19.6825C85.464 19.6123 85.2242 19.5801 84.984 19.5871C84.7546 19.5778 84.5258 19.6182 84.3134 19.7057C84.101 19.7931 83.9101 19.9255 83.7537 20.0937C83.5974 20.2619 83.4792 20.462 83.4075 20.6801C83.3357 20.8983 83.312 21.1294 83.338 21.3576V25.9222H81.7584L81.7584 18.2266Z" fill="white"/>
                <path d="M92.9788 23.6616C92.7663 25.0586 91.4058 26.0174 89.6651 26.0174C87.4264 26.0174 86.0368 24.5174 86.0368 22.111C86.0368 19.6972 87.4338 18.1317 89.5987 18.1317C91.7278 18.1317 93.0668 19.5942 93.0668 21.9276V22.4688H87.6314V22.5643C87.6063 22.8475 87.6418 23.1328 87.7354 23.4013C87.8291 23.6697 87.9788 23.9152 88.1746 24.1214C88.3704 24.3275 88.6078 24.4897 88.8711 24.597C89.1344 24.7044 89.4175 24.7545 89.7016 24.744C90.0749 24.779 90.4495 24.6926 90.7697 24.4975C91.0899 24.3025 91.3386 24.0093 91.4788 23.6616L92.9788 23.6616ZM87.6389 21.3648H91.4863C91.5005 21.1101 91.4619 20.8553 91.3729 20.6163C91.2839 20.3773 91.1465 20.1592 90.9692 19.9758C90.792 19.7924 90.5787 19.6476 90.3429 19.5505C90.107 19.4534 89.8537 19.4062 89.5987 19.4116C89.3414 19.4101 89.0864 19.4595 88.8484 19.5571C88.6104 19.6546 88.394 19.7983 88.2118 19.9799C88.0296 20.1615 87.8852 20.3773 87.7868 20.615C87.6885 20.8527 87.6382 21.1076 87.6389 21.3648V21.3648Z" fill="white"/>
                <path d="M32.1516 7.42165C32.4828 7.39788 32.8151 7.44791 33.1246 7.56813C33.4341 7.68835 33.7131 7.87576 33.9414 8.11684C34.1697 8.35792 34.3416 8.64666 34.4448 8.96225C34.548 9.27783 34.5799 9.61237 34.5381 9.94176C34.5381 11.5621 33.6624 12.4935 32.1516 12.4935H30.3196V7.42165H32.1516ZM31.1074 11.7762H32.0636C32.3003 11.7903 32.5371 11.7515 32.7569 11.6626C32.9766 11.5737 33.1738 11.4369 33.334 11.2622C33.4942 11.0874 33.6135 10.8792 33.683 10.6525C33.7526 10.4259 33.7708 10.1866 33.7362 9.9521C33.7683 9.71849 33.7483 9.48068 33.6776 9.25572C33.607 9.03076 33.4874 8.8242 33.3276 8.65086C33.1677 8.47752 32.9715 8.34168 32.753 8.25308C32.5345 8.16448 32.2991 8.12531 32.0636 8.13838H31.1074V11.7762Z" fill="white"/>
                <path d="M35.4272 10.5776C35.4032 10.3261 35.432 10.0723 35.5117 9.83252C35.5915 9.59277 35.7205 9.37232 35.8904 9.18534C36.0604 8.99835 36.2675 8.84895 36.4986 8.74672C36.7297 8.64449 36.9796 8.59167 37.2322 8.59167C37.4849 8.59167 37.7348 8.64449 37.9659 8.74672C38.197 8.84895 38.4041 8.99835 38.5741 9.18534C38.744 9.37232 38.873 9.59277 38.9528 9.83252C39.0325 10.0723 39.0613 10.3261 39.0373 10.5776C39.0618 10.8294 39.0333 11.0835 38.9538 11.3237C38.8742 11.5638 38.7453 11.7847 38.5753 11.972C38.4053 12.1594 38.198 12.3091 37.9667 12.4115C37.7354 12.514 37.4852 12.5669 37.2322 12.5669C36.9793 12.5669 36.7291 12.514 36.4978 12.4115C36.2665 12.3091 36.0592 12.1594 35.8892 11.972C35.7192 11.7847 35.5903 11.5638 35.5107 11.3237C35.4312 11.0835 35.4027 10.8294 35.4272 10.5776ZM38.2603 10.5776C38.2603 9.74794 37.8876 9.26276 37.2335 9.26276C36.5769 9.26276 36.2075 9.74794 36.2075 10.5776C36.2075 11.4139 36.5769 11.8954 37.2335 11.8954C37.8876 11.8953 38.2603 11.4106 38.2603 10.5776H38.2603Z" fill="white"/>
                <path d="M43.8366 12.4933H43.053L42.262 9.67431H42.2022L41.4145 12.4933H40.6383L39.5833 8.66577H40.3495L41.0351 11.5864H41.0916L41.8785 8.66577H42.6031L43.3901 11.5864H43.4498L44.1321 8.66577H44.8875L43.8366 12.4933Z" fill="white"/>
                <path d="M45.7757 8.66572H46.5028V9.27375H46.5593C46.655 9.05537 46.8166 8.87231 47.0213 8.75007C47.226 8.62784 47.4638 8.57252 47.7015 8.59184C47.8877 8.57784 48.0747 8.60592 48.2486 8.674C48.4225 8.74208 48.5789 8.84842 48.7061 8.98514C48.8333 9.12186 48.9282 9.28545 48.9836 9.4638C49.039 9.64214 49.0536 9.83067 49.0263 10.0154V12.4932H48.2709V10.2051C48.2709 9.59002 48.0036 9.28413 47.445 9.28413C47.3185 9.27824 47.1923 9.29976 47.075 9.34722C46.9576 9.39468 46.8519 9.46694 46.7651 9.55907C46.6783 9.6512 46.6124 9.76101 46.5721 9.88097C46.5317 10.0009 46.5177 10.1282 46.5311 10.2541V12.4932H45.7757L45.7757 8.66572Z" fill="white"/>
                <path d="M50.2297 7.17163H50.985V12.4933H50.2297V7.17163Z" fill="white"/>
                <path d="M52.0335 10.5777C52.0095 10.3261 52.0383 10.0723 52.1181 9.83256C52.1979 9.59279 52.3269 9.37234 52.4968 9.18535C52.6668 8.99836 52.874 8.84896 53.1051 8.74672C53.3362 8.64449 53.5861 8.59167 53.8388 8.59167C54.0915 8.59167 54.3414 8.64449 54.5724 8.74672C54.8035 8.84896 55.0107 8.99836 55.1807 9.18535C55.3506 9.37234 55.4797 9.59279 55.5594 9.83256C55.6392 10.0723 55.668 10.3261 55.644 10.5777C55.6685 10.8295 55.64 11.0836 55.5604 11.3237C55.4808 11.5639 55.3519 11.7847 55.1819 11.9721C55.0119 12.1594 54.8046 12.3091 54.5732 12.4116C54.3419 12.514 54.0917 12.567 53.8388 12.567C53.5858 12.567 53.3356 12.514 53.1043 12.4116C52.873 12.3091 52.6656 12.1594 52.4956 11.9721C52.3256 11.7847 52.1967 11.5639 52.1171 11.3237C52.0375 11.0836 52.0091 10.8295 52.0335 10.5777ZM54.8666 10.5777C54.8666 9.74801 54.4939 9.26283 53.8398 9.26283C53.1832 9.26283 52.8138 9.74801 52.8138 10.5777C52.8138 11.414 53.1832 11.8954 53.8398 11.8954C54.4939 11.8954 54.8666 11.4107 54.8666 10.5777H54.8666Z" fill="white"/>
                <path d="M56.441 11.4106C56.441 10.7216 56.954 10.3245 57.8646 10.268L58.9014 10.2082V9.87788C58.9014 9.47363 58.6341 9.24536 58.1178 9.24536C57.6961 9.24536 57.4039 9.40017 57.3201 9.67078H56.5888C56.666 9.01335 57.2844 8.59167 58.1527 8.59167C59.1122 8.59167 59.6534 9.06938 59.6534 9.87788V12.493H58.9263V11.9551H58.8665C58.7452 12.1481 58.5749 12.3054 58.3729 12.411C58.1709 12.5165 57.9445 12.5666 57.7169 12.5561C57.5562 12.5728 57.3938 12.5557 57.2401 12.5058C57.0865 12.4559 56.945 12.3743 56.8248 12.2664C56.7046 12.1585 56.6084 12.0266 56.5423 11.8792C56.4762 11.7318 56.4417 11.5722 56.441 11.4106ZM58.9014 11.0836V10.7636L57.9667 10.8233C57.4396 10.8586 57.2006 11.0379 57.2006 11.3753C57.2006 11.7198 57.4994 11.9203 57.9103 11.9203C58.0307 11.9325 58.1523 11.9203 58.2679 11.8845C58.3835 11.8488 58.4908 11.7901 58.5832 11.712C58.6757 11.634 58.7515 11.5381 58.8062 11.4301C58.8608 11.3222 58.8932 11.2043 58.9014 11.0836Z" fill="white"/>
                <path d="M60.6459 10.5779C60.6459 9.36845 61.2677 8.60228 62.2347 8.60228C62.4739 8.59126 62.7113 8.64855 62.9192 8.76746C63.127 8.88637 63.2967 9.06199 63.4084 9.27378H63.4649V7.17163H64.2203V12.4933H63.4964V11.8885H63.4367C63.3163 12.0989 63.1407 12.2723 62.9288 12.39C62.7169 12.5078 62.4769 12.5653 62.2347 12.5563C61.261 12.5564 60.6459 11.7902 60.6459 10.5779ZM61.4262 10.5779C61.4262 11.3897 61.8089 11.8782 62.4489 11.8782C63.0855 11.8782 63.479 11.3826 63.479 10.5812C63.479 9.78348 63.0814 9.28087 62.4489 9.28087C61.813 9.28087 61.4262 9.77269 61.4262 10.5779H61.4262Z" fill="white"/>
                <path d="M67.3449 10.5776C67.3208 10.3261 67.3496 10.0723 67.4294 9.83252C67.5091 9.59277 67.6381 9.37232 67.8081 9.18534C67.978 8.99835 68.1852 8.84895 68.4163 8.74672C68.6473 8.64449 68.8972 8.59167 69.1499 8.59167C69.4026 8.59167 69.6525 8.64449 69.8835 8.74672C70.1146 8.84895 70.3218 8.99835 70.4917 9.18534C70.6617 9.37232 70.7907 9.59277 70.8704 9.83252C70.9502 10.0723 70.979 10.3261 70.9549 10.5776C70.9794 10.8294 70.951 11.0835 70.8714 11.3237C70.7919 11.5638 70.663 11.7847 70.493 11.972C70.323 12.1594 70.1157 12.3091 69.8844 12.4115C69.6531 12.514 69.4029 12.5669 69.1499 12.5669C68.8969 12.5669 68.6467 12.514 68.4154 12.4115C68.1841 12.3091 67.9768 12.1594 67.8068 11.972C67.6368 11.7847 67.5079 11.5638 67.4284 11.3237C67.3488 11.0835 67.3204 10.8294 67.3449 10.5776ZM70.178 10.5776C70.178 9.74794 69.8052 9.26276 69.1512 9.26276C68.4946 9.26276 68.1252 9.74794 68.1252 10.5776C68.1252 11.4139 68.4946 11.8954 69.1512 11.8954C69.8053 11.8953 70.178 11.4106 70.178 10.5776Z" fill="white"/>
                <path d="M71.9682 8.66572H72.6954V9.27375H72.7518C72.8476 9.05537 73.0091 8.87231 73.2138 8.75007C73.4185 8.62784 73.6563 8.57252 73.894 8.59184C74.0802 8.57784 74.2672 8.60592 74.4411 8.674C74.615 8.74208 74.7714 8.84842 74.8986 8.98514C75.0259 9.12186 75.1207 9.28545 75.1761 9.4638C75.2316 9.64214 75.2461 9.83067 75.2188 10.0154V12.4932H74.4634V10.2051C74.4634 9.59002 74.1961 9.28413 73.6375 9.28413C73.5111 9.27824 73.3848 9.29976 73.2675 9.34722C73.1501 9.39468 73.0444 9.46694 72.9576 9.55907C72.8708 9.6512 72.805 9.76101 72.7646 9.88097C72.7242 10.0009 72.7102 10.1282 72.7236 10.2541V12.4932H71.9682V8.66572Z" fill="white"/>
                <path d="M79.4865 7.71295V8.68331H80.3158V9.31957H79.4865V11.2877C79.4865 11.6886 79.6517 11.8642 80.0277 11.8642C80.124 11.8639 80.2202 11.858 80.3158 11.8467V12.4759C80.1801 12.5002 80.0427 12.5131 79.9049 12.5145C79.0648 12.5145 78.7303 12.219 78.7303 11.4811V9.31954H78.1227V8.68328H78.7303V7.71295H79.4865Z" fill="white"/>
                <path d="M81.3481 7.17163H82.0968V9.28086H82.1566C82.257 9.06044 82.4229 8.87634 82.6318 8.75364C82.8406 8.63095 83.0822 8.57563 83.3236 8.59522C83.5089 8.58514 83.6941 8.61607 83.866 8.68581C84.038 8.75554 84.1924 8.86236 84.3183 8.99865C84.4442 9.13494 84.5385 9.29733 84.5944 9.47425C84.6503 9.65116 84.6665 9.83824 84.6418 10.0221V12.4933H83.8857V10.2085C83.8857 9.59712 83.6009 9.28749 83.0672 9.28749C82.9374 9.27685 82.8068 9.2947 82.6846 9.33979C82.5624 9.38489 82.4515 9.45614 82.3597 9.54857C82.2679 9.64099 82.1974 9.75236 82.1532 9.87488C82.1089 9.9974 82.092 10.1281 82.1035 10.2579V12.4933H81.3481L81.3481 7.17163Z" fill="white"/>
                <path d="M89.0457 11.4597C88.9431 11.8095 88.7207 12.112 88.4176 12.3145C88.1144 12.5169 87.7497 12.6063 87.3872 12.567C87.1351 12.5736 86.8845 12.5253 86.6529 12.4254C86.4212 12.3255 86.2141 12.1764 86.0459 11.9885C85.8777 11.8005 85.7524 11.5782 85.6787 11.3369C85.605 11.0957 85.5847 10.8413 85.6192 10.5914C85.5856 10.3407 85.6062 10.0858 85.6798 9.84383C85.7533 9.60186 85.8779 9.37851 86.0453 9.18889C86.2126 8.99928 86.4187 8.84784 86.6497 8.74481C86.8806 8.64178 87.131 8.58958 87.3839 8.59174C88.4489 8.59174 89.0914 9.31934 89.0914 10.5212V10.7848H86.3886V10.8271C86.3768 10.9676 86.3945 11.109 86.4405 11.2422C86.4865 11.3755 86.5598 11.4976 86.6558 11.6008C86.7518 11.7041 86.8683 11.7861 86.9978 11.8417C87.1273 11.8973 87.267 11.9252 87.408 11.9236C87.5887 11.9453 87.7717 11.9128 87.9339 11.8302C88.096 11.7475 88.2299 11.6186 88.3186 11.4596L89.0457 11.4597ZM86.3886 10.2262H88.3219C88.3314 10.0977 88.3139 9.96867 88.2706 9.84736C88.2273 9.72605 88.1591 9.61514 88.0704 9.52176C87.9817 9.42839 87.8744 9.3546 87.7554 9.30515C87.6365 9.2557 87.5085 9.23168 87.3797 9.23464C87.2491 9.23299 87.1194 9.25752 86.9984 9.30678C86.8774 9.35603 86.7675 9.42901 86.6751 9.52142C86.5828 9.61382 86.5098 9.72378 86.4606 9.84482C86.4114 9.96586 86.3869 10.0955 86.3886 10.2262H86.3886Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_5756_73147">
                    <rect width="101.714" height="34" fill="white" transform="translate(0.000427246 -0.000244141)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default IStoreIcon;