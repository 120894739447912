import React from "react";

export const IncrementArrowIcon = (props) =>{

    return(
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M10.1714 6.39215L6.28191 1.90973C6.24675 1.86917 6.20328 1.83664 6.15445 1.81435C6.10563 1.79206 6.05258 1.78052 5.9989 1.78052C5.94522 1.78052 5.89217 1.79206 5.84334 1.81435C5.79451 1.83664 5.75105 1.86917 5.71589 1.90973L1.82761 6.39215C1.81586 6.40566 1.80824 6.42227 1.80566 6.43999C1.80308 6.45771 1.80565 6.47579 1.81306 6.49209C1.82047 6.50839 1.83241 6.52222 1.84745 6.53193C1.8625 6.54163 1.88002 6.54681 1.89792 6.54684H2.84714C2.90105 6.54684 2.95261 6.5234 2.98894 6.48239L5.55417 3.52575V10.1246C5.55417 10.1761 5.59636 10.2183 5.64792 10.2183H6.35105C6.40261 10.2183 6.4448 10.1761 6.4448 10.1246V3.52575L9.01003 6.48239C9.04519 6.5234 9.09675 6.54684 9.15183 6.54684H10.101C10.1807 6.54684 10.2241 6.45309 10.1714 6.39215Z"
            fill="black"
            fillOpacity={0.45}
            />
        </svg>
    )
}