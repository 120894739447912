import React from "react";
import "./editUtmParamsContent.scss";

function EditUTMParamsContent(props){
    const { utmFieldList, setUTMTags, utmTags, errorUTMSourceStatus, errorUTMMediumStatus, setUTMSourceErrorStatus, setUTMMediumErrorStatus } = props;
    
    const setUTMTagObject = (utm_name, value) => {
            setUTMTags({...utmTags, [utm_name] : value});
    }
    const resetErrors = (utm_name, value) => {
        if(errorUTMSourceStatus && utm_name === "utm_source" && value.length > 0){
            setUTMSourceErrorStatus("");
        }else if(errorUTMMediumStatus && utm_name === "utm_medium" && value.length > 0){
            setUTMMediumErrorStatus("");
        }
    }
    return(
        <div className="utmParamsContent">
            {
                utmFieldList && 
                utmFieldList.map((utm_item, index) => <div key ={index}>
                        <div>
                            <div>{utm_item.name}</div>
                            <div>{utm_item.desc}</div>
                        </div>
                        <div>
                            <input 
                                id={utm_item.name} 
                                className = {`utm-input ${((utm_item.name === 'utm_source' && errorUTMSourceStatus) || (utm_item.name === 'utm_medium' && errorUTMMediumStatus)) ? 'errorStatus' : ''}`} 
                                type="text" 
                                placeholder={utm_item.placeholder} 
                                maxLength={200} 
                                required={(utm_item.name === 'utm_source' || utm_item.name === 'utm_medium') ? true : false} 
                                onBlur={(e) => setUTMTagObject(utm_item.name, e.target.value)} 
                                onChange={(e) => resetErrors(utm_item.name, e.target.value.trim())}
                                defaultValue={utm_item.value}
                            />
                            {
                                ((utm_item.name === 'utm_source' && errorUTMSourceStatus) || (utm_item.name === 'utm_medium' && errorUTMMediumStatus)) ? <div className="errorMessage">{utm_item.name} is required</div> : ""
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default EditUTMParamsContent;

