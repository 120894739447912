import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { Button, ConfigProvider, Modal } from "antd";
import UnlockAccount from "../../../assets/svg/UnlockAccount";
import "./AccountCreationFeeModal.scss";
import { benefits } from "./utils";


const AccountCreationFeeModal = ({componentData}) => {
    const navigateToPayment = () => {
        const accountCreationFee = componentData?.account_fee?.replace("$", '');
        const paymentDetails = {"type":"account_creation_fee","itemName":"Recharge","original_amount": accountCreationFee}
        sessionStorage.setItem("billing", JSON.stringify(paymentDetails));
        window.location.href = '/payment/';
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F06514',
                    borderRadius: 4,
                },
            }}
        >
            <Modal
                centered
                closable={false}
                footer={null}
                open={true}
                zIndex={10000}
                maskClosable={false}
            >
                <div className="unlock-container">
                    <UnlockAccount />
                    <p className="header">Unlock the Full Power of CallHub</p>
                    <div className="unlock-benefits">
                        {benefits.map((benefit) => (
                            <div key={benefit.id}>
                                <CheckCircleFilled style={{fontSize:'19px', color: '#F06514'}} />
                                <p>{benefit.text(componentData?.account_fee)}</p>
                            </div>
                        ))}
                    </div>
                    <Button 
                        size="large" 
                        className="unlock-action"
                        onClick={navigateToPayment}
                        type="primary"
                    >
                        Pay {componentData.account_fee} to Unlock
                    </Button>
                </div>
            </Modal>
        </ConfigProvider>
    )
}

export default AccountCreationFeeModal;