import React from "react";
import InboundCallingContainer from "../../components/InboundCalling/InboundCalling";


function InboundCalling (props) {
    return (
        <div>
        <InboundCallingContainer componentData={props.componentData}/> 
        </div>
    )
}


export default InboundCalling;