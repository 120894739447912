import React from 'react';
import './PieChartWrapper.scss';

const PieChartEmpty = () => {
  return (
    <div className='empty-pie-chart'>
      <p>No data to show pie chart</p>
      <p>The pie chart will appear when we capture sufficient data</p>
    </div>
  )
}

export default PieChartEmpty;