import { SettingOutlined } from "@ant-design/icons";
import { Dropdown, message } from "antd";
import React, { useState } from "react";
import DncSegregationModal from "./dncSegregationModal";
import "./dncSettings.scss";

const DncSettingsDropdown = (props) => {
  const { componentData, unMountTheComponent } = props;
  const [showModal, setShowModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleSettingsClick = () => {
    setShowModal(true);
  };

  const handleModalClose = (reload = false) => {
    setShowModal(false);
    if (reload) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const showSuccessMessage = (isApplied) => {
    messageApi.open({
      type: "success",
      content: "Settings applied",
    });
  };

  const items = [
    {
      key: "1",
      label: (
        <a target="_blank" href="/block_numbers/">
          Block numbers
        </a>
      ),
    },
    {
      key: "2",
      label: <p onClick={handleSettingsClick}>Settings</p>,
    },
  ];

  const getItems = () => {
    if (!componentData.showBlockNumbers) {
      items.shift();
      return { items };
    }
  };

  return (
    <>
      {componentData.renderSettings ? (
        <Dropdown 
          menu={getItems()} 
          trigger={["click"]}
          overlayClassName = "dnc-settings-dropdown"
        >
          <SettingOutlined />
        </Dropdown>
      ) : componentData.showBlockNumbers ? (
        <a target="_blank" href="/block_numbers/" className="dnc-block_numbers">
          Block numbers
        </a>) : <></>
      }
      {showModal && (
        <DncSegregationModal
          open={showModal}
          onClose={handleModalClose}
          settingsApplied={componentData.isApplied}
          showSuccessMessage={showSuccessMessage}
          isWhiteLabel={componentData.is_whitelabel}
        />
      )}
      {contextHolder}
    </>
  );
};

export default DncSettingsDropdown;
