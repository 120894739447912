import React, { useEffect, useState, useRef } from "react";
import { Switch, Button, Popover, Tag, Select, Spin, Modal, message } from 'antd';
import {CONTACT_CATEGORIZATION, CATEGORIZATION_SUBHEADING, SUGGESTIONS_TITLE, REVISE_CATEGORIE, SUGGESTIONS_CATEGORIE_POPOVER, CONTACT_CATEGORIE, CONTACT_CATEGORIZATION_POPOVER, ENABLE_RECORDING_TITLE,  ENABLE_RECORDING_SUBHEADING, ENABLE_RECORDING_BUTTON, SMART_INSIGHT_BETA_POPOVER} from '../SmartInsightConstants/SmartInsightConstants';
import {SmartInsightStarLineIcon} from '../../../../../assets/svg/SmartInsightStartLineIcon';
import {RefreshLineIcon} from '../../../../../assets/svg/RefreshLineIcon';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {goalCreation} from '../../CcAiPowerd/apis/CcAiPowedApi';

import './ContactCategorization.scss';

const ContactCategorization = (props) =>{
    const {auto_tags_enabled, selected_auto_tags, survey_id, campaign_action, is_campaign_live} = props;
    const [isContactCategorizationChecked , setIsContactCategorizationChecked] = useState(false);
    const [selectedTags , setSelectedTags] = useState([]);
    const [selectOptions, setSelectOptions] = useState([{label: '', value: ' '}]);
    const [noOptionMatch , setNoOptionMatch] = useState(false);
    const [tagToBeAdded, setTagToBeAdded] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const isEditMode = (campaign_action == 'edit' ? true : false);
    const initalLoad = useRef(null);
    const autoTagErrorContainerRef = useRef(null);
    const [data, setData] = useState([]);
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, messageContextHolder] = message.useMessage();
    const selectRef = useRef(null);

    const handleContactCategorization = () =>{
        setIsContactCategorizationChecked(prev => !prev);

        if(!document.getElementById('id_recording').checked && !isContactCategorizationChecked){
            modal.confirm({
                title: ENABLE_RECORDING_TITLE,
                icon: <ExclamationCircleOutlined />,
                content: ENABLE_RECORDING_SUBHEADING,
                okText: ENABLE_RECORDING_BUTTON,
                onOk:enableRecordCalls,
                onCancel: handleCancleButton,
                cancelText: 'Cancel',
                className: 'enable-profanity-modal'
            });
        }
    }
    const enableRecordCalls = () =>{
        document.getElementById("id_recording").checked = true;
    }
    const handleCancleButton = () =>{
        setIsContactCategorizationChecked(false);
    } 

    const handleTagSelection = (value)=>{
        if(selectedTags.length < 5){
            setSelectedTags((prevTags) => {
                if (!prevTags.includes(value)) {
                  return [...prevTags, value];
                }
                return prevTags;
            });
        }else{
            autoTagErrorContainerRef.current.innerHTML = 'Maximum 5 tags can be added';
            setTimeout(()=>{
                autoTagErrorContainerRef.current.innerHTML ='';
            },5000)
        }
    }

    const generateTags = async() =>{
        setShowLoader(true)
        let response = await goalCreation(survey_id, {regenerate_labels:true});
        if(response.status == 200){
            setData(response.data.labels);
        }else{
            error();
        }
        setShowLoader(false)
        return;
    }

    const handleSelectDelete = (value) =>{
        if(value != ' '){
            setSelectedTags(value);
        }
    }

    const AddNewTag = () =>{
        if(selectedTags?.length < 5 && tagToBeAdded.length){

            setSelectedTags((prevTags) => ([...prevTags , tagToBeAdded]));
        }else{
            autoTagErrorContainerRef.current.innerHTML = 'Maximum 5 tags can be added';
            setTimeout(()=>{
                autoTagErrorContainerRef.current.innerHTML ='';
            },5000)
        }
        selectRef.current.blur();
    }

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong, please try again',
        });
    };

    useEffect(()=>{
        let local_data = localStorage.getItem(`${survey_id}_contact_categories_selected`);    
        if(isEditMode && auto_tags_enabled){
            let tags = selected_auto_tags.filter(item => item.crm_type === 7).map(item => item.name);
            setSelectedTags(tags);
            setIsContactCategorizationChecked(true);
        }else if(local_data){
            setSelectedTags(JSON.parse(local_data));
            setIsContactCategorizationChecked(true);
        }
    },[])

    useEffect(()=>{
        if(initalLoad.current){
            localStorage.setItem(`${survey_id}_contact_categories_selected` ,JSON.stringify(selectedTags));
        }
        let allOptions = [];
        if(selectedTags.length){
            for(let i = 0 ; i< selectedTags.length; i++){
                allOptions.push({
                    label: selectedTags[i],
                    value: selectedTags[i],
                });
            }
            setSelectOptions(allOptions);
        }else{
            setSelectOptions([{label: '', value: ' '}]);
        }
        initalLoad.current = true;

        if (autoTagErrorContainerRef.current) {
            autoTagErrorContainerRef.current.innerHTML = '';
        }
    },[selectedTags])

    useEffect(()=>{
        if(isContactCategorizationChecked && !isEditMode){
            generateTags();
            handleSegmentTrackEvent('si-categrisation-enabled')
        }
    },[isContactCategorizationChecked])

    return(
        <>
            {contextHolder}
            {messageContextHolder}
            <div id='contactCategorizationContainer'>
                <div className='categorization-heading-section'>
                    <Switch checked={isContactCategorizationChecked} onChange={handleContactCategorization} id='contactCategorizationSwitch' disabled={(isEditMode && is_campaign_live) ? true : false }/>
                    <div className='categorization-heading'>
                        <div>
                            {CONTACT_CATEGORIZATION}
                        </div>
                        <Popover placement="right" arrow={false} content={CONTACT_CATEGORIZATION_POPOVER} trigger="click" overlayClassName="contact-categorization-popover">
                            <QuestionCircleOutlined />
                        </Popover>
                        <Popover placement="top" content={SMART_INSIGHT_BETA_POPOVER} overlayClassName="smart-insight-beta-popover">
                            <p className='smart-insight-beta-version'>Beta</p>
                        </Popover>

                    </div>
                </div>
                <div className="categorization-subheading">{CATEGORIZATION_SUBHEADING}</div>
                {isContactCategorizationChecked &&
                    <>
                        <div className="contact-categorie-select">
                            <div className="categorie-select-title">{CONTACT_CATEGORIE}</div>
                            <Select
                                mode="multiple"
                                allowClear
                                autoFocus={true}
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Type to add categories manually"
                                ref={selectRef}
                                onChange={handleSelectDelete}
                                options={selectOptions}
                                disabled = {(isEditMode && is_campaign_live)? true : false}
                                optionFilterProp="children"
                                filterOption={(input, option) =>{
                                        const optionLabel = (option?.label ?? '').toLowerCase();
                                        const inputLowerCase = input.toLowerCase();
                                        
                                        if (optionLabel.includes(inputLowerCase)) {
                                            return true;
                                        }
                                        if (input !== '') {
                                            setNoOptionMatch(true);
                                            setTagToBeAdded(input);
                                        }
                                        
                                        return false;
                                    }
                                }
                                notFoundContent={noOptionMatch ? <div onClick={AddNewTag} className="add-tag-option">Add "{tagToBeAdded}"</div> : null}
                                value = {selectedTags}
                            />
                            <div id='contact_categorization_error' ref={autoTagErrorContainerRef}></div>
                        </div>
                        <div className="suggested-categories-container">
                            <div className="suggestions-title-container">
                                <SmartInsightStarLineIcon size={20} />
                                <div className="suggestions-title">{SUGGESTIONS_TITLE}</div>
                                <Popover placement="rightTop" content={SUGGESTIONS_CATEGORIE_POPOVER} trigger="click">
                                    <QuestionCircleOutlined />
                                </Popover>
                            </div>
                            <div className={(isEditMode && is_campaign_live) ? 'suggestion-revise disabled' : 'suggestion-revise'} onClick={generateTags}>
                                <div className='suggest-revise-title'>{REVISE_CATEGORIE}</div>
                                <RefreshLineIcon size={20} />
                            </div>
                        </div>
                        <div className={(isEditMode && is_campaign_live) ? 'suggest-tags-container disabled' : 'suggest-tags-container'}>
                            {data.map((tag, key)=>(
                                <Tag onClick={()=> handleTagSelection(tag)} id={key}>{tag}</Tag>
                            ))}
                            { showLoader &&
                                <div className="generating-goal-loader">
                                    <Spin />
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default ContactCategorization