import React, { useEffect } from "react";
import {IncrementArrowIcon} from '../../../../../assets/svg/IncrementArrowIcon';
import {DecrementArrowIcon} from '../../../../../assets/svg/DecrementArrowIcon';
import './OverAllSentiment.scss';
const OverAllSentimentDetail = (props)=>{
    const { title,  number, increase, subhead , percentage, type, hidePercentage} = props
    return(
        <div className={type == 'Positive' ? "overall-sentiment-call-detail green-left-border" :(type == 'Negative' ? "overall-sentiment-call-detail red-left-border" : "overall-sentiment-call-detail yellow-left-border") }>
            <div className="call-sentiment-title">{title}</div>
            <div className="call-sentiment-data-section">
                <div className="call-sentiment-data-number">{number}</div>
                {!hidePercentage &&
                    <div className="call-sentiment-data-change">
                        {increase ? <IncrementArrowIcon size={12} /> : <DecrementArrowIcon size={12} />}
                        <div className="call-sentiment-percentage">{percentage}%</div>
                        <div className="call-sentiment-hours">last 24 hours</div>
                    </div>
                }
            </div>
            <div className="call-sentiment-subhead">{subhead}</div>
        </div>
    )
}

export default OverAllSentimentDetail;