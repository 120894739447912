import React from "react";

export const RefreshLineIcon = (props) =>{

    return(
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M17.7559 4.08789L16.6543 4.94922C15.1524 3.0293 12.8164 1.79688 10.1934 1.79688C5.66407 1.79688 1.99805 5.45898 1.99219 9.99023C1.98634 14.5254 5.66016 18.2031 10.1934 18.2031C13.7344 18.2031 16.752 15.957 17.9004 12.8105C17.9297 12.7285 17.8867 12.6367 17.8047 12.6094L16.6973 12.2285C16.6587 12.2153 16.6164 12.2177 16.5795 12.2352C16.5427 12.2527 16.5141 12.284 16.5 12.3223C16.4649 12.4199 16.4258 12.5176 16.3848 12.6133C16.0469 13.4141 15.5625 14.1328 14.9453 14.75C14.3331 15.3634 13.6083 15.8528 12.8106 16.1914C11.9844 16.541 11.1035 16.7188 10.1973 16.7188C9.28907 16.7188 8.41016 16.541 7.58399 16.1914C6.78551 15.8542 6.0604 15.3646 5.44923 14.75C4.83536 14.1379 4.34646 13.4121 4.00977 12.6133C3.66016 11.7852 3.48243 10.9062 3.48243 9.99805C3.48243 9.08984 3.66016 8.21094 4.00977 7.38281C4.34766 6.58203 4.83204 5.86328 5.44923 5.24609C6.06641 4.62891 6.78516 4.14453 7.58399 3.80469C8.41016 3.45508 9.29102 3.27734 10.1973 3.27734C11.1055 3.27734 11.9844 3.45508 12.8106 3.80469C13.609 4.14188 14.3341 4.63148 14.9453 5.24609C15.1387 5.43945 15.3203 5.64453 15.4883 5.85938L14.3125 6.77734C14.2892 6.79534 14.2715 6.81954 14.2614 6.84715C14.2513 6.87477 14.2492 6.90468 14.2553 6.93345C14.2614 6.96222 14.2755 6.98867 14.296 7.00977C14.3165 7.03088 14.3425 7.04577 14.3711 7.05273L17.8008 7.89258C17.8984 7.91602 17.9941 7.8418 17.9941 7.74219L18.0098 4.20898C18.0078 4.08008 17.8574 4.00781 17.7559 4.08789Z"
            fill="black"
            fillOpacity={0.45}
            />
        </svg>
    )
}