import React, { Component, useEffect } from "react";
// Importing Components
import _ from "lodash";
import Select from '../../../components/common/Select';
import DropDownComponent from '../../../components/common/dropDownComponent';
import { trackSegementEvents } from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';

//css
import "../commonStyle.scss";
import "./contactTracking.scss";
import Button from "../common/Button";

const OptionItem = (props) => (
    <option value={props.optionValue} defaultValue={props.selectOption ? true : null}>{props.optionText}</option>
)

const AddToDomainButton = () => {
    return (
        <Button
            handleClick={(e) => {
                window.open('https://callhub.io/', '_blank').focus();
                e.stopPropagation();
            }}
            name={"Add domain"} 
            type={"primary"}
            customStyles={{height: 30, fontSize: 14, width: '100%'}}
        />
    )
}

class ContactTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsList: this.props.domainList,
            customShortURLPreview : "",
            selectedDomain : "",
            selectedDomainId : "",
            backHalf : this.props.backHalf,
            backhalf_error_msg : "",
            title_error_msg : "",
            domainSelectOptions: {}
        }
    }

    handleDropDownChange = (event) => {
        this.setState({
            selectedDomainId: event.target.value,
            selectedDomain : event.target.selectedOptions[0].innerHTML,
        }, () => {
            this.props.genrateBackHalf(this.state.selectedDomainId);
            this.createPreviewShortenURL();
        })
    }
    handleInputChange = (event) => {
        let error = ""
        if(event.target.value.indexOf(" ") !== -1){
            return;
        }
        if(event.target.value.length < 30){
            this.props.updatedBackHalf(event.target.value)
            this.setState({backHalf : event.target.value}, () => {
                this.createPreviewShortenURL();
            }) 
            error = "";
        }else{
            error = "Back-half must not be greater than 30 characters.";
        }
        this.setState({backhalf_error_msg : error})
    }
    handleTitle = (event) => {
        let error = "", error_border="";
        if(event.target.value.length > 200){
            error = 'Title must not be greater than 200 characters.';
        }else{
            this.props.getUpdatedMetaTitle(event.target.value);
            error = "";
        }



        this.setState({title_error_msg : error, title_error_msg_bdr : error_border})
    }

    createPreviewShortenURL = () => {
        const { backHalf, selectedDomain, selectedDomainId, optionsList } = this.state;
        let customShortURL = (selectedDomain || optionsList[0].domain) + "/" + this.props.backHalf;
        this.setState({customShortURLPreview : customShortURL, backHalf: this.props.backHalf}, () => {
            this.props.getCustomShortURL({
                'fixedPrefix' : backHalf || this.props.backHalf, 
                "customShortURL": customShortURL, 
                "domainID" : selectedDomainId || optionsList[0].pk_str, 
                "selectedDomain" : selectedDomain
            });
        });
    }

    updateSelectedDomain = (event) => {
        if(event.value === "add_domain_btn"){
            return;
        }
        this.setState({
            domainSelectOptions : {...this.state.domainSelectOptions, selectedOption : event }
        });

        this.setState({
            selectedDomainId: event.value,
            selectedDomain : event.label,
        }, () => {
            this.props.genrateBackHalf(this.state.selectedDomainId);
            this.createPreviewShortenURL();
        });
    }

    componentWillUnmount() {
        this.props.setSegmentUrlType("default-back-half-no-tracking")
    }

    componentDidMount() {
        // segment for url shortner preview page

        trackSegementEvents('visited-url-short-link-preview-page',{
            'product_category': this.props.eventData.productCategory,
            'url_type': "default-back-half-no-tracking",
            'customer_type' : this.props.segmentCustomerType,
            'industry': this.props.eventData.industry
        });

        // this.createPreviewShortenURL()
        let domainsOptionList = [];
        this.props.domainList.map(domainItem => {
            domainsOptionList.push({value : domainItem.pk_str, label : domainItem.domain});
        });
        //Add domain button removed
        //domainsOptionList.push({label: <AddToDomainButton/>, value: "add_domain_btn"});
        let domainSelectOptions = {};
        domainSelectOptions.options = domainsOptionList;
        domainSelectOptions.selectedOption = (domainsOptionList.length > 0) ? domainsOptionList[0] : {label: "", value: ""};
        this.setState({
                        domainSelectOptions: domainSelectOptions,
                        selectedDomain: domainSelectOptions.selectedOption.label,
                        selectedDomainId:  domainSelectOptions.selectedOption.value,              
                      }, () => {
                        this.createPreviewShortenURL();
                      });
    }

    componentDidUpdate(prevProps) {
        if (this.props.backHalf !== prevProps.backHalf) {
            this.createPreviewShortenURL();
        }
    }

    domainSelectCustomStyles = {
        option: (provided, state) => ({
        ...provided,
        fontSize: "16px",
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: "3px 0 0 3px",
          display: "flex",
          fontSize: 16,
          minHeight: 40,
        })
      };
    
    render() {
        const { longURL, metaTitle, contactTrackingErrorMessage, contactTrackingTitleErrorMessage } = this.props;
        const { optionsList, customShortURLPreview, title_error_msg, backhalf_error_msg, backHalf } = this.state;
        return (
            <div style={{'paddingBottom': '100px'}}>			
                <div className="originalURL">
                    <div>Long URL</div>
                    <div>{longURL}</div>
                </div>
                <div className="urlTitle">
                    <label>Title</label>
                    <input className={(title_error_msg || contactTrackingTitleErrorMessage) ? 'errorBorder' : ''} onChange={this.handleTitle} type="text" value={metaTitle}/>
                    <div className="errorColor">{title_error_msg || contactTrackingTitleErrorMessage}</div>
                </div>
    
                <div className = "contructURL">
                    <div>
                        <label>Domain</label>
                        <Select
                            {...this.state.domainSelectOptions}
                            name="domains"
                            customStyle={this.domainSelectCustomStyles}
                            isClearable = {false}
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            isMulti={false}
                            changed={(event) => {this.updateSelectedDomain(event)}}
                            components={{
                                IndicatorSeparator: () => {
                                    return null;
                                },
                                DropdownIndicator: () =>{
                                    return <DropDownComponent/>
                                }
                            }}
                        />
                    </div>
                    <div>
                        <label>Customize backhalf</label>
                        <input type="text" className={(backhalf_error_msg || contactTrackingErrorMessage) && 'errorBorder'} onChange={this.handleInputChange} id="customBackHalf" value={backHalf}/>
                        <div className="errorColor">{backhalf_error_msg}</div>
                    </div>
                    <div className="errorColor">{contactTrackingErrorMessage}</div>
                </div>
                <div className="shortURLPreview">Shortened URL preview: <span>{customShortURLPreview}</span></div>
            </div>
        );
    }
}

export default ContactTracking;
