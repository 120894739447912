import React, {useRef, useState, useEffect} from "react";
import "./InboundAudioFileContainerStyles.scss";
import Select from "antd/lib/select"
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSelect.scss";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSelectDefault.scss";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSelectEmpty.scss";
import { getDefaultAudioFliles, getAudioFiles } from "../../utilities/InboundcallingUtilities";
import axios from "axios";
import { useRecoilState } from "recoil";
import { audioPrimaryKeysState, defaultAudioFileSatate, inboundCallingDataState, mediaAudioFileState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";


function AudioFileContainer(props) {
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState)
    const messageType = {
        "welcomeMessage": "welcomeAudio",
        "holdMusic": "holdMusic",
        "thankyouMessage": "ThankyouMessageAudio"
    }

    const nameTypes = {
        "welcomeMessage": "welcomeAudioFileName",
        "holdMusic": "holdMusicAudioFileName",
        "thankyouMessage": "thnakyouMessageAudioFileName"
    }
    const audioComponentKey = nameTypes[props.componentType]
    const audioNameInitValue = inboundCallingData[audioComponentKey]
    const componentKey = messageType[props.componentType]
    const initValue = inboundCallingData[componentKey]
    const [audioFile, setAudioFile] = useState([{media_url: initValue}]);
    const [audioFileName, setAudioFileName] = useState(audioNameInitValue);
    const { Option } = Select;
    const [audioPrimaryKeys, setAudioPrimaryKeys] = useRecoilState(audioPrimaryKeysState)
    
    const [audioFiles, setAudioFiles] = useRecoilState(mediaAudioFileState);
    const [audioFileError, setAudioFileError] = useState("") 
    const [defaultAudioFiles, setDefaultAudioFiles] = useRecoilState(defaultAudioFileSatate);
    const [loading, setLoading] = useState(false);
    const [defaultAudio, setDefaultAudio] = useState({})
    const [allAudioMediaFiles, setAllAudioMediaFiles] = useState([])
    const medeaAudioFiles = useRef([]);
    medeaAudioFiles.current = audioFiles;
    const [selectValue, setSelectValue] = useState("");
    
    

    const urlTypes = {
        "welcomeMessage": "welcomeAudioUrl",
        "holdMusic": "holdMusicUrl",
        "thankyouMessage": "ThankyouMessageAudioUrl"
    }

    

    const primaryKeyTypes = {
        "welcomeMessage": "welcomeAudioKey",
        "holdMusic": "holdAudioKey",
        "thankyouMessage": "thankyouAudioKey"
    }

    const handleSelectAudioFile = (value) => {
        sessionStorage.removeItem('inboundCallingData')
        const selectedData = value.split("@") 
        let updatedSelectedaudio = [{pk_str: selectedData[0]}];
        setSelectValue(selectedData[2])
        setAudioFile([{media_url: selectedData[1]}])
        setInboundCallingData({...inboundCallingData, [messageType[props.componentType]]: updatedSelectedaudio[0].pk_str, [urlTypes[props.componentType]]: selectedData[1], [nameTypes[props.componentType]]: selectedData[2]})
        setAudioPrimaryKeys({...audioPrimaryKeys, [primaryKeyTypes[props.componentType]]: ""})
    }


    const handleDefaultMusic = () => {
        if(props.componentType == "welcomeMessage") {
            setDefaultAudio({
                "pk_str": "https://static.callhub.io/brand/callhub/samples/welcome_message_default_inbound_calling.mp3",
                "media_url": "https://static.callhub.io/brand/callhub/samples/welcome_message_default_inbound_calling.mp3",
                "name": "sample-welcome-message-inbound-calling.mp3",
                "id": 1
            })
        } else if (props.componentType == "holdMusic") {
            setDefaultAudio({
                "pk_str": "https://static.callhub.io/brand/callhub/samples/valse-gymnopedie-hold-music.mp3",
                "media_url": "https://static.callhub.io/brand/callhub/samples/valse-gymnopedie-hold-music.mp3",
                "name": "sample-hold-music.mp3",
                "id": 2
            })
        } else if (props.componentType == "thankyouMessage") {
            setDefaultAudio({
                "pk_str": "https://static.callhub.io/brand/callhub/samples/contact_exceeds_default_wait_time_default_inbound_calling.mp3",
                "media_url": "https://static.callhub.io/brand/callhub/samples/contact_exceeds_default_wait_time_default_inbound_calling.mp3",
                "name": "sample-exceeds-wait-time-inbound-calling.mp3",
                "id": 3
            })
        }
    }
    


    const getAudioMediaFile = async (key) => {
            
            const response = await getAudioFiles(props.componentData.apiToken, {media_files:"True"})
            setAudioFiles(response.data.media_files)
            medeaAudioFiles.current = response.data.media_files;
            if (key == "NewFileUploaded") {
                const uploadedData = response.data.media_files[0]
                handleSelectAudioFile(`${uploadedData.pk_str}@${uploadedData.media_url}@${uploadedData.name}`)
            }
            
    }

    const handleAddAudioFiles = () => {
        if(audioFiles.length === 0) {
            getAudioMediaFile();
            setAllAudioMediaFiles([...medeaAudioFiles.current, defaultAudio])
        } else {
            setAllAudioMediaFiles([...audioFiles, defaultAudio])
        }
    }

    

    const getDefaultAudioFiles = async () => {
        if(props.componentType == "holdMusic") { // use props.componentType
            setAudioFile([{media_url : inboundCallingData.holdMusic}])
        } else if (props.componentType == "welcomeMessage") {
            setAudioFile([{media_url : inboundCallingData.welcomeAudio}])
        } else if (props.componentType == "thankyouMessage") {
            setAudioFile([{media_url : inboundCallingData.ThankyouMessageAudio}])
        }

        if(props.componentType == "holdMusic") {
            setAudioFileName(inboundCallingData.holdMusicAudioFileName)
        } else if (props.componentType == "welcomeMessage") {
            setAudioFileName(inboundCallingData.welcomeAudioFileName)
        } else if (props.componentType == "thankyouMessage") {
            setAudioFileName(inboundCallingData.thnakyouMessageAudioFileName)
        }
    }

    const handleUploadMedia = (e) => {
        setAudioFileError("")
        setLoading(true)
        let apiToken;
        if(props.componentData && props.componentData.apiToken) {
            apiToken = props.componentData.apiToken;
        }else {
            apiToken = token
        }

        const fileSize = e.target.files[0].size 


        if (fileSize > 5242880) {
            setAudioFileError("The file you upload must be an MP3 or WAV file upto 5 MB")
            setLoading(false)
            return
        }

        let fileData = e.target.files[0]
        var formData = new FormData();
        const CancelToken = axios.CancelToken;
        let cancel = CancelToken.source()
        formData.append('file', fileData)

        axios({
            method: 'POST',
            url: '/v2/media/',
            headers: {
                'Authorization': 'Token ' + apiToken,
                'Content-Type': 'application/json'
            },
            data: formData,
            cancelToken: cancel.token
        })
        .then(res => {
            if(res.data.data) {
                getAudioMediaFile("NewFileUploaded")
                setLoading(false)
            }
        })
        .catch((error) => {
            if(axios.isCancel(error)) {
                setLoading(false)
            } else {
                setLoading(false)
            }
        });
    }

    useEffect(() => {
        setSelectValue(audioFileName !== "" ? audioFileName : props.componentType === "holdMusic" ? "sample-hold-music.mp3" : props.componentType === "thankyouMessage" ? "sample-exceeds-wait-time-inbound-calling.mp3" : "sample-welcome-message-inbound-calling.mp3")
        if(audioFiles.length === 0){
            getAudioMediaFile();
            setAllAudioMediaFiles([...medeaAudioFiles.current, defaultAudio])
        }
        getDefaultAudioFiles();
        handleDefaultMusic();
    },[])

    return (
        <>
        <div className={audioFileError !== "" ? "audio-file-container inbound-audio-error-border" : "audio-file-container"} style={props.componentType === "thankyouMessage" ? {width: "100%", maxWidth: "100%"} : {}}>
                <Select
                    showSearch
                    onClick={handleAddAudioFiles}
                    notFoundContent={'Not Found'}
                    placeholder="Choose existing audio file" 
                    style={{ width: 476 }} 
                    onChange={handleSelectAudioFile}
                    disabled={loading}
                    value={selectValue}
                    defaultValue={audioFileName !== "" ? audioFileName : props.componentType === "holdMusic" ? "sample-hold-music.mp3" : props.componentType === "thankyouMessage" ? "sample-exceeds-wait-time-inbound-calling.mp3" : "sample-welcome-message-inbound-calling.mp3"}
                >
                    { allAudioMediaFiles.length> 0 && allAudioMediaFiles.map((item) => 
                    <Option key={item.id} value={`${item.pk_str}@${item.media_url}@${item.name}`}>{item.name}</Option>)}
                </Select>
                { 
                   !loading ? audioFile.length > 0 ?
                    <audio src={audioFile[0].media_url} controls style={{width:"100%", marginTop:"8px", background:"#F5F5F5", borderRadius:"6px", height: "44px"}}/> : null : null
                }
                <div className="file-upload-wrapper">
                    <input type="file" id={props.componentType} onClick={(e) => e.target.value = ""} onChange={ (event) => handleUploadMedia(event)} hidden name="file" accept={`.mp3, .wav`} disabled={loading}/>
                    <label for={props.componentType} className={loading ? "upload-button-opacity" : ""}>{loading ? "Uploading..." : "Browse your computer"}</label>
                    <div>Only MP3 and WAV files allowed upto 5 MB.</div>
                </div>
            </div>
            {audioFileError !== "" && <div className="inbound-audio-file-error">{audioFileError}</div>}
            </>
    )
}

export default AudioFileContainer;