import { transfer_settings_url, getSubAccountsAndMainAccountsUrl, transferCreditsUrl } from "../configuration/autoTransferCredits";
import { apiPostCall, apiGetCall, apiPutCall } from "./utilities";

export const postAutoTransferSettings = async(data, userId) => {
    try{
        const url = transfer_settings_url(userId);
        const action = userId ? apiPutCall : apiPostCall;
        const response = await action(url, data);
        return response;
    }
    catch(error){
        throw error;
    }
}

export const getAutoTransferSettings = async(userId) => {
    try{
        const url = transfer_settings_url(userId);
        const response = await apiGetCall(url);
        return response;
    }
    catch(error){
        throw error;
    }
}

export const get_all_accounts_of_manageGroup = async () => {
    try {
        const url = getSubAccountsAndMainAccountsUrl()
        const response = await apiGetCall(url);
        return response
    } catch (error) {
        throw error;
    }
}

export const transferCredits = async (payload) => {
    try {
        const url = transferCreditsUrl()
        const response = await apiPostCall(url, payload)
        return response
    } catch (error) {
        throw error
    }
}