import * as React from "react";
const KYCBeginnerIcon = (props) => (
  <svg
  width={113}
  height={80}
  viewBox="0 0 113 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx={55.0819} cy={39.7739} r={35.7739} fill="#EFF0F4" />
  <rect x={17} y={31.696} width={13.0786} height={25.388} fill="#FFA75B" />
  <path d="M25.4626 52.8949V50.5869" stroke="#484848" strokeWidth={1.53866} />
  <path d="M25.4626 48.2789V45.9709" stroke="#484848" strokeWidth={1.53866} />
  <path
    d="M53.928 23.2334C49.8249 25.6696 41.6187 26.5416 41.6187 10.5394C48.4144 10.283 60.3904 12.4628 53.928 23.2334Z"
    fill="#AED581"
    stroke="#AED581"
    strokeWidth={1.53866}
  />
  <path
    d="M63.3895 23.6502C60.8179 19.6306 59.673 11.458 75.6662 10.9247C76.149 17.7082 74.3695 29.7501 63.3895 23.6502Z"
    fill="#CEEA98"
    stroke="#CEEA98"
    strokeWidth={1.53866}
  />
  <path
    d="M30.848 34.7732H40.4646L53.5433 39.3892H67.0066C69.2609 40.2659 72.3961 42.8063 67.3787 46.0784L70.8532 45.5438L75.0846 44.0052L90.8559 38.2352C92.9074 38.6199 96.1643 40.2355 92.7792 43.6205L89.3172 45.5438L77.0079 52.0832L61.2366 60.9305H57.3899L35.464 54.0065H30.848V34.7732Z"
    fill="white"
    stroke="white"
    strokeWidth={1.53866}
  />
  <path
    d="M17 31.696H30.0786V57.084H17"
    stroke="#484848"
    strokeWidth={1.53866}
  />
  <path
    d="M30.848 34.4309H40.4646L53.5433 39.0469H66.6219"
    stroke="#484848"
    strokeWidth={1.53866}
  />
  <path
    d="M66.6219 39.0469C67.1271 39.0469 67.6273 39.1464 68.094 39.3397C68.5607 39.5331 68.9847 39.8164 69.3419 40.1736C69.6991 40.5308 69.9825 40.9548 70.1758 41.4215C70.3691 41.8882 70.4686 42.3884 70.4686 42.8936C70.4686 43.3987 70.3691 43.8989 70.1758 44.3656C69.9825 44.8323 69.6991 45.2564 69.3419 45.6136C68.9847 45.9708 68.5607 46.2541 68.094 46.4474C67.6273 46.6408 67.1271 46.7403 66.6219 46.7403H51.2353C50.0813 46.7403 48.158 47.5096 48.158 50.5869"
    stroke="#484848"
    strokeWidth={1.53866}
  />
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M92.2344 37.1267C93.3702 37.3802 94.3325 38.067 94.8778 39.0623C95.423 40.0576 95.4838 41.2383 95.0861 42.3319C94.6899 43.4215 93.8704 44.3448 92.8229 44.9187L92.8162 44.9065L61.2248 62.415L61.0508 62.5114H60.8519H57.7746H57.6518L57.5351 62.4732L35.3412 55.2028H30.848V53.6641H35.464H35.5868L35.7035 53.7023L57.8974 60.9728H60.653L92.3694 43.395C92.9678 42.9908 93.4146 42.4262 93.6401 41.8061C93.8987 41.0949 93.8444 40.3784 93.5283 39.8016C93.2123 39.2248 92.6378 38.7933 91.8992 38.6284C91.2221 38.4773 90.7731 38.6036 89.5448 39.0142L67.667 47.4161L67.1154 45.9797L88.9624 37.5896L88.9614 37.5869L88.9883 37.5779L88.9956 37.5769L89.0414 37.5593L89.0455 37.5699L92.2344 37.1267Z"
    fill="#484848"
  />
  <path
    d="M58.9289 38.2774V27.8914M58.9289 27.8914L54.3129 23.2754M58.9289 27.8914L63.5449 23.6601M68.1608 19.4288L63.5449 23.6601M50.4662 19.4288L54.3129 23.2754M54.3129 23.2754C49.8251 26.481 40.9265 28.353 41.2342 10.1968C48.543 9.81213 61.3909 11.8893 54.3129 23.2754ZM63.5449 23.6601C60.3392 19.1723 58.5439 10.1968 77.0079 10.1968C77.2645 17.7619 74.9312 31.0457 63.5449 23.6601Z"
    stroke="#484848"
    strokeWidth={1.53866}
  />
</svg>
);
export default KYCBeginnerIcon;
