import React from 'react';

const StarIconSmall = () => {
    return (
        <svg className="nav-dropdown-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            overflow="visible"
            preserveAspectRatio="none"
            version="1.2"
            viewBox="0 0 12 11"
            >
            <defs>
                <path
                d="M7.2 9.998l3.708 2.16-.984-4.07L13.2 5.349l-4.314-.353L7.2 1.158 5.514 4.996 1.2 5.35l3.276 2.739-.984 4.07L7.2 9.998z"
                vectorEffect="non-scaling-stroke"
                ></path>
            </defs>
            <path
                fill="#818181"
                d="M7.2 9.998l3.708 2.16-.984-4.07L13.2 5.349l-4.314-.353L7.2 1.158 5.514 4.996 1.2 5.35l3.276 2.739-.984 4.07L7.2 9.998z"
                transform="translate(-1.2 -1.158)"
                vectorEffect="non-scaling-stroke"
            ></path>
        </svg>
    )
}

export default StarIconSmall;