import React, { useEffect, useState } from 'react';
import NotMobileFriendlyIcon from '../../../../assets/svg/NotMobileFriendlyIcon';
import LinkSentIcon from '../../../../assets/svg/LinkSentIcon'
import { sendLoginLink } from "../../../utilities/subscriptionUtilities";
import './NotMobileFriendly.scss';

export const NotMobileFriendly = ({user_email})=>{
    const [linkSent, setLinkSent] = useState(false)

    const handleLoginLink = async()=>{
        const data = {
            email: user_email,
            compatibility_issue: 'True'
        }
        let response = await sendLoginLink(data);
        setLinkSent(true)
    }

    return(
        <div className='not-mobile-friendly-wrapper'>
            {linkSent 
                ?
                <div className='magic-link-sent-container'>
                    <LinkSentIcon width={302} height={223} />
                    <p className='magic-link-sent-title'>Login link sent!</p>
                    <p className='magin-link-sent-content'>Please check your inbox, a login link has been sent to <b>{user_email}</b></p>
                    <p className='use-laptop-desktop'>💻 Use a laptop/desktop to login</p>
                </div>
                :
                <div className='not-mobile-friendly-container'>
                    <NotMobileFriendlyIcon />
                    <p className='not-mobile-friendly-title'>We aren't mobile-browser friendly</p>
                    <p className='not-mobile-friendly-content'>Please use a desktop. Should we send you a login link to <b>{user_email}</b> so you can switch?</p>
                    <button onClick={handleLoginLink} className='send-link-button'>Send Login Link</button>
                </div>
            }
        </div>
    )
}