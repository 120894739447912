export const callhubCustomFieldUrl = () => {
  return `/v1/custom_fields/`;
}

export const ngpVanCallhubFieldUrl = () => {
	return `/van/v4/user/settings/`;
};

export const ngpVanRevokeAccessUrl = () => {
	return `/van/v4/revoke/`;
};

export const salesforceFieldsUrl = () => {
	return `/salesforce/customfields/add/`;
};

export const addQuestionCampaignCreationSectionUrl = () => {
	return `/salesforce/customfields/add/`;
};

export const addQuestionsCampaignEditUrl = campaignId => {
	return `/salesforce/${campaignId}/customfields/add/`;
};

export const editQuestionsCampaignEditUrl = (
	campaignId,
	sectionId,
	campaignType
) => {
	return `/salesforce/${campaignId}/customfields/${sectionId}/edit/${campaignType}/`;
};

export const editQuestionCampaignCreationSectionUrl = (
	sectionId,
	campaignType
) => {
	return `/salesforce/customfields/${sectionId}/edit/${campaignType}/`;
};

export const getDetailsForQuestionEditUrl = (
	campaignId,
	sectionId,
	campaignType
) => {
	return `/salesforce/${campaignId}/customfields/${sectionId}/edit/${campaignType}/`;
};

export const getDetailsForQuestionEditInCampaignCreationUrl = (
	sectionId,
	campaignType
) => {
	return `/salesforce/customfields/${sectionId}/edit/${campaignType}/`;
};

export const salesforceSettingsPageDetails = () => {
  return `/salesforce/user/settings/`;
}

export const salesforceRevokeAccessUrl = () => {
  return `/salesforce/settings/disconnect/`;
}

export const vanDispositionUrl = () => {
	return `/van/v4/canvass/`;
}
export const pdiMappingDataUrl = (question) => {
	if(question){
		return `/pdi/settings/?question_choice=${question}`;
	}else{
		return `/pdi/settings/`;
	}
	

}