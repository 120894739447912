import { Input, Modal, notification } from "antd";
import React, { useState, useRef } from "react";
import BlockSettings from "../NewBlockSettings";
import "./CreateBlock.scss";
import { createNewProactiveBlock } from "../../../utilities/NumberRotationUtilities";
import { trackSegementEvents } from "../../common/SegmentEventsConstant/SegmentEventsConstant";
import { segmentEvents } from "../constants";

const BlockCreationModal = (props) => {
  const { showModal, handleCancel, handleOptions, campaign_id } = props;
  const [priorities, setPriorities] = useState({});
  const [showError, setShowError] = useState(false)
  const [api, contextHolder] = notification.useNotification();
  const [name, setName] = useState("");
  const childRef = useRef();

  const handleNameChange = (event) => {
    const nameChosen = event.target.value?.trim()
    setName(nameChosen);
    setShowError(!nameChosen);
  }

  const openNotification = () => {
    api.success({
      message: `Series created`,
      description: <>Series <b>{name}</b> has been created</>,
    });
  };

  const handlePriorityChange = (value) => {
    setPriorities(value);
  }

  const validateBlockName = () => {
    if(name){
      setShowError(false);
      return true;
    }else{
      setShowError(true);
      return false;
    }
  }

  const validateSettings = () => {
    if(childRef?.current?.validatePreferences() && validateBlockName()){
      return true;
    }else{
      childRef?.current?.updateErrorState();
      return false;
    }
  }

  const handleOk = async () => {
    if(validateSettings()){
      const dataToPost = {
        blockName: name,
        blockPriorities: priorities
      }
      const response = await createNewProactiveBlock(dataToPost);
      if(response.data){
        const blockCreated = JSON.parse(response.data);
        handleOptions(blockCreated);
      }
      openNotification();
      handleCancel();
      const segmentTraits = {
        place: campaign_id ? 'campaign_edit' : 'campaign_creation',
        priority: Object.values(priorities).length
      };
      trackSegementEvents(segmentEvents.creation, segmentTraits);
    }
  }

  return (
    <>
      <Modal
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Create a series"
        okText="Create series"
        cancelText="Discard"
        maskClosable={false}
        destroyOnClose={true}
        className="number-rotation-settings-modal"
        width={460}
      >
        <div>
            <p className="block-sub-heading">Series name</p>
            <div className="input-container">
              <Input 
                placeholder="Enter a name for your series" 
                onChange={handleNameChange} 
                size="default"
                status={showError ? "error": ''}
                maxLength={90}
              />
              {showError && (<p className="error-text">Please enter a name</p>)}
            </div>
        </div>
        <BlockSettings ref={childRef} handlePriorityChange={handlePriorityChange} />
      </Modal>
      {contextHolder}
    </>
  );
};

export default BlockCreationModal;
