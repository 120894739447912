import React from 'react'
import "./Button.scss";
import ButtonLoading from "../../../../assets/svg/ButtonLoading";

function Button(props) {
    return (
        <button 
            className={`react-common-button ${props.type ? props.type : ""} ${props.isLoading ? "button-is-loading" : ""} ${props.className ? props.className : ""} ${props.disabled ? "disable-button" : ""}`}
            style={{...props.customStyles}}
            onClick={() => props.disabled || props.isLoading ? null : props.handleClick()}
            type={props.buttonType ? props.buttonType : 'button'}
        > 
            {
                props.isLoading 
                ? <ButtonLoading size={"35"} color="#3f89de"/>
                : props.name
            }
        </button>
    )
}

export default Button
