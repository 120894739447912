import React from 'react'

function BlueExclamationCircle(props) {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 514 514"
            height={props.size}// {24}
            width={props.size}// {24}
            {...props}
            >
            <g transform="translate(1 1)">
                <title>{"Spam Copy 3"}</title>
                <path
                fillRule="nonzero"
                fill="#3f89de"
                d="M64 256c0-106.039 85.961-192 192-192s192 85.961 192 192-85.961 192-192 192S64 362.039 64 256zm191.645 126.29c16.925 0 30.645-13.72 30.645-30.645 0-16.925-13.72-30.645-30.645-30.645C238.72 321 225 334.72 225 351.645c0 16.925 13.72 30.645 30.645 30.645zm5.498-85.147c9.941 0 18-8.059 18-18v-108c0-9.941-8.059-18-18-18h-10.286c-9.941 0-18 8.059-18 18v108c0 9.941 8.059 18 18 18h10.286z"
                vectorEffect="non-scaling-stroke"
                />
            </g>
        </svg>
    )
}

export default BlueExclamationCircle
