import React, { useState } from 'react';
import Button from "antd/es/button"
import notification from "antd/es/notification"
import {PlusOutlined} from "@ant-design/icons";
import CreateContactModal from "./CreateContactModal/CreateContactModal"


const CreateContactWithOtp = ({componentData}) =>{
    const {contactsCount,phonebookName, isCampaignCreation } = componentData
    const [isModalOpen,setIsModalOpen ] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [currentContactsCount,setCurrentContactCount] = useState(contactsCount);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const openNotification = (message, description, type) => {
        api[type]({
            message: message,
            className: 'creation-success-notification'
        });
    };
    const updateCurrentContactsCount = () =>{
        setCurrentContactCount(currentContactsCount+1)
    }

    return (
        <>
            {contextHolder}
            {isCampaignCreation ?
                currentContactsCount <= 5 ?
                    <a href="#" onClick={showModal} >
                        <PlusOutlined/> Add OTP verified contact
                    </a> : null
                    :
                <Button type="primary" disabled={contactsCount > 5} className="create-otp-verified-contact"
                        onClick={showModal} icon={<PlusOutlined/>}>
                    Add contact
                </Button>
            }

                <CreateContactModal
                phonebookName ={phonebookName}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
                handleCancel={handleCancel}
                openNotification = {openNotification}
                isCampaignCreation={isCampaignCreation}
                updateCurrentContactsCount={updateCurrentContactsCount}
            />

        </>
    )
}
export default CreateContactWithOtp