import React from "react";

export const DecrementArrowIcon = (props) =>{

    return(
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M1.82864 5.60785L5.71809 10.0903C5.75325 10.1308 5.79672 10.1634 5.84555 10.1857C5.89437 10.2079 5.94742 10.2195 6.0011 10.2195C6.05478 10.2195 6.10783 10.2079 6.15666 10.1857C6.20549 10.1634 6.24895 10.1308 6.28411 10.0903L10.1724 5.60785C10.1841 5.59434 10.1918 5.57773 10.1943 5.56001C10.1969 5.54229 10.1944 5.52421 10.1869 5.50791C10.1795 5.49161 10.1676 5.47778 10.1525 5.46807C10.1375 5.45837 10.12 5.45319 10.1021 5.45316L9.15286 5.45316C9.09895 5.45316 9.04739 5.4766 9.01106 5.51761L6.44583 8.47425L6.44583 1.87542C6.44583 1.82386 6.40364 1.78167 6.35208 1.78167L5.64895 1.78167C5.59739 1.78167 5.5552 1.82386 5.5552 1.87542L5.5552 8.47425L2.98997 5.51761C2.95481 5.4766 2.90325 5.45316 2.84817 5.45316L1.89895 5.45316C1.81927 5.45316 1.77591 5.54691 1.82864 5.60785Z"
            fill="black"
            fillOpacity={0.45}
            />
        </svg>
    )
}