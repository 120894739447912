import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, message, Spin, Table, Input } from 'antd';
import {getTeamAgentsData, deleteAgents} from './apis/ManageTeamPopoverApis';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import AssignAgentsPopover from '../AssignAgentsPopover/AssignAgentsPopover';
import './ManageTeamPopover.scss';

const ManageTeamPopover = ({ componentData }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [agentData , setAgentData] = useState({'agent_list':[], 'total_count':0});
    const [page , setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [teamName , setTeamName] = useState('');
    const isInitialLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const showModal = async() => {
        teamAgentsDataFetch(searchText);
        setIsModalOpen(true);
    };

    const teamAgentsDataFetch = async(searchText, pageNumber=page)=>{
        try{
            setLoading(true);
            let teamId = JSON.parse(localStorage.getItem('team_id'));
            let final_search_text = searchText.replace(/\+/g, '%2B');
            let response = await getTeamAgentsData(teamId, pageNumber , final_search_text);
          
            if(response.status == 200){
                let count = response.data.count;
                let agentlist = [];
                let agentResponse = response.data.results.agents_data
                for(let i = 0 ; i < agentResponse.length ; i++){
                    agentlist.push({
                        key: agentResponse[i].pk_str,
                        name: agentResponse[i].username,
                        first_name: agentResponse[i].first_name,
                        last_name: agentResponse[i].last_name,
                        email: agentResponse[i].email
                    });
                }
                setAgentData({'agent_list':agentlist, 'total_count':count})
                setTeamName(response.data.results.team.name)
            }
        }catch(err){
            errorMessage('Oops something went wrong');
        }
        setLoading(false);
    }

    const handleOk = async() => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setSearchText('');
        setPage(1);
        setSelectedRowKeys([]);
        setIsModalOpen(false);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'name',
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
        },
        {
            title: 'Last name',
            dataIndex: 'last_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        }
    ];

    const handleSearch = _.debounce(async(e)=>{
        let value = e.target.value;
        setSearchText(value);
        setPage(1);
        teamAgentsDataFetch(value, 1);
    },400)

    const updateTableData = (pagination, filters, sorter) => {
        setPage(pagination.current);
    };

    const handleDelete = async()=>{
        try{
            let teamId = JSON.parse(localStorage.getItem('team_id'));
            let response = await deleteAgents(teamId, selectedRowKeys);
            deletionSuccessMessage();
            setTimeout(()=>{
                window.location.reload();
            },2000)
        }catch(err){
            errorMessage('Oops something went wrong while deleting agents');
        }
        
    }

    const deletionSuccessMessage = () => {
        messageApi.open({
          type: 'success',
          content: `Agent(s) deleted from ${teamName}`,
        });
    };
    const errorMessage = (message) => {
        messageApi.open({
          type: 'error',
          content: message
        });
    };

    useEffect(()=>{
        if(!isInitialLoad.current){
            teamAgentsDataFetch(searchText);
        }
        isInitialLoad.current = false;
    },[page])


    useEffect(()=>{
        const manageTeamButton = document.querySelectorAll('.manage_team_button')
        if(manageTeamButton){
            manageTeamButton.forEach(button => {
                button.addEventListener('click', () => {
                showModal();
                });
            });
        }
    },[])


    return (
        <div id='manageTeamPopoverContainer'>
            {contextHolder}
            <Modal
                title={teamName}
                open={isModalOpen}
                onOk={handleOk}
                okText='Assign'
                onCancel={handleCancel}
                maskClosable={false}
                centered={true}
                className='manage-agnet-modal'
            >
                <div className='search-button-container'>
                    <Input className="manage-team-search" onChange={handleSearch} placeholder="Search agent by name..." prefix={<SearchOutlined /> } ></Input>
                    <div className='buttons-container'>
                        <AssignAgentsPopover team_name={teamName}/>
                        <div className="verticle-devider"></div>
                        <div className= {selectedRowKeys.length ? 'delete-button-container' : "delete-button-container disabled-div"}  onClick={handleDelete}>
                            <DeleteOutlined />
                        </div>
                    </div>
                </div>
                
                <div className="manage-team-table-container">
                    {loading && <Spin size="large" className="manage-team-spin" />}
                    <Table 
                        className="manage-team-table" 
                        rowSelection={rowSelection}
                        columns={columns} 
                        dataSource={agentData.agent_list}
                        onChange={updateTableData}
                        pagination={{
                            showTotal: (total, range) =>
                              `Total ${total} ${total == 1 ? "item" : "items"}`,
                            total: agentData.total_count,
                            current: page,
                            showSizeChanger: false
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default ManageTeamPopover