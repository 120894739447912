import React, { useEffect } from "react";
import Heading from "./Heading/Heading";
import { P2P_SORT_LABEL, P2P_SORT_ORDER_INFO_TEXT, SORT_OPTIONS } from "../../../constants/SortOrder";
import CustomSelect from "../../../components/common/CustomSelectForJquery/CustomSelect";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IsSortOrderToggledAtom, P2PComponentDataAtom } from "../../../RecoilSharedState/SortOrder/SortOrder";

import './p2pSortOrder.scss';

const P2PSortOrderComponent = ({ componentData }) => {

    const setComponentData = useSetRecoilState(P2PComponentDataAtom)
    const isSortOrderToggled = useRecoilValue(IsSortOrderToggledAtom)

    useEffect(() => {
        setComponentData(componentData)
    }, [componentData])
 
    return (
        <div>
            <Heading 
                inputFieldName={componentData.inputFieldName} 
                label = {P2P_SORT_LABEL}
                tooltiptext = {P2P_SORT_ORDER_INFO_TEXT}
                sortOrderSwitchId = 'id_p2p_switch'
            />
            {
                isSortOrderToggled &&
                    <CustomSelect 
                        sort_order_select_id = 'id_p2p_selected_sort_order'
                        customSelectClass = 'custom-select-text-div'
                        optionList = {SORT_OPTIONS}
                        selectedOptionBgColor = 'selected-option-bg-color'
                        jqueryComponentData = {componentData}
                    />
            }        
        </div>
    )
}

export default P2PSortOrderComponent