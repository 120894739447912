import React, {useEffect, useState} from "react";

import './CustomSelect.scss';

const CustomSelect = (props) => {

    const [callDirection, setCallDirection] = useState('ALL')
    const [callStatus, setCallStatus] = useState('ALL')

    const callDirectionSelecthandler = (e) => {
        console.log("event", e.target.value)
        setCallDirection(e.target.value)
        console.log("props.setShowCallStatusDropDown", props.setShowCallStatusDropDown)
        if(!props.setShowCallStatusDropDown){
            return;
        }
        if(e.target.value === 'Incoming') {
            props.setShowCallStatusDropDown(true)
        }else {
            props.setShowCallStatusDropDown(false)
        }
    }

    const callStatusHandler = (e) => {
        console.log("event", e.target.value)
        setCallStatus(e.target.value)
    }

    useEffect(() => {
        if(props.componentData.call_direction !== 'ALL') {
            setCallDirection(props.componentData.call_direction)
            if(!props.setShowCallStatusDropDown){
                return;
            }
            if (props.componentData.call_direction === 'Incoming') {
                props.setShowCallStatusDropDown(true)
            }else{
                props.setShowCallStatusDropDown(false)
            }
        }

        if(props.componentData.call_status && props.componentData.call_status !== 'ALL') {
            setCallStatus(props.componentData.call_status)
        }

    }, [])

    return (
        <div className={`${props.componentData.className ? props.componentData.className : 'custom-select span4'}`}>
            <label>{props.componentData.label}</label>
            <select 
                aria-label="Call Direction Select"
                id={`${props.componentData && props.componentData.id ? props.componentData.id : 'id_call_direction'}`} 
                name={`${props.componentData && props.componentData.name ? props.componentData.name : 'call_direction'}`} 
                value={props.componentData && props.componentData.id === 'id_incoming_call_status' ? callStatus : callDirection} 
                onChange={props.componentData && props.componentData.id === 'id_incoming_call_status' ? callStatusHandler : callDirectionSelecthandler}>
                {
                    props.componentData.options.map((name, index) => 
                        <option key={index} value={name} >{name}</option>
                    )
                }
            </select>
        </div>
    )
}

export default CustomSelect