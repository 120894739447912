import React from 'react'

function ButtonLoading(props) {
    return (
        <svg
      width={props.size} // {200}
      height={props.size} // {200}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      display="block"
      {...props}
    >
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.9166666666666666s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(30 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.8333333333333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(60 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.75s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(90 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.6666666666666666s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(120 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5833333333333334s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(150 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.5s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(180 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.4166666666666667s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(210 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.3333333333333333s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(240 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.25s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(270 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.16666666666666666s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(300 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="-0.08333333333333333s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={46.5}
        y={25.5}
        rx={3.5}
        ry={3.5}
        width={7}
        height={7}
        fill={props.color} // "#3f89de"
        transform="rotate(330 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
    )
}

export default ButtonLoading
