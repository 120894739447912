
export const creditSummaryUrl = () => {
    return `/subscription/credit-summary/`;
}

export const subscriptionPlans = () => {
    return `/subscription/plan/`;
}

export const textCallCost = () => {
    return `/subscription/pricing-details/`;
}

export const cardDetailsUrl = () => {
    return '/subscription/card/'
}

export const orderSummaryUrl = () => {
    return '/subscription/order-summary/'
}

export const currentPlanUrl = () => {
    return '/subscription/current-plan/'
}

export const saveFreeTrialGiftUrl = () => {
    return '/subscription/save-free-trial-gift/'
}

export const storeProductUrl = () => {
    return `/subscription/product/`
}

export const subscriptionUrl = (id) => {
    return `/subscription/${id}/`
}

export const paymentHistoryUrl = () => {
    return '/billing/api/payment-history/'
}

export const verifyEmailUrl = () => {
    return '/v1/activation_mail/'
}

export const activePlansUrl = () => {
    return '/subscription/active-addon/'
} 

export const billingVerificationStatusUrl = () => {
    return '/billing_verification_status/'
}

export const downgradePlanUrl = () => {
    return '/subscription/downgrade-summary/';
}

export const billingReceiptUrl = (receiptId) => {
    return `/api/billing/payment/${receiptId}/receipt/`
}

export const bulkRechargeDetailsUrl = () => {
    return '/subscription/bulk-recharge/'
}

export const addonSurveyResponseUrl = () => {
    return '/subscription/add-on/survey-response/'
}

export const planSurveyResponseUrl = () => {
    return '/subscription/plan/survey-response/'
}

export const getCalendlyTalkToAnExpertUrl = () => {
    return 'https://calendly.com/callhubio/get-started-with-callhub/'
}

export const addonsDunningMessageUrl = () => {
    return '/subscription/addons-dunning-message/'
}

export const removeDunningMessageUrl = () => {
    return '/subscription/remove-dunning-warning/'
}

export const validateKycUrl = () => {
    return '/customer_has_kyc/'
}

export const customerKycUrl = () => {
    return '/customer_kyc_details/'
}

export const usageTableUrl = () => {
    return '/billing/usage_data/'
}

export const invoiceDataUrl = (month, year) => {
    return `/billing/invoice/?month=${month}&year=${year}`;
}

export const magicLinkUrl = ()=>{
    return `/magic-link/`
}