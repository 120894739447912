import React, { useEffect, useState } from "react";
import EditUTMParams from "./editUtmParamsModalWrapper"
import EditPersonalizedTags from './editPersonalizedTagsWrapper'
import { getAPIData } from './shortenURLUtils';
import { mergeTagsAPI } from "./constant"
import LineChartIcon from "../../../../assets/svg/LineChartIcon.js"
import PersonalizedTagsIcon from "../../../../assets/svg/PersonaliseTagIcon.js"
import { trackSegementEvents } from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant'
import CloseConfirmationScreen from './closeScreen'

//css
import "../commonStyle.scss";
import "./customizeURL.scss";

function CustomizeURL(props) {

    const {personaliseParams, setPersonaliseParams, originalUrl, setOriginalUrl} = props;
    const {utmParamsObj, setUtmParams} = props;

    const [modalState, handleModalControl] = useState(null);
    const [param, setParam] = useState("");
    const [customLongURL, setCustomLongURL] = useState(props.longURL);
    const [isChecked, handleCheckbox] = useState(props.isTrackingEnabled);
    const [personaliseTags, handlePersonaliseTags] = useState([]);
    //const [isParamadded, handleIsParamadded] = useState(false);
    const [textareaError, handleTextAreaError] = useState("")
    const [customStyle, handleCustomStyle] = useState('')
    const [hightlightTextarea, setHighlightTextarea] = useState(false);
    const [showWarningPopup, setShowWarningPopup] = useState(false);

    useEffect(() => {
        trackSegementEvents('visited-long-url-edit-page',{
            'product_category': props.eventData.productCategory,
            "customer_type" : props.segmentCustomerType,
            'industry': props.eventData.industry
        });

        handleUtmParamsUpdate(utmParamsObj);
        if(props.isTrackingEnabled){
            personaliseParamUpdate(personaliseParams);
        }

        //In case url duplication, to segrate utm params and personalize params if already present
        segregateUtmAndPersonalParams(originalUrl);

        if(props.longURL && props.longURL.length >= 2048) { 
            error = 'URL must not be greater than 2048 characters.' 
            error_border = "errorBorder"
            handleTextAreaError(error)
            handleCustomStyle(error_border)
        }
    },[]);
    
    const openModal = (modalName) => {
        if(modalName === 'EditUTMParams'){
            handleModalControl('open-utm-modal');
        }else
            handleModalControl('open-personalise-modal');
            getPersonalizedTags();
    }
    const closeModal = (modalName) => {
        if(modalName === 'EditUTMParams')
            handleModalControl('close-utm-modal')
        else
            handleModalControl('close-personalise-modal')
    }
    const preFillUTMParams = () => {
        let urlString = props.longURL;
        var url = new URL(urlString);
        let utmParams = {
            'utm_source' : url.searchParams.get("utm_source") || "",
            'utm_medium' : url.searchParams.get("utm_medium") || "",
            'utm_campaign' : url.searchParams.get("utm_campaign") || "",
            'utm_term' : url.searchParams.get("utm_term") || "",
            'utm_content' : url.searchParams.get("utm_content") || ""
        };
        setUtmParams(utmParams);
    }

    const getPersonalizedTags = async() => {
        let personalizedTagsList = await getAPIData(mergeTagsAPI, props.token);
        handlePersonaliseTags(personalizedTagsList.data.data.merge_tags);
    }

    const handleContactTrackingButton = (e) => {
        e.preventDefault();
        handleCheckbox(false);
        props.trackingEnabled(false);
        props.setFixedPrefix("");
        let longURL = removePersonaliseParams(props.longURL);
        setCustomLongURL(longURL);
        props.setLongURL(longURL);
        setShowWarningPopup(false);
    }

    const handleContactTrackingCheckbox = (event) => {

        if(event.target.checked == false) {
            let personalizeParamNotPresent = true;
            try{
                const url = new URL(props.longURL);
                const params = new URLSearchParams(url.search);
                for(let paramKey of params.keys()){
                    const paramValue = url.searchParams.get(paramKey);
                    if(paramValue.match(/\{([^}]+)\}/g)){
                        personalizeParamNotPresent = false;
                        break;
                    }
                }
            }catch(e) {
                handleTextAreaError("Invalid URL.")
                handleCustomStyle("errorBorder")
            }
                    
            if(personalizeParamNotPresent) {
                handleCheckbox(false);
                props.trackingEnabled(false);
                props.setFixedPrefix("");
            }else {
                setShowWarningPopup(true);
            }        
        }else {
            handleCheckbox(true);
            props.trackingEnabled(true);
            props.setFixedPrefix("");
        }
    }

    const closeWarningModal = (e) => {
        e.preventDefault()
        setShowWarningPopup(false);
    }

    const handleTextArea = (event) => {
        event.preventDefault();
        let error = "", error_border = "";
        // handleIsParamadded(false);
        if(event.target.value.indexOf(" ") !== -1){
            return;
        }
        if(event.target.value.length >= 2048) { 
            error = 'URL must not be greater than 2048 characters.' 
            error_border = "errorBorder"
        }else if(event.target.value.length === 0){
            error = 'Invalid URL.' 
            error_border = "errorBorder"
        }else{
            error = "";
            error_border = ""
        };
        // if(event.target.value.includes("?")) {
        //     handleIsParamadded(true);
        // }
        
        setOriginalUrl(event.target.value);

        handleTextAreaError(error)
        handleCustomStyle(error_border)

        try{
            new URL(event.target.value);
        }catch(e){
            setCustomLongURL(event.target.value);
            props.setLongURL(event.target.value);
            handleTextAreaError("Invalid URL.")
            handleCustomStyle("errorBorder")
            return;
        }

        const urlWithUtm = handleUtmParamsUpdate(utmParamsObj, event.target.value);
        personaliseParamUpdate(personaliseParams, urlWithUtm);

        handleTextAreaHeight();
    }

    const handleUtmParamsUpdate = (paramsObject, originalUrl) => {
        let longURL = originalUrl || props.longURL;

        try{
            const url = new URL(longURL);
            
            for(let key in paramsObject){
                if(paramsObject[key]){
                    url.searchParams.set(key, paramsObject[key]);
                }
            }

            let urlString = url.toString();
            urlString = urlString.replaceAll("%7D", "}");
            urlString = urlString.replaceAll("%7B", "{");

            setCustomLongURL(urlString);
            props.setLongURL(urlString);
            return urlString;
        }catch(e){
            handleTextAreaError("Invalid URL.")
            handleCustomStyle("errorBorder")
            return longURL;
        }
    }

    const personaliseParamUpdate = (paramsArray, originalUrl) => {
        let longURL = removePersonaliseParams(originalUrl || props.longURL);

        try{
            const url = new URL(longURL);

            for(let param of paramsArray){
                if(param.paramName === ""){
                    continue;
                }
                url.searchParams.set(param.paramName, param.paramValue);
            }

            let urlString = url.toString();
            urlString = urlString.replaceAll("%7D", "}");
            urlString = urlString.replaceAll("%7B", "{");

            setCustomLongURL(urlString);
            props.setLongURL(urlString);
            return urlString;
        }catch(e){
            handleTextAreaError("Invalid URL.")
            handleCustomStyle("errorBorder")
            return longURL;
        }
    }

    const removePersonaliseParams = (longURL) => {
        try{
            const url = new URL(longURL);
            const params = new URLSearchParams(url.search);
            for(let paramKey of params.keys()){
                const paramValue = url.searchParams.get(paramKey);
                if(paramValue.match(/\{([^}]+)\}/g)){
                    url.searchParams.delete(paramKey);
                }
            }

            let urlString = url.toString();
            urlString = urlString.replaceAll("%7D", "}");
            urlString = urlString.replaceAll("%7B", "{");
            return urlString;
        }catch(e){
            handleTextAreaError("Invalid URL.")
            handleCustomStyle("errorBorder")
            return longURL;
        }
    }

    const handleTextAreaHeight = () => {
        let longURL = document.querySelector("#longURL");
        longURL.style.height = "auto";
        longURL.style.height = longURL.scrollHeight + "px";
    }

    const segregateUtmAndPersonalParams = (originalUrl) => {
        try{
            const url = new URL(originalUrl);
            const params = new URLSearchParams(url.search);

            const personalizedParams = []

            //remove and record the persionalize params from original url
            for(let paramKey of params.keys()){
                const paramValue = url.searchParams.get(paramKey);
                if(paramValue.match(/\{([^}]+)\}/g)){
                    personalizedParams.push({
                        id: `${Date.now()}${Math.random() * 20}`,
                        paramName: paramKey,
                        paramValue: paramValue
                    })
                    url.searchParams.delete(paramKey);
                }
            }

            const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
            const utmParams = {}

            //remove and record the utm param from original url
            for(let utmKey of utmKeys){
                if(url.searchParams.get(utmKey)){
                    utmParams[utmKey] = url.searchParams.get(utmKey);
                    url.searchParams.delete(utmKey);
                }
            }

            let urlString = url.toString();
            let urlStringWithParams = urlString;

            //update the removed personalize params into map
            if(personalizedParams.length > 0){
                setPersonaliseParams(personalizedParams);
                urlStringWithParams = personaliseParamUpdate(personalizedParams, urlString);
            }

            //update the removed utm params into map
            if(Object.keys(utmParams).length > 0){
                setUtmParams(utmParams);
                handleUtmParamsUpdate(utmParams, urlStringWithParams);
            }

            //update the original url with utm and personalize params removed
            setOriginalUrl(urlString);
        }catch(e){
            handleTextAreaError("Invalid URL.")
            handleCustomStyle("errorBorder")
        }
    }

    useEffect(() => {
        if(props.isCentralUrlManagement){
            props.setLongURL(props.longURL)
        }
    }, [props.isCentralUrlManagement])
    
    return (
        <div>			
            <div className={"customURLWrapper " + customStyle + (textareaError || props.customizeURLErrorMsg ? ' errorBorder ' : " " ) + ((hightlightTextarea && !(textareaError || props.customizeURLErrorMsg)) ? ' highlight-custom-url-input-focus ' : ' ')}>
                <textarea 
                    maxLength='2048' 
                    type="text" 
                    id="longURL" 
                    value={originalUrl} 
                    onChange={handleTextArea} 
                    onFocus={() => {
                        if(!textareaError && !props.customizeURLErrorMsg)
                            setHighlightTextarea(true)
                    }}
                    onBlur={() => setHighlightTextarea(false)}>
                </textarea>
                <div className="params-container">
                    <div className="params-title">Long URL</div>
                    <div className="param-values">{props.longURL}</div>
                </div>
                <div className="addParamsWrapper">
                    <button className="" onClick={(e) => {e.preventDefault(); openModal('EditUTMParams')}}><LineChartIcon/><span>UTM</span></button>
                    {
                        modalState === 'open-utm-modal' &&
                            <EditUTMParams 
                                isModalClosed = {props.isModalClosed}
                                closeEditUTMParamsModal = {() => closeModal("EditUTMParams")}
                                longURL = {props.longURL}
                                utmParamsObj = {utmParamsObj}
                                setUtmParams = {setUtmParams}
                                setUtmString = {handleUtmParamsUpdate}
                                utmString = {param}
                            />
                    }
                    <button id='personalizedTags' disabled={!isChecked ? true : false} className={!isChecked ? "personalisedTooltip" : ""} onClick={(e) =>  {e.preventDefault(); openModal("EditPersonalizedTags")}}>
                        <PersonalizedTagsIcon id={isChecked ? 'onHoverBlueClr':""} size={13}/> <span>Personalise</span>
                        <span className={`personalisedTooltiptext ` + (isChecked ? "hide" : "")}>To add personalisation tags to URLs, enable contact tracking below.</span>
                    </button>
                    {
                        modalState === 'open-personalise-modal' &&
                            <EditPersonalizedTags 
                                isModalClosed = {props.isModalClosed}
                                closeEditPersonalizeParamsModal = {() => closeModal("EditPersonalizedTags")}
                                longURL = {props.longURL}
                                personaliseTagsList = {personaliseTags}
                                setPeronsonaliseString = {personaliseParamUpdate}
                                personalizeParams={personaliseParams}
                                setPeronsonaliseParams={setPersonaliseParams}
                            />
                    }
                </div>
            </div>
            <div className="errorColor mb20">{textareaError || props.customizeURLErrorMsg}</div>
            <div>
                <label className="contactTrackingSwitch">
                    <input onChange={handleContactTrackingCheckbox} checked={isChecked} id="contactTrackingCheckbox" type="checkbox"/>
                    <span className="contactTrackingSlider round"></span>
                    {
                        showWarningPopup &&
                        <div className='warning-popup-contact-tracking'>
                            <div className='warning-popup-text'>Personalisation tags added to the URL will be removed. Are you sure you want to disable contact tracking?</div>
                            <div className='warning-popup-button'>
                                <button className='warning-popup-contact-tracking-confirm-button' onClick = {(e) => handleContactTrackingButton(e)}>Disable Contact tracking</button>
                                <button className='warning-popup-contact-tracking-cancel-button' onClick = {(e) => closeWarningModal(e)}> Cancel</button>   
                            </div>
                        </div>
                    }
                </label>
                <div className="contactTracking">
                    <div>Contact tracking</div>
                    <div>Keep track of which contacts clicked on the shortened URL and how often they did so. 
                        { props.showHelpLinks && 
                            <a href="https://support.callhub.io/hc/en-us/articles/4405059927065-What-is-Click-through-rate-and-how-to-enable-Click-through-tracking-on-CallHub-" target='_blank'> Learn More.</a>
                        } 
                    </div>
                </div>
            </div>
        </div>
    )
  }

export default CustomizeURL;
