import React from 'react'

const P2PTextingKycIcon = props => (
    <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 443.77 443.77"
    width="30px"
    height="30px"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#fcfdfc}.cls-5{fill:#4d4d4f}"}</style>
    </defs>
    <g id="Layer_1-2">
      <circle
        cx={221.89}
        cy={221.89}
        r={221.89}
        style={{
          fill: "#79a1f8",
        }}
      />
      <g
        style={{
          isolation: "isolate",
          opacity: 0.32,
        }}
      >
        <path
          className="cls-5"
          d="m133.93 321.41 59.3-29.88-46.04-13.19-13.26 43.07zM402.95 185.86 55.71 252.15l70.92 20.31 276.32-86.6z"
        />
        <path
          className="cls-5"
          d="m147.19 278.34 46.04 13.19 69.18 19.82 140.54-125.49-255.09 90.29-.67 2.19z"
        />
        <path
          className="cls-5"
          d="m126.63 272.46 7.3 48.95 13.26-43.07.67-2.19 255.09-90.29-276.32 86.6z"
        />
      </g>
      <path
        style={{
          fill: "#e2e6e9",
        }}
        d="m135.29 321.67 57.2-43.99-48.04-26.63-9.16 70.62z"
      />
      <path
        className="cls-2"
        d="M394.78 122.1 48.99 198.14l74 41.02L394.78 122.1zM144.45 251.05l48.04 26.63 72.2 40.02 130.09-195.6-249.87 125.34-.46 3.61z"
      />
      <path
        style={{
          fill: "#eef0f2",
        }}
        d="m122.99 239.16 12.3 82.51 9.16-70.62.46-3.61L394.78 122.1 122.99 239.16z"
      />
    </g>
  </svg>
)

export default P2PTextingKycIcon