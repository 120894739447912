import React, {useState, useEffect, useMemo} from 'react';
import {Modal, Button, Form, Input, Select, Typography, notification, Tooltip} from 'antd';
import './transferCreditsModal.scss';
import transferCreditsSuccessBody from "./TransferCreditsSuccessBody";
import TransferCreditsIcon from "../../../assets/svg/TransferCreditsIcons";
import {transferCredits, get_all_accounts_of_manageGroup} from "../../utilities/autoCreditsTransferUtilities";

const TransferCreditsModal = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [fromAccount, setFromAccount] = useState({});
  const [toAccount, setToAccount] = useState({});
  const [errors, setErrors] = useState({});
  const [accounts, setAccounts] = useState([]);
  const {Paragraph} = Typography
  const transactionSuccessKey = `transactionData${props.componentData.userId}`

  const fetchAccounts = async () => {
      const response = await get_all_accounts_of_manageGroup();
      if (response.data) {
          setAccounts(response.data.accounts);
      }
  };
  const retrieveTransactionData = () => {
    const data = sessionStorage.getItem(transactionSuccessKey);
    if (data) {
      const { toAccountUsername, fromAccountUsername, amountSent } = JSON.parse(data);
      openSuccessNotification(toAccountUsername,fromAccountUsername,amountSent);
      sessionStorage.removeItem(transactionSuccessKey);
    }
  };
  useEffect(() => {
    retrieveTransactionData(); // toCheck if there is any previous transaction happened to show the notification
  }, []);
  useEffect(() => {
      if (open) {
        fetchAccounts();
      }
    }, [open]);
  const showModal = () => {
    setOpen(true);
  };
  const openSuccessNotification = (toAccountUsername,fromAccountUsername,amountSent) => {
    api.success({
      message: 'Credit Transfer successful!',
      description: transferCreditsSuccessBody(toAccountUsername, fromAccountUsername, amountSent),
      style:{
        width:424,
      },
      className: 'transfer-success-notification'
    });
  };
  const openFailureNotification = () => {
    const toAccountUsername = toAccount.label
    const fromAccountUsername = fromAccount.label
    const amountSent = "$0"
    api.error({
      message: 'Credit Transfer failed!',
      description: transferCreditsSuccessBody(toAccountUsername, fromAccountUsername, amountSent),
      style:{
        width:424,
      },
      className: 'transfer-success-notification' //same style as success notification
    });
  };
  const handleTransfer = async () => {
    const newErrors = {};
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      newErrors.amount = 'Please enter amount';
    }
    if ( Object.keys(fromAccount).length == 0) {
      newErrors.fromAccount = 'Please select an account';
    } else if (amount > fromAccount.balance) {
      newErrors.amount = 'Selected account does not have enough credits'
      newErrors.fromAccount = 'Selected account does not have enough credits'
    }
    if (Object.keys(toAccount).length == 0) {
      newErrors.toAccount = 'Please select an account';
    }
    if (fromAccount && toAccount && fromAccount === toAccount) {
      newErrors.fromAccount = 'From and To account cannot be the same';
      newErrors.toAccount = 'From and To account cannot be the same';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const payload ={
          to_account_id: toAccount.value,
          from_account_id: fromAccount.value,
          credits: parseFloat(amount.replace(/[^0-9.]/g, '')) // Remove formatting
        }
        const response = await transferCredits(payload)
        if (response.data.success) {
          sessionStorage.setItem(
            transactionSuccessKey,
            JSON.stringify({
              toAccountUsername : toAccount.label,
              fromAccountUsername: fromAccount.label,
              amountSent:formatNumber(parseInt(amount).toString()),
            })
          );
          handleCancel()
          window.location.reload();
        } else {
          openFailureNotification()
          handleCancel()
        }
      } catch (error) {
        console.log(`Credits Transfer Failed due to data error ${error}`)
        openFailureNotification()
        handleCancel()
      }
    }
  };
  const formatNumber = (value) => {
    if (!value) return '';
    let creditsInput = value.replace(/[^0-9]/g, '')
    creditsInput = creditsInput.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return creditsInput
  };

  const handleCancel = () => {
    setOpen(false);
    setAmount('');
    setFromAccount({});
    setToAccount({});
    setErrors({});
  };
  const handleAmountChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d]/g, ''); // Remove non-numeric characters except for decimal points
    setAmount(value);
  };

  const filterOption = useMemo(() => {
    return (input, option) => {
      const lowercasedInput = input.toLowerCase();
      const lowercasedUsername = (option.username ?? '').toLowerCase();
      const lowercasedAccountType = (option.accountType ?? '').toLowerCase();

      return (
        lowercasedUsername.includes(lowercasedInput) || lowercasedAccountType.includes(lowercasedInput)
      );
    };
  }, []);

  const get_from_label = () => {
    return (<p>From <span
        style={{fontWeight: 300}}> (Balance: {fromAccount?.balance ? "$" + fromAccount.balance : "Please select an account"}) </span>
    </p>)
  }
  const get_to_label = () => {
    return (<p>To <span
        style={{fontWeight: 300}}> (Balance: {toAccount?.balance ? "$" + toAccount.balance : "Please select an account"}) </span>
    </p>)
  }

  const getOptions = (order) => {
    const sortedAccounts = [...accounts].sort((a, b) => {
        return order === 'asc' ? a.balance - b.balance : b.balance - a.balance;
    });
    const formattedOptions = sortedAccounts.map((option) => ({
      value: option.value,
      label: (
          <div className="custom-options">
            <p className="option_username">{option.label}</p>
            {option.accountType.length > 15 ? (
                <Tooltip title={`username: ${option.label} Account type: ${option.accountType}`}>
                  <Paragraph className="ellipsis child-to-render">
                    {option.accountType}
                  </Paragraph>
                </Tooltip>
            ) : (
                <p className="option_account_type">{option.accountType}</p>
            )}
            <p className="balance_column">$ {option.balance}</p>
          </div>
      ),
      username: option.label,
      accountType: option.accountType,
    }));
    const optionsWithLabel =[{
      label: <div className="custom-options">
                <p className="header_username option_username">Username</p>
                <p className="header_account_type option_account_type">Account Type</p>
                <p className="header_balance_column">Credits</p>
              </div>,
      title:'options',
      options: formattedOptions,
    }]
    return optionsWithLabel;
  }


  return (
      <>
        <Button className="transfer-credits-btn" variant="primary" size="large" onClick={showModal}>
          <TransferCreditsIcon></TransferCreditsIcon>
          <span>Transfer Credits</span>
        </Button>
        <Modal
            title="Transfer credits"
            open={open}
            centered
            destroyOnClose={true}
            closeIcon={null}
            className="transfer-credits-container"
            okText={"Transfer"}
            cancelText={"Cancel"}
            onOk={handleTransfer}
            onCancel={handleCancel}
            width={637}
        >
          <Form layout="vertical">
            <Form.Item className="amount-form-item" label="Enter amount" validateStatus={errors.amount && 'error'}
                       help={errors.amount}>
              <Input
                  className="amount-input"
                  placeholder="Enter amount"
                  value={formatNumber(amount)}
                  onChange={handleAmountChange}
                  prefix="$"
              />
            </Form.Item>
            <Form.Item className="from-form-item" label={get_from_label()}
                       validateStatus={errors.fromAccount && 'error'} help={errors.fromAccount}>
              <Select
                  placeholder="--Search account to select--"
                  showSearch
                  optionFilterProp="children"
                  value={fromAccount?.value}
                  size="large"
                  onChange={(value) => setFromAccount(accounts.find(account => account.value === value))}
                  options={getOptions('desc')}
                  filterOption={filterOption}
              />

            </Form.Item>
            <Form.Item className="to-form-item" label={get_to_label()} validateStatus={errors.toAccount && 'error'}
                       help={errors.toAccount}>
              <Select
                  placeholder="--Search account to select--"
                  showSearch
                  optionFilterProp="children"
                  size="large"
                  value={toAccount?.value}
                  onChange={(value) => setToAccount(accounts.find(account => account.value === value))}
                  options={getOptions('asc')}
                  filterOption={filterOption}
              />
            </Form.Item>
          </Form>
        </Modal>
        {contextHolder}
      </>
  );
};

export default TransferCreditsModal;
