import React from 'react'

function GreenTickIcon(props) {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 10 8"
            width={props.width}
            height={props.height}
            {...props}
            >
            <path
                d="M1 4l3 3 5-6"
                vectorEffect="non-scaling-stroke"
                stroke="#2fbc79"
                fill="none"
            />
        </svg>
    )
}

export default GreenTickIcon
