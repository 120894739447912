import React, { useEffect, useState } from "react";
import AgentProfanitySheildIcon from '../../../../assets/svg/AgentProfanitySheildIcon';
import AgentProfanityIcon from '../../../../assets/svg/AgentProfanityIcon';
import AgentProfanityNotEnabled from './AgentProfanityNotEnabled';
import AgentProfanityIsEnabled from './AgentProfanityIsEnabled';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {EnableAgentProfanitySheild, GetProfanityData} from './AgentProfanityUtils';
import {AGENT_PROFANITY_TITLE, PROFANITY_MODAL_TITLE, PROFANITY_MODAL_SUBHEADING, ENABLE_PROFANITY_MODAL_BUTTON, PROFANITY_ENABLED_SUCCESSFULLY} from './AgentProfanityConstants';
import './AgentProfanityGuard.scss';

const AgentProfanityGuard = ({componentData})=>{
    const [modal, contextHolder] = Modal.useModal();
    const [ifProfanityEnabled , setIsProfanityEnabled] = useState(false);
    const [profaneData , setProfaneData] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(()=>{
        if(componentData.is_profanity_enabled){
            setIsProfanityEnabled(true);
            getAgentProfanityData();
        }
    },[])

    useEffect(()=>{
        if(showSuccessMessage){
            setTimeout(()=>{
                setShowSuccessMessage(false);
            },3000)
        }
    },[showSuccessMessage])

    const handleEnableProfanity = () =>{
        modal.confirm({
            title: PROFANITY_MODAL_TITLE,
            icon: <ExclamationCircleOutlined />,
            content: PROFANITY_MODAL_SUBHEADING,
            okText: ENABLE_PROFANITY_MODAL_BUTTON,
            onOk:handleOk,
            cancelText: 'Cancel',
            className: 'enable-profanity-modal'
        });
    }

    const handleOk = async () =>{
        let response = await EnableAgentProfanitySheild(componentData.campaign_id)
        if(response.status==200) {
            setIsProfanityEnabled(true);
            setShowSuccessMessage(true);
            getAgentProfanityData();
        }
    }

    const getAgentProfanityData = async () =>{
        let response = await GetProfanityData(componentData.campaign_id);
        console.log("response data : ", response);
        if(response.status == 200 ){
            setProfaneData(response.data);
        }
    }

    return(
        <>
            {contextHolder}
            <div id='agentProfantiyGuardSection'>
                <div className='agnet-profanity-title-section'>
                    <AgentProfanitySheildIcon size={32}></AgentProfanitySheildIcon>
                    <div className="profanity-title-icon">
                        <p className='agnet-profanity-title'>{AGENT_PROFANITY_TITLE}</p>
                        <AgentProfanityIcon size={17}></AgentProfanityIcon>
                    </div>
                </div>
                {!ifProfanityEnabled &&
                    <AgentProfanityNotEnabled
                        handleEnableProfanity={handleEnableProfanity}
                    />
                }
                {ifProfanityEnabled &&
                    <AgentProfanityIsEnabled
                        profaneData={profaneData}
                        campaignId={componentData.campaign_id}
                        user_email={componentData.user_email}
                        user_name = {componentData.user_name}
                    />
                }
            </div>
            {showSuccessMessage &&
                <div className="profanity-success-message"> {PROFANITY_ENABLED_SUCCESSFULLY} </div>
            }
        </>
    )
}

export default AgentProfanityGuard