const { atom } = require("recoil");

export const CallSentimentDataAtom = atom({
    key: 'CallSentimentDataAtom',
    default: [],
})

export const SentimentGraphDataAtom = atom({
    key: 'SentimentGraphData',
    default: [],
})
export const ContactCategoriesDataAtom = atom({
    key: 'contactCategoriesData',
    default: [],
})