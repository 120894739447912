import {formatText} from './method';
import Select from '../../../components/common/Select';
import DropDownComponent from './../../../components/common/dropDownComponent';
import './TextEditor.scss';
import React, { useEffect, useState } from 'react';

export const TextEditor = (props) =>{

    let [font , setFont] = useState("sans")
    let [selectedOption , setSelectedOption] = useState({label:"Sans", value:"sans"})
    let [options, setOptions] = useState([{label:"Sans",value:"sans"}, {label:"S𝒸𝓇𝒾𝓅𝓉",value:"script"},{label:"𝙼𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎",value:"monospace"}, {label:"𝔉𝔯𝔞𝔨𝔱𝔲𝔯" , value:"fraktur"} , {label:"𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜" , value:"doublestruck"}]);

    useEffect(()=>{
        if(props.textInputField){
            props.updateFont(font);
        }
        if(props.componentData){
            props.componentData.updateP2PFont(font);
        }
    },[font])

    useEffect(()=>{
        if(font != "sans"){
            if(props.inputType == "insertText"){
                props.textInputField.selectionStart = props.cursonPoint;
                props.textInputField.selectionEnd = props.cursonPoint ;
            }

            if(props.componentData){
                if(props.componentData.exceptionKeys){
                    return props.componentData.settingPoint(props.componentData.cursonPoint);
                }else{
                    return props.componentData.settingPoint(props.componentData.cursonPoint+1);
                }
            }
        }
    },[props.inputData , props.componentData ? props.componentData.inputData : ""])

    let handleSelectFont = (e)=>{
        setSelectedOption({label: e.label , value: e.value})
        setFont(e.value)
    }

    let handleLanguage = (e) =>{
        e.preventDefault();   
        let fonts;
        if(e.target.value == "Bold" && font.includes("Bold") || e.target.value == "Italic" && font.includes("Italic")){
            fonts = selectedOption.value
            setFont(selectedOption.value);
        }else{
            fonts = handleMultipleStyle(e.target.value)
            setFont(fonts)
        }       

        if (document.getSelection().toString().length) {

            let inputField = props.componentData ? props.componentData.inputField : props.textInputField;
            let inputData = props.componentData ? props.componentData.inputData : props.inputData;
            let start = inputField.selectionStart;
            let end = inputField.selectionEnd;
            let selectedText = inputData.slice(start,end);
            let convertedText = formatText(selectedText, fonts);
            let removeSelectedText = inputData.slice(0, start) + inputData.slice(end);
            let finalText = addingEditedText(removeSelectedText, start, convertedText);

            if(finalText.length > 1600 && fonts != "sans"){
                return
            }
            
            if(props.componentData){
                props.componentData.handleSelectedText(finalText);
            }else{
                props.handleFields("trigger_message", finalText)
            }            
        }
    }

    let addingEditedText = (str, index, joint) => {
        const result = [str.slice(0, index), joint, str.slice(index)];
    
        return result.join("");
    };


    let handleMultipleStyle = (button) => {
        return selectedOption.value + button;
    }

    return (
        <div className="textToolbar">
                <div>
                    <Select
                        isClearable = {false}
                        closeMenuOnSelect = {true}
                        isSearchable = {false}
                        components = {{
                            IndicatorSeparator: () => {
                                return null;
                            },
                            DropdownIndicator: () =>{
                                    return <DropDownComponent/>
                            }
                            }}
                        name="selectedFontList"
                        changed={event => handleSelectFont(event)}
                        className="select-font-style"
                        options = {options}
                        selectedOption = {selectedOption}
                    />
                </div>
                <div class="textEditorButtons">
                    <button onClick={(e) =>{handleLanguage(e)}} value="Bold" className={(font=="sansBold" || font=="scriptBold" || font=="frakturBold") ? "selected-font-button" : ((font == "monospace" || font == "doublestruck") ? "disable-font-button": "")}>Bold</button>
                    <button onClick={(e) =>{handleLanguage(e)}} value="Italic" className={(font=="Italic" || font=="sansItalic") ? "selected-font-button" : ((font!="normal" && font != "Bold" && font != "sans" && font!= 'sansBold') ? "disable-font-button" : "")}>𝐼𝑡𝑎𝑙𝑖𝑐</button>
                </div>
        </div>
    )
}