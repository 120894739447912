import axios from 'axios';

import {
    campaignListUrl,
    searchFilterUrl,
    getCampaignInfoUrl,
    getUrls,
    getPowerCampaignUrls
} from '../../configuration/CentralURLManagementConfig';

export const getOptions = async (token) => {
    const response = await axios.get(campaignListUrl(), {
        headers: {
            'Authorization': 'Token ' + token
        }
    });
    return response.data.data.campaign_list
}

export const getSearchFilterResponse = async (limit, offset, filterData) => {
    try {
        const config = {
            method: 'get',
            url: searchFilterUrl(limit, offset),
            headers: {
                'Authorization': 'Token ' + token
            },
            params: filterData
        }
        const response = await axios(config);
        return response;

    }catch(err) {
        console.log(err)
    }  
}

export const getCampaignInfo = async (data) => {
    try {
        const response = await axios.post(getCampaignInfoUrl(), data, {
            headers: {
                'Authorization': 'Token ' + token
            },
        });
        return response;

    }catch(err) {
        console.log(err)
    }  
}

export const getAvailableUrls = async (token, campaignId, campaignType) => {
    try {
          const response = await axios.get(
            campaignType === 'POWER_CAMPAIGN' ? getPowerCampaignUrls(campaignId) : getUrls(campaignId), {
                headers: {
                    'Authorization': 'Token ' + token
                }
            });
        return response.data.data.url_list
    } catch(err) {
        // Return the error
        console.log(err)
    }  
}