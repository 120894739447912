import React from 'react';
import NudgeDetailsContent from './NudgeDetailsContent';
import CollaborateIcon  from '../../../../assets/images/CollaborateIcon.png';
import SupportIcon from "../../../../assets/svg/SupportIcon";
import NudgeConvoIcon from "../../../../assets/svg/NudgeConvoIcon";
import "./NudgeDetailsBox.scss";
import CalenderIcon from '../../../../assets/svg/CalenderIcon';
import CalendlyTextPopUp from '../common/CalendlyTextPopUp';

function NudgeDetailsBox({productTrainingDate, productTrainingTime, localTimeZone, isError}) {
    return (
        <div className="nudge-details fade-in">
            <div className="nudge-content-container">
                <div className="nudge-content-1">
                    <NudgeDetailsContent 
                        icon={<NudgeConvoIcon width={48} height={44}/>}
                        title="3x more conversations"
                        content="Increase the time spent by agents on conversations by upto 3x when compared to a 1:1 dialer."
                        link="Create a Call center campaign"
                        url="/power_campaign/add/"
                        segmentEventName="clicked_call_center_campaign"
                    >
                    </NudgeDetailsContent>
                </div>
                <div className="ml-25px nudge-content-2">
                    <div className="mb-10px">
                        <NudgeDetailsContent 
                            icon={<img src={CollaborateIcon} alt="collaborate" height={48} width={46}/>}
                            title="Collaborate"
                            content="Grant access to the admin panel to teammates. Each person can create, manage & own specific campaigns and its outcomes."
                            link="Invite a teammate"
                            url="/user_roles/"
                            segmentEventName="clicked-user-roles-cta"
                        />
                    </div>
                    <div>
                        <NudgeDetailsContent 
                            content="Manage multiple accounts under one roof. Oversee the budget and progress of distributed campaigns while enabling campaign managers to work independently."
                            link="Send an account invite"
                            url="/enterprise/accounts/"
                            segmentEventName="clicked-sub-accounts-cta"
                        />
                    </div>
                </div>
                <div className="ml-25px nudge-content-3">
                    <NudgeDetailsContent 
                        icon={<SupportIcon size={48}/>}
                        title="Faster support"
                        content="Campaigns critical to your business usually require quicker resolutions. With a response time of 4 hours (3x faster than the free plan), you'll be on your way in no time."
                        link="Contact support"
                        url="https://support.callhub.io/hc/en-us"
                        target="blank"
                        segmentEventName="clicked-on-support-cta"
                    />
                </div>
                {!(productTrainingDate && productTrainingTime && localTimeZone) && (!isError) && <div className="ml-25px nudge-content-4">
                    <CalenderIcon />
                    <div>Stuck somewhere?</div>
                    <div>Book free product training</div>
                    <div>Our technical specialist will get on a call with you to clear any doubts and help you get the most out of CallHub.</div>
                    <CalendlyTextPopUp url="https://calendly.com/d/cnc-b6b-x3z/free-product-training?" className="nudge-calendly-button" text="Book free training"/>
                </div>}
            </div>
        </div>
    )
}

export default NudgeDetailsBox
