import React, { useEffect } from 'react';
import * as Constant from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';
import './CalendlyTextPopUp.scss';

const CalendlyTextPopUp = (props) => {

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        const link = document.createElement('link');
        link.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css');
        link.setAttribute('rel', 'stylesheet');
        head.appendChild(script);
        head.appendChild(link);
    },[]);

    return (
        <React.Fragment>
            <button
                href="#" 
                className={`calendly-open-button ${props.className}`} 
                style={props.customStyles ? props.customStyles : {}}
                onClick={() => {
                    if(props.planName && props.upgradeButtonClicked)
                        props.trackSegementEvents(Constant.segmentEventNamesForUpgradePlans[props.planName], "Adoption rate");
                    window.Calendly.initPopupWidget({url: props.url})
                }}>
                    {props.text}
            </button>
        </React.Fragment>
    )

}

export default CalendlyTextPopUp;