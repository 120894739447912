import axios from 'axios';
import {
    powerCampaignUrlShortenerStatisticsUrl,
    urlShortenerStatisticsUrl
} from '../../configuration/urlShortenerConfigurations';

export const apiGetCall = (url) => {
    return axios.get(url, {
        headers: {
            'Authorization': 'Token ' + token
        }
    })
}
    
export const apiPostCall = (url, data) => {
    return axios.post(url, data, {
        headers: {
            'Authorization': 'Token ' + token
        }
    })
}

export const getUrlStatsData = async (apiToken, campaignId) => {
    try {
        const response = await axios.get(urlShortenerStatisticsUrl(campaignId), {
            headers: {
                'Authorization': 'Token ' + apiToken
            }
        })

        return response.data.data.urlStatsTableData;
           
    } catch (err) {
        console.log(err);
    }
};

export const getPowerCampaignUrlStatsData = async (apiToken, campaignId) => {
    try {
        const response = await axios.get(powerCampaignUrlShortenerStatisticsUrl(campaignId));
        return response.data.data.urlStatsTableData;

    } catch (err) {
        console.log(err);
    }
};