import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, message, Table, Input } from 'antd';
import {getCampaignAgents, assignNewAgent} from './apis/AssignAgentsPopoverApis';
import { SearchOutlined } from '@ant-design/icons';
import './AssignAgentPopover.scss';

const AssignAgentsPopover = ({team_name}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [agentData , setAgentData] = useState([]);
    const [nextExist,setNextExist] = useState(false);
    const [searchText , setSearchText] = useState('');
    const tableRef = useRef();
    const [messageApi, contextHolder] = message.useMessage();
    const successMessage = () => {
        messageApi.open({
          type: 'success',
          content: `${selectedRowKeys.length} agents added to ${team_name}`,
        });
    };
    const errorMessage = () => {
        messageApi.open({
          type: 'error',
          content: 'Oops something went wrong',
        });
    };

    const handleAssignAgents = ()=>{
        showModal();
    }

    const showModal = () => {
        gettingAgentsData(page , searchText)
        setIsModalOpen(true);
    };
    const handleOk = async() => {
        try{
            let teamId = JSON.parse(localStorage.getItem('team_id'));
            let response = await assignNewAgent(teamId , selectedRowKeys);
            if(response.status == 200){
                setIsModalOpen(false);
                successMessage();
                setTimeout(()=>{
                    window.location.reload();
                },2000)
            }
        }catch(err){
            errorMessage();
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const gettingAgentsData = async (page , searchText) => {
        try{
            let final_search_text = searchText.replace(/\+/g, '%2B');
            let response = await getCampaignAgents(page , final_search_text);

            if(response.status == 200){
                let agents = [];
                let agentResponse = response.data.results
                for(let i = 0 ; i < agentResponse.length ; i++){
                    agents.push({
                        key: agentResponse[i].pk_str,
                        name: agentResponse[i].username
                    });
                }
                setAgentData(prevAgentData => page == 1 ? agents : [...prevAgentData,...agents]);
    
            }
            setNextExist(response.data?.next ? true : false);
        }catch(err){
            
        }
    }

    const columns = [
        {
            title: 'Agent Name',
            dataIndex: 'name',
        }
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleScroll = ()=> {
        if(tableRef.current.scrollTop + tableRef.current.clientHeight > tableRef.current.scrollHeight-5){
            if(nextExist){
                setPage(page+1);
            }
        }
    }

    const handleSearch = _.debounce(async(e)=>{
        let value = e.target.value;
        setSearchText(value);
        setPage(1);
        gettingAgentsData(1 ,value);
    },400)

    useEffect(()=>{

        if(page != 1){
            gettingAgentsData(page , searchText);
        }
    },[page])

    return (
        <div id='assignAgentsPopoverContainer'>
            {contextHolder}
            <button className='assign-agent-button' onClick={handleAssignAgents}>Assign agents</button>
            <Modal
                title="Assign Agent(s)"
                open={isModalOpen}
                onOk={handleOk}
                okText='Assign'
                onCancel={handleCancel}
                maskClosable={false}
                centered={true}
                className='assign-agent-modal'
            >
                <Input className="assign-agent-search" onChange={handleSearch} placeholder="Search agent by name..." prefix={<SearchOutlined /> } ></Input>
                <div ref={tableRef} onScroll={handleScroll} style={{ overflow: 'auto', height: '300px' }}>
                    <Table 
                        className="assign-agents-table" 
                        rowSelection={rowSelection}

                        columns={columns} 
                        dataSource={agentData}
                        pagination={false}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default AssignAgentsPopover