import React, { useEffect, useRef, useState } from 'react';
import "./InterstedProducts.scss";
import "./TrailGiftContainer.scss";
import {InterstedProductsData} from './KycConstants'
import {ProductContainer} from './ProductContainer';

import Form from 'antd/lib/form'
import Checkbox from 'antd/lib/checkbox'
// imported antd styles
import "../AntDesignCustomStyles/AntForm.scss";
import "../AntDesignCustomStyles/AntInput.scss";
import "../AntDesignCustomStyles/AntCheckbox.scss";
import "../AntDesignCustomStyles/AntRadio.scss";
import "../AntDesignCustomStyles/AntMessage.scss";

export function InterstedProducts(props){

    const [form] = Form.useForm();
    const [intrestedProducts , setIntrestedProducts] = useState([]);

    useEffect(() => {
        const data = InterstedProductsData();
        setIntrestedProducts(data);

        if (isIphone()) {
            const element = document.querySelector('.kyc-product-check-container');
            if (element) {
                element.style.height = 'calc(92vh - 24px - 164px - 100px)';
            }
        }
    }, [])
    const isIphone = () => {
        return /iPhone/.test(navigator.userAgent);
    };
    return(
        <div className="second_slide">
            <div className="slide_two_heading">What products are you interested in?</div>
            <div className="mobile-slide_two_heading">
                <p className="heading">Interested campaigns?</p>
                <p className="sub-heading">Your choices won’t limit what you can do in {props.getBrandName}</p>
            </div>
            <div className='products-form'>
                <Form
                    layout="vertical" 
                    className="intersted_product_from"
                    requiredMark="optional"
                    form={form}
                    name="kyc-form-2"
                    scrollToFirstError={true}
                >
                    <Form.Item
                        name = 'productsInterersted'
                        required={true}
                        rules={[({getFieldValue}) => ({
                            validator(_, value) {
                                let selected_products = getFieldValue('productsInterersted');
                                if(selected_products){
                                    if (getFieldValue('productsInterersted').length < 1) {
                                        return Promise.reject('*Please select at least one product to continue');
                                    }
                                    return Promise.resolve();
                                }else{
                                    return Promise.reject('*Please select at least one product to continue');
                                }
                            },
                        })]}
                    >
                        <Checkbox.Group className='kyc-product-check-container' >
                            {intrestedProducts?.map((product, index) =>(
                                <ProductContainer product={product} key={index} />
                            ))}
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item>
                        <button className='kyc-continue-button' type="submit" onClick={()=>{props.handleContinue(3 , form.getFieldValue(), form)}}>Next</button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}