export const rechargeOptions = [
  { label: "$25", value: "1" },
  { label: "$50", value: "2" },
  { label: "$100", value: "3" },
  { label: "$250", value: "4" },
  { label: "$500", value: "5" },
  { label: "$1,000", value: "6" },
  { label: "$2,500", value: "7" },
  { label: "$5,000", value: "8" },
  { label: "$10,000", value: "9" },
];

export const transferSettingsSchema = [
  {
    label: "When balance is below:",
    name: "minRecharge",
    options: rechargeOptions,
    id: 1,
  },
  {
    label: "Recharge sub-account with:",
    name: "recharge",
    options: rechargeOptions,
    id: 2,
  },
  {
    label: "Monthly recharge limit",
    name: "rechargeThreshold",
    options: rechargeOptions,
    id: 3,
  },
];

export const validResponseStatuses = [201,200]