import React, { Component } from "react";
//components
import SearchBox from "./searchBox.js";
import CustomizeURL from "./customizeURL.js"
import ContactTrackingEnabled from "../ShortenedUrl/contactTrackingEnabled.js"
import ConfirmationScreen from "./confirmationScreen.js"
import ContactTrackingDisabled from "./contactTrackingDisabled.js"
import CommonSideModal from '../common/SideModalWrapper'
import CloseConfirmationScreen from './closeScreen'
import {trackSegementEvents} from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';
//variables
import { postApiData, getAPIData } from "./shortenURLUtils.js"
import { metaTitleAPI, validateTitleAPI, createShortURLAPI, validateFixedPrefixAPI , urlValidationAPI, availableDomainsAPI, generateIdentifierAPI} from "./constant"
import { getProductByName } from '../../../utilities/subscriptionUtilities';
//icons
import CloseButton from "../../../../assets/svg/CloseIcon";
import ShortUrlIcon from '../../../../assets/svg/ShortenURLIcon.js'
import QuestionMarkIcon2 from '../../../../assets/svg/QuestionMarkIcon2.js'
import Loading from '../../../../assets/svg/ButtonLoading.js';

//others
import _ from "lodash";
//css
import "../common/SideModalWrapper.scss";
import "./searchBox.scss";


class URLShortenerSideModal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeScreen: this.props.componentData.currentScreen,
            screenList: ["searchBox", "customizeURL", "contactTracking", "confirmationScreen"],
            searchTerm : "",
            metaTitle : "",
            fixedPrefix : "",
            shortURLPreivew : "",
            domainId : "",
            apiToken : this.props.componentData.apiToken,
            existingShortenUrl : "",
            isTrackingEnabled: true,
            isPersonaliseField : false,
            isSearchTermValid : "",
            isCloseConfirmModalOpen : false,
            baseURLId : "",
            creationErrorMessage : "",
            validUrlErrorMessage : "",
            contactTrackingErrorMessage : "",
            contactTrackingTitleErrorMessage : "",
            domainList : {},
            selectedDomain : "",
            backHalf : "",
            updatedMetaTitle : "",
            isDetailedModalOpen : false,
            personaliseParams : [{paramName : "", paramValue : "", id: Date.now()}],
            utmParamsObj: {},
            customizeURLErrorMsg : "",
            displayProperty : "show",
            segmentUrlType : '',
            segmentCustomerType: '',
            originalUrl: '',
        }
    }
    
    componentDidMount() {
        this.getDomainList()
        this.loadProductDetails();
        
        if(this.props.componentData.propSearchTerm){
            this.setOriginalUrl(this.props.componentData.propSearchTerm);
            this.props.componentData.propSearchTerm=""
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.componentData.isModalClosed === false && 
           this.props.componentData.isModalClosed !== prevProps.componentData?.isModalClosed){
            trackSegementEvents('clicked-shorten-url-button',{
                'product_category': this.props.componentData.eventData.productCategory,
                'customer_type' : this.state.segmentCustomerType,
                'industry': this.props.componentData.eventData.industry
            });
        }
        if(this.state.isTrackingEnabled !== prevState.isTrackingEnabled && this.state.isTrackingEnabled == true){
            this.genrateBackHalf(this.state.domainList[0].pk_str);
        }
    }
    loadProductDetails = async () => {
        let authToken = (token ? token : this.props.componentData.apiToken);
        const productResponse = await getProductByName(authToken, {name: 'URL Shortener'});
        let productType = ''
        if(productResponse.is_promotional){
            productType = 'free-trial'
        }else if(productResponse.extra && productResponse.extra.active){
            productType = 'purchased-add-on'
        }else if(productResponse.extra && productResponse.extra.included){
            productType = 'got-it-free-with-plan'
        }
        this.setState({segmentCustomerType : productType}, () => {
            trackSegementEvents('clicked-shorten-url-button',{
                'product_category': this.props.componentData.eventData.productCategory,
                'customer_type' : this.state.segmentCustomerType,
                'industry': this.props.componentData.eventData.industry
            });
        });
        
    }
    getStringifiedData = (dataObject) => {
        return JSON.stringify(dataObject);
    }
    handleSearchTerm = _.debounce(async (searchTerm) => {
        this.setState({searchTerm: searchTerm, metaTitle: ""})
        let data = this.getStringifiedData({'url' : searchTerm})
        let metaTitle = await postApiData(metaTitleAPI, data, this.state.apiToken);
        if(metaTitle.data !== undefined){
            this.setState({
                metaTitle: metaTitle.data.data.title || "",
                contactTrackingErrorMessage : "", 
                contactTrackingTitleErrorMessage : ""
            })
        }
    }, 400);
    handleIsTrackingEnabled = (trackingEnabled) => {
        this.setState({isTrackingEnabled: trackingEnabled});
    }
    handlePersonaliseField = (isPersonaliseField) => {
        this.setState({isPersonaliseField: isPersonaliseField})
    }
    handleUpdatedMetaTitle = (updatedMetaTitle) => {
        this.setState({metaTitle: updatedMetaTitle})
    }
    handleCustomshortenURL = (shortenURLDetails) => {
        this.setState({
            fixedPrefix: shortenURLDetails.fixedPrefix,
            shortURLPreivew : shortenURLDetails.customShortURL,
            domainId : shortenURLDetails.domainID,
            selectedDomain : shortenURLDetails.selectedDomain,
        })
    }
    getDomainList = async() => {
        let availableDomainList = await getAPIData(availableDomainsAPI,  this.state.apiToken)
        this.setState({domainList : availableDomainList.data.data});
        this.genrateBackHalf(this.state.domainList[0].pk_str);
    }

    genrateBackHalf = async(domainId) => {
        let data = {"domain_id" : domainId};
        const backHalfCode = await postApiData(generateIdentifierAPI, data,  this.state.apiToken)

        if(backHalfCode.data !== undefined) {
            this.setState({backHalf : backHalfCode.data.data.identifier}, () => {
                console.log("backHalfCode.data.data.identifier", backHalfCode.data.data.identifier)
            })
        }else{
            console.log(backHalfCode.error.message)
        }
        
    }

    validateLongURL = async(url) => {
        let searchTermValid="",isValidURLErrMsg="", displayProperty = "";
        //customizeURLErrorMsg = "";
        url = url ? url.trim(): '';
        let data = this.getStringifiedData({'url' : url})
        let isValidURL = await postApiData(urlValidationAPI, data, this.state.apiToken);

        if(isValidURL.error !== undefined && this.state.activeScreen === 'customizeURL'){
            this.setState({customizeURLErrorMsg : isValidURL.error.message})
            return false;
        }else{
            this.setState({customizeURLErrorMsg : ""})
        }
    
        if(isValidURL.data !== undefined) {
            searchTermValid = true;
            isValidURLErrMsg = "";
        }else{
            if(isValidURL.error.message !== 'Enter a valid URL.'){
                isValidURLErrMsg = isValidURL.error.message;
                searchTermValid = false;
                displayProperty = 'show';
            }else{
                displayProperty = 'hide';
            }
        }
        this.setState({validUrlErrorMessage : isValidURLErrMsg, isSearchTermValid : searchTermValid, displayProperty: displayProperty});
    }
    
    closeModal = async () => {
        this.props.componentData.isModalClosed = true;
        this.props.handleCloseSideModal && this.props.handleCloseSideModal("showURLShortener", false)
        this.props.setShowSideModal && this.props.setShowSideModal(false)
        await this.handleNextScreen()
    }
    handleCloseModal = async () => {
        await this.closeModal();
        this.closeDetailsModal();
        this.setState({ 
            activeScreen : "searchBox" , 
            searchTerm : '',
            originalUrl: '',
            utmParamsObj : {},
            personaliseParams: [{paramName : "", paramValue : "", id: Date.now()}],
            isSearchTermValid: "",
            fixedPrefix: '',
            isTrackingEnabled: true
        });
    };
    validateActiveScreen = () => {
        //empty validation for each screen

    }
    handleNextScreen = async() => {
        const { screenList, activeScreen, searchTerm, customizeURLErrorMsg } = this.state;
        let active = activeScreen, validUrl;
        let url = searchTerm || this.props.componentData.propSearchTerm
        if(active === 'customizeURL'){
            validUrl = await this.validateLongURL(url);
        }
        if(validUrl === undefined){
            active = screenList[screenList.indexOf(activeScreen) + 1];
        }
        this.setState({
            activeScreen : active,
        });
    }
    handlePrevScreen = () => {
        const { screenList, activeScreen } = this.state;
        let prev = screenList[screenList.indexOf(activeScreen) - 1];
        if(prev === "searchBox"){
            this.setState({ 
                searchTerm : '',
                originalUrl: '',
                utmParamsObj : {},
                personaliseParams: [{paramName : "", paramValue : ""}],
                isSearchTermValid: ""
            });
        }
        this.setState({activeScreen : prev, creationErrorMessage: ""});
    }

    setPersonaliseParams = (data) => {
        this.setState({personaliseParams : data});
    }

    setUtmParams = (data) => {
        this.setState({utmParamsObj : data});
    }

    setSegmentUrlType = (data) => {
        this.setState({segmentUrlType : data});
    }

    addToScript = (urlTitle, urlId, shortUrlPreview, url_type = "") => {
        if(this.props.modalPosition !== undefined && urlTitle !== ""){

            if(this.props.componentData.setTriggerMessage){
                //react side (sms broadcast)
                this.props.componentData.setTriggerMessage(`{URL: ${urlTitle}}`, shortUrlPreview, this.state.segmentCustomerType);
            }else{
                //jquery side p2p
                //document.querySelector(this.props.modalPosition).value += `{URL: ${urlTitle}}`;
                this.props.componentData.appendPersonaliseTagFieldArea(`{URL: ${urlTitle}}`);
                // if(this.props.componentData.showShortUrlCopyPasteWarning){
                //     this.props.componentData.showShortUrlCopyPasteWarning();
                // }
                if(this.props.componentData.setShortUrlUsed){
                    this.props.componentData.setShortUrlUsed(this.state.segmentCustomerType);
                }
            }

            trackSegementEvents('added-short-link-to-text-box',{
                'product_category': this.props.componentData.eventData.productCategory,
                'url_type': (url_type !== "") ? url_type : this.state.segmentUrlType,
                'domain_name': this.state.selectedDomain,
                'customer_type' : this.state.segmentCustomerType,
                'industry': this.props.componentData.eventData.industry
            });

            let baseURLId = urlId;
            let notesElement = document.getElementById('url-notes-container');
            if(notesElement){
                if(notesElement.classList.contains('display-none')){
                    notesElement.classList.remove('display-none');
                }
            }
            if(this.props.componentData.shortUrlsStorageKey){
                let storedUrlIds = window.sessionStorage.getItem(this.props.componentData.shortUrlsStorageKey);
                if(storedUrlIds){
                    storedUrlIds = JSON.parse(storedUrlIds);
                    storedUrlIds = [...storedUrlIds, baseURLId];
                }else{
                    storedUrlIds = [baseURLId];
                }
                window.sessionStorage.setItem(this.props.componentData.shortUrlsStorageKey, JSON.stringify(storedUrlIds));
                this.setState({
                    baseURLId : "",
                })
            }else{
                window.sessionStorage.setItem('scriptBaseURLIds' , baseURLId)
            }
        }

        this.props.componentData.isModalClosed = true;
        this.props.handleCloseSideModal && this.props.handleCloseSideModal("showURLShortener", false)
        this.props.setShowSideModal && this.props.setShowSideModal(false)
        this.closeDetailsModal();
        this.setState({ 
            activeScreen : "searchBox" , 
            searchTerm : '',
            originalUrl: '',
            utmParamsObj : {},
            personaliseParams: [{paramName : "", paramValue : "", id: Date.now()}],
            isSearchTermValid: "",
            fixedPrefix: '',
            isTrackingEnabled: true,
            creationErrorMessage : "",
            validUrlErrorMessage : "",
            contactTrackingErrorMessage : "",
        });
    }

    handleExistingTitle = (title, url) => {
        this.setState({searchTerm : url, originalUrl: url});
    }

    handleShortUrlCreation = async() => {
        const {metaTitle, isTrackingEnabled, fixedPrefix, domainId, searchTerm, 
            existingShortenUrl, domainList, apiToken, personaliseParams} = this.state;

        //To check if personalize parameters are added.
        let isPersonaliseField = false;
        const url = new URL(searchTerm);
        for(let paramObj of personaliseParams){
            if(url.searchParams.get(paramObj.paramName)){
                isPersonaliseField = true;
                break;
            }
        }

        let data = this.getStringifiedData({
            "title": metaTitle,
            "contact_tracking": isTrackingEnabled,
            "merge_tags": (isTrackingEnabled && isPersonaliseField),
            "fixed_prefix": fixedPrefix,
            "domain_id": domainId || domainList[0].pk_str,
            "target_url": searchTerm
        })

        let error_message = "";
        let createShortenedUrlData = "";
        let urlTitle = '';

        createShortenedUrlData = await postApiData(createShortURLAPI, data, apiToken);
        if(createShortenedUrlData.data !== undefined && !createShortenedUrlData.data.error){
            
            trackSegementEvents('created-url-short-link',{
                'product_category': this.props.componentData.eventData.productCategory,
                'url_type': this.state.segmentUrlType,
                'domain_name': this.state.selectedDomain,
                'customer_type' : this.state.segmentCustomerType,
                'industry': this.props.componentData.eventData.industry
            });

            urlTitle = createShortenedUrlData.data.data.short_url.title.split('\"').join("");
            const baseURLId = createShortenedUrlData.data.data.short_url.pk_str
            this.setState({
                baseURLId : baseURLId
            })
            const shortUrlPreview = createShortenedUrlData.data.data.short_url.preview;
            error_message = "";
            this.addToScript(urlTitle, baseURLId, shortUrlPreview);
            this.genrateBackHalf(domainList[0].pk_str);
            this.props.componentData.setCentralURLManagementTableData && this.props.componentData.setCentralURLManagementTableData(!this.props.componentData.centralURLManagementTableData);
        }else if(createShortenedUrlData.error !== undefined){
            error_message = createShortenedUrlData.error.message.non_field_errors[0] || createShortenedUrlData.error.message.target_url[0];
        }else{
            urlTitle = "";
            error_message = "";
        }
        this.setState({creationErrorMessage : error_message});
    }

    handleUpdatedBackHalf = (updatedBackHalf) => {
        this.setState({backHalf : updatedBackHalf});      
    }
    handleExistingTitle = (title, url) => {
        this.setState({existingShortenUrl : title, searchTerm : url, originalUrl: url});
    }
    openCloseConfirmModal = () => {
        this.setState({isCloseConfirmModalOpen : true});
    }
    closeCloseConfirmModal = () => {
        this.setState({isCloseConfirmModalOpen : false});
    }
    openDetailsModal = () => {
        this.setState({isDetailedModalOpen : true})
	}
    closeDetailsModal = () => {
		this.setState({isDetailedModalOpen : false, searchTerm : ''})
    }

    setFixedPrefix = (value) => {
        this.setState({fixedPrefix : value})
    }
    setOriginalUrl = (value) => {
        this.setState({originalUrl: value});
    }

    closeURLShortenerMainModal = (e) => {
        e.preventDefault();
        this.handleCloseModal();
        this.closeCloseConfirmModal()
    }

    handleContactTracking = async() => {
        let errorMessage = "", errorMessage2 = "", isFixedPrefixValid;
        let data = {
            "fixed_prefix": this.state.fixedPrefix.trim() || "",
            "domain_id": this.state.domainId || this.state.domainList[0].pk_str,
        }
        if(data["fixed_prefix"]){
            isFixedPrefixValid = await postApiData(validateFixedPrefixAPI, data, this.state.apiToken);
        }else if(!this.state.isTrackingEnabled){
            errorMessage="Enter valid Customize backhalf";
        }
        if(this.state.metaTitle !== ""){
            let data = {"title" : this.state.metaTitle}
            let validTitle = await postApiData(validateTitleAPI, data ,this.state.apiToken);
            if(((isFixedPrefixValid && isFixedPrefixValid.data !== undefined) || (this.state.isTrackingEnabled && (isFixedPrefixValid === undefined || isFixedPrefixValid.error === undefined))) && validTitle.error === undefined) {
                this.handleNextScreen();
                errorMessage = "";
            }else{
                if(isFixedPrefixValid && isFixedPrefixValid.error !== undefined){
                    errorMessage = isFixedPrefixValid.error.message;
                }
                if(validTitle.error !== undefined){
                    errorMessage2 = validTitle.error.message;
                }
            }
        }else{
            errorMessage2 = "title is required";
        }
        this.setState({contactTrackingErrorMessage : errorMessage, contactTrackingTitleErrorMessage : errorMessage2})
    }
    render() {
        const {
            activeScreen,
            searchTerm,
            metaTitle,
            shortURLPreivew,
            isTrackingEnabled,
            isSearchTermValid,
            isCloseConfirmModalOpen,
            creationErrorMessage,
            validUrlErrorMessage,
            contactTrackingErrorMessage,
            contactTrackingTitleErrorMessage,
            domainList,
            backHalf,
            isDetailedModalOpen,
            customizeURLErrorMsg,
            displayProperty,
        } = this.state;
        const {
            submitButtonclass,
            componentData,
        } = this.props;
        let activeModalScreen;
        let active_screen = activeScreen
        switch (active_screen){
            case "searchBox":
                activeModalScreen = <SearchBox 
                    getSearchTerm={(searchTerm) => {
                        this.handleSearchTerm(searchTerm);
                        this.setOriginalUrl(searchTerm);
                    }} 
                    getTitleFromExistingURL = {(title, url) => this.handleExistingTitle(title, url)}
                    addTitleToScript = {this.addToScript}
                    handleNextScreen = {this.handleNextScreen}
                    isModalClosed = {componentData.isModalClosed}
                    title= {this.state.metaTitle}
                    validateLongURL = {this.validateLongURL}
                    token={componentData.apiToken}
                    closeDetailsModal = {this.closeDetailsModal}
                    isDetailedModalOpen = {isDetailedModalOpen}
                    openDetailsModal = {this.openDetailsModal}
                    modalId={componentData.id}
                    addToScriptButton = {componentData.addToScriptButton}
                    setSegmentUrlType={this.setSegmentUrlType}/>;
                break;
            case "customizeURL" : 
                activeModalScreen = <CustomizeURL 
                    longURL={searchTerm || this.props.componentData.propSearchTerm}
                    isCentralUrlManagement = {this.props.componentData.setCentralURLManagementTableData}
                    isModalClosed = {componentData.isModalClosed}
                    setLongURL={(longURL) => this.handleSearchTerm(longURL)}
                    token={componentData.apiToken}
                    personaliseField={(isPersonaliseField) => this.handlePersonaliseField(isPersonaliseField)}
                    trackingEnabled = {(trackingEnabled) => this.handleIsTrackingEnabled(trackingEnabled)}
                    isTrackingEnabled = {this.state.isTrackingEnabled}
                    setFixedPrefix={this.setFixedPrefix}
                    showHelpLinks = {this.props.componentData.showHelpLinks}
                    setPersonaliseParams={this.setPersonaliseParams}
                    personaliseParams={this.state.personaliseParams}
                    setUtmParams={this.setUtmParams}
                    utmParamsObj={this.state.utmParamsObj}
                    customizeURLErrorMsg = {customizeURLErrorMsg}
                    eventData={this.props.componentData.eventData}
                    segmentCustomerType={this.state.segmentCustomerType}
                    setOriginalUrl={this.setOriginalUrl}
                    originalUrl={(!this.state.originalUrl && this.props.componentData.propSearchTerm) ? this.props.componentData.propSearchTerm : this.state.originalUrl}
                    />;
                break;
            case "contactTracking" : 
                activeModalScreen = (!isTrackingEnabled) ? 
                        <ContactTrackingDisabled
                        longURL={searchTerm|| this.props.componentData.propSearchTerm}
                        token={componentData.apiToken}
                        metaTitle={metaTitle}
                        domainList = {domainList}
                        backHalf ={backHalf}
                        contactTrackingErrorMessage = {contactTrackingErrorMessage}
                        contactTrackingTitleErrorMessage = {contactTrackingTitleErrorMessage}
                        genrateBackHalf = {this.genrateBackHalf}
                        getUpdatedMetaTitle = {(updatedMetaTitle) => {this.handleUpdatedMetaTitle(updatedMetaTitle)}}
                        getCustomShortURL={(getShortenURLDetails) => this.handleCustomshortenURL(getShortenURLDetails)}
                        updatedBackHalf = {this.handleUpdatedBackHalf}
                        eventData={this.props.componentData.eventData}
                        setSegmentUrlType={this.setSegmentUrlType}
                        segmentCustomerType={this.state.segmentCustomerType}
                    /> 

                    :
                        <ContactTrackingEnabled
                        longURL={searchTerm|| this.props.componentData.propSearchTerm}
                        token={componentData.apiToken}
                        metaTitle={metaTitle}
                        domainList = {domainList}
                        backHalf = {backHalf}
                        contactTrackingErrorMessage = {contactTrackingErrorMessage}
                        contactTrackingTitleErrorMessage = {contactTrackingTitleErrorMessage}
                        genrateBackHalf = {this.genrateBackHalf}
                        getUpdatedMetaTitle = {(updatedMetaTitle) => {this.handleUpdatedMetaTitle(updatedMetaTitle)}}
                        getCustomShortURL={(getShortenURLDetails) => this.handleCustomshortenURL(getShortenURLDetails)}
                        fixedPrefix={this.state.fixedPrefix}
                        eventData={this.props.componentData.eventData}
                        setSegmentUrlType={this.setSegmentUrlType}
                        segmentCustomerType={this.state.segmentCustomerType}/>
                break;
            case "confirmationScreen" :
                activeModalScreen = <ConfirmationScreen
                    longURL={searchTerm || this.props.componentData.propSearchTerm}
                    shortURLPreivew = {shortURLPreivew}
                    isContactTrackingEnabled = {isTrackingEnabled}
                    errorMessage = {creationErrorMessage}
                    eventData={this.props.componentData.eventData}
                    segmentUrlType={this.state.segmentUrlType}
                    selectedDomain={this.state.selectedDomain}
                    segmentCustomerType={this.state.segmentCustomerType}/>;
                break;
            default : 
                null;
        }
        return (
            <>
                <CommonSideModal 
                    isModalClosed = {componentData.isModalClosed}
                    id={componentData.id}
                    modalClass={`sideModal animate-right url_shortener_modal ` + (componentData.isModalClosed ? "hide" : "show")} 
                    modalBodyClass = {active_screen === 'searchBox' ? 'heightAuto' : ""}
                    modalHeader={
                            <>
                                <button
                                    type="button"
                                    className="close panel-modal-close"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                    onClick={active_screen === 'searchBox' ? this.handleCloseModal : this.openCloseConfirmModal}><CloseButton width="16" height="16" /></button>
                                {
                                    isCloseConfirmModalOpen && 
                                    <CloseConfirmationScreen 
                                        closeCloseConfirmModal = {this.closeCloseConfirmModal}
                                        closeURLShortenerMainModal = {(e) => this.closeURLShortenerMainModal(e)}
                                        heading = 'Are you sure you want to exit?' 
                                        paraText = 'All modifications made to the URL will be lost.' 
                                    />
                                }
                                <div className="side-modal-header panel-modal-header-title panel-modal-header-title-padding">
                                    {
                                        active_screen !== 'confirmationScreen' ? 
                                        <>
                                            <div className="heading-icon"><ShortUrlIcon/></div>
                                            <div className="heading-text">
                                                <h3>Shorten URL</h3>
                                                <h5>Convert your long URL into customized short URL</h5>
                                            </div>
                                        </>
                                        : 
                                        <>
                                            <div className="heading-icon"><QuestionMarkIcon2/></div>
                                            <div className="heading-text"> 
                                                <h3>Confirmation</h3>
                                                <h5>You are just a click away from creating a new short URL</h5>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                    }
                    modalBodyId="modal-body"
                    activeModalScreen = {activeModalScreen}
                    footerClassName={"panel-modal-footer m-0 p-0 " + (active_screen !== "searchBox" ? "side-modal-footer" : null)}
                    footerContent = {
                        active_screen !== "searchBox" ? 
                            <>
                                {   active_screen !== "customizeURL" &&
                                    <a className="btn btn-default mr-5 modal-button-padding common-modal-button-margin"
                                    data-dismiss="modal" onClick={this.handlePrevScreen}>Back</a>
                                }
                                {
                                    active_screen !== "confirmationScreen" ?
                                            <a id="modal-discard-btn"
                                            className={`btn common-modal-button-margin mr-20 ${submitButtonclass}`} onClick={active_screen !== "contactTracking" ? this.handleNextScreen : this.handleContactTracking}>{active_screen !== "contactTracking" ? 'Next' : 'Create'}</a>
                                        :
                                        <a id="modal-discard-btn"
                                            className={`btn common-modal-button-margin mr-20 ${submitButtonclass}`} onClick={this.handleShortUrlCreation}>Confirm</a>
                                }
                            </>
                        : 
                        <>
                            <div className={"createShortURLWrapper peninsulaGreyBorder shortenUrlBtn"}>
                                <div id="searchTerm" className="url-search-result">{searchTerm}</div>
                                <button active_layout='customizeURL'disabled={!isSearchTermValid} className={`createShortURLBtn ` + (!isSearchTermValid ? 'opacity40' : "")} onClick={this.handleNextScreen}>
                                    <span className={displayProperty}>{(!isSearchTermValid) && <Loading/> }</span>
                                    <span>Shorten URL</span>
                                </button>
                            </div>
                            <div className="errorColor" style={{'padding': '0 10px 10px 10px', "backgroundColor" : "white"}}>{validUrlErrorMessage}</div>
                        </>
                    }
                />
            </>
        );
    }
};

export default URLShortenerSideModal;