import React, { useEffect, useState, useRef } from "react";
import SingleTransferAgent from "./SingleTransferAgent";
import SingleTransferAgentWrapper from "./SingleTransferAgentWraper";
import ProfileIcon from "../../../../../assets/svg/ProfileIcon";
import './SingleTransferAgent.scss';

const AgentFilter = ({campaign_id, getCampaignAgents, filterData , setFilterData, showFooterButton, componentRenderdIn, applyFilter}) => {
 
    const containerRef = useRef(null);
    const [dropDownOpen, setDropDownOpen] = useState(false)
    // const [filterData, setFilterData] = useState({agent_ids:[]})


    const onSelectClick = () => {
        setDropDownOpen(!dropDownOpen)
    }

    useEffect(() => {
        function handleClickOutside(event) {
          if (containerRef.current && !containerRef.current.contains(event.target) && dropDownOpen) {
                setDropDownOpen(false);
                document.removeEventListener('click', handleClickOutside);
          }
        }
    
        document.addEventListener('click', handleClickOutside);

    }, [dropDownOpen]);

    return(
        <div className="single-transfer-agent-contact" ref={containerRef}>
            <div className='ch-agent-filter-layout'>
                <SingleTransferAgent dropDownOpen={dropDownOpen} onSelectClick={onSelectClick} containerIcon = {ProfileIcon} iconWidth={12} iconHeight={14} selectedData={filterData.agent_ids} title={"Agents"} />
                {dropDownOpen && <SingleTransferAgentWrapper campaignId={campaign_id} setDropDownOpen={setDropDownOpen} getCampaignAgents={getCampaignAgents} filterData={filterData} setFilterData={setFilterData} showFooterButton={showFooterButton} componentRenderdIn={componentRenderdIn} applyFilter={applyFilter} />}
            </div>
        </div>
        
    )
}

export default AgentFilter