import React, { useState, useEffect, useCallback } from 'react'
import AllPlanTypeTab from "./AllPlanTypeTab";
import AllPlanTypeCards from "./AllPlanTypeCards";
import "./AllSubscriptionPlans.scss";
import _ from 'lodash';

function AllSubscriptionPlans(props) {

    const {
        direction,
        tabWidth,
        allPlanTabData,
        allPlancardData,
        buttonStates,
        currentPlanBillingPeriod,
        currentPlanName,
        billingRankings,
        trialData,
        handleDowngrade,
        minTextCallCost,
        apiToken,
        daysLeft,
        isEmailVerified,
        upgradeButtonClicked
    } = props;

    const [smallScreen, setsmallScreen] = useState(false);
    const [currentPlanTab, setCurrentPlanTab] = useState(currentPlanBillingPeriod && currentPlanBillingPeriod !=="" && currentPlanBillingPeriod!=="Trial" ? currentPlanBillingPeriod : "Monthly");

    const delayedScreenCheck = useCallback(_.debounce(() => checkScreenWidth(), 500), []);

    const checkScreenWidth = () => {
        let screenWidth = window.innerWidth;
        if(screenWidth < 1200) {
            setsmallScreen(true);
        }else {
            setsmallScreen(false);
        }
    }

    useEffect(() => {
        checkScreenWidth();
        window.addEventListener('resize', delayedScreenCheck);
        return () => {
            window.removeEventListener('resize', delayedScreenCheck);
        }
    }, [])
    
    return (
        <>
            <div className={`disp-flex justify-center align-center ${direction !== "column" || smallScreen ? "flex-col flex-wrap overflow-auto" : ""}`}>
                <div className="mb-20px">
                    <AllPlanTypeTab 
                        direction={smallScreen ? "row" : direction}
                        tabWidth={tabWidth}
                        allPlanTabData={allPlanTabData}
                        handleTabChange={setCurrentPlanTab}
                        selectedTab={currentPlanTab}
                        title="CHOOSE TERM"
                    />
                </div>
                <div className="allplan-cards-container">
                    <AllPlanTypeCards 
                        allPlancardData={allPlancardData}
                        width="100%"
                        planType={currentPlanTab} // Monthly billing, Half-yearly, Quarterly, Yearly
                        buttonStates={buttonStates}
                        currentPlanBillingPeriod={currentPlanBillingPeriod}
                        currentPlanName={currentPlanName}
                        billingRankings={billingRankings}
                        trialData={trialData}
                        handleDowngrade={handleDowngrade}
                        minTextCallCost={minTextCallCost}
                        apiToken={apiToken}
                        daysLeft={daysLeft}
                        isEmailVerified={isEmailVerified}
                        upgradeButtonClicked={upgradeButtonClicked}
                    />
                </div>
            </div>
            <div className={`${smallScreen ? "in-depth-pricing-smallscreen" : "in-depth-pricing"}`}>
                <p className="in-depth-text">
                    For price per text and call, see <br/>
                    <a href="https://callhub.io/pricing/#in-depth-pricing-section-container" target="_blank" className="in-depth-link">in-depth pricing</a>
                </p>
            </div>
        </>
    )
}

export default AllSubscriptionPlans
