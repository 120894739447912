import React from 'react'

const SMSBroadcastKycIcon = props => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 444 444"
    width="30px"
    height="30px"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <circle
          cx={222}
          cy={222}
          r={222}
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>
        {
          ".cls-2{fill:none;stroke:#b4bfc9;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.29px}"
        }
      </style>
    </defs>
    <g
      style={{
        clipPath: "url(#clippath)",
      }}
      id="Layer_1-2"
    >
      <path
        style={{
          fill: "#ffb3c3",
        }}
        d="M0 0h444v444H0"
      />
      <path
        id="_221701808"
        d="M376.59 328.73h-254.9l-18.73 17.21-18.73 17.21v-34.42h-8.4c-16.05 0-29.18-13.13-29.18-29.19V157.77c0-16.05 13.13-29.18 29.18-29.18h300.76c16.05 0 29.18 13.13 29.18 29.18v141.77c0 16.05-13.13 29.19-29.18 29.19Z"
        style={{
          fill: "#4d4d4f",
          isolation: "isolate",
          opacity: 0.32,
        }}
      />
      <path
        id="_221701808-2"
        d="M368.86 320.14h-254.9l-18.73 17.21-18.73 17.21v-34.42h-8.4c-16.05 0-29.18-13.13-29.18-29.19V149.18c0-16.05 13.13-29.18 29.18-29.18h300.76c16.05 0 29.18 13.13 29.18 29.18v141.77c0 16.05-13.13 29.19-29.18 29.19Z"
        style={{
          fill: "#fcfdfc",
        }}
      />
      <path
        className="cls-2"
        d="m77.82 155.23 15.02 15.02 14.14-14.13 15.24 15.23 15.87-15.86 19.07 19.07 18.27-18.27 19.18 19.18 19.24-19.23 19.21 19.21 20.19-20.2 19.46 19.47 21-21 20.97 20.98 21.12-21.11 21.58 21.58M110.87 217.47l14.14-14.14 15.24 15.24 15.87-15.86 19.07 19.07 18.27-18.28 19.18 19.19 19.24-19.23 19.21 19.21 20.19-20.2"
      />
    </g>
  </svg>
)

export default SMSBroadcastKycIcon