import { InfoCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Popover, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./EnableRotation.scss";
import BlockCreationModal from "../BlockCreationModal";
import { getAvailableProactiveBlocks, proactivePopoverContent, getSessionKey } from "../../../utilities/NumberRotationUtilities";
import { trackSegementEvents } from "../../common/SegmentEventsConstant/SegmentEventsConstant";
import { segmentEvents } from "../constants";

const EnableNumberRotation = (props) => {
  const {spamSwitch, rotation_enabled_earlier, nextButton, targeting_wizard, campaign_id,
      dynamicCallerIdSwitch, disable_feature, isRotationSelected, showHelpLinks} = props.componentData;
  const [blockSelected, setBlockSelected] = useState(isRotationSelected);
  const [showBlockCreation, setShowBlockCreation] = useState(false);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(disable_feature);
  const [showError, setShowError] = useState(false);
  const [selection, setSelection] = useState(null);
  const rotationEnabled = useRef(); // using ref to check the value of checkbox inside event listener

  const sessionKey = getSessionKey(campaign_id);

  useEffect(() => {
    trackSpamSwitch();
    trackDynamicCallerIdSwitch();
    trackNextButtonClick();
    getAvailableBlocks();
    return () => {
      spamSwitch.removeEventListener("click", builderEventController);
      dynamicCallerIdSwitch.removeEventListener("click", trackDynamicCallerIdSwitch);
      nextButton.removeEventListener("click", trackNextButtonClick);
      sessionStorage.removeItem(sessionKey);
    };
  }, []);

  const trackSpamSwitch = () => {
    spamSwitch.addEventListener("click", builderEventController);
  };

  const trackDynamicCallerIdSwitch = () => {
    if(dynamicCallerIdSwitch){
      dynamicCallerIdSwitch.addEventListener("click", () => {
        const isDynamicCallerIdEnabled = dynamicCallerIdSwitch.checked;
        setDisabled(rotation_enabled_earlier || isDynamicCallerIdEnabled);
      })
    }
  }

  const trackNextButtonClick = () => {
    nextButton.addEventListener("click", () => {
      const isSelectionMade = sessionStorage.getItem(sessionKey);
      if(targeting_wizard.classList.contains('active') && (rotationEnabled.current && !isSelectionMade)){
        setShowError(true);
      }else{
        setShowError(false);
      }
    })
  }

  const showBlockCreationModal = () => {
    handleBlockShow(true);
  };

  const closeBlockCreationModal = () => {
    handleBlockShow(false);
  };

  const handleBlockShow = (show) => {
    setShowBlockCreation(show);
  };

  const handleOptions = (newOption) => {
    setOptions([newOption, ...options]);
  }

  const builderEventController = () => {
    return props.unMountTheComponent(false);
  }

  const saveSelection = (value) => {
    setSelection(value);
    sessionStorage.setItem(sessionKey, value);
    setShowError(false);
  }

  const getAvailableBlocks = async () => {
    let prevSelection = null;
    if(isRotationSelected){
      prevSelection = sessionStorage.getItem(sessionKey);
      setSelection(prevSelection);
    }
    const response = await getAvailableProactiveBlocks(prevSelection);
    if(response.data){
      setOptions(response.data);
    }
  }

  const toggleNumberRotation = (event) => {
    const isChecked = event.target.checked;
    setBlockSelected(isChecked);
    rotationEnabled.current = isChecked;
    if(isChecked){
      const segmentTraits = {
        place: campaign_id ? 'campaign_edit' : 'campaign_creation'
      };
      trackSegementEvents(segmentEvents.enabled, segmentTraits);
    }else{
      setSelection(null);
      sessionStorage.removeItem(sessionKey);
    }
  }

  return (
    <div className="nudge-number-rotation">
      <div>
        <Checkbox
          id="enableNumberRotation"
          onChange={(e) => toggleNumberRotation(e)}
          disabled={disabled}
          checked={blockSelected}
        >
          Proactively swap numbers from a series of preferred area codes{" "}
        </Checkbox>
        <Popover
          title="Proactively number swapping"
          content={proactivePopoverContent(showHelpLinks)}
          trigger="click"
          overlayClassName="proactive-popover"
        >
          <InfoCircleFilled style={{ fontSize: "1rem", color: "#00000073" }} />
        </Popover>
      </div>
      {blockSelected && (
        <>
          <div className="block-selection-container">
            <div className="block-header">
              <p>Select a series</p>
            </div>
            <div className="blocks-container">
              <div className="rotation-select-block">
                <Select
                  className="blocks-select"
                  size="medium"
                  placeholder="Select series"
                  options={options}
                  onSelect={saveSelection}
                  disabled={rotation_enabled_earlier}
                  status={showError ? "error" : ''}
                  style={{width: "160px"}}
                  value={selection}
                />
                {showError && (<p className="error-text">Please select a series</p>)}
              </div>
              <Button 
                className="create-series" 
                onClick={showBlockCreationModal} 
                disabled={rotation_enabled_earlier}
              >
                <PlusOutlined style={{ fontSize: "1rem", color: rotation_enabled_earlier ? "rgba(0, 0, 0, 0.25)" : "#3E89DE" }} />{" "}
                Create a series
              </Button>
            </div>
          </div>
          <BlockCreationModal
            showModal={showBlockCreation}
            handleCancel={closeBlockCreationModal}
            handleOptions={handleOptions}
            campaign_id={campaign_id}
          />
        </>
      )}
    </div>
  );
};

export default EnableNumberRotation;
