
import axios from 'axios';
import {
    callhubCustomFieldUrl,
    ngpVanCallhubFieldUrl,
    ngpVanRevokeAccessUrl,
    salesforceFieldsUrl,
    salesforceSettingsPageDetails,
    addQuestionCampaignCreationSectionUrl,
    addQuestionsCampaignEditUrl,
    editQuestionsCampaignEditUrl,
    getDetailsForQuestionEditUrl,
    getDetailsForQuestionEditInCampaignCreationUrl,
    editQuestionCampaignCreationSectionUrl,
    salesforceRevokeAccessUrl,
    vanDispositionUrl,
    pdiMappingDataUrl
} from './integrationConfig';

import _ from "lodash";

export const apiGetCall = (url, id) => {
    return axios.get(url,  {
        params: {
            jobID: id
        },
        headers: {
            'Authorization': 'Token ' + token
        }
    })
}
    
export const apiPostCall = (url, data) => {
    return axios.post(url, data, {
        headers: {
            'Authorization': 'Token ' + token
        }
    })
}

export const getCallhubCustomFields = async () => {
    try {
        const callhub_customfield_url = callhubCustomFieldUrl();
        const response = await apiGetCall(callhub_customfield_url);
        return response.data
    }
    catch(error){
        console.log(error); 
    }
}

export const getngpVanCallhubFields = async () => {
  try {
    const ngpVan_callhubfield_url = ngpVanCallhubFieldUrl();
    const response = await apiGetCall(ngpVan_callhubfield_url);
    return response.data.data
  }
  catch(error){
      console.log(error); 
  }
}

export const addCustomField = async (formData) => {
  try{
      const ngpVan_customfield_url = callhubCustomFieldUrl();
      const response = await apiPostCall(ngpVan_customfield_url, formData);
      return response.data; 
  }
  catch(error){
      console.log(error)
      return error.message;
  }
}

export const saveNgpVanList = async (formData) => {
  try{
      const ngpVan_customfield_url = ngpVanCallhubFieldUrl();
      const response = await apiPostCall(ngpVan_customfield_url, formData);
      return response.data; 
  }
  catch(error){
      return error.response.data;
  }
}

export const ngpVanRevokeAccess = async () => {
    try{
        const ngpVan_revoke_access_url = ngpVanRevokeAccessUrl();
        const response = await apiPostCall(ngpVan_revoke_access_url);
        return response.data; 
    }
    catch(error){
        return error.response.data;
    }
  }


export const getSalesforceFields = async (apiToken) => {
    try {
        const salesforce_field_url = salesforceFieldsUrl();
        const response = await axios.get(salesforce_field_url, {
            headers: {
                'Authorization': 'Token ' + apiToken
            }
        })
    return response.data
    }
    catch(error){
        console.log(error); 
    }
}

export const addQuestionsCampaignCreation = async (data, apiToken) => {
	try {
		const add_question_section_url = addQuestionCampaignCreationSectionUrl();
		const response = await axios.post(add_question_section_url, data, {
			headers: {
				Authorization: "Token " + apiToken,
			},
		});
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};


export const addQuestionsCampaignEdit = async (data, apiToken, campaignId) => {
	try {
		const add_question_section_url = addQuestionsCampaignEditUrl(campaignId);
		const response = await axios.post(add_question_section_url, data, {
			headers: {
				Authorization: "Token " + apiToken,
			},
		});
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

export const editQuestionsCampaignCreation = async (data, apiToken, sectionId, campaignType) => {
	try {
		const edit_question_section_campaign_creation_url = editQuestionCampaignCreationSectionUrl(sectionId, campaignType);
		const response = await axios.post(edit_question_section_campaign_creation_url, data, {
			headers: {
				Authorization: "Token " + apiToken,
			},
		});
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};


export const editQuestionsCampaignEdit = async (data, apiToken, sectionId, campaignId,campaignType) => {
	try {
		const edit_question_section_campaign_edit_url = editQuestionsCampaignEditUrl(campaignId, sectionId, campaignType);
		const response = await axios.post(edit_question_section_campaign_edit_url, data, {
			headers: {
				Authorization: "Token " + apiToken,
			},
		});
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};


export const getQuestionDetails = async (apiToken, campaignId, sectionId, campaignType) => {
	try {
        const get_question_details_url = _.isEmpty(campaignId)
            ? getDetailsForQuestionEditInCampaignCreationUrl(sectionId, campaignType)
            : getDetailsForQuestionEditUrl(campaignId, sectionId, campaignType);
		const response = await axios.get(get_question_details_url, {
			headers: {
				Authorization: "Token " + apiToken,
			}
		});
	return response.data;
	} catch (error) {
		return error.response.data;
	}
};
export const getSalesforceSettingsDetails = async () => {
    try {
        const salesforce_details_url = salesforceSettingsPageDetails();
        const response = await apiGetCall(salesforce_details_url);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const postSalesForceSettingsDetails = async (formData) => {
    try {
        const salesforce_details_url = salesforceSettingsPageDetails();
        const response = await apiPostCall(salesforce_details_url, formData);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const salesforceRevokeAccess = async () => {
    try{
        const salesforce_revoke_access_url = salesforceRevokeAccessUrl();
        const response = await apiPostCall(salesforce_revoke_access_url);
        return response.data; 
    }
    catch(error){
        return error.response.data;
    }
}

export const getVanDispositionFields = async (id) => {
    try {
        const van_disposition_url = vanDispositionUrl();
        const response = await apiGetCall(van_disposition_url, id)
        return {
            data: response.data,
            status: response.status
        }
    }
    catch(error) {
        return {
            error: error.response.data,
            status: error.response.status
        }
    }
}

export const getPdiIntegrationFields = async (question) => {
    try {
        const pdi_mappingData_url = pdiMappingDataUrl(question);
        const response = await apiGetCall(pdi_mappingData_url)
        return {
            data: response.data,
            status: response.status
        }
    }
    catch(error) {
        return {
            error: error.response.data,
            status: error.response.status
        }
    }
}

export const postPdiIntegrationFields = async (data) => {
    try {
        const pdi_mappingData_url = pdiMappingDataUrl();
        const response = await apiPostCall(pdi_mappingData_url, data)
        return {
            data: response.data,
            status: response.status
        }
    }
    catch(error) {
        return {
            error: error.response.data,
            status: error.response.status
        }
    }
}