import React from 'react';
import "./TrailGiftContainer.scss";
import { Form, Radio } from 'antd';
import {ExpertiseLevels} from "./KycConstants"
import KYCBeginnerIcon from '../../../../assets/svg/KYCBeginnerIcon';
import "./ExpertiseLevel.scss";
import KYCAdvancedIcon from '../../../../assets/svg/KYCAdvancedIcon';
import KYCVeteranIcon from '../../../../assets/svg/KYCVeteranIcon';

const ExpertiseLevel = ({handleContinue, getBrandName}) => {
    const {Item} = Form;
    let [form] = Form.useForm();
    return(
        <div className="fourth-slide">
            <div className="slide_four_heading">Your level of expertise? </div>
            <div className="mobile-slide_four_heading">
                <p className="heading">Your level of expertise? </p>
                <p className='sub-heading'>Your choice will tailor your experience in {getBrandName}</p>
            </div>
            <div className='expertise-level-form'>
                <Form
                    layout="vertical" 
                    className="expertise_level_form"
                    requiredMark="optional"
                    form={form}
                    name="kyc-form-4"
                    scrollToFirstError={true}
                >
                    <Item
                        required={true}
                        rules={[{required:true,message:"Select one option"}]}
                        name="ExpertiseLevel"
                    >
                        <Radio.Group className='kyc-expertise-list-radioGroup'>
                            <Radio.Button value={ExpertiseLevels.basic.heading}>
                                <div className="expert_container">
                                    <KYCBeginnerIcon/>
                                    <div className="content">
                                        <p className='expert-level-heading'>{ExpertiseLevels.basic.heading}</p>
                                        <p className='expert-level-des'>{ExpertiseLevels.basic.description}</p>
                                    </div>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                            <Radio.Button  value={ExpertiseLevels.medium.heading} >
                                <div className="expert_container">
                                    <KYCAdvancedIcon/>
                                    <div className="content">
                                        <p className='expert-level-heading'>{ExpertiseLevels.medium.heading}</p>
                                        <p className='expert-level-des'>{ExpertiseLevels.medium.description}</p>
                                    </div>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                            <Radio.Button value={ExpertiseLevels.advanced.heading} >                            
                                <div className="expert_container">
                                    <KYCVeteranIcon/>
                                    <div className="content">
                                        <p className='expert-level-heading'>{ExpertiseLevels.advanced.heading}</p>
                                        <p className='expert-level-des'>{ExpertiseLevels.advanced.description}</p>
                                    </div>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                        </Radio.Group>

                    </Item>
                    <Item>
                        <button className='kyc-continue-button' type="submit" onClick={()=>{handleContinue(5 , form.getFieldValue(), form)}}>Finish</button>
                    </Item>
                </Form>
            </div>
        </div>
    )
}

export default ExpertiseLevel;