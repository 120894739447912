import React, { useState, useEffect, Fragment, useRef } from "react";
import CaretIcon from "./../../../../assets/svg/CaretIcon";
import Select from "../../../components/common/Select";
import PersonaliseTag from "../../../../src/components/common/PersonaliseTag";
import TrashIcon from "../../../../assets/svg/TrashIcon";
import AddButton from "../../../components/common/AddButton";
import NoteIcon from "../../../../assets/svg/NoteIcon";

// Importing Components
import _ from "lodash";

//css
import "./AddSurvey.scss";
import "../commonStyle.scss";

const dropDownComponent = () => {
	return (
		<span className="position-caret-icon">
			<CaretIcon size={24} labelstyle="dropdown-icon" />
		</span>
	);
};

const AddSurvey = props => {
	const {
		salesforceField,
		question,
		showPersonaliseTagSection,
		showMultichoiceSection,
		multichoice,
		multichoiceTags,
		salesTagErrorStatus,
	} = props;

	const questionRef = useRef(null);

	const handleInsertTextAtCursorPosition = textMessage => {
		let messageEle = questionRef.current;
		let scrollPositionMessageArea = messageEle.scrollTop;
		let cursorPosition = questionRef.current.selectionStart;
		let textBeforeCursorPosition = messageEle.value.substring(
			0,
			cursorPosition
		);
		let textAfterCursorPosition = messageEle.value.substring(
			cursorPosition,
			messageEle.value.length
		);
		messageEle.value =
			textBeforeCursorPosition + textMessage + textAfterCursorPosition;
		cursorPosition = cursorPosition + textMessage.length;
		messageEle.selectionStart = cursorPosition;
		messageEle.selectionEnd = cursorPosition;
		messageEle.focus();
		messageEle.scrollTop = scrollPositionMessageArea;
		return messageEle.value;
	};
	const handleAppendPersonaliseTagToQuestion = (tags) => {
			const tag = `${tags}`;
			props.handleAppendPersonaliseTagToQuestion(`${handleInsertTextAtCursorPosition(tag)}`);
	}
		return (
		<div id="salesforce-addquestion-modal">
			<div>
				<div className="salesforceLabelSection">
					<span>
						Salesforce field:{" "}
						<span className="salesforceLabelContent">
							{salesforceField.selectedOption.label}
						</span>
					</span>
				</div>
			</div>
			<div className="msg-content">
				<textarea
					id="question"
					ref={questionRef}
					value={question}
					placeholder="Enter a question that will collect data for the salesforce field. Eg. What is the supporter rating?"
					onChange={event =>
						props.changeSalesforceFieldHandler(event, "question")}
				/>
				{salesTagErrorStatus &&
					<p className="text-content error-message-color">
						Please enter the question
					</p>
				}
			</div>
			{showMultichoiceSection &&
				<div className="multi-choice-box">
					<div className="multi-choice-container">
						<div className="option-box width-210">
							<label>RESPONSE OPTIONS</label>
						</div>
						<div className="tags-box">
							<label>SALESFORCE TAGS</label>
						</div>
					</div>
					{!_.isEmpty(multichoice) &&
						multichoice.map((choice, index) => {
							return (
              <>
								<div className="multi-choice-container" key={index+choice.pk_str}>
									<div className="option-box">
										<i className={`${salesforceField.selectedOption.field_type == 6 ? 'icon-check-empty' : 'icon-circle-blank'} section-multi-option background-dark h-30`} />&nbsp;
										<input type="text" disabled value={choice.choice} />
									</div>
									<div
										className="tags-box"
									>
										<Select
											{...multichoiceTags}
											options={[{value: "Choose" , label: "Choose"}, ...multichoiceTags.options]}
											selectedOption={[
												{
													label: choice.tag_label ? choice.tag_label : "Choose",
												},
											]}
											placeholder="Choose"
											isClearable={false}
											closeMenuOnSelect={true}
											isSearchable={false}
											components={{
												IndicatorSeparator: () => {
													return null;
												},
												DropdownIndicator: dropDownComponent,
											}}
											name="multichoiceTags"
											changed={event =>
												props.changeMultichoiceHandler(event, index)}
											className="input-style"
											loadingMessage="Fetching tags......"
											menuPortalTarget={document.body}
          						menuPosition={'fixed'}
											customStyle={{
												menuPortal: provided => ({ ...provided, zIndex: 9999 }),
												menu: provided => ({ ...provided, zIndex: 9999 }),
											}}  
										/>
									</div>
									{
										index > 1 &&
										<div className="question-trash-icon">
											<span>
												<TrashIcon size={24} />
											</span>
										</div>
									}
								</div>
              </>
							);
						})}
					<AddButton classStyle="ml-0" btnName="Add another option" />
				</div>}
			{showMultichoiceSection &&
				<div className="tip-grey-container text-left m-t-10">
					<h5 className="tip-heading-title">
						<NoteIcon size={"16"} /> NOTE
					</h5>
					<p className="text-grey-style text-line-height-19 text-size-13 m-0 text-left">
						To modify response options, update the fields back in Salesforce.
					</p>
				</div>}
			{showPersonaliseTagSection &&
				<PersonaliseTag
					apiToken={props.apiToken}
					handleClosePerosnaliseTags={props.PersonaliseTagHandler}
					handleAppendTags={handleAppendPersonaliseTagToQuestion}
				/>}
		</div>
	);
};

export default AddSurvey;
