import React from "react"

function Close(props) {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns xlink="http://www.w3.org/1999/xlink" 
    version="1.2" 
    overflow="visible" 
    preserveAspectRatio="none" 
    viewBox="0 0 14 18" 
    width="14" 
    height="18">
        <g transform="translate(0, 0)">
            <g transform="translate(0, 0) rotate(0)">
                <path d="M11,6v10h-8v-10zM9.5,0h-5l-1,1h-3.5v2h14v-2h-3.5zM13,4h-12v12c0,1.1 0.9,2 2,2h8c1.1,0 2,-0.9 2,-2z" 
                style={{"strokeWidth": "0", "strokeLinecap": "butt", "strokeLinejoin": "miter" }}
                fill={props.color??"#000"}
                vectorEffect="non-scaling-stroke"/>
            </g>
            <defs>
                <path 
                    id="path-16293597685792225" 
                    d="M11,6v10h-8v-10zM9.5,0h-5l-1,1h-3.5v2h14v-2h-3.5zM13,4h-12v12c0,1.1 0.9,2 2,2h8c1.1,0 2,-0.9 2,-2z" 
                    vectorEffect="non-scaling-stroke"
                />
            </defs>
        </g>
    </svg>

  )
}

export default Close
