import React, { useState } from 'react';
import CancelIcon from '../../../assets/svg/CancelIcon';
import WorkflowV2BannerIcon from '../../../assets/svg/WorkflowV2BannerIcon';
import "./WorkflowDiscoverabilityBanner.scss";
import { trackSegementEvents } from '../common/SegmentEventsConstant/SegmentEventsConstant';
import { mobileDiscoverabilityStatus } from '../../configuration/mobileDiscoverConfig';
import { postMobileDiscoverabilityStatus } from '../../utilities/mobileDiscoverUtilities'

function WorkflowDiscoverabilityBanner({type, setShowContent, userDetails, token = ""}) {

    const [optedIn, setOptedIn] = useState(false);

    const onOptInLinkClick = async (campaignType, flow) => {
        trackEvent('workflow-optin-link-clicked', {
            'campaign-type': campaignType,
            'flow': flow,
            'email': userDetails?.userEmail,
            'username': userDetails?.username,
            'user-id': userDetails?.userId,
        });
        const body = {
            workflow_discover_status: 'opted-in',
        }
        const url = mobileDiscoverabilityStatus();
        await postMobileDiscoverabilityStatus(url, body, token);
    }

    const onBannerClose = async (campaignType, flow) => {
        trackEvent('workflow-banner-closed', {
            'campaign-type': campaignType,
            'flow': flow,
            'email': userDetails?.userEmail,
            'username': userDetails?.username,
            'user-id': userDetails?.userId,
        });
        const body = {
            workflow_discover_status: 'dismissed',
        }
        const url = mobileDiscoverabilityStatus();
        await postMobileDiscoverabilityStatus(url, body, token);
    }

    const trackEvent = (event, data) => {
        trackSegementEvents(event, data);
    }

  return (
    <div className={`workflow-discoverability-banner ${type=="p2p" ? 'workflow-discoverability-banner-p2p' : 'workflow-discoverability-banner-cc'}`}>
        <div>
            <div>
                <a href="https://membr.us/WorkflowBeta" target="_blank">
                    <WorkflowV2BannerIcon />
                </a>
            </div>
            <div className='banner-content-container'>
                <p><span className='bnr-ttl'>Workflows Beta:</span> 
                {type=="cc" &&
                    " Send texts and emails based on survey responses, automate journeys across products, and share data with your CRM using webhooks."
                }
                {type=="p2p" &&
                    " Send emails or add to follow-up campaigns based on survey responses, automate journeys across products, and share data with your CRM using webhooks."
                }
                &nbsp;
                {!optedIn && <span className='wfv2-optin-text' onClick={async () => {
                    await onOptInLinkClick(type, 'Campaign creation');
                    setOptedIn(true);
                }}>Click to join Beta</span>}
                {optedIn && <span className='opted-in-text'>Thanks! We'll reach out soon via email.</span>}
                </p>
            </div>
            <div>
                <CancelIcon className='close-banner-btn' onClick={async () => {
                    setShowContent(false);
                    await onBannerClose(type, 'Campaign creation');
                }} width="12" height="12" />
            </div>
        </div>
    </div>
  )
}

export default WorkflowDiscoverabilityBanner;