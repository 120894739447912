import React from 'react'
import "./AllPlanTypeTab.scss";

function PlanTab(props) {
    let tabPosition;
    let { plan, index, length, direction, handleClick, selected } = props
    if(index === 0) {
        tabPosition = "first";
    }else if(index === length - 1) {
        tabPosition = "last";
    }else {
        tabPosition = "mid"
    }

    return (
        <div onClick={handleClick} className={`cursor-pointer plan-tab ${selected ? "bg-3690e6" : "bg-ffffff plan-tab-not-selected"}`}>
            <div className={`plan-tab-radio ${!selected ? "radio-border" : ""}`}></div>
            <p className={`text-size-13 ${selected ? "text-ffffff" : "text-444444"} m-0`}>
                <span>{plan.name}</span>
                {
                    direction === "column" && <br/>
                }
                {
                    plan.savings && plan.savings !== null && plan.savings !== "" &&
                    <span className={`${selected ? "text-ffffff" : "text-1ab394"}`}>&nbsp;SAVE ${plan.savings}</span>
                }
            </p>
        </div>
    )
}

function AllPlanTypeTab(props) {

    const {
        direction,
        tabWidth,
        allPlanTabData,
        handleTabChange,
        selectedTab,
        title
    } = props;

    const isSelectedTab = (planName) => {
        return selectedTab === planName 
        || selectedTab.toLowerCase() === planName.toLowerCase() 
        || selectedTab.toLowerCase().replace("-", " ") === planName.toLowerCase() 
        || selectedTab.toLowerCase().replace(" ", "-") === planName.toLowerCase(); 
    }

    return (
        <>
            <div className={`allplantabs-heading ${direction === "column" ? '' : 'text-align-center'}`}>{title}</div>
            <div 
                style={{width: direction !== "column" ? "" : tabWidth}} 
                className={`allplantype-container disp-flex ${direction === "column" && "flex-col mr-20px"}`}
            >
                {
                    allPlanTabData.map((plan, index) => {
                        return <PlanTab 
                            plan={plan} 
                            index={index} 
                            key={index} 
                            length={allPlanTabData.length}
                            direction={direction}
                            handleClick={() => handleTabChange(plan.name)}
                            selected={isSelectedTab(plan.name)} //{selectedTab === plan.name || selectedTab === plan.name.toLowerCase() ? true : false}
                        />
                    })
                }

            </div>
        </>
        
    )
}

export default AllPlanTypeTab
