
export const getAgentList = (campaign_id, limit, offset, search_term) => {
    return `email/v_1/power-campaign/agent-list/${campaign_id}/?limit=${limit}&offset=${offset}&search_term=${search_term}`
}

export const getSmartFilterDataUrl = (campaign_id)=>{
    return `nlp_insights/${campaign_id}/`
}

export const goalCreationUrl = (campaign_id) =>{
    return `email/v_1/nlp_insights/${campaign_id}/create/`
}