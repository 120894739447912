import React, { useEffect, useState } from "react";
import { Line } from '@ant-design/plots';
import { useRecoilValue } from "recoil";
import {SentimentGraphDataAtom} from '../../../../RecoilSharedState/CcAiPowed/CcAiPowerdState';
import './SentimentOvertime.scss';

const SentimentOvertime = ()=>{
    const sentimentGraphData = useRecoilValue(SentimentGraphDataAtom);
    const [data, setData] = useState([]);

    const config = {
        data,
        xField: 'date',
        yField: 'value',
        yAxis: {
            //   label: {
            //     formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            //   },
            line: {
                visible: true, // Show the y-axis label line
                style: {
                    opacity: 0.3, // Adjust the opacity of the y-axis line
                },
            },
            tickCount: 6, // Adjust the number of ticks on the y-axis as per the desired interval
            nice: true, // Enable nice scale for the y-axis
            min: 0, // Optional: Set the minimum value for the y-axis
            max: 100, // Optional: Set the maximum value for the y-axis
        },
        xAxis: {
            label: null,
        },
        seriesField: 'type',
        color: ({ type }) => {
          return type === 'Negative' ? '#F4664A' : type === 'Positive' ? '#30BF78' : '#FAAD14';
        },
        lineStyle: () => {
          return {
            opacity: 0.5,
          };
        },
    };

    useEffect(() => {
        setData(sentimentGraphData);
    },[sentimentGraphData])
    return(
        <div id='sentimentOvertimeContainer'>
            <div className='sentiment-overtime-title'>Sentiment overtime</div>
            <div className="sentiment-overtime-graph">
                <div className="y-axis-label">Percentage of calls</div>
                <Line {...config} />
            </div>
        </div>
    )
}

export default SentimentOvertime;