import React from "react";

export const EditLineIcon = (props) =>{

    return(
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M10 17H17"
            stroke="black"
            strokeOpacity={0.45}
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <path
            d="M13.2504 3.50805C13.5524 3.18275 13.9621 3 14.3893 3C14.6008 3 14.8103 3.04487 15.0057 3.13204C15.2011 3.21921 15.3787 3.34698 15.5282 3.50805C15.6778 3.66912 15.7964 3.86034 15.8774 4.07079C15.9583 4.28124 16 4.5068 16 4.73459C16 4.96238 15.9583 5.18794 15.8774 5.39839C15.7964 5.60884 15.6778 5.80006 15.5282 5.96113L6.03715 16.1823L3 17L3.75929 13.7292L13.2504 3.50805Z"
            stroke="black"
            strokeOpacity={0.45}
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        </svg>
    )
}