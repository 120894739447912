const { atom } = require("recoil");

export const IsSortOrderToggledAtom = atom({
    key: 'IsSortOrderToggled',
    default: true
})

export const P2PComponentDataAtom = atom({
    key: 'p2pComponentData',
    default: {}
})