import React from 'react'

function WorkflowV2BannerIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={56}
            height={56}
            fill="none"
            {...props}
            style={{
                borderRadius: '6px'
            }}
        >
            <rect width={56} height={56} rx={6.222} fill="#FF7A45" />
            <path
                d="M28.252 16.142v5.297M16.144 32.038V24.47a1.01 1.01 0 0 1 1.009-1.01h2.27M40.36 32.284v-7.568c0-.557-.452-1.009-1.009-1.009h-1.514M15.892 32.794v3.027m0 0H9.586c-.557 0-1.01.452-1.01 1.01v1.26m7.316-2.27h6.559c.557 0 1.009.452 1.009 1.01v1.26"
                stroke="#FFD666"
                strokeWidth={0.505}
            />
            <rect
                x={18.163}
                y={11.092}
                width={20.18}
                height={6.054}
                rx={0.505}
                fill="#fff"
            />
            <rect
                x={18.163}
                y={19.678}
                width={20.18}
                height={6.054}
                rx={0.505}
                fill="#fff"
            />
            <rect
                x={6.054}
                y={28.255}
                width={20.18}
                height={6.054}
                rx={0.505}
                fill="#fff"
            />
            <rect
                x={30.27}
                y={28.255}
                width={20.18}
                height={6.054}
                rx={0.505}
                fill="#fff"
            />
            <rect
            x={19.171}
            y={12.104}
            width={12.108}
            height={1.009}
            rx={0.505}
            fill="#FA541C"
            />
            <rect
            x={19.171}
            y={14.128}
            width={18.667}
            height={0.505}
            rx={0.252}
            fill="#FF9C6E"
            />
            <rect
            x={21.694}
            y={21.191}
            width={14.126}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={21.694}
            y={23.206}
            width={14.126}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={11.603}
            y={30.771}
            width={12.613}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={3.028}
            y={37.333}
            width={12.108}
            height={6.054}
            rx={0.505}
            fill="#fff"
            />
            <rect
            x={17.154}
            y={37.333}
            width={12.108}
            height={6.054}
            rx={0.505}
            fill="#fff"
            />
            <rect
            x={4.541}
            y={38.846}
            width={8.577}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={4.541}
            y={40.861}
            width={8.577}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={19.171}
            y={38.846}
            width={8.577}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={19.171}
            y={40.861}
            width={8.577}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={36.325}
            y={30.771}
            width={12.613}
            height={1.009}
            rx={0.505}
            fill="#FF9C6E"
            />
            <rect
            x={19.171}
            y={15.139}
            width={18.667}
            height={0.505}
            rx={0.252}
            fill="#FF9C6E"
            />
            <circle cx={20.684} cy={21.696} r={0.505} fill="#FA541C" />
            <circle cx={20.684} cy={23.71} r={0.505} fill="#FA541C" />
            <circle cx={8.83} cy={31.024} r={1.766} fill="#59E2D1" />
            <path
            d="m8.123 31.021.53.53 1.059-1.236"
            stroke="#fff"
            strokeWidth={0.353}
            />
            <circle cx={33.046} cy={31.037} r={1.766} fill="#FFC53D" />
            <path
            d="m32.398 31.787.493-.767-.455-.707h.28l.233.358a1.315 1.315 0 0 1 .084.141 2.405 2.405 0 0 1 .086-.141l.23-.358h.283l-.458.707.496.767h-.283l-.27-.418a3.933 3.933 0 0 1-.044-.073l-.04-.069a1.603 1.603 0 0 1-.084.141l-.271.418h-.28Z"
            fill="#fff"
            />
            <path fill="#111" fillOpacity={0.32} d="M0 0h56v56H0z" />
            <path d="M22.945 38.765V16.987l17.11 10.889-17.11 10.889Z" fill="#fff" />
        </svg>
    )
}

export default WorkflowV2BannerIcon;