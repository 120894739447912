import React, { useState } from "react";
import { Tooltip, Typography } from "antd";
import "./tooltipParagraph.scss";

const TooltipParagraph = ({ children }) => {
  const {Paragraph} = Typography;

  return (
    <Tooltip title={children}>
      <Paragraph
        className="ellipsis child-to-render"
      >
        {children}
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;
