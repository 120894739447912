import React from "react";

function ExclamationCircleIcon(props) {
    return (
        <div>
            <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 .5C5.202.5.5 5.2.5 11c0 5.798 4.702 10.5 10.5 10.5S21.5 16.797 21.5 11C21.5 5.2 16.798.5 11 .5Zm0 19.218A8.72 8.72 0 0 1 2.281 11 8.72 8.72 0 0 1 11 2.281a8.72 8.72 0 0 1 8.719 8.718A8.72 8.72 0 0 1 11 19.72Z"
      fill={props.color ?? "#FAAD14"}
    />
    <path
      d="M9.875 15.124a1.125 1.125 0 1 0 2.25 0 1.125 1.125 0 0 0-2.25 0Zm.562-2.625h1.125a.188.188 0 0 0 .188-.187V5.937a.188.188 0 0 0-.188-.187h-1.125a.188.188 0 0 0-.187.187v6.375c0 .103.084.188.187.188Z"
      fill={props.color ?? "#FAAD14"}
    />
  </svg>
        </div>
    )
}

export default ExclamationCircleIcon;