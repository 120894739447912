import React from "react";
import Select from "react-select";

const customStylesFlag = {
  option: (provided, state) => ({
    ...provided,
    padding: "0.375rem",
    color: state.isDisabled ? "#ccc" : "#444",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    background: state.isFocused && !state.isDisabled ? '#5897fb' : 'white',
    transition: "background-color .1s linear, color .1s linear",
    fontSize: "13px",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #c8c8c8",
    border: state.isFocused ? "none!important" : "1px solid #c8c8c8",
    '&:hover': {
      border: "1px solid #ff8935",
    },
    '&:focus': {
      border: "1px solid #ff8935",
    },
    '&:active:focus': {
      border: "none!important",
    },
    '&:active': {
      border: "none!important",
    },
    borderRadius: "4px",
    display: "flex",
    fontSize: 14,
    minHeight: 36,
    color : state.isDisabled && "#444"
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.7 : 1;
    const color = state.isDisabled && "#444" 
    const transition = "opacity 300ms";
    return { ...provided, opacity, color, transition };
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  }
};

const labelStyle = {
    display: "block",
    fontSize: "13px",
    marginBottom: "2px",
    marginTop: "5px"
}
const select = props => {
  let selectWithBorder = props?.disabled ? {
    control: base => ({
        ...base,
        border: 0,
        fontSize: 14
    })
  } : {};


  let errorCaseStyle = props.errorStatus ? {
    control: (provided, state) => ({
        ...provided,
        border: "1px solid red !important",
        '&:hover': {
          border: " 1px solid red !important",
        },
        '&:focus': {
          border: "1px solid red !important",
        },
        boxShadow: "none",
        borderRadius: "4px",
        display: "flex",
        fontSize: 14,
        minHeight: 36,
    })
  } : {};

  return <div>
      <div className="select-dropdown"
          style={{ cursor: props.disabled  ? "not-allowed" : 'pointer' }}
          onClick={props.clicked}
      >
          {props.label &&
          <label style = {{...labelStyle}}
            >{props.label}</label> }
          <Select
            minMenuHeight = {props.minMenuHeight}
            maxMenuHeight= {props.maxMenuHeight}
            menuPortalTarget={props.menuPortalTarget}
            menuPosition={props.menuPosition} 
            value={
              props.clearAfterResponse ? props.value : props.selectedOption
            }
            onChange={props.changed}
            onBlur={props.onBlur}
            isClearable={
              props.isClearable === undefined
                ? true
                : props.isClearable
            }
            options = {props.options}
            isSearchable={props.isSearchable}
            isLoading={props.isLoading}
            isMulti={props.isMulti}
            isDisabled={props.disabled}
            isOptionDisabled={(option) => option.isDisabled}
            ref={props.forwardRef}
            styles={{ ...customStylesFlag, ...props.customStyle, ...selectWithBorder, ...errorCaseStyle }}
            components={{...props.components}}
            className={`${props.className && props.className}`}
            loadingMessage={() => {
            return <div><p>Loading.....</p><p>{props.loadingMessage}</p></div>
            }}
            placeholder={ props.placeholder
                ? props.placeholder
                : "Select Option"
            }
            onFocus={props.onFocus}
            onMenuOpen = {props.onMenuOpen ? props.onMenuOpen : false}
            onMenuClose = {props.onMenuClose ? props.onMenuClose : false}
          />
          <input
            defaultValue={
              props.selectedOption && props.selectedOption !== undefined
                ? props.selectedOption.value
                : props.value
                  ? props.value
                  : ""
            }
            required={props.required}
            style={{
              opacity: 0,
              height: 0,
              position: "absolute",
            }}
          />
        </div>
      </div>;
};
export default select;
