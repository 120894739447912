import React from 'react'
import "./Popover.scss";
import CardFooter from "./CardFooter";
import Button from "./Button";

function Popover(props) {

    const {
        buttonCustomStyles,
        areButtonLoading, 
        handleClickWarningBtn,
        handleClickLightBtn,
        buttonTexts,
        footerHeight,
        padding,
        dimensions,
        children,
        className
    } = props;

    return (
        <div className={`popover-for-react-jquery ${className ? className : ""}`} style={{...dimensions}}>
            <div className="rj-popover-body" style={{ padding: padding }}>
                {children}
            </div>
            <div className="rj-popover-footer" style={{minHeight: footerHeight, padding: `0 ${padding}px`}}>
                <Button 
                    type="light"
                    isLoading={areButtonLoading}
                    customStyles={{...buttonCustomStyles}}
                    handleClick={handleClickLightBtn}
                    name={buttonTexts.light}
                    className="rj-popover-btn-light"
                />
                <Button 
                    type="warning"
                    isLoading={areButtonLoading}
                    customStyles={{...buttonCustomStyles}}
                    handleClick={handleClickWarningBtn}
                    name={buttonTexts.warning}
                    className="rj-popover-btn-warning"
                />
            </div>
        </div>
    )
}

export default Popover
