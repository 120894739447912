import axios from 'axios';
import {spamDetectionUrl} from "../configuration/spamDetectionConfig"

export const postSpamDetectionStatus = async (data, apiToken, campaign_id) => {
    try {
        let url = spamDetectionUrl(campaign_id);
        if (window.location.pathname.split('/').includes('voice_campaign')){
            url = url+'?campaign_type=voicebroadcast'
        }
        const response = await axios.post(url, data, {
            headers: {
				Authorization: "Token " + apiToken,
			},
        });
        return response.data
    }catch(error) {
        return {
            error : error.response.data, 
            status: error.response.status
        }
    }
}