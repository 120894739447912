import React, { useEffect } from "react";
import "./SideModalWrapper.scss";
import "../ShortenedUrl/searchBox.scss";

const CommonSideModal = (props) => {
        // useEffect(() => {
        //     document.querySelector("body").style.overflow = !props.isModalClosed ? 'hidden' : 'scroll';
        // });
        return (
            <>
                <div className={"overlay " + (props.isModalClosed || props.noOverlay ? "hide" : "show")}></div>
                <div id={props.id} className={props.modalClass}>
                    <div className={"side-modal-dialog " + props.modalDialogClass} >
                        <div className="side-modal-content">
                            <div className="modal-header panel-modal-header-no-padding">
                                {props.modalHeader}
                            </div>
                            <div style={{'padding': '30px'}} className={props.sideModalWrapper}>
                                <div className={`side-modal-body ` + props.modalBodyClass} id={props.modalBodyId}>
                                    {props.activeModalScreen}
                                </div>
                                <div className={props.footerClassName}>
                                    {props.footerContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default CommonSideModal;
