import React from "react";
import { Button } from "antd";
import OtpVerifyIcon from "../../../../assets/svg/OtpVerifyIcon"
import VerifyOtpInput from "../../../Signup/components/VerifyOtp/verifyOtpInput";

const OtpVerification = ({ inputRefs,onOtpChange,validateOtp,mobileNumber, onCancel,otpErrorStatus }) => {

    return (
    <div className={`otp-verification-step ${otpErrorStatus ? "otp-verification-failed" : null}`}>

        <div className="otp-sent-description">
            <OtpVerifyIcon width={84} height={84} status="pending"/>
            <h3 className="contact-creation-otp-title">Verify Number</h3>
            <p className="description-text">We have sent a code to <span>{ mobileNumber}</span></p>
        </div>
        <VerifyOtpInput onChange={onOtpChange} inputRefs={inputRefs}/>
        { otpErrorStatus ? <p className="otp-incorrect-error">Wrong OTP entered</p> : <></>}
      <div class="otp-buttons">
        <Button className="otp-verify-btn" type="primary" onClick={validateOtp}>
          Verify
        </Button>
        <Button className="otp-cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
    );
};

export default OtpVerification;
