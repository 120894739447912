import * as React from "react"

const ExlamationIcon = (props) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#FAAD14">
      <path d="M11.5.5C5.425.5.5 5.425.5 11.5s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11Zm0 20.134A9.135 9.135 0 0 1 2.366 11.5 9.136 9.136 0 0 1 11.5 2.366a9.135 9.135 0 0 1 9.134 9.134 9.135 9.135 0 0 1-9.134 9.134Z" />
      <path d="M10.321 15.821a1.179 1.179 0 1 0 2.358 0 1.179 1.179 0 0 0-2.358 0Zm.59-2.75h1.178a.197.197 0 0 0 .197-.196V6.196A.197.197 0 0 0 12.089 6h-1.178a.197.197 0 0 0-.197.196v6.679c0 .108.088.196.197.196Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5 .5)" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default ExlamationIcon