import React, { useEffect, useState } from 'react';

import { getAvailableUrls } from '../../../CentralURLManagementWrapper/CentralURLManagementUtilities';

import './NormalSelect.scss';

const NormalSelectBox = ({componentData}) => {
    const [url, setUrl] = useState('-1');
    const [urlOption, setUrlOption] = useState('ALL')
    const [disabled, setDisabled] =  useState(true)
    const [options, setOptions] = useState([]);

    const urlChangeHandler = (e) => {
        const value = e.target.value
        setUrl(value);
        if(value === '-1' || value === '0' || value === '1') {
            setDisabled(true);
            setUrlOption('ALL')
        } else {
            setDisabled(false);
        }
    }

    const urlOptionChangeHandler = (e) => {
        setUrlOption(e.target.value);
    }

    useEffect(() => {
        setDisabled(true);
        if (componentData.selected_url !== '-1') {
            setUrl(componentData.selected_url);
            if (componentData.selected_url === '0' || componentData.selected_url === '1') {
                setDisabled(true);
            } else {
                setDisabled(false);

            }
        }

        if (componentData.selected_url_option !== 'ALL') {
            setUrlOption(componentData.selected_url_option);
        }

        const getAllUrlsForCampaign = async () => {
        const url_list = await getAvailableUrls(componentData.apiToken, componentData.campaignId, componentData.campaignType)
        setOptions(url_list)
        }

        getAllUrlsForCampaign();
    }, [])

    return (
        <div className="question-option-choices">
            <div className="clearfix question-choice filter-options" id="url-choices">
                <label className="control-label">URL(s)</label>
                <div className="input" id='filter-results'>
                    <select id="id_url" name="url" className="url-select" value={url} onChange = {urlChangeHandler}>
                        <option value="-1">Select URL</option>
                        <option value="0">Clicked any URL</option>
                        <option value="1">Did not click any URL</option>
                        {
                            options && options.map((op, index) => 
                                <option className='url-choices-option' key={index} value={op.pk_str}>{op.title}</option>
                            )
                        }
                    </select>
                    <span className="help-block"> </span>
                </div>
            </div>

            <div className="clearfix url-option-choice filter" id="url-option-choices">
                <label className="control-label"></label>
                <div className="input">
                    <select id="id_url_options" name="url-options" disabled={disabled} value={urlOption} onChange = {urlOptionChangeHandler}>
                        <option value="ALL">Select option</option>
                        <option value="clicked">Clicked</option>
                        <option value="notClicked">Not clicked</option>
                    </select>
                    <span className="help-block"> </span>
                </div>
            </div>
        </div>
    )
}

export default NormalSelectBox