const prefix = "/v1/"
const prefix2 = "/v2/"

export const campaignStatusUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/status/`;
}

export const keywordStaticsUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/keyword_statistics/`;
}
export const messageStatusUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/message_statistics/`;
}
export const subscriberStaticsUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/subscriber_statistics/`;
}

export const campaignSummaryUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/summary/${campaign_id}/`;
}

export const campaignResultsUrl = (campaign_id) => {
    return `${prefix2}sms_campaign/${campaign_id}/subscribers/results/`;
}

export const campaignFilteredResultsUrl = (campaign_id) => {
    return `${prefix2}sms_campaign/${campaign_id}/subscribers/filtered_results/`;
}

export const campaignResponseUrl = (campaign_id) => {
    return `${prefix2}sms_campaign/${campaign_id}/responses/`;
}

export const campaignResponseConversationUrl = (campaign_id, sub_id) => {
    return `${prefix2}sms_campaign/${campaign_id}/responses/messages/${sub_id}/`;
}

export const updateInteractionStatusUrl = (subscriber_id) => {
    return `${prefix2}sms_campaign/subscribers/${subscriber_id}/interaction_status/`;
}

export const getCampaignSubscribersUnreadCountUrl = (campaign_id) => {
    return `${prefix2}sms_campaign/${campaign_id}/subscribers/unread_count/`;
}

export const replyMessage = (campaign_id, sub_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/reply/${sub_id}/`;
}

export const testSmsMesssageUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/test_sms/`;
}

export const deleteCampaignUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/`;
}

export const editCampaignUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/${campaign_id}/`;
}

export const phonebooksUrl = (pageNumber) => {
    // return `${prefix}phonebooks/?page=${pageNumber}`;
    return `${prefix2}user_phonebooks/`;
}

export const shortCodeKeywordUrl = () => {
    return `${prefix}shortcode_keywords/`;
}

export const numberRentRatesUrl = () => {
    return `${prefix}number_rent_rates/`;
}

export const validatedNumbersUrl = () => {
    return `${prefix}validated_numbers/`;
}

export const rentedAndValidatedNumbersUrl = () => {
    return `${prefix2}validated_and_rented_numbers/`;
}

export const integrationsUrl = () => {
    return `${prefix}user_integrations/`;
}

export const campaignCreationUrl = () => {
    return `${prefix2}sms_broadcast/create/`;
}

export const campaignEditUrl = (campaignId) => {
    return `${prefix2}sms_broadcast/${campaignId}/`;
}

export const addBlackbaudUrl = () => {
    return `${prefix2}blackbaud/interaction_type/`;
}

export const mergeTagsUrl = () => {
    return `${prefix2}merge-tags/`;
}
export const createPhonebookUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/create_pb/${campaign_id}/`;
}
export const exportResultUrl = (campaign_id) => {
    return `${prefix2}sms_broadcast/export/${campaign_id}/`;
}
export const exportProgressBarUrl = (job_id) => {
    return `/exported_file/progress/${job_id}/`;
}
export const timezoneListUrl = () => {
  return `${prefix2}sms_broadcast/timezonelist/`
}
export const dedicatedShortcodeApplicationUrl = () => {
    return `${prefix2}requested_dedicated_shortcode/`;
}
export const dedicatedShortCodeProductsUrl = () => {
    return `${prefix2}dedicated_shortcode/products/`;
}

/*
export const urls = {
    //'phonebooks': prefix + 'phonebooks/',
    //'shortCodeKeyword': prefix + 'shortcode_keywords/',
   // 'number_rent_rates': prefix + 'number_rent_rates/',
    //'validated_numbers': prefix + 'validated_numbers/',
   // 'rented_numbers': prefix2 + 'sms_number/show_rented_number/',
    'sms_campaign_overview': prefix2 + 'sms_broadcast/overview/',
   // 'test_sms_messsage': prefix2 + 'sms_broadcast/2307174640990032605/test_sms/',
   // 'campaign_status': prefix2 + 'sms_broadcast/2307174640990032605/status/',
   // 'message_status': prefix2 + 'sms_broadcast/2307174640990032605/message_statistics/',
   // 'campaign_summary': prefix2 + 'sms_broadcast/summary/',
   // 'subscriber_statics': prefix2 + 'sms_broadcast/2307174640990032605/subscriber_statistics',
   // 'keyword_statics': prefix2 + 'sms_broadcast/2307174640990032605/keyword_statistics/',
    //'delete_campaign': prefix2 + 'sms_broadcast/'
}
*/
