import * as React from "react"

const InfoIcon=(props) =>{
  return (
    <svg
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      {...props}
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        vectorEffect="non-scaling-stroke"
        fill={props.color ?? "#999"}
      />
    </svg>
  )
}

export default InfoIcon;
