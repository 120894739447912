import React, {useRef, useState} from "react";
import { Modal, message, Form } from "antd";
import ContactForm from "./ContactForm";
import OtpVerification from "./OtpVerification";
import OtpSuccess from "./OtpSuccess";
import './createContactModal.scss';
import { postCreateContactSendOtp, postVerifyOtp } from "../../../utilities/contactCreationWithOtpUtilities";

const CreateContactModal = ({ phonebookName, isModalOpen, setIsModalOpen, openNotification, isCampaignCreation,updateCurrentContactsCount }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const inputRefs = Array.from({ length: 6 }, () => useRef(null));
  const [form] = Form.useForm();
  const [otpErrorStatus, setOtpErrorStatus] = useState();
  const [formData, setFormData] = useState({});
  const [availableAttempts, setAvailableAttempts] = useState(3)

  const getAttemptsRemainingForNumber = (number) => {
    const key = `attemptsRemaining_${number}`;
    const savedAttempts = localStorage.getItem(key);
    return savedAttempts ? parseInt(savedAttempts, 10) : 3;
  };

  const updateAttemptsRemainingForNumber = (number, remainingAttempts) => {
    const key = `attemptsRemaining_${number}`;
    localStorage.setItem(key, remainingAttempts);
    setAvailableAttempts(remainingAttempts)
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const onOtpChange = (event) => {
    setOtpErrorStatus(null)
  }

  const handleCancel = () => {
    setFormData({});
    form.resetFields();
    setIsModalOpen(false);
    setCurrentStep(1);
    setOtpErrorStatus(null);
    resetInputs()
  };

  const sendOtp = async () => {
    try {
      const values = await form.validateFields();
      const enteredNumber = values.mobileNumber;

      const attemptsRemaining = getAttemptsRemainingForNumber(enteredNumber);
      if (attemptsRemaining <= 0) {
        openNotification("You’ve reached the limit of 3 OTP requests and entry attempts for this Contact. Please try again after 24 hours.", null, "warning");
        return;
      }

      const payload = { mobile_number: enteredNumber };
      const response = await postCreateContactSendOtp(null, payload);

      if (response.data.status === "SUCCESS") {
        setFormData((prevData) => ({ ...prevData, ...values }));
        updateAttemptsRemainingForNumber(enteredNumber, attemptsRemaining - 1);
        handleNextStep();
      } else {
        openNotification(response.data.message, null, "warning");
      }
    } catch (error) {
      console.log("Validation or API error:", error);
    }
  };

  const resendOtp = async () => {
    const enteredNumber = formData.mobileNumber
    const attemptsRemaining = getAttemptsRemainingForNumber(enteredNumber)
    if (attemptsRemaining <= 0) {
      openNotification("You’ve reached the limit of 3 attempts to create this contact. Please try again after 24 hours.", null, "warning");
      return;
    }
    const payload = { mobile_number: enteredNumber };
    try {
      const response = await postCreateContactSendOtp(null, payload);

      if (response.data.status === "SUCCESS") {
        message.success("OTP resent successfully!");
        updateAttemptsRemainingForNumber(enteredNumber, attemptsRemaining-1);
      } else {
        message.error("Failed to resend OTP");
        setTimeout(() => {
          handleCancel();
          oopenNOtification(response.data.message,null,"warning");
        }, 3000);
        return;
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      message.error("Failed to resend OTP");
    }
    resetInputs()
  };

  const resetInputs = () => {
        inputRefs.forEach((ref) => {
          if (ref.current) {
            ref.current.value = '';
          }
        });
        inputRefs[0].current?.focus();
      };


  const validateOtp = async () => {
    try {
      const valArray = inputRefs.map((ref) => {
        return ref.current.value;
      })
    let otp = valArray.join("");
      const payload = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        mobile_number: formData.mobileNumber,
        otp: otp,
      };
      const response = await postVerifyOtp(null, payload);
      if (response.data.status === "SUCCESS") {
        handleNextStep();
        openNotification(`1 contact added to ${phonebookName}`, null, "success");
        setTimeout(() => {
          if (!isCampaignCreation) {
            window.location.reload();
          }
          handleCancel();
        }, 3000);
      } else {
        setOtpErrorStatus("error");
        message.error(response.data.message || "Invalid OTP");
      }
    } catch (error) {
      setOtpErrorStatus("error")
      console.error("Error validating OTP:", error);
      message.error("Failed to validate OTP");
    }
  }

  const renderFooter = (props, { OkBtn, CancelBtn }) => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        );

      case 2:
        return (
          <div className="contact-creation-modal-footer">
            {availableAttempts} attempt(s) remaining,{' '}
            <span className="resent-otp-btn" onClick={resendOtp} type="link">
              Resend OTP
            </span>
          </div>
        );

      default:
        return null;
    }
  };


  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <ContactForm form={form} />
            <p className="consent-msg">By entering the phone number, you consent to receive campaign text messages at the number provided, including messages sent by autodialer. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP.</p>
          </>
      );
      case 2:
        return (
          <OtpVerification
            inputRefs = {inputRefs}
            mobileNumber={formData?.mobileNumber}
            validateOtp={validateOtp}
            onCancel={handleGoBack}
            otpErrorStatus={otpErrorStatus}
            onOtpChange={onOtpChange}
          />
        );
      case 3:
        return <OtpSuccess mobileNumber={formData?.mobileNumber} />;
      default:
        return null;
    }
  };

  const handleGoBack = () => {
    setOtpErrorStatus(null);
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal
      open={isModalOpen}
      className="contact-creation-modal"
      onCancel={handleCancel}
      onOk={sendOtp}
      okText="Create"
      cancelText="Discard"
      title={currentStep === 1 ? "Create Contact" : null}
      footer={renderFooter}
      closable={false}
      width={400}
    >
      {renderContent()}
    </Modal>
  );
};

export default CreateContactModal;
