import React from 'react';
import CaretIcon from './../../../assets/svg/CaretIcon';

const dropDownComponent =  () => {
    return <span className="position-caret-icon">
        <CaretIcon size={24} labelstyle="dropdown-icon"/>
        </span>
    ;
}

export default dropDownComponent;