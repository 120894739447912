import React from 'react'

const Plus = props => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 24 24"
    width={props.size}
    height={props.size}
    {...props}
  >
    <path
      d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
      vectorEffect="non-scaling-stroke"
      fill="#444"
    />
  </svg>
)

export default Plus
