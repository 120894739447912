import React from "react";

export const GreenPlusIcon = ({width = 8, height = 8, ...restProps}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_45_2265)">
        <path d="M3.99414 0V7.98782" stroke="#2FBC79" />
        <path d="M0 3.9939H7.98782" stroke="#2FBC79" />
      </g>
      <defs>
        <clipPath id="clip0_45_2265">
          <rect width="7.98782" height="7.98782" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
