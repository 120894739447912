import React, { useRef } from 'react';
import './verifyOtp.scss';

const VerifyOtpInput = ({onChange, error, inputRefs}) => {
  // const inputRefs = Array.from({ length: 6 }, () => useRef(null));

  const handleKeyUp = (event, index) => {
    const input = event.target;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }

    const nextIndex = index + 1;

    if(input.value && nextIndex < inputRefs.length) {
      inputRefs[nextIndex].current.focus();
    } else {
      const prevIndex = index - 1;
      if ((charCode === 8 || charCode === 46) && prevIndex >= 0) {
        inputRefs[prevIndex].current.focus();
      }
    }
  }

  const onKeyPress = (event, index) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return;
    }
    const currentValue = event.key;
    if(!isNaN(currentValue)) {
      inputRefs[index].current.value = currentValue;
    }
  }

  return (
    <div className='verify-otp-dis-grid'>
      {
        inputRefs.map((inputRef, index) => {
          return (
            <div className='verify-otp-input-container' key={index}>
              <input
                role={`verifyOtpInput-${index}`}
                data-testid={`verifyOtpInput-${index}`}
                ref={inputRef}
                key={index}
                type='text'
                className='verify-otp-input'
                maxLength="1"
                onKeyUp={(event) => handleKeyUp(event, index)}
                onKeyPress={(event) => onKeyPress(event, index)}
                onChange={onChange}
                required
              />
            </div>
          )
        })
      }
      { error && <p className='error-message error' role='otpError'>{error}</p> }
    </div>
  )
}

export default VerifyOtpInput