import { message } from "antd";
import React, { useEffect } from "react";

const AntDMessage = (props) => {
    const {componentData, unMountTheComponent} = props;
    const [messageApi, contextHolder] = message.useMessage();

    const clearUserData = () => {
        const keysToDelete = ['updatedLimit', 'transferSettingsEnabled', 'transferSettingsDisabled']
        keysToDelete.map((key) => {
            localStorage.removeItem(key);
        })
    }

    const getMessageContent = () => {
        let content = "";
        switch(componentData.actionType){
            case 'updatedLimit':
                content = <>Auto recharge settings updated for <b>{componentData.username}</b></>
                break;
            case 'transferSettingsEnabled':
                content = <>Auto recharge enabled for <b>{componentData.username}</b></>
                break;
            case 'transferSettingsDisabled':
                content = <>Auto recharge disabled for <b>{componentData.username}</b></>
                break;
            default:
                content = ""
        }
        return content;
    }

    const mountMessage = () => {
        messageApi.open({
            type: componentData.type,
            content: getMessageContent(),
        });
        clearUserData();
    }

    useEffect(() => {
        mountMessage();
        setTimeout(() => {
            unMountTheComponent(false);
        }, 2500)
    }, [])

    return (
        <>{contextHolder}</>
    )
}

export default AntDMessage;