import React, { useEffect, useState } from 'react';

import CloseIcon from './../../../assets/svg/CloseIcon';

import {
    mergeTags
} from './../../screens/campaigns/campaignServices';

function PersonaliseTag(props){

    const [personaliseTags, updatePersonaliseTags] = useState([])

    useEffect((props) => {
        handleListOfPersonaliseTag();
    }, []);

    // Handle get list of personalise tags.
    const handleListOfPersonaliseTag = async () => {
        await mergeTags(props.apiToken).then(res => {
            if(res.data.data){
                updatePersonaliseTags(res.data.data.merge_tags);
            }
        }).catch(error => console.log(error));
    }

    return (
        <React.Fragment>
            <div className="personalise-tag-section animated fadeIn">
                <div className="peronalise-tag-list">
                    { 
                        personaliseTags.map((data, index) => (
                            <span key={index} className="badge-tags" data-text={data} onClick={() => props.handleAppendTags(data)}>{ FormatPersonaliseTags(data) }</span>
                        ))
                    }
                </div>
                <a href="#" className="close-tags-section" onClick={() => props.handleClosePerosnaliseTags("showPersonalise", false)}>
                    <CloseIcon
                        size="14"
                    />
                </a>
            </div>  
        </React.Fragment>
    ) 
}

function FormatPersonaliseTags(data) {
    let formatPersonaliseTags = '';
    let formattedTags = data.slice(1, -1).replace(/_/g," ");
    formatPersonaliseTags = formattedTags.charAt(0).toUpperCase() + formattedTags.slice(1);
    return formatPersonaliseTags;
}

export default PersonaliseTag;

