import React from 'react';

function CaretIcon(props) {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 24 24"
            width={props.size}
            height={props.size}
            {...props}
        >
            <path
                className={props.labelstyle}
                d="M14.8 11.52l-4.66-4.66a.676.676 0 00-.95.01.66.66 0 00-.19.46v9.33c0 .36.29.66.65.67.18 0 .35-.07.48-.2l4.66-4.66c.26-.24.28-.65.04-.92l-.03-.03z"
                vectorEffect="non-scaling-stroke"
                fill="rgba(68, 68, 68, 0.7)"
            />
        </svg>
    )
}
export default CaretIcon;