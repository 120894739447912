import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './editPersonalizedTagsContent.scss';
import DeleteIcon from '../../../../assets/svg/DeleteIcon.js' 
import "../common/SideModalWrapper.scss";

const EditPersonalizeContent = (props) => {
    const [personaliseTags, handlePersonaliseTags] = useState([])
    const [paramObj, handleParamObj] = useState(props.personalizeParams)
    const [personaliseFieldArr, handlePersonaliseFieldArr] = useState(props.personalizeParams)
    
    const addPersonaliseField = (e) => {
        e.preventDefault();
        props.setErrorMessage('')
        props.setErrorSelectMessage('')
        
        let id = Date.now();
        handlePersonaliseFieldArr([...personaliseFieldArr, {paramName : "", paramValue : "", id}])
        handleParamObj([...personaliseFieldArr, {paramName : "", paramValue : "", id}])
    }

    const personalisedCustomStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #c4c4c4',
            borderRadius: 3,
            '&:focus' : {
                borderColor: '#4ba5e6',
                boxShadow: 'rgb(75 165 230 / 50%) 0 0 5px',
            }
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor : 'white !important',
            
            height: '250px !important',
        }),
        indicatorSeparator: () => {},
        dropdownIndicator: defaultStyles => ({
        ...defaultStyles,
        '& svg': { display: 'none' }
        }),
    };

    const handleKey = (index, key, value) => {
        let updateOjb = personaliseFieldArr;
        updateOjb[index][key] = value;
        handlePersonaliseFieldArr([...updateOjb])
        handleParamObj(personaliseFieldArr)
        if(key === 'paramName') props.setErrorMessage('')
        if(key === 'paramValue') props.setErrorSelectMessage('')
    }

    const deletePersonaliseField = (index) => {
        let updatedPersonalisedField  = personaliseFieldArr;
        updatedPersonalisedField.splice(index, 1);

        handlePersonaliseFieldArr([...updatedPersonalisedField]);
        handleParamObj(personaliseFieldArr)
    }

    useEffect(() => {handleParamObj(personaliseFieldArr)}, [])
    useEffect(() => {
        props.personaliseParamsObject(paramObj)
    }, [paramObj])

    useEffect(() => {
        let personaliseTagsArr = [];

        props.personaliseTagsList.map((item) => {
            let itemObject = {
                'value' : item, 'label' : item
            }
            personaliseTagsArr.push(itemObject)
        })
        handlePersonaliseTags(personaliseTagsArr)
        
    }, [props.personaliseTagsList])


    return(
        <>
            <div className="personalizedTags">
                <div className="personlizedHeading">
                    <div>Name of form field</div>
                    <div>CallHub merge field</div>
                </div>
                <div className="personalizedFieldList">
                    { personaliseFieldArr && personaliseFieldArr.map((item, index) => 
                        <div key={String(index + item.id)} className={`personalizedMergeTags`}>
                            <div className="personalizeInput" key={'personalizeInput' + index}>
                                <input key={'personalizeInputField' + index} type="text" className={`props.errorMessage`} placeholder="eg: first_name, last_name..." defaultValue={item.paramName} maxLength={200} onBlur={(e) => handleKey(index, "paramName", e.target.value)}/>       
                                {
                                    props.errorMessageIndex.map((errorMessageIndexItem, i) => 
                                        (props.errorMessage && errorMessageIndexItem  === index)
                                            && <div key={'errorMessageIndexItem' + i} className='errorColor'>{ props.errorMessage }</div>
                                    )
                                }
                            </div>
                           
                            <div id="personaliseTagsOption" className = ''>
                                <Select 
                                    className={`personalizedTagsDropdown`}
                                    options = {personaliseTags}
                                    styles = {personalisedCustomStyles}
                                    value = {
                                        personaliseTags.find(option => 
                                           option.label === item.paramValue)
                                     }
                                    placeholder='eg: {First_name}'
                                    onChange={(e) => handleKey(index, "paramValue", e.value)}
                                    isSearchable="true"
                                    maxMenuHeight={250}
                                />
                                {
                                    index > 0 &&
                                    <button className="deleteBtn" onClick={() => deletePersonaliseField(index)}>
                                        <DeleteIcon/>
                                    </button>
                                }
                                {
                                    props.selectErrorMessageIndex.map((errorSelectMessageIndexItem) => 
                                        (props.errorSelectMessage && errorSelectMessageIndexItem  === index)
                                            && <div className='errorColor'>{ props.errorSelectMessage }</div>
                                    )
                                }               
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <button id="addParamaButton" class="addPersonaliseFieldClass" onClick={(e) => addPersonaliseField(e)}>Add Parameter</button>
        </>
    )
}

export default EditPersonalizeContent;
