import React from 'react'

function ContactListRange1KycIcon(props) {
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M8.5 7.286a3.628 3.628 0 0 0 3.63-3.643A3.628 3.628 0 0 0 8.5 0a3.638 3.638 0 0 0-3.643 3.643A3.638 3.638 0 0 0 8.5 7.286Zm0 2.428c-2.83 0-8.5 1.421-8.5 4.25V17h17v-3.036c0-2.829-5.67-4.25-8.5-4.25Z"
            fill="#F07635"
            />
        </svg>
    )
}

export default ContactListRange1KycIcon
