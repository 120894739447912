import axios from 'axios';
import DOMPurify from "dompurify";

export const apiGetCall = (url, apiToken = "", params = {}) => {
  return axios.get(url, {
    headers: {
      Authorization:
        "Token " + `${apiToken && apiToken !== "" ? apiToken : ""}`,
    },
    params: params,
  });
};

export const apiPostCall = (url, data, apiToken="") => {
    return axios.post(url, data, {
        headers: {
            'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : ""}`
        }
    })
}

export const apiPutCall = (url, data, apiToken="") => {
    return axios.put(url, data, {
        headers: {
            'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : ""}`
        }
    })
}

export const apiPatchCall = (url, data, apiToken="") => {
    return axios.patch(url, data, {
        headers: {
            'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : ""}`
        }
    })
}

export const apiDeleteCall = (url, apiToken = "", dataTodelete=null) => {
    if(dataTodelete){
        return axios.delete(url,{
            headers:{
                Authorization: `Token ${apiToken && apiToken !== "" ? apiToken : ""}`
            },
            data:dataTodelete
        })
    }
    return axios.delete(url,{
        headers:{
            Authorization: `Token ${apiToken && apiToken !== "" ? apiToken : ""}`
        }
    })
}

export const createUrgentMessageMarkup = (props,notification) => {
        let urgentMessage = "";
        if(notification)
            return {__html : DOMPurify.sanitize(notification,{ ADD_ATTR: ['target'] })};
        if(props.user_details && props.user_details.hasOwnProperty('urgent_message')){
            urgentMessage = props.user_details.urgent_message;
            return {
                __html: urgentMessage
            };
        }
     };