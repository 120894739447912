import React, { useRef, useEffect } from "react"

function DetectOutSideClickingHandler({onOutsideClickFn, children}) {
    const wrapperRef = useRef();

    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            onOutsideClickFn();
            console.log("hello")
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])
    return(
        <div ref={wrapperRef}>
            {children}
        </div>
    )
}

export default DetectOutSideClickingHandler;