import React from "react";

const AlertIcon = (props) => {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 20.674 18.124"
            width={20.674 || props.width}
            height={18.124 || props.height}
            {...props}
        >
            <path
                className="alert-svg-icon"
                d="M.206 16.623c-.48.83-.093 1.501.865 1.501h18.531c.958 0 1.346-.672.866-1.5L11.202.618c-.478-.825-1.253-.825-1.73 0zm11.13-2.499a1 1 0 01-2 0 1 1 0 012 0zm0-3a1 1 0 01-2 0v-3a1 1 0 012 0z"
                vectorEffect="non-scaling-stroke"
                fill={"#e64e40" || props.fillColor}
            />
        </svg>
    )
}
export default AlertIcon;