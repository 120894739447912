import axios from 'axios';

export const postMobileDiscoverabilityStatus = async (url,data, apiToken) => {
    try {
        const response = await axios.post(url, data, {
            headers: {
				Authorization: "Token " + apiToken,
			},
        });
        return response.data
    }catch(error) {
        return {
            error : error.response.data, 
            status: error.response.status
        }
    }
}