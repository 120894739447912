import React from 'react';

export const TickMark = () => {

    return (
    <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="45.4037" cy="45.4037" r="43.4037" stroke="white" strokeWidth="4"/>
        <path d="M25 45.0631L40.4466 60.9272L67.165 34" stroke="#E2F8F3" strokeWidth="4" strokeLinecap="round"/>
    </svg>
    )
};