import React from 'react';
import {SwitchAccountsUrl} from "../../configuration/SwitchAccountsConfig";
import {postSelectedSubAccount} from "../../utilities/SwitchAccountUtilities";

const handleAccountSelection = async (userId,fullName)=>{
    let response = await postSelectedSubAccount(SwitchAccountsUrl(),{switch_user: userId});
    if(!response.error){
        let infoWithExpiry = {
            fullName : fullName,
            time : new Date().getTime() + 10000,
        }
        sessionStorage.setItem("accountSwitched",JSON.stringify(infoWithExpiry));
        if(response.data.redirect_url){
            let reseller_url = `https://${response.data.redirect_url}`
            let Redirecturl = reseller_url + `/enterprise/switch_login/${userId}/${response.data.sessionid}/`;
            window.location.href = Redirecturl;
        }
        else{
            window.location.href=`/guide/`;
        }
    }
}

export const AccountsCard = (props)=>{
    return (
        <div className="accountCard" onClick={()=>handleAccountSelection(props.user_id,props.fullName)}>
            <div className='accountImage'>
                <img src={props.image} alt='image'/>
            </div>
            <div className='accountDetails'>
                <div><p className={props.isParent ? "mainName" :"name"}>{props.fullName}</p>{props.isParent && <span>MAIN ACCOUNT</span>}</div>
                <p className='email'>{props.email}</p>
            </div>
        </div>
    )
}