import React, { useState } from "react";
import Switch from "antd/lib/switch"
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntPopover.scss";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSwitch.scss";
import {postSpamDetectionStatus} from "../../utilities/spamDetectionUtilities"
import {SpamShieldIcon} from "../../../assets/svg/SpamShieldIcon"
import Lottie from "react-lottie"
import animation_data from "../../../assets/lotties/SpamShieldActiate.json"
import {ContainerHeightWithCharge,ContainerHeightWithoutCharge,charge, animatedIconHeight, animatedIconWidth, charge_in_VB} from "./Constants"

export const EnableSpamDetection = ({campaign_id,apiToken,handleReload,is_whitelabel}) => {
    const [showpopup,setShowpopup] = useState(false);
    const [showCost,setShowCost] = useState(false);
    const [containerHeight,setContainerHeight] = useState(ContainerHeightWithoutCharge);
    const [showAnimation, setShowAnimation] = useState(false);
    
    const PostSpamDetectionActions = (spam_status,reload,cost,containerHeight) => {
        postSpamDetectionStatus({spam_detection_enabled : spam_status},apiToken,campaign_id);
        handleReload(reload);
        setShowCost(cost);
        setContainerHeight(containerHeight);
    }

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animation_data,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    const handleSpamDetection = (is_checked)=>{
            if(is_checked){
                PostSpamDetectionActions("True",true,true,ContainerHeightWithCharge);
                setShowAnimation(true);
            }else{
                PostSpamDetectionActions("False",false,false,ContainerHeightWithoutCharge);
                setShowAnimation(false);
            }
    }
    
    const handlepopupshow = () =>{
        setShowpopup(!showpopup);
    }
    const openLinks = ()=>{
        window.open('https://support.callhub.io/hc/en-us/articles/8378997135001-What-is-Prevent-spam-labelling-Why-is-it-required-in-my-campaigns-','_blank');
    }
    return (
        <div className="spam-container" style={{height:containerHeight}}>
            <div className="spam-switch-nudge-wrapper">
                <div>
                    <Switch onChange={(checked)=>handleSpamDetection(checked)} />
                </div>
                <div>
                    <div className="nudge-heading-wrapper">
                        <div className="nudge-heading">Spam Label Shield</div>
                        <div className="nudge-popup">                        
                            <span>
                                <button onBlur={()=>setShowpopup(false)}>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handlepopupshow}>
                                        <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7.5 10.375C7.5 10.4438 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4438 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z" fill="#8C8C8C"/>
                                    </svg>
                                    {showpopup && <div className="popupContent">
                                        <img src="https://static.callhub.io/dashboard/assets/images/spamPopupImage.jpg"/>
                                        <div>
                                            <p>Monitor calls made to US numbers and keep track of number reputation. Calls marked as “spam likely” are auto-replaced. </p>
                                            {!is_whitelabel && <p className='tutorialLinks' onClick={openLinks}>Learn more</p>}
                                        </div>
                                    </div>}
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="nudge-message-text">Auto-replace flagged numbers to maximize your answer rate.</div>
                    {showCost ? <div className="nudge-price-text">Additional charges of ${window.location.pathname.split('/').includes('voice_campaign') ? charge_in_VB : charge}/call apply</div> : null}
                </div>
            </div>
            <div className={showAnimation ? "spam-animated-icon" :"spam-icon"}>
                {showAnimation ? <Lottie options = {defaultOptions} height={animatedIconHeight} width={animatedIconWidth} /> : <SpamShieldIcon />}
            </div>
        </div>)
}

