import axios from 'axios';
import { getDefualtAudioFilesUrl, getInboundPopUpDetailsUrl } from '../configuration/inboundCallingConfig';
import { apiGetCall, apiPostCall } from './utilities';


export const getDefaultAudioFliles = async (apiToken) => {
    try {
        const url = getDefualtAudioFilesUrl();
        const response = await apiGetCall(url, apiToken);
        return response.data;
    } catch (error) {
        return {
            error : error.response.data,
            status : error.response.status
        }
    }
}

export const getAudioFiles = async (apiToken, params) => {
    try {
        const url = getDefualtAudioFilesUrl();
        const response = await axios.get(url, {
            headers: {
				Authorization: 'Token ' + apiToken,
			},
            params: params
        });
        return response.data
    } catch (error) {
        return {
            error : error.response.data,
            status : error.response.status
        }
    }
}

export const getInboundPopUpDetails = async (apiToken) => {
    try {
        const url = getInboundPopUpDetailsUrl();
        const response = await apiGetCall(url, apiToken);
        return response.data;
    } catch (error) {
        return {
            error : error.response.data,
            status : error.response.data
        }
    }
}

export const postInboundPopUpDetails = async (apiToken) => {
    try {
        const url = getInboundPopUpDetailsUrl();
        const response = await apiPostCall(url, {}, apiToken);
        return response.data;
    } catch (error) {
        return {
            error : error.response.data,
            status: error.response.data
        }
    }
}

