const { atom, selector } = require("recoil");


export const inboundCallingDataState = atom({
    key: 'inboundCallingInitData',
    default: {
        welcomeText : "Thank you for calling. Please hold the line while we try to connect you.",
        welcomeAudio : "https://static.callhub.io/brand/callhub/samples/welcome_message_default_inbound_calling.mp3",
        holdMusic : "https://static.callhub.io/brand/callhub/samples/valse-gymnopedie-hold-music.mp3",
        maximumHoldSeconds : 240,
        ThankyouMessageText: "All our agents are busy. An agent will call you back as soon as they are available. This call will hang up now.",
        ThankyouMessageAudio: "https://static.callhub.io/brand/callhub/samples/contact_exceeds_default_wait_time_default_inbound_calling.mp3",
        welcomeAudioFileName: "",
        holdMusicAudioFileName: "",
        thnakyouMessageAudioFileName: "",
        welcomeAudioSelected: false,
        thankyoumessageAudioSelected: false,
        welcomeAudioUrl: "",
        holdMusicUrl: "",
        ThankyouMessageAudioUrl: "",
        OutsideCampaignSubscriberAllowed: false
    }
})

export const stepperCurrentState = atom({
    key: 'current',
    default: 0
})

export const holdTimeErrorState = atom({
    key: 'holdTimeError',
    default: ""
})

export const showInboundFormState = atom({
    key: 'showInboundForm',
    default: false
})

export const defaultAudioFileSatate = atom({
    key: 'defaultAudioFiles',
    default: {}
})

export const mediaAudioFileState = atom({
    key: 'audioFiles',
    default: []
})

export const isInboundDataPresent = atom({
    key: 'isDataPresent',
    default: true
})

export const audioPrimaryKeysState = atom({
    key: 'audioPrimaryKeys',
    default: {
        "welcomeAudioKey": "",
        "holdAudioKey": "",
        "thankyouAudioKey": ""
    }
})

export const isDynamicCallerIdCheckedState = atom({
    key: 'isDynamicCallerIdChecked',
    default: null
})

export const isValidatedNumberCheckedSatate = atom({
    key: 'isValidatedNumberChecked',
    default: null
})

export const loaderState = atom({
    key: 'isLoading',
    default: false
})

export const inboundTextErrorState = atom({
    key: '',
    default: false
})