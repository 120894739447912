import React, { useEffect, useState } from "react";
import { Bar } from '@ant-design/plots';
import {CONTACT_CATEGOREIS_TITLE} from '../../../../constants/CcAiPowerdConstant';
import { useRecoilValue } from "recoil";
import {ContactCategoriesDataAtom} from '../../../../RecoilSharedState/CcAiPowed/CcAiPowerdState';
import './ContactCategories.scss';

const ContactCategories = () => {

    const data = useRecoilValue(ContactCategoriesDataAtom);

    const config = {
        data,
        xField: 'value',
        yField: 'tag',
        legend: {
          position: 'top-left',
        },
        color: '#FF9C6E',
        barBackground: {
          style: {
            fill: 'rgba(0,0,0,0.1)',
          },
        },
        interactions: [
          {
            type: 'active-region',
            enable: false,
          },
        ],
        xAxis: {
            min: 0,
            max: 100,
            tickCount: 6,
            tickInterval: 20,
        },
    };

    return(
        <div id='contactCategoriesContainer'>
            <div className='contact-categories-title'>
                {CONTACT_CATEGOREIS_TITLE}
            </div>
            <hr></hr>
            <div className='contact-categories-chart'>
                <Bar {...config} />
            </div>
        </div>
    )
}

export default ContactCategories