import { apiGetCall, apiPostCall, apiPutCall , apiDeleteCall} from '../../../../../src/utilities/utilities';
import {getTeamDataUrl, deleteAgentUrl} from './ManageTeamPopoverConfig';

export const getTeamAgentsData = async(teamId, page, searchText)=>{

    try{
        const agent_list_url = getTeamDataUrl(teamId, page, searchText);
        const host = window.location.hostname;
        const response = await apiGetCall(`https://${host}/${agent_list_url}`);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const deleteAgents = async(teamId, data)=>{

    try{
        const agent_list_url = deleteAgentUrl(teamId);
        const host = window.location.hostname;
        const response = await apiDeleteCall(`https://${host}/${agent_list_url}`, apiToken = "",{"agent_ids":data});
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}
