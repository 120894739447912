import React, { useEffect, useState } from "react";
import {generatePdiQuestion, PdiQuestionType} from './Apis/apis';
import { Select, Modal, Checkbox, Input } from 'antd';
import './PdiIntegrationQuestion.scss';

const PdiIntegrationQuestion = ({componentData}) =>{

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionOptions , setQuestionOptions] = useState([]);
    const [selectedOption , setSelectedOption] = useState('');
    const [isMultiChoiceQuestion , setIsMultiChoiceQuestion] = useState(false);
    const [showInitialMessage, setShowInitialMessage] = useState(false);
    const [isP2pInitialMessage, setIsP2pInitialMessage] = useState(true);
    const [p2pQuestionType , setP2pQuestionType] = useState('')
    const [pdiQuestionName, setPdiQuestionName] = useState('');
    const [showPdiQuestionNameError, setShowPdiQuestionNameError] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = async() => {

        if(handleError()){
            setShowPdiQuestionNameError(true)
            return;
        }

        let data = {
            'pdi_question':selectedOption,
            'order_number':componentData.order_number,
            'id_name': $('#id_name').val(),
            'id_script':$("#id_script").val(),
            'id_callerid':$('#id_callerid').val(),
            "id_maxretry" : $('#id_maxretry').val(),
            "id_intervalretry" : $('#id_intervalretry').val(),
            "voicemail_audiofile": $('#id_voicemail_audiofile').val(),
            "voicemail_enabled": $('#id_voicemail_enabled').val(),
            "checkbox_selected": isMultiChoiceQuestion,
            "question_name": pdiQuestionName.trim(),
        }
        if(componentData.campaign_type =="p2p"){
            data['is_initial_message'] = isP2pInitialMessage;
        }
        let campaignId = componentData.campaign_id

        let response = await generatePdiQuestion(data, campaignId,componentData.campaign_type);
        if(response.status == 200){
            if (componentData.post_integration_question_add){
                componentData.post_integration_question_add(response.data,p2pQuestionType,isP2pInitialMessage);
                setIsModalOpen(false);
            }else {
                addScriptToJSON(response.data);
                window.location.reload();
            }
        }
    };

    const handleError = ()=>{
        if(!pdiQuestionName.trim() && componentData.campaign_type !="p2p"){
            setShowPdiQuestionNameError(true)
            return true;
        }
    }
    const addScriptToJSON =(data,nextQuestion=null,skipQuestion=null,nextEvent=null)=>{
        let SCRIPT_GRAPH_KEY = componentData.script_graph_key;
        if(JSON.parse(localStorage.getItem(SCRIPT_GRAPH_KEY)) == null){
            localStorage.setItem(SCRIPT_GRAPH_KEY,"{}");
        }
        let script_graph = JSON.parse(localStorage.getItem(SCRIPT_GRAPH_KEY));
        let choices;
        if(data.choices?.length>0){                
            choices = data.choices.map(choice=>({...choice,nextQuestion : null}));
        }
        if(choices){                
            if(nextEvent){
                script_graph[data.id] = {
                    id : data.id,
                    order_number : data.order_number,
                    nextQuestion : nextQuestion,
                    type : data.type,
                    question : data.question,
                    choices : choices,
                    skipQuestion : skipQuestion,
                    nextEvent : nextEvent,
                }
            }else{
                script_graph[data.id] = {
                    id : data.id,
                    order_number : data.order_number,
                    nextQuestion : nextQuestion || SPECIFIC_SECTION,
                    type : data.type,
                    question : data.question,
                    choices : choices,
                    skipQuestion : skipQuestion
                }
            }
        }
        else{
            script_graph[data.id] = {
                id : data.id,
                order_number : data.order_number,
                nextQuestion : nextQuestion,
                type : data.type,
                question : data.question
            }
        }
        if(data.question_name){
            script_graph[data.id].question_name = data.question_name
        }
        localStorage.setItem(SCRIPT_GRAPH_KEY, JSON.stringify(script_graph)); 
    }
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleChange = (value) => {
        setSelectedOption(value);
    };

    const getPdiQuestionOptions = async() =>{
        
        let response = await PdiQuestionType();
        if(response.status == 200){
            setQuestionOptions(response.data.question_choices);
        }
    }

    const handleInitialMessage = (e)=>{
        let isInitialMessage = e.target.checked;
        setIsP2pInitialMessage(isInitialMessage);
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handlePdiQuestionName = (e)=>{
        let value = e.target.value;
        setPdiQuestionName(value);

        if(showPdiQuestionNameError){
            setShowPdiQuestionNameError(false);
        }
    }

    const randomThreeNumber=()=>{
        var number = Math.floor(Math.random() * 900) + 100; // Generate a random number between 100 and 999 inclusive
        return number.toString();
    }

    useEffect(()=>{
        const pdiQuestionButton = document.getElementById("add-pdi-question");
        pdiQuestionButton.addEventListener("click", function() {
            showModal();
            getPdiQuestionOptions();
            setIsMultiChoiceQuestion(false);
            setP2pQuestionType('pdi_question')
            if (($('.is_initial').length > 0) && componentData.campaign_type =="p2p") {
                setShowInitialMessage(false);
                setIsP2pInitialMessage(false);
            }else if(componentData.campaign_type =="p2p"){
                setShowInitialMessage(true);
                setIsP2pInitialMessage(true);
            }
        });
        const pdiMultiSelectButton = document.getElementById("add-pdi-checkbox-response");
        pdiMultiSelectButton.addEventListener("click", function() {
            showModal();
            getPdiQuestionOptions();
            setIsMultiChoiceQuestion(true)
            setP2pQuestionType('pdi_multi_question')
            if (($('.is_initial').length > 0) && componentData.campaign_type =="p2p") {
                setShowInitialMessage(false);
                setIsP2pInitialMessage(false);
            }else if(componentData.campaign_type =="p2p"){
                setShowInitialMessage(true);
                setIsP2pInitialMessage(true);
            }
        });
        setPdiQuestionName('Q'+randomThreeNumber());
    },[])

    useEffect(()=>{
        if(questionOptions.length){
            setSelectedOption(questionOptions[0].value)
        }
    },[questionOptions])

    return(
        <div id='pdiIntegrationQuestionContainer'>
            <Modal
                title="Add from PDI question" 
                open={isModalOpen} 
                onOk={handleOk} 
                okText={'Add'}
                onCancel={handleCancel} 
                className='pdiIntegrationQuestionModal' 
                centered={true}
            >
                <div className='pdiQuestionModalContainer'>
                    <div className='pdi-select-question-title'>Select Question</div>
                    <Select
                        value={selectedOption}
                        showSearch={true}
                        style={{ width: 120 }}
                        placeholder="Select a question"
                        optionFilterProp="children"
                        filterOption={filterOption}
                        className='pdi-question-select'
                        popupClassName="pdi-question-dropdown"
                        onChange={handleChange}
                        options={questionOptions}
                    />
                    {componentData.campaign_type !="p2p" && componentData.has_question_name_permission &&
                        <div className="pdi-question-name">
                            <p className="pdi-question-name-title">Enter question name</p>
                            <Input className="pdi-question-name-input" placeholder="Enter a question name" value={pdiQuestionName} onChange={handlePdiQuestionName} maxLength={70} />
                            {showPdiQuestionNameError &&
                                <p className="pdi-question-name-error">Please enter a question name</p>
                            }
                        </div>
                
                    }
                    {showInitialMessage &&
                        <div className="pdi-initialMessage-contianer">
                            <Checkbox onChange={handleInitialMessage} checked = {isP2pInitialMessage} >Set as initial message</Checkbox>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default PdiIntegrationQuestion;