import * as React from "react"

const ReferralGiftIcon = (props) => (
  <svg
    width={40}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36 8h-4.36c.22-.62.36-1.3.36-2 0-3.32-2.68-6-6-6-2.1 0-3.92 1.08-5 2.7l-1 1.34-1-1.36C17.92 1.08 16.1 0 14 0c-3.32 0-6 2.68-6 6 0 .7.14 1.38.36 2H4C1.78 8 .02 9.78.02 12L0 34c0 2.22 1.78 4 4 4h32c2.22 0 4-1.78 4-4V12c0-2.22-1.78-4-4-4ZM26 4c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2ZM14 4c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm22 30H4v-4h32v4Zm0-10H4V12h10.16L10 17.66 13.24 20 18 13.52l2-2.72 2 2.72L26.76 20 30 17.66 25.84 12H36v12Z"
      fill="#E67540"
    />
  </svg>
)

export default ReferralGiftIcon