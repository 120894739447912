import axios from 'axios';

export const postSelectedSubAccount = async (url,data) => {
    try {
        const response = await axios.post(url, data);
        return {
            data : response.data,
            status: response.status
        }
    }catch(error) {
        return {
            error : error.response.data, 
            status: error.response.status
        }
    }
}

export const getApiCall = async (url, params = {}, headers = {}) => {
    try {
        const response = await axios.get(url,{params: params}, {'headers': headers});
        return response.data
    }catch(error) {
        return {
            error : error.response.data, 
            status: error.response.status
        }
    }
}