import axios from 'axios';

export const apiGetCall = async (url, headers, apiToken="") => {
    try{
        const response = await axios.get(url, { 
            headers: {
                ...headers,
                'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : token}`,
            } 
        });
        return response;
    }catch(error){
        return error.response.data;
    }
};

export const apiPostCall = async (url, data, headers, apiToken="") => {
    try{
        const response = await axios.post(url, data, { 
            headers: {
                ...headers,
                'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : token}`,
            }
         });

        return response;
    }catch(error){
        return error.response.data; // check response in error
    }
}

export const apiPutCall = async (url, data, headers, apiToken="") => {
    try{
        const response = await axios.put(url, data, { 
            headers: {
                ...headers,
                'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : token}`,
            }
         });

        return response;
    }catch(error){
        return error.response.data;
    }
}

export const apiDeleteCall = async (url, headers, apiToken="") => {
    try{
        const response = await axios.delete(url, { 
            headers: {
                ...headers,
                'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : token}`,
            }
         });

        return response;
    }catch(error){
        return error.response.data;
    }
}

export const apiPatchCall = async (url, data, headers, apiToken="") => {
    try{
        const response = await axios.patch(url, data, { 
            headers: {
                ...headers,
                'Authorization': 'Token ' + `${apiToken && apiToken !== "" ? apiToken : token}`,
            }
         });

        return response;
    }catch(error){
        return error.response.data;
    }
}