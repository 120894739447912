import React, { useEffect } from 'react';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import QuestionMarkIcon from './../../../assets/svg/QuestionMarkIcon';
import InfoIcon from './../../../assets/svg/InfoIcon';

function PopoverComponentWithNoTitle(props){
    const popover = (
        <Popover id={props.popoverId && props.popoverId} className={`popover-class ${props.popoverClass}`} >
            <Popover.Content>
                {props.children}
            </Popover.Content>
        </Popover>
    );
    


    return(
        <span className="question-container">
            <OverlayTrigger trigger={props.trigger} rootClose placement={props.placement} delay={{ show: 0, hide: props.hideDelay }} overlay={popover}>
                <span>
                    { props.iconInfo && <InfoIcon size={15}></InfoIcon>}
                    { props.textChecked && props.linkText }
                    { props.questionMarkChecked && <QuestionMarkIcon size={15} fillcolor={`${props.fillColor ? props.fillColor : "question-mark"}`}></QuestionMarkIcon>}
                </span>
            </OverlayTrigger>
        </span>
    )
}
export default PopoverComponentWithNoTitle;