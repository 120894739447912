import React from 'react'
import OpenArrowIcon from "../../../../../assets/svg/OpenArrowIcon";

import './SingleTransferAgent.scss';

const SingleTransferAgent = (props)=>{

    return(
            <div
                className={`ch-transfer-contact-select ${props.dropDownOpen && 'ch-transfer-dropdown-open'} 
                ${props.selectedData.length > 0 && 'ch-transfer-contact-select-border-color'}`}
                onClick={props.onSelectClick}
                >
                <props.containerIcon width={props.iconWidth} height={props.iconHeight} fill={`${props.selectedData.length > 0 ? '#2597A6' : '#000'}`}/>
                <div className={`ch-transfer-contact-select-label ${props.selectedData.length > 0 && 'ch-transfer-contact-select-label-selected'}`}>
                    {props.title} {`(${props.selectedData.length})`}
                </div>
                {props.dropDownOpen ?
                  <OpenArrowIcon width={12} height={8} fill={`${props.selectedData.length > 0 ? '#2597A6' : '#000'}`}
                                fillOpacity={1} transform={'rotate(180)'}/>
                    :
                  <OpenArrowIcon width={12} height={8} fill={`${props.selectedData.length > 0 ? '#2597A6' : '#000'}`} fillOpacity={1}/>

                }

            </div>
    )
}

export default SingleTransferAgent