import React from "react"
import styled from "styled-components"
export function OfferTag(props){
    const OfferTag = styled.div`
        width: 146px;
        height: 18px;
        padding: 0px 8px;
        border-radius: 4px;
        background-color: #1ab394;
        background-size: cover;
        font-family: Open Sans;
        font-size: 10px;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
    `
    return <OfferTag>{props.TagTitle}</OfferTag>
}
