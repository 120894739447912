import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Alert, Modal, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { apiPostCall, apiGetCall } from "../../utilities/utilities";
import {dncSegregationUpdateUrl} from "../../configuration/dncSegregationConfig"
import "./dncSegregationModal.scss";

const DncSegregationModal = (props) => {
    const {open, onClose, settingsApplied, showSuccessMessage, isWhiteLabel} = props;
    const IsSettingsApplied = useRef({current : settingsApplied});
    const [okDisabled, setOkDisabled] = useState(true);
    const [segregationEnabled, setSegregationEnabled] = useState(settingsApplied);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const DNC_SETTINGS_INFO = <p>The <b>Calling/Texting DNC Segregation</b> setting is effective only if your campaign has never been started.</p>
    
    const handleApplySettings = async () => {
        setConfirmLoading(true)
        const url = dncSegregationUpdateUrl();
        const dataToPost = {
            dnc_bucket_settings : segregationEnabled
        }
        const response = await apiPostCall(url, dataToPost);
        setConfirmLoading(false);
        if(response.status == 200){ 
            onClose(true);
            showSuccessMessage();
        }
    }

    const handleSwitchChange = (checked) => {
        setSegregationEnabled(checked)
        setOkDisabled(IsSettingsApplied.current == checked);
    }

    const getDNCBucketSettings = async () =>{
        const url = dncSegregationUpdateUrl()
        const response = await apiGetCall(`${url}?dnc_bucket_settings=false`);
        if (response.status == 200){
            const currentSettings = response.data.dnc_bucket_settings
            setSegregationEnabled(currentSettings);
            IsSettingsApplied.current = currentSettings
        }
    }

    useEffect(() => {
        getDNCBucketSettings();
    },[])

    return (
        <Modal 
            title="DNC Settings"
            okText="Apply"
            cancelText="Dismiss"
            onCancel={() => onClose()}
            onOk={handleApplySettings}
            okButtonProps={{disabled: okDisabled}}
            open={open}
            centered
            className="settings-modal"
            maskClosable={false}
            confirmLoading={confirmLoading}
        >
            <Alert type="info" message={DNC_SETTINGS_INFO} closable={false} showIcon />
            <div className="modal-body">
                <b>Calling/Texting DNC Separation</b>
                <div>
                    <p>Turning this ON will create separate DNC lists for calling and texting campaigns. Calling lists will not filter contacts from the texting list and vice versa. {!isWhiteLabel && <a>Learn more</a>}</p>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange = {handleSwitchChange}
                        checked={segregationEnabled}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DncSegregationModal;