import { Alert, Form, Modal, Radio, Select } from "antd";
import React, { useState, useEffect } from "react";
import { transferSettingsSchema } from "./constant";
import "./transferSettings.scss";
import { postAutoTransferSettings, getAutoTransferSettings } from "../../../utilities/autoCreditsTransferUtilities";
import TooltipParagraph from "../tooltipParagraph";

const TransferSettings = (props) => {
    const {componentData, unMountTheComponent} = props;
    const [showModal, setShowModal] = useState(false);
    const [settingsEnabled, setSettingsEnabled] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [hasSettings, setHasSettings] = useState(false);
    const [additionalJson, setAdditionalJson] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        getTransferSettingsForUser();
    },[]);


    const handleOk = async () => {
        setConfirmLoading(true);
        const userID = hasSettings ? componentData.sub_userId : null;
        const dataToPost = {
            status: settingsEnabled,
            credits_below: form.getFieldValue('minRecharge'),
            recharge_amount: form.getFieldValue('recharge'),
            monthly_threshold: form.getFieldValue('rechargeThreshold'),
            user: componentData.sub_userId,
            origin_type: 'USER',
            origin: componentData.userId,
            suspended: false,
            additional_vars: userID ? {...additionalJson, monthly_threshold_reached: false} : additionalJson
        }
        try{
            const response = await postAutoTransferSettings(dataToPost, userID);
            setConfirmLoading(false);
            setShowModal(false);
            const event_key = (hasSettings && settingsEnabled) ? 'updatedLimit' : settingsEnabled ? 'transferSettingsEnabled' : 'transferSettingsDisabled';
            localStorage.setItem(event_key, componentData.username)
            window.location.reload();
            unMountTheComponent(false);
        }
        catch(error){
            const error_data = error.response.data;
            setConfirmLoading(false);
            if(error_data['monthly_threshold']){
                const error_text = error_data['monthly_threshold'][0]
                form.setFields([{ 
                    name : 'rechargeThreshold',
                    errors: [error_text]
                }]);
            }
            return;
        }
    }

    const handleCancel = () => {
        setShowModal(false);
        unMountTheComponent(false);
    }

    const handleSetSettings = (event) => {
        const status = event.target.value
        setSettingsEnabled(status);
        form.setFieldValue('auto-recharge-status', status);
    }

    const handleRechargeSettings = (type, value) => {
        form.setFieldValue(type, value);
    }

    const getTransferSettingsForUser = async () => {
        let transferSettings = {
            minRecharge: null,
            recharge: null,
            rechargeThreshold: null,
            'auto-recharge-status': false
        }
        try{
            const response = await getAutoTransferSettings(componentData.sub_userId);
            const response_data = response.data;
            if(response_data){
                setSettingsEnabled(response_data.status);
                setHasSettings(response_data.monthly_threshold ? true : false);
                setAdditionalJson(response_data.additional_vars ?? {});
                if(response_data.status){
                    transferSettings = {
                        minRecharge: response_data.credits_below,
                        recharge: response_data.recharge_amount,
                        rechargeThreshold: response_data.monthly_threshold,
                        'auto-recharge-status': response_data.status
                    }
                }
            }
        }
        catch(error){
            console.log(error);
        }finally{
            form.setFieldsValue(transferSettings);
            setShowModal(true);
        }
    }

    return(
        <Modal 
            open={showModal} 
            onOk={form.submit} 
            onCancel={handleCancel}
            closable={false}
            centered
            destroyOnClose
            className="transfer-settings-container"
            confirmLoading={confirmLoading}
            title="Auto recharge"
            okButtonProps={{disabled: !hasSettings && !settingsEnabled}}
        >
            <Form
                layout="vertical"
                requiredMark="optional"
                form={form}
                name="auto-transfer-settings-form"
                scrollToFirstError={true}
                autoComplete="off"
                onFinish={handleOk}
            >
            <div className="switch-container">
                <div className="user-details">
                    <p>Enable auto recharge for&nbsp;</p>
                    {componentData.username.length > 50  ? (
                        <TooltipParagraph>
                            <b>{componentData.username}</b>
                        </TooltipParagraph>) : 
                        <b>{componentData.username}</b>
                    }
                </div>
                <Form.Item
                    name='auto-recharge-status'
                    label= ''
                    required={true}
                >
                    <Radio.Group onChange={handleSetSettings}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
            </div>
            <div className="settings">
                {componentData.error && (
                    <Alert
                        closable
                        message={"Sub account has reached monthly auto recharge limit, increase limit to allow further use."}
                        type="error"
                        showIcon
                    />)
                }
                {transferSettingsSchema.map((setting) => (
                    <div key={setting.id}>
                        <Form.Item
                            name={setting.name}
                            label={setting.label}
                            required={true}
                            rules={[
                                {required:true, message: "Please select a value to enable auto recharge"},
                                ({getFieldValue}) => ({
                                    validator(rule, value){
                                        if(rule.field === "recharge" && getFieldValue('rechargeThreshold') && (value > getFieldValue('rechargeThreshold'))){
                                            return Promise.reject(new Error('Error: Recharge value can’t be higher than monthly recharge limit.'));
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                        >
                            <Select
                                options={setting.options}
                                onChange={(value) => handleRechargeSettings(setting.name, value)}
                                placeholder='-- Select --'
                                style={{width: '100%'}}
                                value={form.getFieldValue(setting.name)}
                                disabled={!settingsEnabled}
                            />
                        </Form.Item>
                    </div>
                ))}
            </div>
            </Form>
        </Modal>
    );
}

export default TransferSettings;