export const pdiQuestionUrl = (campaignId,campaign_type)=>{
    if(campaignId && campaign_type=="cc"){
        return `power_campaign/${campaignId}/pdi-question/add/`
    }
    else if (campaignId && campaign_type=="p2p")
        return `snowflake/${campaignId}/pdi-question/add/`
    else if (campaign_type=="p2p")
        return `snowflake/pdi-question/add/`
    return `power_campaign/pdi-question/add/`
}

export const pdiConnectUrl = ()=>{
    return `pdi/connect/`
}