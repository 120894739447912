import React, {useState} from 'react';

import './tablerow.scss';
import DisabledContactTracking from '../../../../../assets/svg/DisabledContact'
import ContactTrackingEnabled from '../../../../../assets/svg/ContactTrackingEnabled'
import InfoIcon from '../../../../../assets/svg/InfoIcon';

const TableRow = ({tablerowdata, index, totalContacts}) =>{
    const [toolTipDisplay, setToolTipDisplay] = useState(false)

    const percentageSent = ((tablerowdata.totalsent && totalContacts) && (tablerowdata.totalsent !==0 || totalContacts !== 0)) ? (tablerowdata.totalsent * 100)/totalContacts : 0
    const percentageUniqueClicks = ((tablerowdata['unique clicks'] && tablerowdata.totalsent) && (tablerowdata['unique clicks'] !==0 || tablerowdata.totalsent)) ? (tablerowdata['unique clicks'] * 100)/tablerowdata.totalsent : 0

    const contact_tracking_type = tablerowdata.contact_tracking_type

    let shorturl = `${tablerowdata.domain}/${tablerowdata.custom_prefix ? `${tablerowdata.custom_prefix}/` : ''}`
    if (contact_tracking_type !== 1) { 
        shorturl += 'ge3r4'
    }
 
    return (
        <tr>
            <td className='table-row'>
                <div className="table-row-first-child">
                    <div className="contact-tracking-tooltip">
                        <div className="icon-tracking">
                            {contact_tracking_type && contact_tracking_type === 1 ?
                                <DisabledContactTracking />:
                                <ContactTrackingEnabled />
                            }
                        </div>  
                        <span className="contact-tracking-tooltiptext">
                            {contact_tracking_type && contact_tracking_type === 1 ? 'Contact tracking disabled' : 'Contact tracking enabled'}
                        </span>
                    </div>
                    <div className="url-details">
                        <p className="title">{tablerowdata.title}</p>
                        <p className="targetUrl">{tablerowdata.target_url}</p>
                        <div className="shortUrl">
                            {shorturl}
                            { !(contact_tracking_type === 1) &&                               
                                <div className="info-tooltip">
                                    <span className='info-icon'>
                                        <InfoIcon size='16px'/>
                                    </span>
                                    <span className="tooltiptext">The shortened URL is merely for illustration purpose. A unique link is assigned to each contact in the phonebook.</span>
                                </div>
                            }
                        </div>
                    </div>        
                </div>
            </td>
            <td className='table-row-data totalSent'>{tablerowdata.totalsent ? tablerowdata.totalsent : 0}{" (" + percentageSent.toFixed(2) + "%)"}</td>
            <td className='table-row-data'>{tablerowdata['total clicks'] ? tablerowdata['total clicks'] : 0}</td>
            {   
                contact_tracking_type === 1 ? 
                    <td className='contact-tracking-diabled uniqueclicks'>-/-</td>
                : 
                    <td className='table-row-data uniqueclicks'>{tablerowdata['unique clicks'] ? tablerowdata['unique clicks'] : 0}{" (" + percentageUniqueClicks.toFixed(2) + "%)"}</td>
            }
        </tr>
    )
}

export default TableRow