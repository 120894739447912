import { apiGetCall, apiPostCall, apiPutCall } from '../../../../../src/utilities/utilities';
import {getAgentList , getSmartFilterDataUrl, goalCreationUrl} from './CcAiPowedConfig';

export const getCampaignAgents = async(campaign_id, limit=50, offset=0, search_term='')=>{
    try{
        const agent_list_url = getAgentList(campaign_id, limit, offset, search_term);
        const host = window.location.hostname;
        const response = await apiGetCall(`https://${host}/${agent_list_url}`);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const applyingSmartFilter = async(campaign_id, data) => {
    try{
        const smartFilterUrl = getSmartFilterDataUrl(campaign_id);
        const host = window.location.hostname;
        const response = await apiPostCall(`https://${host}/${smartFilterUrl}`, data);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const goalCreation = async(campaign_id, data) => {
    try{
        const smartFilterUrl = goalCreationUrl(campaign_id);
        const host = window.location.hostname;
        const response = await apiPostCall(`https://${host}/${smartFilterUrl}`, data);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}