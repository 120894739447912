import React from "react"

const DownArrow = (props) => (
  <svg
    width={props.width}
    height={props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.928 7.429h1.005a.215.215 0 0 0 .173-.089L5.91 2.096 9.715 7.34c.04.055.105.089.173.089h1.005c.087 0 .138-.1.087-.17L6.257.749a.427.427 0 0 0-.692 0L.843 7.258a.107.107 0 0 0 .085.17Z"
      fill="#000"
      fillOpacity={0.25}
    />
  </svg>
)

export default DownArrow
