import * as React from "react"

const EnterpiseIcon = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      {...props}
    >
      <g data-name="Group 11">
        <path
          data-name="Path 11"
          d="M25.757 13.532L32 16l-6.243 2.467a12.9 12.9 0 00-7.263 7.277L16.032 32l-2.462-6.256a12.9 12.9 0 00-7.263-7.277L.063 16l6.243-2.468a12.9 12.9 0 007.263-7.277L16.032 0l2.462 6.255a12.9 12.9 0 007.263 7.277z"
          fill="#222"
        />
      </g>
      <g data-name="Group 12">
        <path
          data-name="Path 12"
          d="M7.666 5.67l.105-1.17.105 1.174A2.508 2.508 0 009.971 7.7l1.209.1-1.209.1a2.5 2.5 0 00-2.094 2.034l-.105 1.174-.105-1.174A2.5 2.5 0 005.573 7.9l-1.209-.1 1.209-.1a2.5 2.5 0 002.093-2.03z"
          fill="#222"
        />
      </g>
      <g data-name="Group 13">
        <path
          data-name="Path 13"
          d="M9.83.918l.073-.78.071.783a1.668 1.668 0 001.4 1.356l.805.068-.805.068a1.671 1.671 0 00-1.4 1.356l-.071.783-.07-.783a1.671 1.671 0 00-1.4-1.356l-.806-.068.8-.071A1.67 1.67 0 009.83.918z"
          fill="#222"
        />
      </g>
      <g data-name="Group 14">
        <path
          data-name="Path 14"
          d="M23.782 7.162l.071-.783.073.781a1.666 1.666 0 001.4 1.356l.8.071-.8.071a1.671 1.671 0 00-1.4 1.356l-.073.78-.073-.78a1.668 1.668 0 00-1.4-1.356l-.8-.071.805-.068a1.67 1.67 0 001.397-1.357z"
          fill="#222"
        />
      </g>
      <g data-name="Group 15">
        <path
          data-name="Path 15"
          d="M5.779 22.209l.185-2.055.184 2.054a4.382 4.382 0 003.665 3.56l2.115.179-2.115.18a4.384 4.384 0 00-3.665 3.56l-.184 2.054-.184-2.053a4.386 4.386 0 00-3.665-3.56L0 25.949l2.114-.179a4.386 4.386 0 003.665-3.561z"
          fill="#222"
        />
      </g>
      <g data-name="Group 16">
        <path
          data-name="Path 16"
          d="M24.509 21.328l.132-1.468.132 1.468a3.135 3.135 0 002.618 2.543l1.507.129-1.511.129a3.133 3.133 0 00-2.618 2.543l-.132 1.468-.129-1.47a3.137 3.137 0 00-2.618-2.543L20.382 24l1.516-.13a3.132 3.132 0 002.611-2.542z"
          fill="#222"
        />
      </g>
    </svg>
  )
}

export default EnterpiseIcon