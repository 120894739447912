import * as React from "react";

const TipIcon = props => {
	return (
		<svg
			overflow="visible"
			preserveAspectRatio="none"
			viewBox="0 0 24 24"
			width={16.252}
			height={16}
			{...props}
		>
			<defs default="http://www.w3.org/2000/svg">
				<path
					d="M0 0h24v24H0V0z"
					id="prefix__a"
					vectorEffect="non-scaling-stroke"
					fill="#e67642"
				/>
			</defs>
			<clipPath
				default="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				id="prefix__b"
				vectorEffect="non-scaling-stroke"
			>
				<use
					overflow="visible"
					xlinkHref="#prefix__a"
					vectorEffect="non-scaling-stroke"
				/>
			</clipPath>
			<path
				default="http://www.w3.org/2000/svg"
				clipPath="url(#prefix__b)"
				d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6A4.997 4.997 0 017 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z"
				vectorEffect="non-scaling-stroke"
				fill="#e67642"
			/>
		</svg>
	);
};

export default TipIcon;
