import React from 'react'

function SupportIcon(props) {
    return (
        <svg
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 48 48"
            width={props.size} // {48}
            height={props.size} // {48}
            {...props}
            >
            <path
                d="M48 3.748v8.778c0 2.078-1.66 3.765-3.731 3.765H34.192c-.197 0-.36-.002-.495-.004a4.109 4.109 0 00-.365.002 5.16 5.16 0 00-.378.332c-.06.057-.13.118-.204.187l-2.85 2.608a.795.795 0 01-1.325-.576V3.748C28.575 1.669 30.29 0 32.362 0H44.27C46.339 0 48 1.67 48 3.748zm-9.86 7.023c-.429 0-.76.35-.76.78v.006c0 .431.332.777.76.777a.781.781 0 000-1.563zm2.498-4.017a2.327 2.327 0 00-2.323-2.328 2.33 2.33 0 00-2.322 2.331c0 .431.347.78.777.78.429 0 .777-.349.777-.78 0-.425.345-.771.769-.771a.77.77 0 01.768.771c0 .21-.254.44-.641.766-.493.416-1.108.922-1.1 1.767.003.428.35.762.776.762h.007c.429 0 .774-.344.77-.775 0-.11.355-.404.546-.565.505-.425 1.196-1.005 1.196-1.958zm-25.82 26.32c4.043 0 7.351-3.359 7.351-7.463 0-4.105-3.308-7.463-7.35-7.463-4.044 0-7.352 3.358-7.352 7.463 0 4.104 3.308 7.463 7.351 7.463zm.042 2.268C4.997 35.342 0 40.26 0 45.297v.694C0 47.101.895 48 2 48h25.72c1.104 0 2-.9 2-2.009v-.694c0-5.037-4.998-9.955-14.86-9.955z"
                vectorEffect="non-scaling-stroke"
                fill="#e67540"
            />
        </svg>
    )
}

export default SupportIcon
