import React, { useState, useEffect, Fragment, useRef } from "react";
import CaretIcon from "./../../../../assets/svg/CaretIcon";
import Select from "../../../components/common/Select";
import Tips from "../../../components/common/Tips";

// Importing Components
import _ from "lodash";

//css
import "./AddSurvey.scss";
import "../commonStyle.scss";

const dropDownComponent = () => {
	return (
		<span className="position-caret-icon">
			<CaretIcon size={24} labelstyle="dropdown-icon" />
		</span>
	);
};

const AddSurveyField = props => {
	const { salesforceField, errorStatus } = props;
	return (
		<div>
			<div className={`${errorStatus && "border-red-error-status"}`}>
				<label className="integration-subheading">Salesforce field</label>
				<Select
					{...salesforceField}
					options={[
						{ value: "Choose", label: "Choose" },
						...salesforceField.options,
					]}
					placeholder="Choose"
					isClearable={false}
					closeMenuOnSelect={true}
					isSearchable={true}
					components={{
						IndicatorSeparator: () => {
							return null;
						},
						DropdownIndicator: dropDownComponent,
					}}
					name="salesforceField"
					errorStatus={errorStatus}
					changed={event =>
						props.changeSalesforceFieldHandler(event, "salesforceField")}
					className="input-style"
					loadingMessage="Fetching salesforce field......"
					menuPortalTarget={document.body}
					menuPosition={"fixed"}
					customStyle={{
						menuPortal: provided => ({ ...provided, zIndex: 9999 }),
						menu: provided => ({ ...provided, zIndex: 9999 }),
					}}
				/>
				{errorStatus &&
					<p className="text-content error-message-color">
						Choose a field to create the survey
					</p>}
			</div>
			<Tips heading={true} size="big">
				Fields that are mapped in the{" "}
				<a href="/salesforce/settings/" target="_blank" className="learn-more">
					integration's settings
				</a>{" "}
				will not be available here.
			</Tips>
		</div>
	);
};

export default AddSurveyField;
