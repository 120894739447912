import React, { useEffect, useState, useRef } from "react";
import Button from "antd/lib/button"
import Popover from "antd/lib/popover"
import Switch from "antd/lib/switch"
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntButton.scss";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntPopover.scss";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSwitch.scss";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntButton.scss";
import './InboundCallingNudgeStyles.scss';
import PopoverComponentWithNoTitle from "../common/PopoverComponentWithNoTitle";
import DialInIcon from "../../../assets/svg/ContactDialIcon";
import CloseIcon from "../../../assets/svg/CloseIcon";
import CrossIcon from "../../../assets/svg/CrossIcon";
import CancelIcon from "../../../assets/svg/CancelIcon";
import { useRecoilState } from "recoil";
import { defaultAudioFileSatate, inboundCallingDataState, isDynamicCallerIdCheckedState, isInboundDataPresent, isValidatedNumberCheckedSatate, loaderState, mediaAudioFileState, showInboundFormState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";
import { getAudioFiles, getDefaultAudioFliles, getInboundPopUpDetails, postInboundPopUpDetails } from "../../utilities/InboundcallingUtilities";

function InboundCallingNudge ({componentData}) {
    const [showinboundForm, setShowInboundForm] = useRecoilState(showInboundFormState)
    const [showPopover, setShowPopover] = useState(false);
    const [defaultAudioFiles, setDefaultAudioFiles] = useRecoilState(defaultAudioFileSatate);
    const [audioFiles, setAudioFiles] = useRecoilState(mediaAudioFileState)
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState);
    const [isDynamicCallerIdChecked, setIsDynamicCallerIdChecked] = useRecoilState(isDynamicCallerIdCheckedState);
    const [isValidatedNumberChecked, setIsValidatedNumberChecked] = useRecoilState(isValidatedNumberCheckedSatate);
    const showInbound = useRef(false)
    showInbound.current = showinboundForm
    const [isLoading, setIsLoading] = useRecoilState(loaderState)
    const [isDataPresent, setIsDataPresent] = useRecoilState(isInboundDataPresent)

    const handleShowInboundForm = () => {
        setShowInboundForm((status) => !status)
        showInbound.current = !showinboundForm
        
        if(showInbound.current) {
            getDefaultAudioFiles();
            getMediaAudioFiles();
            sessionStorage.setItem('isInboundSelected', JSON.stringify(true))
            sessionStorage.setItem('isInboundEnabled', JSON.stringify(true))
            const isDynamicCallerId = JSON.parse(sessionStorage.getItem("isDynamicCallerIdChecked"));
            const isValidateNumber = JSON.parse(sessionStorage.getItem("isValidateNumber"));
            setIsDynamicCallerIdChecked(isDynamicCallerId)
            setIsValidatedNumberChecked(isValidateNumber)
            handleSegmentTrackEvent('cc-enabled-inbound-calling')
        } else {
            sessionStorage.removeItem("isInboundSelected");
            sessionStorage.removeItem("inboundCallingData");
            sessionStorage.setItem('isInboundEnabled', JSON.stringify(false));
            setIsDataPresent(true);
        }
        handleSegmentTrackEvent("cc-toggled-inbound-calling") 
    }

    useEffect(()=> {
        sessionStorage.removeItem("isInboundSelected")
        if(!componentData.inboundData.show_inbound_form) {
            sessionStorage.setItem('isInboundEnabled', JSON.stringify(false))
        } else if (componentData.inboundData.show_inbound_form) {
            sessionStorage.setItem('isInboundEnabled', JSON.stringify(true))
        }
    }, [])

    const getDefaultAudioFiles = async () => {
        setIsLoading(true)
        const response = await getDefaultAudioFliles(componentData.apiToken);
       
        setInboundCallingData({...inboundCallingData, "welcomeAudio": response.data.default_config.default_welcome_music, "holdMusic": response.data.default_config.default_hold_music, "ThankyouMessageAudio": response.data.default_config.default_thankyou_music })
        setDefaultAudioFiles(response.data.default_config)
        setIsLoading(false)
    }

    const getInboundPopUpFlag = async () => {
        setIsLoading(true)
        const response = await getInboundPopUpDetails(componentData.apiToken);
        if(response.inbound_pop_seen_flag == false) {
            setShowPopover(true);
        } else {
            setShowPopover(false);
        }
        setIsLoading(false);
    }
    const getMediaAudioFiles = async () => {
        setIsLoading(true)
        const response = await getAudioFiles(componentData.apiToken, {media_files:"True"})
        setAudioFiles(response.data.media_files)
        setIsLoading(false)
    }

    const handleShowPopover = async () => {
        const response = await postInboundPopUpDetails(componentData.apiToken);
        getInboundPopUpFlag();

    }

    const handleLernMore = () => {
        window.open('https://support.callhub.io/hc/en-us/articles/5751278242073-How-to-set-up-Inbound-calls-for-Call-Center-Campaigns-', '_blank')
    }
    const content = (
        <div className="inbound-nudge-popover-wrapper">
          <div className="inbound-content-popover-wrapper">
              <div className="inbound-popover-close-wrapper" onClick={handleShowPopover}><CancelIcon /></div>
              <div className="inbound-dial-in-icon"><DialInIcon /></div>
              <div>
                  <div className="inbound-popover-heading">Almost 40% of calls go unanswered. Let’s fix that.</div>
                  <div className="inbound-popover-message">{getBrandName} now works for incoming calls. Out of the 40% who don’t pick up a call, 15% - 20% call back almost immediately or within 30 seconds. With Inbound Calling, you no longer lose out on people who call back.</div>
              </div>
          </div>
          <div className="inbound-popver-button-wraaper">
              <Button className={ componentData.inboundData.show_help_links ? 'inbound-popover-left-button' : 'inbound-popover-left-button inbound-popver-primary-button'} type={!componentData.inboundData.show_help_links && 'primary'} onClick={handleShowPopover}>Okay</Button>
              { componentData.inboundData.show_help_links && <Button type="primary" onClick={handleLernMore} className="inbound-popover-right-button">Learn more</Button>}
          </div>
        </div>
      );

      useEffect(() => {
        getInboundPopUpFlag();
      }, [])

    return (
        <div id="inbound-calling-nudge-wrapper">
            <div>
                {/* <Switch onChange={handleShowInboundForm} checked={} className="inbound-calling-switch"/> */}
                <div
                    className={`inbound-calling-switch`}
                    onClick={handleShowInboundForm}
                >
                    <input                    
                    type="checkbox"
                    checked={showinboundForm}
                    />
                    <span className={`switch`} />
                </div>
            </div>
            <div>
                <div className="nudge-heading-wrapper">
                    <div className="nudge-heading">Inbound calling</div>
                    <div>
                        <Popover content={content} visible={showPopover} placement="right" overlayClassName="inbound-popover-container">
                        </Popover>
                    </div>
                </div>
                <div className="nudge-message-text">If someone misses your call and calls back, route them to agents in your campaign.</div>
            </div>
        </div>
    )
}

export default InboundCallingNudge;