import React from 'react';
import CallCenterKycIcon from '../../../../assets/svg/CallCenterKycIcon';
import SMSBroadcastKycIcon from '../../../../assets/svg/SMSBroadcastKycIcon';
import VoiceBroadcastKycIcon from '../../../../assets/svg/VoiceBroadcastKycIcon';
import P2PTextingKycIcon from '../../../../assets/svg/P2PTextingKycIcon';
import RelationalKycIcon from '../../../../assets/svg/RelationalKycIcon';

export const InterstedProductsData = () => {
    return [
        {productValue:1 ,productName:'Call Center' , statement:'Calling campaign with multiple dialers', icon:CallCenterKycIcon},
        {productValue:2 ,productName:'Peer to Peer Texting' , statement:'One-to-one text message conversations', icon:P2PTextingKycIcon},
        {productValue:3 ,productName:'Text Broadcast' , statement:'Text large groups in one go', icon:SMSBroadcastKycIcon},
        {productValue:4 ,productName:'Voice Broadcast' , statement:'Pre recorded call to large groups', icon:VoiceBroadcastKycIcon},
        {productValue:6 ,productName:'Relational Organizing' , statement:"Use volunteer's personal network for outreach", icon:RelationalKycIcon} ,   
        {productValue:5 ,productName:'Text To Join' , statement:'Collect Opt-in to build list', icon:CallCenterKycIcon},
    ]
}

export const ContactListRangeData = () => {
    return {
        one:1,
        two:2,
        three:3,
        four:4,
        five:5
    }
}

export const CONTACT_LIST_RANGE = {
    1:'0 - 5000',
    2:"5000 - 10000",
    3:"10000 - 50000",
    4:"50000 - 100000",
    5:'100000+'
}

export const INTERESTED_PRODUCT = {
    1:'CallCenter',
    2:"PeerToPeerTexting",
    3:"SMSBroadcast",
    4:"VoiceBroadcast",
    5:'SMSOptIn',
    6:'RelationalOrganizing'
}

export const ORGANISATION_MAPPING = {
    "1": 'Political',
    "2": 'Advocacy',
    "3": 'Non Profit',
    "4": 'Business',
    "5": 'Other',
    "6": 'Union',
    "7": 'Education',
    "8": 'Financial Services',
    "9": 'Real Estate',
    "10": 'Ecommerce',
    "11": 'Healthcare',
    "12": 'Energy/Utilities',
    "13": 'Market Research',
    "14":'Telecom',
    "15": 'Services',
    "16": 'Non Profit - 501 (c)(3)',
    "17": 'Non Profit - 501 (c)(4)',
    "18": 'Advocacy - 501 (c)(3)',
    "19": 'Advocacy - 501 (c)(4)',
    "20": 'Debt Collection'
}

export const PREMIUM_CONTACT_LIST_STARTS_FROM = ContactListRangeData().three

export const ExpertiseLevels = {
    basic: {
        heading: 'Beginner',
        description: 'I need help figuring things out'
    },
    medium: {
        heading: 'Advanced',
        description: "I have used similar products and may need help only with advanced features"
    },
    advanced: {
        heading: 'CallHub Veteran',
        description: "I have used CallHub extensively and know how things work here"
    }
}

export const UserTypes = {
    manager: {
        heading: 'Campaign Manager',
        description: 'I am here to create a campaign'
    },
    agent: {
        heading: 'Agent', 
        description: 'I am here to join a campaign I have been assigned'
    }
}
