import React, {useEffect, useState} from "react";
import AudioFileContainer from "./InboundAudioFileContainer";
import InboundTextContainer from "./InboundTextContainer";
import "./InboundTabsContainerStyles.scss";
import { useRecoilState } from "recoil";
import { inboundCallingDataState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";

function InboundTabsContainer({componentData, componentType, handleInboundTextError, setThankyouMessageTextError}) {
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState);
    const messageType = {
        "welcomeMessage":  "welcomeAudioSelected",
        "thankyouMessage": "thankyoumessageAudioSelected"
    }
    const componentKey = messageType[componentType]
    const value = inboundCallingData[componentKey]
    const [isTextSpeech, setIsTextSpeech] = useState(!value);
    

    


    const handleTabsClicked = (key) => {
        if(key === "AudioTab"){
            setInboundCallingData({...inboundCallingData, [messageType[componentType]] : true})
            setIsTextSpeech(false)
            sessionStorage.removeItem('inboundCallingData')
        } else if (key === "TextTab") {
            setInboundCallingData({...inboundCallingData, [messageType[componentType]] : false})
            setIsTextSpeech(true)
            sessionStorage.removeItem('inboundCallingData')
        }
    }


    
    return (
            <React.Fragment>
                <div id="inbound-tabs-container">
                    <div onClick={() => handleTabsClicked("TextTab")} className={isTextSpeech ? "inbound-text-speech-tab inbound-tab-focus" : "inbound-text-speech-tab"}>Text to speech</div>
                    <div onClick={() => handleTabsClicked("AudioTab")} className={!isTextSpeech ?  "inbound-audio-tab-focus inbound-audio-file-tab" : "inbound-audio-file-tab "} >Audio file</div>
                </div>
                {isTextSpeech ? <InboundTextContainer componentType={componentType} handleInboundTextError={handleInboundTextError} setThankyouMessageTextError={setThankyouMessageTextError}/> : 
                <AudioFileContainer componentData={componentData} componentType={componentType}/>
            }
            </React.Fragment>
    )
}

export default InboundTabsContainer;