import { CustomerKYCSheduledEventUrl, trainingWidgetUrl } from "../configuration/trainingWidgetConfig"
import { apiGetCall, apiPostCall } from "./utilities";

export const getScheduledEventData = async (apiToken) => {
    try {
        const scheduledEventUrl = trainingWidgetUrl();
        const response = await apiGetCall(scheduledEventUrl, apiToken);
        return response.data;
    } catch (error) {
        return error?.response?.data;
    }
}

export const postScheduledEventData = async (formData, apiToken) => {
    try {
        const scheduledEventUrl = trainingWidgetUrl();
        const response = await apiPostCall(scheduledEventUrl, formData, apiToken)
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}
export const postCustomerKYCSheduledEvent = async ( apiToken) => {
    try {
        const scheduledEventUrl = CustomerKYCSheduledEventUrl();
        const response = await apiPostCall(scheduledEventUrl, {}, apiToken)
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}