import React, { useRef } from "react";
import PersonaliseTagIcon from "../../../../assets/svg/PersonaliseTagIcon";
import CloseButton from "../../../../assets/svg/CloseIcon";

import "./Modal.scss";

function Modal(props) {
	const scrollToBottom = useRef(null);
	return (
		<div className={`modal ${props.className}`}>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header panel-modal-header-no-padding">
						<button
							type="button"
							className="close panel-modal-close"
							data-dismiss="modal"
							aria-hidden="true"
							onClick={props.handleCloseModal}
						>
							<CloseButton width="16" height="16" />
						</button>
						<h4 className="panel-modal-header-title panel-modal-header-title-padding">
							{props.title}
						</h4>
						{props.subtitle ? <p className="sub-title">{props.subtitle}</p> : <></>}
					</div>
					<div className={`modal-body ${props.modalBodyClassName}`} ref={scrollToBottom} id="modal-body">
						{props.children}
					</div>
					<div className="modal-footer panel-modal-footer m-0 p-0">
						<div className="pull-left-personalise-tag-section plr-15 common-modal-button-padding">
							{props.showPersonalise &&
								<label
									className="common-style"
									onClick={async () => {
										await props.PersonaliseTagHandler();
										const scroll =
											scrollToBottom.current.scrollHeight -
											scrollToBottom.current.clientHeight;
										console.log(scroll);
										scrollToBottom.current.scrollTo(0, scroll);
									}}
								>
									<span className="personalise-tag-icon" id="salesForceTag">
										<PersonaliseTagIcon size="18" fillColor="rgba(17, 17, 17, 0.85)"/>
									</span>
									<span className="personalise-tag-text">Personalise</span>
								</label>}
						</div>
						<a
							onClick={props.handleCloseModal}
							className="btn btn-default btn-grey-color mr-5 modal-button-padding common-modal-button-margin"
							data-dismiss="modal"
						>
							{props.cancelButton}
						</a>
						<a
							onClick={props.submitHandler}
							id="modal-discard-btn"
							className={`btn common-modal-button-margin mr-20 ${props.submitButtonclass}`}
						>
							{props.submitButton}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Modal;
