export const transfer_settings_url = (userId = null) => {
    let url = "/billing/auto-transfer/"
    if(userId) {
        url += `${userId}/`
    }
    return url;
}
export const transferCreditsUrl = () =>{
    return '/enterprise/transfer_credits/'
}

export const getSubAccountsAndMainAccountsUrl = () =>{
    return 'sub_accounts_and_main_accounts/'
}