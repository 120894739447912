import React from "react";

import './InfoTooltip.scss';


const InfoToolTip = ({ children, tooltiptext, alignmentClasses, tooltiptextClasses }) => {
    return (
        <div className={`information-tooltip ${alignmentClasses}`}>
            <span className="information-tooltip-icon">
                {children}
            </span>
            <span className={tooltiptextClasses}>
                {tooltiptext}
            </span>
        </div>
    )
}

export default InfoToolTip