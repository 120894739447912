import Steps from "antd/lib/steps"
import "../../../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSteps.scss";
import "../../../../screens/react_library_for_jquery/AntDesignCustomStyles/AntSelectDefault.scss";
import "../../../../screens/react_library_for_jquery/AntDesignCustomStyles/AntProgress.scss";
import React from "react";
const { Step } = Steps;
export function StepperAnt({
  current = 0,
  direction = "vertical",
  renderSteps,
  progressDot,
  handleOnchange
}) {
  return (
    <Steps progressDot={progressDot} direction={direction} current={current} onChange={handleOnchange}>
      {renderSteps &&
        renderSteps.map((step, index) => {
          const { title, component } = step;
          return (
            <Step
              key={index}
              title={title}
              description={current === index ? component : null}
            />
          );
        })}
    </Steps>
  );
}
