import React, { useEffect, useState } from 'react';
import "./NavMenuSubscriptionButton.scss";
import { getCurrentPlanData } from '../../../utilities/subscriptionUtilities';
import StarIconSmall from '../../../../assets/svg/StarIconSmall';

const NavMenuSubscriptionButton = (props) => {

    const {componentData} = props;

    const [isTrial, setIsTrial] = useState(false);

    useEffect(() => {

        loadCurrentPlanData();

        return () => {
            if(!componentData.fromReact){
                props.unMountTheComponent(false);
            }
        }
    },[])

    const loadCurrentPlanData = async () => {
        if(componentData.fromReact){
            setIsTrial(componentData.isTrial);
        }else{
            const currentPlanResponse = await getCurrentPlanData(componentData.apiToken);
            setIsTrial(currentPlanResponse.is_trial);
        }
    }

    if(componentData.fromReact){
        return <a className="dropdown-item menu-dropdown-link svg-margin-right-10" href="/my/subscriptions/">
                <StarIconSmall/>
                {(isTrial) ? " Upgrade plan" : " Plans"}
               </a>
    }

    return (
        <React.Fragment>
            <li page="settings">
                <a className="menu-dropdown-link" href="/my/subscriptions/">
                    <StarIconSmall/>
                    {(isTrial) ? " Upgrade plan" : " Plans"}
                </a>
            </li>
        </React.Fragment>
    )

}

export default NavMenuSubscriptionButton;