import React, { Component } from "react";
import NoteIcon from '../../../../assets/svg/NoteIcon.js'
import InfoIcon from '../../../../assets/svg/InfoIcon';

// Importing Components
import _ from "lodash";
import Select from '../../../components/common/Select';
import DropDownComponent from '../../../components/common/dropDownComponent';
// import Button from '../common/Button';
import { trackSegementEvents } from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';

//css
import "../commonStyle.scss";
import "./contactTracking.scss";
// import { Button } from "react-bootstrap";
import Button from '../common/Button';

const OptionItem = (props) => (
    <option value={props.optionValue} defaultValue={props.selectOption ? true : null}>{props.optionText}</option>
)

const AddToDomainButton = () => {
    return (
        <Button
            handleClick={(e) => {
                window.open('https://callhub.io/', '_blank').focus();
                e.stopPropagation();
            }}
            name={"Add domain"} 
            type={"primary"}
            customStyles={{height: 30, fontSize: 14, width: '100%'}}
        />
    )
}

class ContactTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsList: this.props.domainList,
            customShortURLPreview : "",
            selectedDomain : "",
            selectedDomainId : "",
            backHalf : "",
            optionalPart : "",
            domainSelectOptions: {}
        }
    }
    handleDropDownChange = (event) => {
        this.setState({
            selectedDomainId: event.target.value,
            selectedDomain : event.target.selectedOptions[0].innerHTML,
        }, () => {
            this.props.genrateBackHalf(this.state.selectedDomainId)
            this.createPreviewShortenURL();
        })
    }
    handleInputChange = (event) => {
        let error = ""
        if(event.target.value.indexOf(" ") !== -1){
            return;
        }
        if(event.target.value.length < 30){
            this.setState({optionalPart : event.target.value}, () => {
                this.createPreviewShortenURL();
            })
            error = "";
        }else{
            error = "Back-half must not be greater than 30 characters.";
        }
        
        this.setState({backhalf_error_msg : error})
    }
    handleTitle = (event) => {
        let error = "";
        if(event.target.value.length > 200){
            error = 'Title must not be greater than 200 characters.';
        }else{
            this.props.getUpdatedMetaTitle(event.target.value);
            error = "";
        }
        this.setState({title_error_msg : error})
    }
    createPreviewShortenURL = async() => {
        const { backHalf, selectedDomain, selectedDomainId, optionalPart, optionsList } = this.state;
        
        let customShortURL = (selectedDomain || optionsList[0].domain) + (optionalPart !== '' ? "/" + optionalPart : "") + "/" + this.props.backHalf;
        this.setState({customShortURLPreview : customShortURL});
        this.props.getCustomShortURL({
            'fixedPrefix' : (optionalPart), 
            "customShortURL": customShortURL, 
            "domainID" : selectedDomainId,
            "selectedDomain": selectedDomain,
        });
    }
    
    updateSelectedDomain = (event) => {
        if(event.value === "add_domain_btn"){
            return;
        }
        this.setState({
            domainSelectOptions : {...this.state.domainSelectOptions, selectedOption : event }
        });

        this.setState({
            selectedDomainId: event.value,
            selectedDomain : event.label,
        }, () => {
            this.props.genrateBackHalf(this.state.selectedDomainId);
            this.createPreviewShortenURL();
        });
    }

    componentWillUnmount(){
        let urlType = "";
        if(this.state.optionalPart === ""){
            urlType = 'default-back-half-yes-tracking'
        }else{
            urlType = 'custom-back-half-yes-tracking'
        }
        this.props.setSegmentUrlType(urlType);
    }

    componentDidMount() {     
        // segment for url shortners visited preview page
        trackSegementEvents('visited-url-short-link-preview-page',{
            'product_category': this.props.eventData.productCategory,
            'url_type': 'default-back-half-yes-tracking',
            'customer_type' : this.props.segmentCustomerType,
            'industry': this.props.eventData.industry
        });  

        // this.setState({optionalPart: this.props.fixedPrefix}, () => {
        //     this.createPreviewShortenURL();
        // });

        let domainsOptionList = [];
        this.props.domainList.map(domainItem => {
            domainsOptionList.push({value : domainItem.pk_str, label : domainItem.domain});
        });
        //Add domain button removed
        //domainsOptionList.push({label: <AddToDomainButton/>, value: "add_domain_btn"});
        let domainSelectOptions = {};
        domainSelectOptions.options = domainsOptionList;
        domainSelectOptions.selectedOption = (domainsOptionList.length > 0) ? domainsOptionList[0] : {label: "", value: ""};
        
        this.setState({domainSelectOptions: domainSelectOptions, 
                       selectedDomain: domainSelectOptions.selectedOption.label,
                       selectedDomainId:  domainSelectOptions.selectedOption.value,
                       optionalPart: this.props.fixedPrefix}, () => {
                           this.createPreviewShortenURL();
                       });
    }
    componentDidUpdate(prevProps) {
        if (this.props.backHalf !== prevProps.backHalf) {
            this.createPreviewShortenURL();
        }
    }

    domainSelectCustomStyles = {
        option: (provided, state) => ({
        ...provided,
        fontSize: "16px",
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: "3px 0 0 3px",
          display: "flex",
          fontSize: 16,
          minHeight: 40,
        })
      };

    render() {
        const { longURL, metaTitle, backHalf, contactTrackingErrorMessage, contactTrackingTitleErrorMessage } = this.props;
        const { optionsList, customShortURLPreview, title_error_msg, backhalf_error_msg, optionalPart } = this.state;
        return (
            <div style={{'paddingBottom': '100px'}}>			
                <div className="originalURL">
                    <div>Long URL</div>
                    <div>{longURL}</div>
                </div>
                <div className="urlTitle">
                    <label>Title</label>
                    <input onChange={this.handleTitle} className={(title_error_msg || contactTrackingTitleErrorMessage) ? 'errorBorder' : ''} type="text" value={metaTitle}/>
                    <div className="errorColor">{title_error_msg || contactTrackingTitleErrorMessage}</div> 
                </div>
    
                <div className = "contructURL">
                    <div>
                        <label>Domain</label>
                        <Select
                            {...this.state.domainSelectOptions}
                            name="domains"
                            customStyle={this.domainSelectCustomStyles}
                            isClearable = {false}
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            isMulti={false}
                            changed={(event) => {this.updateSelectedDomain(event)}}
                            components={{
                                IndicatorSeparator: () => {
                                    return null;
                                },
                                DropdownIndicator: () =>{
                                    return <DropDownComponent/>
                                }
                            }}
                        />
                    </div>
                    <div>
                        <label>Customize backhalf</label>
                        <input type="text" className={(backhalf_error_msg || contactTrackingErrorMessage) && 'errorBorder'} onChange={this.handleInputChange} placeholder="Optional" value={optionalPart}/>
                        <span>{"/" + backHalf}</span>
                        <div className="contact-tracking-info-tooltip">
                            <span className='info-icon'>
                                <InfoIcon size='16px'/>
                            </span>
                            <span className="tooltiptext">The URL's tail component is unique for each contact, allowing you to track clicks and generate segmented lists for follow-ups.</span>
                        </div>
                    </div>
                    <div className="errorColor">{backhalf_error_msg || contactTrackingErrorMessage}</div>
                </div>
                <div className="shortURLPreview">Shortened URL preview: <span>{customShortURLPreview}</span></div>
                <div className="note">
                    <div>
                        <NoteIcon size={16}/>
                        <span>NOTE</span>
                    </div>
                    <div className="secondaryParagraph">The above shortened URL is only for illustration purpose. A unique link will be assigned to each contact in the contact list. In these URLs, only last characters after the slash (/) will be modified.</div>
                </div>
            </div>
        );
    }
}

export default ContactTracking;

