import React, { useEffect, useState, useRef } from "react";
import { CALL_SENTIMENTS_HEADING, CALL_SENTIMENTS_SUBHEADING, GENERATE_CAMPAIGN_DISCRIPTION, GENERATE_CAMPAIGN_GOAL, SAVE_GOAL, ENABLE_RECORDING_TITLE, ENABLE_RECORDING_SUBHEADING, ENABLE_RECORDING_BUTTON, CALL_SENTIMENT_POPOVER, SMART_INSIGHT_BETA_POPOVER } from "../SmartInsightConstants/SmartInsightConstants";
import { Switch, Button, Input, Tooltip, Modal, Spin, Popover, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {SmartInsightStarIcon} from '../../../../../assets/svg/SmartInsightStarIcon';
import {SmartInsightStarLineIcon} from '../../../../../assets/svg/SmartInsightStartLineIcon';
import {RefreshLineIcon} from '../../../../../assets/svg/RefreshLineIcon';
import { EditLineIcon } from "../../../../../assets/svg/EditLineIcon";
import {goalCreation} from '../../CcAiPowerd/apis/CcAiPowedApi';
import { QuestionCircleOutlined } from "@ant-design/icons";
import Lottie from "react-lottie";
import animationData from "../../../../../assets/lotties/StarAnimation.json";
import './CallSentimentCreation.scss';

const CallSentimentCreation = (props)=>{
    const {campaign_id, campaign_goal, sentiment_enabled, campaign_action, survey_id, is_campaign_live} = props;
    const [isCallSentimentChecked , setIsCallSentimentChecked] = useState(false);
    const [campaignGoal , setCampaignGoal] = useState('');
    const [isEditable ,setIsEditable] = useState(false);
    const [showInputField , setShowInputField] = useState(false);
    const [showSaveButton , setShowSaveButton] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const { TextArea } = Input;
    const isEditMode = (campaign_action == 'edit' ? true : false);
    const [modal, contextHolder] = Modal.useModal();
    const [messageApi, messageContextHolder] = message.useMessage();
    const sentimentErrorContainerRef = useRef(null);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong, please try again',
        });
    };
    
    const handleCallSentiment = ()=>{
        setIsCallSentimentChecked(prev=> !prev);
        if(!document.getElementById('id_recording').checked && !isCallSentimentChecked){
            modal.confirm({
                title: ENABLE_RECORDING_TITLE,
                icon: <ExclamationCircleOutlined />,
                content: ENABLE_RECORDING_SUBHEADING,
                okText: ENABLE_RECORDING_BUTTON,
                onOk:enableRecordCalls,
                onCancel: handleCancleButton,
                cancelText: 'Cancel',
                className: 'enable-profanity-modal'
            });
        }
    }

    const handleCancleButton = () =>{
        setIsCallSentimentChecked(false);
    }  

    const enableRecordCalls = () =>{
        document.getElementById("id_recording").checked = true;
    }

    const handleCampaignGoalEdit = (e)=>{
        let value = e.target.value;
        setCampaignGoal(value);
    }

    const enableEditGoal = () =>{
        setIsEditable(true);
        setShowSaveButton(true);
        sessionStorage.setItem(`${survey_id}_goal_saved`, false);
    }

    const generateCampaignGoal = async() =>{
        setShowLoader(true);
        let response = await goalCreation(survey_id, {regenerate_goal:true});
        if(response.status == 200){
            setCampaignGoal(response.data.goal);
            setShowInputField(true);
            localStorage.setItem(`${survey_id}_campaign_goal`, response.data.goal);
        }else{
            error();
        }
        setShowLoader(false);
        return;
    }

    const handleGoalGenerationButton = () =>{
        setShowAnimation(true);
        handleSegmentTrackEvent('si-click-generate-goal')
        setTimeout(()=>{
            generateCampaignGoal();
        },1000)
    }

    const saveGoal = () =>{
        setIsEditable(false);
        setShowSaveButton(false);
        localStorage.setItem(`${survey_id}_campaign_goal`, campaignGoal);
        sessionStorage.setItem(`${survey_id}_goal_saved`, true);
        messageApi.open({
            type: 'success',
            content: 'Goal saved',
        });
        handleSegmentTrackEvent('si-goal-submit')
    }

    useEffect(()=>{
        let local_data = localStorage.getItem(`${survey_id}_campaign_goal`);
        if(isEditMode && campaign_goal){
            setIsCallSentimentChecked(sentiment_enabled);
            setCampaignGoal(campaign_goal);
            localStorage.setItem(`${survey_id}_campaign_goal`, campaign_goal);
            setShowInputField(true);
        }else if(local_data){
            setIsCallSentimentChecked(true);
            setShowInputField(true);
            setCampaignGoal(local_data);
            enableRecordCalls();
        }
        sessionStorage.setItem(`${survey_id}_goal_saved`, true);
    },[])

    useEffect(()=>{
        sentimentErrorContainerRef
        if (sentimentErrorContainerRef.current) {
            sentimentErrorContainerRef.current.innerHTML = '';
        }
    },[isCallSentimentChecked ,isEditable])

    useEffect(()=>{
        if(isCallSentimentChecked){
            handleSegmentTrackEvent('si-sentiment-enabled')
        }
    },[isCallSentimentChecked])

    return(
        <>
            {contextHolder}
            {messageContextHolder}
            <div id='callSentimentCreationContainer'>
                <div className='call-sentiment-heading-section'>
                    <Switch id="callSentimentSwitch" checked={isCallSentimentChecked} onChange={handleCallSentiment} disabled={(isEditMode && is_campaign_live) ? true : false } />
                    <div className='call-sentiment-heading'>
                        <div>
                            {CALL_SENTIMENTS_HEADING}
                        </div>
                        <Popover placement="rightTop" arrow={false} content={CALL_SENTIMENT_POPOVER} trigger="click" overlayClassName="call-sentiment-popover">
                            <QuestionCircleOutlined />
                        </Popover>
                        <Popover placement="top" content={SMART_INSIGHT_BETA_POPOVER} overlayClassName="smart-insight-beta-popover">
                            <p className='smart-insight-beta-version'>Beta</p>
                        </Popover>
                    </div>
                </div>
                <div className="call-sentiment-subheading">{CALL_SENTIMENTS_SUBHEADING}</div>
                {isCallSentimentChecked &&
                    <>
                        {!showInputField &&
                            <div className="generate-campaign-goal">
                                {showAnimation ?
                                    <Lottie options={defaultOptions} height={24} width={22} />
                                    :
                                    <SmartInsightStarIcon size={18} />
                                }
                                <div className="generate-campaign-discription">{GENERATE_CAMPAIGN_DISCRIPTION}</div>
                                <Button type="primary" className="generate-campaign-button" onClick={handleGoalGenerationButton}>{GENERATE_CAMPAIGN_GOAL}</Button>
                            </div>
                        }
                        {showInputField &&
                            <>
                                <div className={isEditable ? "campaign-goal-input-section boder-focus" : "campaign-goal-input-section"}>
                                    <div className="campaign-goal-input-header">
                                        <SmartInsightStarLineIcon size={18} />
                                        <div className= {(isEditMode && is_campaign_live) ? "revise-edit-goal-section disabled" : 'revise-edit-goal-section'}>
                                            <Tooltip title="Generates new goal based on script" mouseEnterDelay={0.3}>
                                                <div className="revise-goal" onClick={generateCampaignGoal}>
                                                    <p>Revise Goal</p>
                                                    <RefreshLineIcon size={20} />
                                                </div>
                                            </Tooltip>
                                            <Tooltip title="Edit goal" mouseEnterDelay={0.3}>
                                                <EditLineIcon size={20} className="edit-goal-icon" onClick={enableEditGoal} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <TextArea 
                                        style={{ height: 86, resize: 'none' }}
                                        onChange={handleCampaignGoalEdit}
                                        value = {campaignGoal}
                                        placeholder="Type campaign goal"
                                        bordered={false}
                                        disabled ={!isEditable}
                                        id="campaignGoalTextArea"
                                    />
                                    {showLoader &&
                                        <div className="generating-goal-loader">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                                {showSaveButton &&
                                    <Button type="primary" className="save-goal-button" onClick={saveGoal}>{SAVE_GOAL}</Button>                                
                                }
                            </>
                        }
                        <div id='call_sentiment_errors' ref={sentimentErrorContainerRef}></div>
                    </>
                }
                
            </div>
        </>
        
    )
}

export default CallSentimentCreation