import React, { useState, Suspense } from 'react';
import ConfigProvider from "antd/es/config-provider";
import Button from "antd/es/button"
import notification from "antd/es/notification"
import { SettingOutlined } from '@ant-design/icons';
import './AutoUnrentModal/autoUnrentModal.scss';
const AutoUnrentModal = React.lazy(()=> import('./AutoUnrentModal/autoUnrentModal'))

export const AutoUnrent = (props) => {
    const {brand_name} = props?.componentData
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const openNotification = (message, description, type) => {
        api[type]({
            message: message,
            description: description,
            style: {
                width: 424,
            },
            className: 'auto-unrent-success-notification'
        });
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#F06514',
                    borderRadius: 4,
                },
            }}
            wave={{ disabled: true }}
        >
            {contextHolder}
            <Button className="auto-unrent-btn" onClick={showModal} icon={<SettingOutlined />}>
                Auto Un-rent
            </Button>
            {isModalVisible && (
                <Suspense fallback={<></>}>
                    <AutoUnrentModal
                        isModalVisible={isModalVisible}
                        handleCancel={handleCancel}
                        openNotification={openNotification}
                        brand_name={brand_name}
                    />
                </Suspense>
            )}
        </ConfigProvider>
    );
};
