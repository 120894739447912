import React from 'react';

import './header.scss';

const Heading = ({icon, headingText, redirectLink, linkText, className , campaignId}) => {
    
    const handlingRedirect = () => {
        sessionStorage.setItem("campaingFilterData", JSON.stringify(campaignId)); 
    }
    return (
        <div className={`main-div ${className ? className : ''}`}>
            <div className="inner-div">
                <div className="icon-url-statistics">
                    <img src={icon} alt='icon-stats'/>
                </div>
                <div className="heading-url-stats">
                    {headingText}
                </div>
            </div>
            <div className="link-response">
                <a href={redirectLink} onClick={handlingRedirect}>{linkText}</a>
            </div>
        </div>
    )
}

export default Heading