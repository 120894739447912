export const campaignListUrl = () => {
    return '/url_shortener/available_campaign_list/';
}

export const searchFilterUrl = (limit, offset) => {
    return `/url_shortener/search_filter/?limit=${limit}&offset=${offset}`;
}

export const getCampaignInfoUrl = () => {
    return '/url_shortener/campaign_info/';
}

export const getUrls = (campaignId) => {
    return `/url_shortener/url_list/${campaignId}`;
}

export const getPowerCampaignUrls = (campaignId) => {
    return `/url_shortener/cc_url_list/${campaignId}`;
}