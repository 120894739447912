import React, { useState } from "react";
import CloseIcon from "../../../assets/svg/CloseIcon";
import { CloseCircleFilled } from "@ant-design/icons";
import "./ErrorNotification.scss";
import workflowService from "../../WorkFlowV2/services/WorkflowService";

const ErrorNotification = ({ componentData, unMountTheComponent=null }) => {
  const [showNotification, setShowNotification] = useState(true);

  const removeUnpublishMessage = async () => {
    const response = await workflowService.postUnpublishedMessageRemoval();
  }

  const handleClose = () => {
    setShowNotification(false);
    if(unMountTheComponent){
      unMountTheComponent();
    }
    if(componentData.location === 'wfPage'){
      removeUnpublishMessage();
    }
    else if(componentData.location === 'smsBroadcastEdit'){
      sessionStorage.removeItem(`unpublishMessage_${componentData.campaignId}`);
    }
    else if(componentData.location === 'smsBroadcastDelete'){
      componentData.onClose();
    }
  };
  
  return (
    <>
      {showNotification ? (
        <div className="notification-container">
          <CloseCircleFilled style={{ color: "#E64E40", fontSize: "16px" }} />
          <p>{componentData.message}</p>
          <CloseIcon
            size={14}
            color={"rgba(0, 0, 0, 0.45)"}
            onClick={handleClose}
            className="close-icon"
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ErrorNotification;
