import React from 'react'

const CallCenterKycIcon = props => (
    <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 443.78 443.78"
    width="30px"
    height="30px"
    {...props}
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;}.cls-2{clip-path:url(#clippath);}.cls-3{fill:#f6f7f7;}.cls-4{fill:#f8bf6c;}.cls-5{fill:#67cc8e;}.cls-6{fill:#eef0f2;}.cls-7{fill:#fff;}.cls-8{fill:#e3b196;}.cls-9{fill:#b2b5b9;}.cls-10{fill:#c2f0d7;}.cls-11{fill:#d3d3d3;}.cls-12{fill:#405261;}.cls-13{fill:#56bf85;}.cls-14{fill:#78b0f8;}.cls-15{clip-path:url(#clippath-1);}"
        }
      </style>
      <clipPath id="clippath">
        <circle className="cls-1" cx={221.89} cy={221.89} r={221.89} />
      </clipPath>
      <clipPath id="clippath-1">
        <path
          className="cls-1"
          d="M106.99,106.76l28.54-4.05c-.12,1.11-.16,2.25,0,3.41l.63,4.41c1.06,7.49,7.95,12.66,15.44,11.6l116.25-16.49c7.49-1.06,12.66-7.95,11.6-15.44l-.63-4.41c-.16-1.16-.52-2.24-.94-3.27l28.54-4.05c15.81-2.24,30.35,8.68,32.59,24.49l69.05,486.89c2.24,15.81-8.68,30.35-24.49,32.59l-199.43,28.28c-15.81,2.24-30.35-8.68-32.59-24.49L82.49,139.35c-2.24-15.81,8.68-30.35,24.49-32.59h.01Z"
        />
      </clipPath>
    </defs>
    <g id="Layer_2-2">
      <g id="Layer_1-2">
        <g>
          <circle className="cls-10" cx={221.89} cy={221.89} r={221.89} />
          <g className="cls-2">
            <g>
              <g>
                <path
                  className="cls-7"
                  d="M106.99,106.76l28.54-4.05c-.12,1.11-.16,2.25,0,3.41l.63,4.41c1.06,7.49,7.95,12.66,15.44,11.6l116.25-16.49c7.49-1.06,12.66-7.95,11.6-15.44l-.63-4.41c-.16-1.16-.52-2.24-.94-3.27l28.54-4.05c15.81-2.24,30.35,8.68,32.59,24.49l69.05,486.89c2.24,15.81-8.68,30.35-24.49,32.59l-199.43,28.28c-15.81,2.24-30.35-8.68-32.59-24.49L82.49,139.35c-2.24-15.81,8.68-30.35,24.49-32.59h.01Z"
                />
                <g className="cls-15">
                  <g>
                    <rect
                      className="cls-7"
                      x={115.74}
                      y={87}
                      width={260.36}
                      height={563.78}
                      transform="translate(-49.35 38.18) rotate(-8.07)"
                    />
                    <g>
                      <rect
                        className="cls-6"
                        x={81.73}
                        y={112.62}
                        width={266.23}
                        height={66.89}
                        transform="translate(-18.38 31.61) rotate(-8.07)"
                      />
                      <path
                        className="cls-3"
                        d="M87.55,196.48l263.59-37.37,88.13,621.53c.27,1.89-1.05,3.65-2.95,3.92l-256.72,36.4c-1.89,.27-3.65-1.05-3.92-2.95L87.55,196.48h0Z"
                      />
                      <g>
                        <ellipse
                          className="cls-9"
                          cx={117.2}
                          cy={164.13}
                          rx={14.92}
                          ry={15.33}
                          transform="translate(-21.88 18.08) rotate(-8.07)"
                        />
                        <rect
                          className="cls-9"
                          x={138.36}
                          y={141.43}
                          width={80.02}
                          height={9.75}
                          rx={2.46}
                          ry={2.46}
                          transform="translate(-18.77 26.49) rotate(-8.07)"
                        />
                        <rect
                          className="cls-9"
                          x={140.84}
                          y={159.81}
                          width={93.59}
                          height={6.97}
                          rx={1.76}
                          ry={1.76}
                          transform="translate(-21.07 27.96) rotate(-8.07)"
                        />
                      </g>
                      <g>
                        <ellipse
                          className="cls-14"
                          cx={125.85}
                          cy={225.06}
                          rx={14.92}
                          ry={15.33}
                          transform="translate(-30.35 19.9) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={147.01}
                          y={202.37}
                          width={80.02}
                          height={9.75}
                          rx={2.46}
                          ry={2.46}
                          transform="translate(-27.24 28.31) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={149.48}
                          y={220.75}
                          width={93.59}
                          height={6.97}
                          rx={1.76}
                          ry={1.76}
                          transform="translate(-29.54 29.77) rotate(-8.07)"
                        />
                      </g>
                      <g>
                        <ellipse
                          className="cls-5"
                          cx={134.49}
                          cy={286.01}
                          rx={14.92}
                          ry={15.33}
                          transform="translate(-38.82 21.71) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={155.65}
                          y={263.31}
                          width={80.02}
                          height={9.75}
                          rx={2.46}
                          ry={2.46}
                          transform="translate(-35.71 30.12) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={158.13}
                          y={281.69}
                          width={93.59}
                          height={6.97}
                          rx={1.76}
                          ry={1.76}
                          transform="translate(-38 31.59) rotate(-8.07)"
                        />
                      </g>
                      <g>
                        <ellipse
                          className="cls-4"
                          cx={143.13}
                          cy={346.95}
                          rx={14.92}
                          ry={15.33}
                          transform="translate(-47.29 23.53) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={164.29}
                          y={324.25}
                          width={80.02}
                          height={9.75}
                          rx={2.46}
                          ry={2.46}
                          transform="translate(-44.18 31.94) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={166.76}
                          y={342.63}
                          width={93.59}
                          height={6.97}
                          rx={1.76}
                          ry={1.76}
                          transform="translate(-46.47 33.41) rotate(-8.07)"
                        />
                      </g>
                      <g>
                        <ellipse
                          className="cls-8"
                          cx={151.77}
                          cy={407.89}
                          rx={14.92}
                          ry={15.33}
                          transform="translate(-55.76 25.34) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={172.93}
                          y={385.19}
                          width={80.02}
                          height={9.75}
                          rx={2.46}
                          ry={2.46}
                          transform="translate(-52.65 33.76) rotate(-8.07)"
                        />
                        <rect
                          className="cls-11"
                          x={175.4}
                          y={403.57}
                          width={93.59}
                          height={6.97}
                          rx={1.76}
                          ry={1.76}
                          transform="translate(-54.94 35.22) rotate(-8.07)"
                        />
                      </g>
                      <rect
                        className="cls-11"
                        x={181.57}
                        y={446.14}
                        width={80.02}
                        height={9.75}
                        rx={2.46}
                        ry={2.46}
                        transform="translate(-61.12 35.57) rotate(-8.07)"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <path
                className="cls-12"
                d="M108.33,93.51c-24.03,3.41-40.63,25.49-37.22,49.52l4.74,33.42-1.09,.15c-1.27,.18-2.16,1.36-1.98,2.63l2.74,19.3c.18,1.27,1.36,2.16,2.63,1.97l1.09-.15,2.4,16.94-1.09,.15c-1.27,.18-2.16,1.36-1.98,2.63l5.34,37.62c.18,1.27,1.36,2.16,2.63,1.97l1.09-.15,1.32,9.34-1.09,.15c-1.27,.18-2.16,1.36-1.98,2.63l5.34,37.62c.18,1.27,1.36,2.16,2.63,1.97l1.09-.15,44.64,314.76c3.41,24.03,25.49,40.63,49.52,37.22l193.11-27.39c24.03-3.41,40.64-25.49,37.24-49.52l-46.56-328.27c.93-.41,1.59-1.26,1.44-2.32l-8.64-60.95c-.15-1.06-1.03-1.69-2.03-1.83l-12.69-89.47c-3.41-24.03-25.51-40.62-49.54-37.21l-193.11,27.41h.01Zm-1.33,13.25l28.54-4.05c-.12,1.11-.16,2.25,0,3.41l.63,4.41c1.06,7.49,7.95,12.66,15.44,11.6l116.25-16.49c7.49-1.06,12.66-7.95,11.6-15.44l-.63-4.41c-.16-1.16-.52-2.24-.94-3.27l28.54-4.05c15.81-2.24,30.35,8.68,32.59,24.49l69.05,486.89c2.24,15.81-8.68,30.35-24.49,32.59l-199.43,28.28c-15.81,2.24-30.35-8.68-32.59-24.49l-46.94-330.96-22.12-155.94c-2.24-15.81,8.68-30.35,24.49-32.59h0v.02Z"
              />
              <g>
                <circle className="cls-13" cx={314.75} cy={191.49} r={18.34} />
                <path
                  className="cls-3"
                  d="M309.09,191.02c1.87,2.68,4.56,4.71,7.65,5.79l1.93-2.55c.24-.31,.63-.46,1.01-.39,1.19,.22,2.46,.25,3.72,.08,.56-.08,1.08,.32,1.16,.88l.49,3.56c.08,.56-.32,1.08-.88,1.16-9.57,1.33-18.41-5.35-19.74-14.93-.08-.56,.32-1.08,.88-1.16l3.57-.5c.56-.08,1.08,.32,1.16,.88,.18,1.27,.55,2.47,1.09,3.56,.16,.34,.14,.75-.11,1.08l-1.93,2.55h0Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CallCenterKycIcon