import React, { useState,useEffect } from "react";
import { Modal, Button, Typography, Select, ConfigProvider } from "antd";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import {getUsecaseNumbersOptions, postUsecaseNumberChange} from "../../utilities/complianceUtilities";
import notification from "antd/es/notification"

import './useCaseNumberSelectModal.scss'

const { Text } = Typography;

const UseCaseNumberSelectModal = ({componentData,unMountTheComponent}) => {
  const campaign_id = componentData?.campaign_id
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [useCase, setUseCase] = useState(null);
  const [number, setNumber] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [usecaseData,setUsecaseData] = useState({usecaseOptions:[],numbersOptions:{}})
  useEffect(() => {
    fetchOptions()
  }, []);

  const fetchOptions = async () => {
    const response = await getUsecaseNumbersOptions();
    const usecaseOptions = response.data.usecases.map((usecase) => ({
      label: usecase.label,
      value: usecase.id,
    }));

    const numbersOptions = {};

    for (const [usecaseId, phoneNumbers] of Object.entries(response.data.tendlc_numbers)) {
      numbersOptions[usecaseId] = phoneNumbers.map((phoneNumber) => ({
        label: phoneNumber,
        value: phoneNumber,
      }));
    }

    setUsecaseData({ usecaseOptions, numbersOptions });
  };

  const handleNumberChange = async () =>{
    const payload = {usecase_id:useCase, phone_number:number,campaign_id:campaign_id, is_cc_campaign:componentData?.is_cc_campaign}
    try {
      const response = await postUsecaseNumberChange(payload)
      if (response?.status == 'ok') {
        openNotification('The Caller ID has been changed to a 10 DLC number.', null, 'success')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else {
        openNotification('Failed to change the Caller ID', null, 'error')
      }
    }catch(e){
      console.log(e)
      openNotification('Failed to change the Caller ID', null, 'error')
    }

  }

  const handleOk = async () => {
    await handleNumberChange();
    setIsModalVisible(false)
    setTimeout(()=>{unMountTheComponent()},3000)
  };

  const handleCancel = () => {
    unMountTheComponent()
  };

  const handleNumberSelect =(value)=>{
    console.log(value)
    setNumber(value)
  }
  const handleUseCaseSelect =(value)=>{
    console.log(value)
    setUseCase(value)
    setNumber(null)
  }
  const openNotification = (message, description, type) => {
      api[type]({
          message: message,
          className: 'custom-success-notification'
      });
  };
  const dropdownRender = (menu, isUsecase) => {
    return (<div>
      {menu}
      <div style={{padding: "8px", textAlign: "center"}}>
        <a href={isUsecase ? "/brand/" : "/number/search/10dlc/"} className="option-link-to-10dlc">
          <PlusOutlined/> {isUsecase ? "Apply for use case" : "Rent a number"}
        </a>
      </div>
    </div>)
  }


  return (
      <>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#F06514',
            },
          }}
        >
          {contextHolder}
          <Modal
            title={null}
            open={isModalVisible}
            onCancel={handleCancel}
            onOk={handleOk}
            centered
            okText="Resume campaign"
            cancelText="Dismiss"
            className="usecase-number-modal"
            width={690}
            closable={false}
          >
            <div className="usecase-number-modal-header">
              <ExclamationCircleFilled style={{ marginRight: 8,fontSize:28 }} />
              <span className="usecase-number-title">Your campaign has been stopped</span>
            </div>
            <div className="usecase-number-body">
              <div className="usecase-number-description">
                Unregistered numbers are no longer supported by texting campaigns. Please select a 10DLC use case and number to continue campaigning.{" "}
                <a href="https://support.callhub.io/" className="usecase-number-learnmore">Learn more</a>
              </div>
              <div className="tendlc-select-container">
                <div className="tendlc-select-box">
                  <Text>Choose use case</Text>
                  <Select
                    placeholder="Choose use case"
                    value={useCase}
                    onChange={handleUseCaseSelect}
                    style={{ width: 215, marginTop: 8 }}
                    options={usecaseData?.usecaseOptions}
                    dropdownRender={
                      usecaseData?.usecaseOptions?.length > 0
                        ? (menu) => dropdownRender(menu, true)
                        : null
                    }
                  />
                </div>
                <div className="tendlc-select-box">
                  <Text>Choose number</Text>
                  <Select
                    placeholder="Choose number"
                    value={number}
                    onChange={handleNumberSelect}
                    style={{ width: 215, marginTop: 8 }}
                    options={usecaseData?.numbersOptions[useCase]}
                    dropdownRender={
                      usecaseData?.usecaseOptions?.length > 0
                        ? (menu) => dropdownRender(menu, false)
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
        </ConfigProvider>
      </>
  );
};

export default UseCaseNumberSelectModal;
