import React, { useState, useEffect } from 'react';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import NudgeDetailsBox from './NudgeDetailsBox';
import {CustomerKycForm} from "./TrailGiftContainer";
import { NewKycForm } from './NewKycForm';
import {NotMobileFriendly} from './NotMobileFriendly';
import PostTrialAllPlansContainer from "./PostTrialAllPlansContainer";
import { checkKycStatus, getCurrentPlanData, postSaveFreeTrialGift } from "../../../utilities/subscriptionUtilities";
import "./TrialNudgeContainer.scss";
import NudgeOverNav from './NudgeOverNav';
import {getScheduledEventData, postCustomerKYCSheduledEvent, postScheduledEventData} from '../../../../src/utilities/trainingWidgetUtilities'
import moment from 'moment';
import DetectOutSideClickingHandler from "../../../components/ReactDetectOutSideClcker/ReactDetectOutsideCliking"
import { message } from 'antd';

const isCalendlyScheduledEvent = (e) => {
    return e.data.event &&
           e.data.event.indexOf('calendly') === 0 &&
           e.data.event === 'calendly.event_scheduled'
  }

let KycAllowedCountries = {
    "US":1,
    "GB":2,
    "AU":3,
    "CA":4,
    "FR":5,
}
function TrialNudgeContainer(props) {
    const [showNudgeOverNav, setshowNudgeOverNav] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [showGiftModal, setShowGiftModal] = useState(false);
    const [showAllPlansModal, setShowAllPlansModal] = useState(false);
    const [upgradeButtonClicked, setUpgradeButtonClicked] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [isPostTrial, setIsPostTrial] = useState(false);
    const [nudgeDetails, setNudgeDetails] = useState({
        currentPlan: '',
        daysLeft: '',
    });
    const [currentPlan, setCurrentPlan] = useState({});
    const [tokenForApi, setTokenForApi] = useState("");
    const [isEmailVerified, setIsEmailVerified] = useState(true);
    const [productTrainingDate, setProductTrainingDate] = useState("");
    const [productTrainingTime, setProductTrainingTime] = useState("");
    const [localTimeZone, setLocalTimeZone] = useState("");
    const [isEventTimePassed, setIsEventTimePassed] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isDataloaded, setIsDataLoaded] = useState(false);
    const [customerKycInfo,setCustomerKycInfo] = useState({})
    const [showKycModal,setShowKycModal] = useState(false)
    const [customerHasKyc,setCustomerHasKyc] = useState(false)
    const [customerSkippedKyc,setCustomerSkippedKyc] = useState(true)
    const [isMagicLinkSent, SetIsMagicLinkSent] = useState(false)
    const [IsBrandCallhub, setIsBrandCallhub] = useState(false);
    const isRegularLogin = props?.componentData?.isRegularLogin ?? isRegularUserLoggedIn;

    useEffect(() => {
        getNudgeDetails();
        getScheduledEventUri();
        validateCustomerKyc()
        getUserBrand()
        window.addEventListener(
            'message',
            function(e) {
              if (isCalendlyScheduledEvent(e)) {
                console.log(e.data.payload.event.uri);
                const eventData = {
                  calendly_uri: e.data.payload.event.uri
                }
                postScheduledEventUri(eventData)
                getScheduledEventUri();
              }
            }
        );
    }, [])


    useEffect(()=>{
        if(showGiftModal){
            handlePostSaveFreeTrialGift()
        }
    },[showGiftModal])
    
    function handleShowNudgeOverNav(visibility){
        setshowNudgeOverNav(visibility)
    }
    function handleShowKycModal(visibility){
        setShowKycModal(visibility)
    }
    function handleCustomerHasKyc(data){
        setCustomerHasKyc(data)
    }
    function handleCustomerSkippedKyc(data){
        setCustomerSkippedKyc(data)
    }
    const handlePostSaveFreeTrialGift = async () => {
       
        await postSaveFreeTrialGift(tokenForApi);
    }

    const getUserBrand = () => {
        const isCallhubBrand = props?.componentData?.isBrandCallHub ?? isBrandCallHub
        setIsBrandCallhub(isCallhubBrand)
    }

    const getScheduledEventUri = async () => {
        let apiToken = ""
        if(props.componentData && props.componentData.token) {
            apiToken = props.componentData.token;
        }
        const response = await getScheduledEventData(apiToken);

        if (response.hasOwnProperty("error")) {
            setIsError(true);
            return
        }

        if (response.start_time) {
            const zone_name =  moment.tz.guess();
            const timezone = moment.tz(zone_name).zoneAbbr() 

            let utcDate = moment.utc(response.start_time).toDate();
            let localDate = moment(utcDate).local().format("ddd, MMM DD, YYYY, hh:mm A")
            const scheduled_time = moment(utcDate).local().format();
            localDate = localDate.split(",")
            const date = localDate[0] + "," + localDate[1] + "," + localDate[2];
            const time = localDate[3]

            const current_time = moment().local().format()


            const timeCheck = moment(current_time).isAfter(scheduled_time);
            setIsEventTimePassed(timeCheck)

            setLocalTimeZone(timezone);
            setProductTrainingDate(date);
            setProductTrainingTime(time);
        }

        setIsDataLoaded(true);
    }

    const postScheduledEventUri = async (EventUri) => {
        let apiToken = "";
        if(props.componentData && props.componentData.token) {
            apiToken = props.componentData.token;
        }
        const response = await postScheduledEventData(EventUri, apiToken)
        if (response.hasOwnProperty("error")) {
            setIsError(true);
            return
        }
        setTimeout(async()=>{
           let res = await postCustomerKYCSheduledEvent(apiToken);
           const canAddToDataLayer = props?.componentData?.isBrandCallHub || isBrandCallHub
           if(canAddToDataLayer && window.dataLayer){
            window.dataLayer.push({event:'kyc-product-training-booked', 'product_training': 'booked'})
           }
        },1000)
        getScheduledEventUri();
    }
    
    const validateCustomerKyc = async () => {
      
       let res = await checkKycStatus()
       if(!res.error){
           if(res.not_eligible){
            setCustomerKycInfo({customer_got_credit:res.customer_got_credit})
            SetIsMagicLinkSent(res.compatibility_magicLink_sent)
            setCustomerHasKyc(true)
            setCustomerSkippedKyc(true)
           }
           else{
            setCustomerKycInfo(res)
            setCustomerHasKyc(res.customer_has_KYC)
            setCustomerSkippedKyc(res.customer_skipped_kyc)
            setshowNudgeOverNav()
           }
       }
       
    }

    const getNudgeDetails = async () => {
        let apiToken = "";
        if(props.componentData && props.componentData.token) {
            apiToken = props.componentData.token;
        }
        setTokenForApi(apiToken);
        const currentPlanData = await getCurrentPlanData(apiToken);
        if(currentPlanData.error) {
            console.log(currentPlanData.error.detail);
            setIsTrial(false);
            return;
        }
       
        setCurrentPlan(currentPlanData);
        setIsEmailVerified(currentPlanData.is_email_verified);
        let daysLeft = currentPlanData.remaining_days;
        if(!currentPlanData.is_trial && !currentPlanData.show_all_plans_modal) {
            setIsTrial(currentPlanData.is_trial);
            return;
        }
        if((daysLeft <= 0 && currentPlanData.is_trial) || currentPlanData.show_all_plans_modal) {
            setIsPostTrial(true);
            setShowGiftModal(false);
            setshowNudgeOverNav(false);
            sessionStorage.setItem("post_trail", true);
            window.location.pathname === "/payment/" ? setShowAllPlansModal(false) : setShowAllPlansModal(true);
        }else {
            setIsPostTrial(false);
            if(!currentPlanData.show_gift_popup) {
                setshowNudgeOverNav(true);
                setShowGiftModal(false);
            }else {
                setshowNudgeOverNav(false);
                setShowGiftModal(true);
            }
        }
        setIsTrial(currentPlanData.is_trial);
        setNudgeDetails({
            ...nudgeDetails, 
            currentPlanName: currentPlanData.product.display_name, 
            daysLeft,
            isAllPlansPostTrial: currentPlanData.show_all_plans_modal
        });
    }

    const canShowAllPlansModalForEmailNotVerified = () => {
        const isBillingPage = ( location.pathname !== '/billing/recharge/' && location.pathname !== '/billing/recharge')? false : true;
        return (!isEmailVerified && isBillingPage) ? false : true;
    }
    if(isTrial || currentPlan.show_all_plans_modal || (!customerHasKyc)) {
        return (
            <div className="trial-nudge-container">
                {
                     <NudgeOverNav
                        // customerKycInfo={customerKycInfo}
                        isTrial={isTrial}
                        setShowContent={setShowContent}
                        showContent={showContent}
                        handleShowKycModal={handleShowKycModal}
                        setShowAllPlansModal={setShowAllPlansModal}
                        setUpgradeButtonClicked={setUpgradeButtonClicked}
                        nudgeDetails={nudgeDetails}
                        analytics_key={props.componentData && props.componentData.analytics_key 
                                        ? props.componentData.analytics_key 
                                        : analytics_key}
                        productTrainingDate={productTrainingDate}
                        productTrainingTime={productTrainingTime}
                        localTimeZone={localTimeZone}
                        isEventTimePassed={isEventTimePassed}
                        isError={isError}
                        isDataloaded={isDataloaded}
                        isRenderingOnReact={props.componentData ? props.componentData.token ? false : true : true}
                        customerHasKyc={customerHasKyc}
                        customerSkippedKyc = {customerSkippedKyc}
                        handleCustomerHasKyc={handleCustomerHasKyc}
                        handleCustomerSkippedKyc={handleCustomerSkippedKyc}
                        country = {props.componentData?.country || props.user_details?.country_code}
                    />
                }
                {
                    showContent && <DetectOutSideClickingHandler onOutsideClickFn={() => setShowContent(false)}><NudgeDetailsBox productTrainingDate={productTrainingDate} productTrainingTime={productTrainingTime} localTimeZone={localTimeZone} isError={isError}/></DetectOutSideClickingHandler> 
                }
                
                {
                    (!customerSkippedKyc && isRegularLogin) && 
                    <NewKycForm 
                        tokenForApi={tokenForApi}
                        customerKycInfo={customerKycInfo}
                        user_details={props.componentData?.user_details || props.user_details}
                        firstName = {props.componentData?.firstName ?? user_details?.first_name}
                        country = {props.componentData?.country ?? user_details?.country_code ?? 'US'}
                        fullName={props.componentData?.full_name ?? user_details?.full_name ?? ''}
                        organisation={props.componentData?.organisation ?? user_details?.organisation ?? -1}
                        removeModalFormDOM = {props.componentData?.removeModalFormDOM}
                        handleCustomerHasKyc={handleCustomerHasKyc}
                        handleCustomerSkippedKyc={handleCustomerSkippedKyc}
                        handleShowNudgeOverNav={handleShowNudgeOverNav}
                        isTrial={isTrial}
                        isBrandCallHub={IsBrandCallhub}
                        getBrandName={props.componentData?.getBrandName ?? getBrandName}
                    />
                }

                {
                    (showAllPlansModal && canShowAllPlansModalForEmailNotVerified())&& 
                    <PostTrialAllPlansContainer 
                        handleClose={() => {
                            setUpgradeButtonClicked(false)
                            setShowAllPlansModal(false)
                        }}
                        modalHeading={isPostTrial 
                            ? <span>Your Scale plan trial has ended, <span className="text-e67540">choose a plan</span> to continue</span> 
                            : "Choose a plan that fits your need"
                        }
                        showCloseButton={isPostTrial ? false : true}
                        currentPlanBillingPeriod={!_.isEmpty(currentPlan) && !isTrial ? currentPlan.product.billing_period : ""}
                        currentPlanData={currentPlan}
                        upgradeButtonClicked={upgradeButtonClicked}
                        trialData={{ isTrial: isTrial, daysLeft: nudgeDetails.daysLeft, isAllPlansPostTrial: nudgeDetails.isAllPlansPostTrial}}
                        apiToken={tokenForApi}
                        {...props}
                    />
                }
            </div>
        )
    }
    else if(customerHasKyc && customerSkippedKyc && !isMagicLinkSent){
        return (
        <NotMobileFriendly
            user_email={props.componentData?.user_email ?? user_details?.email}
        />
        )
    }
    else {
        return null;
    }
}

export default TrialNudgeContainer
