import React, { useEffect, useRef, useState } from "react";
import "./AddHoldMusicStyles.scss";
import AudioFileContainer from "./InboundAudioFileContainer";
import Alert from "antd/lib/alert"
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntAlert.scss";
// import { ButtonAnt } from "../common/CustomAntDesignComponents/ButtonAnt/ButtonAnt";
import Button from "antd/lib/button"
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntButton.scss";
import { useRecoilState } from "recoil";
import { audioPrimaryKeysState, holdTimeErrorState, inboundCallingDataState, inboundTextErrorState, isInboundDataPresent, mediaAudioFileState, stepperCurrentState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";
import InboundTabsContainer from "./InboundTabsContainer";

function AddHoldMusic({componentData, thankyouMessageTextError, setThankyouMessageTextError}) {
    const [current, setCurrent] = useRecoilState(stepperCurrentState);
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState);
    const [holdTimeError, setHoldTimeError] = useRecoilState(holdTimeErrorState);
    const [isDataPresent, setIsDataPresent] = useRecoilState(isInboundDataPresent)
    const [audioFiles, setAudioFiles] = useRecoilState(mediaAudioFileState)
    const inboundError = useRef("")
    const inboundDataPresent = useRef(true)
    inboundDataPresent.current = isDataPresent
    inboundError.current = holdTimeError;
    const [audioPrimaryKeys, setAudioPrimaryKeys] = useRecoilState(audioPrimaryKeysState);
    // const [inboundTextError, setInboundTextError] = useRecoilState(inboundTextErrorState);
    

    const handleHoldTime = (e) => {
        sessionStorage.removeItem('inboundCallingData')
        setInboundCallingData({...inboundCallingData, "maximumHoldSeconds" : e.target.value})
    }

    const holdMusicNote = () => {
        return (
            <div>Contacts who call outside operational hours will be called back during operational hours when an agent is available. 
                {componentData.inboundData.show_help_links ? <span><a href="https://support.callhub.io/hc/en-us/articles/5751373248537-What-happens-when-a-contact-calls-back-on-my-number-" target="_blank" style={{color:"#3690E6", textDecoration:"none", cursor:"pointer"}}> Learn more</a>.</span> : null}
                </div>
        )
    }

    const handleHoldTimeError = () => {
            if (inboundCallingData.maximumHoldSeconds < 30 ) {
                setHoldTimeError("Choose a minimum wait time of 30 seconds")
                inboundError.current = "Choose a minimum wait time of 30 seconds"
            } else if (inboundCallingData.maximumHoldSeconds > 300) {
                setHoldTimeError("Choose a wait time of 300 seconds or less")
                inboundError.current = "Choose a wait time of 300 seconds or less"
            } else {
                setHoldTimeError("")
                inboundError.current = ""
            }
    }

    const handleSaveData = () => {
        if((!inboundCallingData.thankyoumessageAudioSelected) && thankyouMessageTextError) {
            return
        }
        if((!inboundCallingData.welcomeAudioSelected) && inboundCallingData.welcomeText == "") {
            setCurrent(0);
            return
        }
        // handleHoldTimeError();
        setIsDataPresent(true)
        inboundDataPresent.current = true
        if((inboundError.current !== "") || (inboundDataPresent.current === false)) {
            return
        }else {
            const data = {
                welcomeText : inboundCallingData.welcomeAudioSelected === false ? inboundCallingData.welcomeText : "",
                welcomeAudio : inboundCallingData.welcomeAudioSelected === true ? inboundCallingData.welcomeAudio === "https://static.callhub.io/brand/callhub/samples/welcome_message_default_inbound_calling.mp3" ? "DEFAULT" : audioPrimaryKeys.welcomeAudioKey !== "" ? audioPrimaryKeys.welcomeAudioKey : inboundCallingData.welcomeAudio : "",
                holdMusic : inboundCallingData.holdMusic === "https://static.callhub.io/brand/callhub/samples/valse-gymnopedie-hold-music.mp3" ? "DEFAULT" : audioPrimaryKeys.holdAudioKey !== "" ? audioPrimaryKeys.holdAudioKey : inboundCallingData.holdMusic,
                maximumHoldSeconds : inboundCallingData.maximumHoldSeconds,
                ThankyouMessageText: inboundCallingData.thankyoumessageAudioSelected === false ? inboundCallingData.ThankyouMessageText : "",
                ThankyouMessageAudio: inboundCallingData.thankyoumessageAudioSelected === true ? inboundCallingData.ThankyouMessageAudio === "https://static.callhub.io/brand/callhub/samples/contact_exceeds_default_wait_time_default_inbound_calling.mp3" ? "DEFAULT" : audioPrimaryKeys.thankyouAudioKey !== "" ? audioPrimaryKeys.thankyouAudioKey : inboundCallingData.ThankyouMessageAudio : "",
                welcomeAudioSelected: inboundCallingData.welcomeAudioSelected,
                thankyoumessageAudioSelected: inboundCallingData.thankyoumessageAudioSelected,
                welcomeAudioFileName: inboundCallingData.welcomeAudioFileName,
                holdMusicAudioFileName: inboundCallingData.holdMusicAudioFileName,
                thnakyouMessageAudioFileName: inboundCallingData.thnakyouMessageAudioFileName,
                welcomeAudioUrl: inboundCallingData.welcomeAudioUrl,
                holdMusicUrl: inboundCallingData.holdMusicUrl,
                ThankyouMessageAudioUrl: inboundCallingData.ThankyouMessageAudioUrl,
                showInboundForm: true,
                OutsideCampaignSubscriberAllowed: inboundCallingData.OutsideCampaignSubscriberAllowed
            }
            sessionStorage.setItem('isInboundCallingDataPresent', JSON.stringify({isPresent: true}));
            sessionStorage.setItem('inboundCallingData', JSON.stringify(data));
            sessionStorage.setItem('mediaAudioFiles', JSON.stringify(audioFiles));

            setCurrent(current + 1)
           
            handleSegmentTrackEvent('cc-added-hold-music-inbound-calling')
        }
    }

    

   

    return (
        <div id="inbound-hold-music-wrapper">
            <div className="add-hold-music-heading ">Plays right after the greeting message while contact is waiting to get connected to an agent.</div>
            <AudioFileContainer componentData={componentData} componentType={"holdMusic"}/>
            <div className="hold-time-details-wrapper">
                <div className="hold-time-heading">Maximum hold time</div>
                <div className="hold-time-description-one">The maximum time an incoming caller will be put on hold.</div>
                <div>
                    <input onBlur={handleHoldTimeError} onChange={handleHoldTime} className={inboundError.current !== "" ? "inbound-call-hold-time minimum-hold-time-error" : "inbound-call-hold-time"} type="number" name="hold-time" value={inboundCallingData.maximumHoldSeconds}/> <span className="hold-time-seconds">seconds</span>
                </div>
                {holdTimeError !== "" && <div className="minimum-hold-time">{holdTimeError}</div>}
                <div className="hold-time-description">Once maximum hold time is reached, we will play a thank you message, hangup the call and call the contact back once an agent becomes available.</div>
                <div className="thank-message-heading">Add thank you message:</div>
                {/* <audio ref={audioFile} src="" controls style={{width:"100%", marginTop:"4px"}}/> */}
                <InboundTabsContainer componentData={componentData} componentType={"thankyouMessage"} setThankyouMessageTextError={setThankyouMessageTextError}/>
                
            </div>
            <Alert message={holdMusicNote()} type="info" showIcon className="hold-music-note"/>
            <div className="hold-music-bottom-buttons">
                <Button className="inbound-add-hold-done-button" type="primary" onClick={handleSaveData}>Done</Button>
                <Button className="inbound-add-hold-previous-button" onClick={() => setCurrent(current - 1)}>Previous</Button>
                {/* <ButtonAnt className="inbound-add-hold-done-button" text="Done" type="primary" onClick={handleSaveData}/> 
                <ButtonAnt className="inbound-add-hold-previous-button" text="Previous" type="primary" type="secondary" onClick={() => setCurrent(current - 1)}/> */}
            </div>
            {!isDataPresent && <div className="proceed-to-click-error">Click "Done" to proceed.</div>}
        </div>
    )
}

export default AddHoldMusic;