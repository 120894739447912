import React from 'react'

const LineChartIcon = props => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.2" 
        overflow="visible" 
        preserveAspectRatio="none" 
        viewBox="0 0 21 16" 
        width="21" 
        height="16">
        <g transform="translate(0, 0)">
            <g transform="translate(-6.661338147750939e-16, 0) rotate(0)">
                <path 
                    d="M21,14.66h-19.69981v-14.66h-1.30019v16h21zM19.68996,1.66c0,-0.18 -0.1379,-0.32 -0.3152,-0.32h-0.00985h-4.46201c-0.1773,0 -0.32505,0.14 -0.32505,0.33c0,0.09 0.0394,0.18 0.0985,0.24l1.24109,1.26l-4.7575,4.83l-2.39353,-2.43c-0.12805,-0.13 -0.32505,-0.14 -0.46295,-0.01l-0.00985,0.01l-5.99859,6.09l1.96998,2l4.26501,-4.33l2.39353,2.43c0.12805,0.13 0.32505,0.14 0.46295,0.01l0.00985,-0.01l6.49109,-6.59l1.24109,1.26c0.12805,0.13 0.32505,0.14 0.46295,0.01c0.06895,-0.06 0.0985,-0.15 0.0985,-0.24v-4.53v0z" 
                    style={{"strokeWidth": 0, 'strokeLinecap': 'butt', 'strokeLinejoin': 'miter', 'fill': 'rgb(153, 153, 153)'}}
                    vectorEffect="non-scaling-stroke"
                />
            </g>
        </g>
    </svg>
)

export default LineChartIcon;

