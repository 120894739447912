import React, { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import CaretIcon from "./../../../../assets/svg/CaretIcon";
import Select from "../../../components/common/Select";
import Modal from "../common/Modal";
import Tips from "../../../components/common/Tips";
import PersonaliseTag from "../../../../src/components/common/PersonaliseTag";
import TrashIcon from "../../../../assets/svg/TrashIcon";
import AddButton from "../../../components/common/AddButton";
import NoteIcon from "../../../../assets/svg/NoteIcon";
import AddSurveyField from "./AddFieldSurvey";
import {FIELD_TYPE} from "./constants";
import AddQuestion from "./AddQuestion";
import { trackSegementEvents } from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';
import {
	getSalesforceFields,
	addQuestionsCampaignCreation,
	addQuestionsCampaignEdit,
	editQuestionsCampaignCreation,
	getQuestionDetails,
	editQuestionsCampaignEdit,
} from "../../intergrations/integrationUtilities";

// Importing Components
import _ from "lodash";

//css
import "./AddSurvey.scss";
import "../commonStyle.scss";

const dropDownComponent = () => {
	return (
		<span className="position-caret-icon">
			<CaretIcon size={24} labelstyle="dropdown-icon" />
		</span>
	);
};

const AddSurvey = props => {
	const initialState = {
		showAddSurveyFieldModal: false,
		openFieldTypeTitle: {
			1: "Text-based question",
			2: "Number-based question",
			3: "Boolean-based question",
			4: "Multi choice question",
			6: "Multi Select question"
		},
		openFieldTypeModal: false,
		showPersonaliseTagSection: false,
		showMultichoiceSection: false,
		showBooleanSection: false,
		errorStatus: false,
		salesforceField: {
			isLoading: true,
			options: [],
			selectedOption: [],
		},
		question: "",
		multichoice: [],
		multichoiceTags: {
			isLoading: true,
			options: [],
			selectedOption: [],
		},
		salesTagErrorStatus: null,
		tagsAdded: false,
	};

	const [
		{
			showAddSurveyFieldModal,
			salesforceField,
			errorStatus,
			openFieldTypeTitle,
			openFieldTypeModal,
			question,
			showPersonaliseTagSection,
			showMultichoiceSection,
			multichoice,
			multichoiceTags,
			salesTagErrorStatus,
			tagsAdded,
		},
		setAddSurveyState,
	] = useState(initialState);

	useEffect(() => {
		const getQuestionData = async () => {
			const questionData = await getQuestionDetails(
				props.componentData.apiToken,
				props.componentData.campaign_id,
				props.componentData.selected_section_id,
				props.componentData.campaign_type
			);
			setSalesforceFieldsDetailsForEditQuestion(questionData);
		};
		const getSalesforceFieldsDetails = async () => {
			const salesforceFieldsDetails = await getSalesforceFields(
				props.componentData.apiToken
			);
			setSalesforceFieldsDetailsForAddQuestion(salesforceFieldsDetails);
		};

		if (props.componentData.selected_section_id) {
			getQuestionData();
		} else {
			getSalesforceFieldsDetails();
		}
	}, []);

	const setSalesforceFieldsDetailsForEditQuestion = questionData => {
		setAddSurveyState(prevState => ({
			...prevState,
			openFieldTypeModal: !prevState.openFieldTypeModal,
			question: questionData.data.question,
			salesforceField: {
				...prevState["salesforceField"],
				selectedOption: {
					label: questionData.data.field_name,
					field_type: questionData.data.field_type,
				},
			},
			multichoiceTags: {
				...prevState["multichoiceTags"],
				options: !_.isEmpty(questionData.data.tags)
					? questionData.data.tags.map(salesforceMultichoiceTag => {
							return {
								label: salesforceMultichoiceTag.name,
								value: salesforceMultichoiceTag.pk_str,
								crm_type: salesforceMultichoiceTag.crm_type,
							};
						})
					: [],

				isLoading: false,
			},
			multichoice:
				!_.isEmpty(questionData.data.choices) &&
				questionData.data.choices.map(choice => {
					return {
						tag_label: choice.tag_name,
						tag_id: choice.tag_id,
						choice: choice.answer,
						pk_str: choice.choice_id,
						order_number: choice.order_number,
					};
				}),
			showMultichoiceSection:
				questionData.data.field_type == FIELD_TYPE.BOOLEAN ||
				questionData.data.field_type == FIELD_TYPE.MULTI_CHOICE ||
				questionData.data.field_type == FIELD_TYPE.MULTI_SELECT,
		}));
	};

	const setSalesforceFieldsDetailsForAddQuestion = salesforceFieldsDetails => {
		setAddSurveyState(prevState => ({
			...prevState,
			showAddSurveyFieldModal: !prevState.showAddSurveyFieldModal,
			salesforceField: {
				...prevState["salesforceField"],
				options:
					!_.isEmpty(salesforceFieldsDetails.data.fields) &&
					salesforceFieldsDetails.data.fields.map(salesforceField => {
						return {
							label: salesforceField.label,
							value: salesforceField.pk_str,
							field_type: salesforceField.field_type,
							multichoice: salesforceField.icfmultichoice,
						};
					}),

				isLoading: false,
			},
			multichoiceTags: {
				...prevState["multichoiceTags"],
				options: !_.isEmpty(salesforceFieldsDetails.data.tags)
					? salesforceFieldsDetails.data.tags.map(salesforceMultichoiceTag => {
							return {
								label: salesforceMultichoiceTag.name,
								value: salesforceMultichoiceTag.pk_str,
								crm_type: salesforceMultichoiceTag.crm_type,
							};
						})
					: [],

				isLoading: false,
			},
		}));
	};

	const handleCloseAddSurveyFieldModal = () => {
		document.querySelector('.overlayBody').style.display = 'none';
		setAddSurveyState(prevState => ({
			...prevState,
			showAddSurveyFieldModal: !prevState.showAddSurveyFieldModal,
		}));
		props.unMountTheComponent(false);
	};

	const handleCloseFieldTypeModal = () => {
		document.querySelector('.overlayBody').style.display = 'none';
		setAddSurveyState(prevState => ({
			...prevState,
			openFieldTypeModal: !prevState.openFieldTypeModal,
		}));
		props.unMountTheComponent(true);
	};

	const changeSalesforceFieldHandler = (event, key) => {
		let updatedData = key == "question" ? event.target.value : event;
		setAddSurveyState(prevState => ({
			...prevState,
			[key]:
				key == "question"
					? updatedData
					: {
							...prevState[key],
							selectedOption: updatedData.value == "Choose" ? [] : updatedData,
						},
		}));
	};

	const changeMultichoiceHandler = (event, index) => {
		let updateMultichoice = multichoice;
		if (event.value == "Choose") {
			updateMultichoice[index] = {
				...updateMultichoice[index],
				tag_id: "",
				tag_label: "Choose",
				crm_type: "",
			};
		} else {
			updateMultichoice[index] = {
				...updateMultichoice[index],
				tag_id: event.value,
				tag_label: event.label,
				crm_type: event.crm_type,
			};
		}
		setAddSurveyState(prevState => ({
			...prevState,
			multichoice: updateMultichoice,
		}));
	};

	const addFieldHandler = () => {
		if (props.componentData.campaign_type == "p2p") {
			if (_.isEmpty(salesforceField.selectedOption)) {
				setAddSurveyState(prevState => ({
					...prevState,
					errorStatus: !prevState.errorStatus,
				}));
				return;
			}
			const data = {
				name: salesforceField.selectedOption.label,
				field_type: salesforceField.selectedOption.field_type,
				field_id: salesforceField.selectedOption.value,
			};
			sessionStorage.setItem("fieldData", JSON.stringify(data));
			window.dispatchEvent(new Event("storage"));
			props.unMountTheComponent(false);
			setAddSurveyState(prevState => ({
				...prevState,
				showAddSurveyFieldModal: !prevState.showAddSurveyFieldModal,
			}));
			return;
		}
		if (_.isEmpty(salesforceField.selectedOption)) {
			setAddSurveyState(prevState => ({
				...prevState,
				errorStatus: !prevState.errorStatus,
			}));
			return;
		}

		setAddSurveyState(prevState => ({
			...prevState,
			showAddSurveyFieldModal: !prevState.showAddSurveyFieldModal,
			openFieldTypeModal: !prevState.openFieldTypeModal,
			showMultichoiceSection:
				salesforceField.selectedOption.field_type == FIELD_TYPE.MULTI_CHOICE ||
				salesforceField.selectedOption.field_type == FIELD_TYPE.BOOLEAN ||
				salesforceField.selectedOption.field_type == FIELD_TYPE.MULTI_SELECT,
			multichoice:
				salesforceField.selectedOption.field_type == FIELD_TYPE.MULTI_CHOICE || salesforceField.selectedOption.field_type == FIELD_TYPE.MULTI_SELECT
					? salesforceField.selectedOption.multichoice
					: salesforceField.selectedOption.field_type == 3
						? [{ choice: "True" }, { choice: "False" }]
						: [],
		}));
	};

	const PersonaliseTagHandler = () => {
		setAddSurveyState(prevState => ({
			...prevState,
			showPersonaliseTagSection: !prevState.showPersonaliseTagSection,
		}));
	};

	const handleAppendPersonaliseTagToQuestion = tags => {
		setAddSurveyState(prevState => ({
			...prevState,
			question: tags,
		}));
	};
	
	const errorHandler = () => {
		if (_.isEmpty(question)) {
			setAddSurveyState(prevState => ({
				...prevState,
				salesTagErrorStatus: true,
			}));
			return true;
		}
		return false;
	};

	const addQuestionHandler = async () => {
		if (!errorHandler()) {
			let postApiData = {
				field_type: salesforceField.selectedOption.field_type,
				field_id: salesforceField.selectedOption.value,
				field_name: salesforceField.selectedOption.label,
				question: question,
				display_number: props.componentData.order_number,
				campaign_type: props.componentData.campaign_type,
			};
			if (!_.isEmpty(multichoice)) {
				postApiData["choices"] = multichoice.map(choice => {
					return {
						choice_id: choice.pk_str,
						choice_name: choice.choice,
						tag_name:
							choice.tag_label == "Choose" || _.isEmpty(choice.tag_label)
								? ""
								: choice.tag_label,
						tag_id: choice.tag_id ? choice.tag_id : "",
					};
				});
			}
			let response;
			if (props.componentData.campaign_id) {
				if (props.componentData.selected_section_id) {
					response = await editQuestionsCampaignEdit(
						postApiData,
						props.componentData.apiToken,
						props.componentData.selected_section_id,
						props.componentData.campaign_id,
						props.componentData.campaign_type
					);
					let data = JSON.parse(localStorage.getItem(props.componentData.campaign_id));
					let choices;
					if(response.data.choices?.length > 0) {
						choices = response.data.choices.map((choice) => {
							return {...choice,nextQuestion : null}
						});
					}
					data[response.data.section_id] = {
						nextQuestion : null,
						choices : choices,
						question : response.data.question,
						id : response.data.section_id,
						type : response.data.section_type,
						order_number : data[response.data.section_id].order_number
					};
					localStorage.setItem(props.componentData.campaign_id,JSON.stringify(data));
				} else {
					response = await addQuestionsCampaignEdit(
						postApiData,
						props.componentData.apiToken,
						props.componentData.campaign_id
					);
					if(JSON.parse(localStorage.getItem(props.componentData.campaign_id)) == null){
						localStorage.setItem(props.componentData.campaign_id,"{}");
					}
					let data = JSON.parse(localStorage.getItem(props.componentData.campaign_id));
					let choices;
					if(response.data.section.choices?.length > 0) {
						choices = response.data.section.choices.map((choice) => {
							return {...choice,nextQuestion : null}
						});
					}
					data[response.data.section.id] = {
						...response.data.section,nextQuestion : null,choices : choices
					};
					localStorage.setItem(props.componentData.campaign_id,JSON.stringify(data));
				}
			} else {
				if (props.componentData.selected_section_id) {
					response = await editQuestionsCampaignCreation(
						postApiData,
						props.componentData.apiToken,
						props.componentData.selected_section_id,
						props.componentData.campaign_type
					);
					let data = JSON.parse(localStorage.getItem('scriptQuestions'));
					let choices;
					if(response.data.choices?.length > 0) {
						choices = response.data.choices.map((choice) => {
							return {...choice,nextQuestion : null}
						});
					}
					data[response.data.section_id] = {
						nextQuestion : null,
						choices : choices,
						question : response.data.question,
						id : response.data.section_id,
						type : response.data.section_type,
						order_number : data[response.data.section_id].order_number
					};
					localStorage.setItem('scriptQuestions',JSON.stringify(data));
				} else {
					response = await addQuestionsCampaignCreation(
						postApiData,
						props.componentData.apiToken
					);
					if(JSON.parse(localStorage.getItem('scriptQuestions')) == null){
						localStorage.setItem('scriptQuestions',"{}");
					}
					let data = JSON.parse(localStorage.getItem('scriptQuestions'));
					let choices;
					if(response.data.section.choices?.length > 0) {
						choices = response.data.section.choices.map((choice) => {
							return {...choice,nextQuestion : null}
						});
					}
					data[response.data.section.id] = {
						...response.data.section,nextQuestion : null,choices : choices
					};
					localStorage.setItem('scriptQuestions',JSON.stringify(data));
				}
			}
			if (response.hasOwnProperty("error")) {
				//  seterrorMessageStatus([...errorMessageStatus, response.error.message])
			} else {
				// setsuccesMessageStatus([...successMessageStatus, response.message]);
				handleCloseFieldTypeModal();
			}
		}
	};

	return (
		<div
			id="salesforce-survey-question-container"
			className="salesforce-survey-question-container"
		>
			{showAddSurveyFieldModal &&
				<Modal
					className={"pull-left"}
					title="Add fields to survey"
					handleCloseModal={handleCloseAddSurveyFieldModal}
					submitHandler={addFieldHandler}
					cancelButton="Cancel"
					submitButton="Add field"
					submitButtonclass="btn-primary btn-add-field"
					modalBodyClassName="modal-body-overlay"
				>
					<AddSurveyField
						errorStatus={errorStatus}
						salesforceField={salesforceField}
						changeSalesforceFieldHandler={changeSalesforceFieldHandler}
					/>
				</Modal>}

			{openFieldTypeModal &&
				<Modal
					className={"pull-left"}
					title={`${openFieldTypeTitle[
						salesforceField.selectedOption.field_type
					]}`}
					subtitle={salesforceField.selectedOption.field_type == 6 ? '(Select as many options as you want)': null}
					handleCloseModal={handleCloseFieldTypeModal}
					submitHandler={addQuestionHandler}
					showPersonalise={true}
					PersonaliseTagHandler={PersonaliseTagHandler}
					cancelButton="Cancel"
					submitButton={
						props.componentData.selected_section_id
							? "Save changes"
							: "Add question"
					}
					submitButtonclass="btn-primary btn-add-field"
				>
					<AddQuestion
						salesforceField={salesforceField}
						question={question}
						showPersonaliseTagSection={showPersonaliseTagSection}
						showMultichoiceSection={showMultichoiceSection}
						multichoice={multichoice}
						apiToken={props.componentData.apiToken}
						PersonaliseTagHandler={PersonaliseTagHandler}
						handleAppendPersonaliseTagToQuestion={
							handleAppendPersonaliseTagToQuestion
						}
						tagsAdded={tagsAdded}
						multichoiceTags={multichoiceTags}
						salesTagErrorStatus={salesTagErrorStatus}
						changeSalesforceFieldHandler={changeSalesforceFieldHandler}
						changeMultichoiceHandler={changeMultichoiceHandler}
					/>
				</Modal>}
		</div>
	);
};

export default AddSurvey;
