import * as React from "react"

const CalenderIcon = (props) => (
    <svg
    width={44}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.145 19.1H9.672v3.8h3.473v-3.8Zm6.946 0h-3.473v3.8h3.473v-3.8Zm6.945 0h-3.473v3.8h3.473v-3.8ZM30.51 5.8h-1.736V2H25.3v3.8H11.409V2H7.936v3.8H6.199c-1.927 0-3.455 1.71-3.455 3.8l-.017 26.6c0 2.09 1.545 3.8 3.472 3.8h24.31c1.91 0 3.473-1.71 3.473-3.8V9.6c0-2.09-1.563-3.8-3.473-3.8Zm0 30.4H6.2V15.3h24.31v20.9Z"
      fill="#fff"
    />
    <g clipPath="url(#a)">
      <path
        d="M18.818 36c0-6.627 5.373-12 12-12 6.628 0 12 5.373 12 12s-5.372 12-12 12c-6.627 0-12-5.373-12-12Z"
        fill="#F17730"
      />
      <path
        d="M30.808 26c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10Zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8Z"
        fill="#fff"
      />
      <path d="M31.318 31h-1.5v6l5.25 3.15.75-1.23-4.5-2.67V31Z" fill="#fff" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(18.818 24)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default CalenderIcon;
