import React from 'react'
import "./NudgeDetailsContent.scss";
import * as Constant from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';

function NudgeDetailsContent(props) {
    const {
        title,
        content,
        link,
        url,
        children,
        icon,
        target,
        segmentEventName
    } = props;

    const segmentEventhandler = async(event) => {
        event.preventDefault();
        if(segmentEventName == "clicked-on-support-cta"){
          let res = await Constant.trackSegementEvents("clicked-on-support-cta", "Conversion rate" , url)
          if(res){
            window.location = url;
          }
          return
        }else{
            sessionStorage.setItem(segmentEventName, true );
            window.location = url;
        }
    }

    return (
        <div style={{width: 300}}>
            {
                icon && icon !== "" && 
                <div className="mb-10px">
                    {icon}
                </div>
            }
            {
                title && title !== "" && 
                <div>
                    <p className="text-size-20 font-weight-600 mb-3px lh-20px">{title}</p>
                </div>
            }
            {children}
            {
                content && content !== "" &&
                <div>
                    <p className="text-size-14 text-44444470 mb-3px">{content}</p>
                </div>
            }
            {
                url && url !== "" && 
                <div>
                    <a onClick = {(event) => segmentEventhandler(event)} target={target ? target : ""} className="m-0 text-size-14 text-3f89de underlined cursor-pointer nudge-content-link">{link}</a>
                </div>
            }
        </div>
    )
}

export default NudgeDetailsContent;
