import axios from 'axios';
export const postApiData = async(url, data,  apiToken) => {
    try{
        const response = await axios.post( url, data, {
            headers: {
                'Authorization' : 'Token ' + `${((apiToken) ? apiToken : token)}`,
                'Content-Type' : 'application/json',
            }
        })
        return response;
    }catch(error){
        return error.response.data;
    }
}


export const getAPIData = async(url, apiToken) => {
    try {
        const response = await axios.get(url, {
            headers: { 
                'Authorization' : 'Token ' + `${((apiToken) ? apiToken : token)}`,
            },
        })
        return response;
    }catch(error){
        return error.response.data;
    }
}
