export const CALL_SENTIMENTS_TITLE = 'Call sentiments';
export const OVERALL_SENTIMENT_TITLE = 'Overall sentiment';
export const CALL_SENTIMENTS_DETAIL_DATA = [
    {
        title:'Positive Calls',
        number: 0,
        increase : true,
        subhead: 'Total calls with positive sentiment dialed.',
        percentage: 10,
        type: 'Positive'
    },
    {
        title:'Neutral Calls',
        number: 0,
        increase : false,
        subhead: 'Total calls with neutral sentiment dialed.',
        percentage: 10,
        type: 'Neutral'
    },
    {
        title:'Negative Calls',
        number: 0,
        increase : true,
        subhead: 'Total calls with negative sentiment dialed.',
        percentage: 10,
        type: 'Negative'
    }
];

export const CONTACT_CATEGOREIS_TITLE = 'Contact categories';
export const SMART_INSIGHT_BETA_POPOVER = 'This feature is currently in the beta phase, so results may be preliminary, not very precise.';

