import React, { Component } from "react";
import { render } from "react-dom";
import SalesForceSurvey from "./screens/react_library_for_jquery/SalesForceIntegration/AddSurvey";
import NormalSelectBox from './screens/react_library_for_jquery/common/NormalSelectBox'
import TrialNudgeContainer from "./screens/react_library_for_jquery/SubscriptionTrial/TrialNudgeContainer";
import UpSellWrapperForJquery from "./screens/subscriptions/SusbscriptionUpsell/UpSellWrapperForJquery";
import NavMenuSubscriptionButton from "./screens/react_library_for_jquery/common/NavMenuSubscriptionButton";
import URLShortener from "./screens/react_library_for_jquery/ShortenedUrl/CreateShortenedUrl.js";
import UrlShortenerStatisticsTableLayout from "./components/UrlShortenerStatisticsTableLayout";
import MobileAppPromoBanner from "./components/MobileAppPromo/MobileAppPromoBanner/MobileAppPromoBanner";
import ReactCustomNotification from "./components/ReactCustomNotification/ReactCustomNotification";
import CustomSelect from "./screens/react_library_for_jquery/common/CustomSelect/CustomSelect";
import InboundCalling from "./screens/inboundCalling/InboundCalling";
import { RecoilRoot } from "recoil";
import GuidePageProductList from "./components/common/GuidePageComponents/GuidePageProductList";
import {BranchingScript} from "./components/BranchingScript/BranchingScript"
import CustomSelectForCampaignResult from "./screens/react_library_for_jquery/common/CustomSelect/CustomSelectForCampaignResult";
import {ProgressBar} from "./components/ProgressBar/ProgessBar";
import P2PSortOrderComponent from "./screens/react_library_for_jquery/SortOrderComponent/p2pSortOrder";
import CCSortOrderComponent from "./screens/react_library_for_jquery/SortOrderComponent/ccSortOrder";
import AgentProfanityGuard from './screens/react_library_for_jquery/AgentProfanityGurad/AgentProfanityGuard';
import { MobileDiscoverBanner } from "./components/MobileDiscoverabilityBanner/MobileDiscoverBanner";
import {Emojis} from "./components/common/Emojis/Emojis";
import {TextEditor} from "./components/common/TextEditor/TextEditor";
import CcAiPowerd from "./screens/react_library_for_jquery/CcAiPowerd/CcAiPowerd";
import SmartInsight from './screens/react_library_for_jquery/SmartInsight/SmartInsight';
import LowCreditError from './screens/react_library_for_jquery/LowCreditError/LowCreditError';
import ManageTeamPopover from "./screens/react_library_for_jquery/ManageTeamPopover/ManageTeamPopover";
import PdiIntegrationConnectionModal from "./screens/react_library_for_jquery/pdiIntegrationConnectionModal/pdiIntegrationConnectionModal";
import PdiIntegrationQuestion from "./screens/react_library_for_jquery/pdiIntegrationConnectionModal/PdiIntegrationQuestion";
import TransferCreditsModal from "./components/TransferCreditsModal/TransferCreditsModal";
import CreateContactWithOtp from "./components/ConatctCreateOTP/createContactWithOtp"

import DiscoverabilityBannerCommon from "./components/common/DiscoverabilityBannerCommon/DiscoverabilityBannerCommon";
import axios from 'axios';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
import {SwitchAccounts} from "./components/SwitchAccounts/SwitchAccounts";
import NotificationSettings from "./screens/react_library_for_jquery/NotifcationSettings";
import ErrorNotification from "./components/ErrorNotification";
import DncSettingsDropdown from "./components/DncSettings"
import TransferSettings from "./screens/react_library_for_jquery/autoCreditsTransferSettings";
import AntDMessage from "./screens/react_library_for_jquery/AntDesignMessage";
import EnableNumberRotation from "./components/NumberRotation/NumberRotationContainer/EnableRotation.js";
import UseCaseNumberSelectModal from "./components/useCaseNumberSelectModal/useCaseNumberSelectModal";
import {AutoUnrent} from "./components/AutoUnrent/AutoUnrent";
import AccountCreationFeeModal from "./components/AccountCreationFeeModal";
var componentName;
class Index extends Component {
	state = {
		show: true,
	};

	componentDidUpdate(prevState, nextState) {
		if (nextState.show == false) {
			this.setState({ show: true });
		}
	}

	unMountTheComponent = isRefresh => {
		this.setState({ show: false });
		if (isRefresh) location.reload();
	};

	render() {
		let componentRendered;
		switch (componentName) {
			case "SalesForceSurvey":
				componentRendered = (
					<SalesForceSurvey
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "URLShortenerSelectBox":
				componentRendered = (
					<NormalSelectBox
						componentData={this.props.componentData}
					/>
				);
				break;
			case "UrlShortenerStatisticsTable":
				componentRendered = (
					<UrlShortenerStatisticsTableLayout
						componentData={this.props.componentData}
					/>
				);
				break;
			case "TrialNudgeContainer":
				componentRendered = (
					<TrialNudgeContainer
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "SubscriptionDropdownMenuButton":
				componentRendered = (
					<NavMenuSubscriptionButton
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "AutoPredictiveDialerUpdgradePlanModal":
				componentRendered = (
					<UpSellWrapperForJquery
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "URLShortener":
				componentRendered = (
					<URLShortener
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
				case "ReferralPopUpNotification":
					componentRendered = (
						<ReactCustomNotification
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					);
					break;
			case "InboundCalling":
				componentRendered = (
					<RecoilRoot>
						<InboundCalling
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case "productsGridListWrapperReact":
				componentRendered = (
					<GuidePageProductList
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "BranchingScript":
				componentRendered = (
				<BranchingScript componentData={this.props.componentData}
					unMountTheComponent={this.unMountTheComponent}
				/>
			);
			break;
			case "CustomSelect":
				componentRendered = (
					<CustomSelect
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "CustomSelectForCampaignResult":
				componentRendered = (
					<CustomSelectForCampaignResult
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "ProgressBar" :
				componentRendered = (
					<ProgressBar
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "MobileDiscoverBanner":
				componentRendered = (
					<MobileDiscoverBanner
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "P2PSortOrderComponent":
				componentRendered = (
					<RecoilRoot>
						<P2PSortOrderComponent
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case "CCSortOrderComponent":
				componentRendered = (
					<RecoilRoot>
						<CCSortOrderComponent
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case "LowCreditError":
				componentRendered = (
					<RecoilRoot>
						<LowCreditError
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case "ManageTeamPopover":
				componentRendered = (
					<RecoilRoot>
						<ManageTeamPopover
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case "Emojis":
				componentRendered = (
					<Emojis
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "TextEditor":
				componentRendered = (
					<TextEditor
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "AgentProfanityGuard":
				componentRendered = (
					<AgentProfanityGuard
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case "WorkflowBannerComponentWrapper":
				componentRendered = (
					<DiscoverabilityBannerCommon
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				)
				break;
			case "SwitchAccounts":
				componentRendered = (
					<SwitchAccounts
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				)
				break;
			case "NotificationSettings":
				componentRendered = (
					<RecoilRoot>
					<NotificationSettings
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
					</RecoilRoot>
				)
				break;
			case "errorNotification":
				componentRendered = (
					<ErrorNotification
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				)
				break;
			case "CcAiPowedComponent":
				componentRendered = (
					<RecoilRoot>
						<CcAiPowerd
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case 'CcSmartInsightComponent':
				componentRendered = (
					<RecoilRoot>
						<SmartInsight
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case "dncDropdown":
				componentRendered = (
					<DncSettingsDropdown
						componentData = {this.props.componentData}
					/>
				)
				break;
			case 'pdiIntegrationConnectionModal':
				componentRendered = (
					<RecoilRoot>
						<PdiIntegrationConnectionModal
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case 'pdiIntegrationQuestionModalContainer':
				componentRendered = (
					<RecoilRoot>
						<PdiIntegrationQuestion
							componentData = {this.props.componentData}
							unMountTheComponent={this.unMountTheComponent}
						/>
					</RecoilRoot>
				);
				break;
			case 'autoCreditsTransfer':
				componentRendered = (
					<TransferSettings
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case 'antDMessage':
				componentRendered = (
					<AntDMessage
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case 'NumberRotation':
				componentRendered = (
					<EnableNumberRotation
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case 'transferCreditsModal':
				componentRendered = (
					<TransferCreditsModal
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case 'autoUnrentModal':
				componentRendered = (
					<AutoUnrent
						componentData={this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				);
				break;
			case 'CreateContactWithOtp':
				componentRendered = (
					<CreateContactWithOtp
						componentData = {this.props.componentData}
					/>
				)
				break;
			case 'useCaseNumberSelectModal':
				componentRendered = (
					<UseCaseNumberSelectModal
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				)
				break;
			case 'accountCreationFeeModal':
				componentRendered = (
					<AccountCreationFeeModal
						componentData = {this.props.componentData}
						unMountTheComponent={this.unMountTheComponent}
					/>
				)
				break;
			default:
				componentRendered = null;
		}
		return (
			<div>
				{this.state.show && componentRendered}
			</div>
		);
	}
}

export function renderMyReactApp(element, componentToRender, componentData) {
	componentName = componentToRender;
	render(
		<Index componentData={componentData} />,
		document.getElementById(element)
	);
}
