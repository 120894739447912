import React, { useEffect } from 'react';
import './transferCreditsModal.scss'; // Ensure to import your custom CSS


const TransferCreditsSuccessBody = ( toAccount, fromAccount, amount) => {
  return (
    <div className="transfer-success-body">
      <div className="transfer-success-element">
        <p>Amount transferred</p> <b>${amount}</b>
      </div>
      <div className="transfer-success-element">
        <p>To</p> <b>{toAccount}</b>
      </div>
      <div className="transfer-success-element">
        <p>From</p> <b>{fromAccount}</b>
      </div>
    </div>
  );
};

export default TransferCreditsSuccessBody;
