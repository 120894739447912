import { apiGetCall, apiPostCall, apiPutCall } from "./utilities";
import { 
    subscriptionPlans, 
    textCallCost, 
    cardDetailsUrl, 
    orderSummaryUrl, 
    currentPlanUrl, 
    saveFreeTrialGiftUrl, 
    storeProductUrl, 
    activePlansUrl, 
    subscriptionUrl, 
    paymentHistoryUrl, 
    verifyEmailUrl, 
    downgradePlanUrl,
    billingVerificationStatusUrl,
    billingReceiptUrl, 
    bulkRechargeDetailsUrl,
    addonSurveyResponseUrl,
    planSurveyResponseUrl,
    addonsDunningMessageUrl,
    removeDunningMessageUrl,
    creditSummaryUrl,
    validateKycUrl,
    customerKycUrl,
    usageTableUrl,
    invoiceDataUrl,
    magicLinkUrl
} from "../configuration/subscriptionConfig";


export const getProductByName = async (apiToken, params) => {
    try {
        const min_call_text_url = storeProductUrl();
        const response = await apiGetCall(min_call_text_url, apiToken, params);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getSubscriptionPlans = async (apiToken) => {
    try {
        const plan_details_url = subscriptionPlans();
        const response = await apiGetCall(plan_details_url, apiToken);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getMinCallAndTextCost = async (apiToken) => {
    try {
        const min_call_text_url = textCallCost();
        const response = await apiGetCall(min_call_text_url, apiToken);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getCardDetails = async () => {
    try{
        const card_details_url = cardDetailsUrl();
        const response = await apiGetCall(card_details_url);
        return response.data; 
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getCreditSummary = async(apiToken) => {
    try {
        const credit_summary_url = creditSummaryUrl()
        const response = await apiGetCall(credit_summary_url, apiToken); 
        return response.data;
    } catch (error) {
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getCurrentPlanData = async (apiToken) => {
  try {
    try {
        if(current_plan){
            return current_plan
        }else{
            return {
                error: {detail: "Plan Not Found"},
                status: 404,
              };
        }
    } catch (error) {
      const current_plan_url = currentPlanUrl();
      const response = await apiGetCall(current_plan_url, apiToken);
      return response.data;
    }
  } catch (error) {
    return {
      error: error?.response?.data,
      status: error?.response?.status,
    };
  }
};

export const getActiveAddons = async () => {
    try {
        const active_plan_url = activePlansUrl();
        const response = await apiGetCall(active_plan_url);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const postSaveFreeTrialGift = async (apiToken) => {
    try {
        const save_free_trial_gift_url = saveFreeTrialGiftUrl();
        const response = await apiPostCall(save_free_trial_gift_url, {}, apiToken);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const manipulatePlansData = (plans) => {
    let manipulatedData = [];
    plans.forEach((plan) => {
        let eachManipulatedPlan = {};

        eachManipulatedPlan = {
            id: plan.id,
            planName: plan["display_name"],
            benefits: plan.detail_json.features,
            planItems: plan.detail_json.plan_items,
            prevPlan: plan.previous_plan,
            is_popular: plan.is_popular,
            categorized_features: plan.detail_json.categorized_features ?? null
        }
        eachManipulatedPlan = {...eachManipulatedPlan, ...plan.detail_json.plan_price}

        manipulatedData.push(eachManipulatedPlan);
    });
    return manipulatedData;
}

export const getAllPlansButtonStates = (currentPlanData, allPlansData) => {
    let planRankings = [], createdButtonStates = [], currentPlanName;
    allPlansData.forEach(plan => planRankings.push(plan.level));
    const currentPlanLevel = currentPlanData.product.level;
    currentPlanName = currentPlanData.product.name;

    if(currentPlanData.is_trial) {
        planRankings.forEach(plan => createdButtonStates.push("Choose"));
        return createdButtonStates;
    }else {
        planRankings.forEach(level => {
            if(level < currentPlanLevel && (currentPlanName.toLowerCase() === "lite" || currentPlanName.toLowerCase() === 'lite plus')){
                createdButtonStates.push("Current plan");
                return;
            }
            if(level < currentPlanLevel){
                createdButtonStates.push("Downgrade");
            }else{
                createdButtonStates.push("Upgrade");
            }
            
        });
        return createdButtonStates;
    }
}

export const getBillingRankings = (data) => {
    let billingRankings = [];
    data.forEach(billingPeriod => {
        billingRankings.push(billingPeriod.name.toLowerCase());
    });
    return billingRankings;
}

export const addCardDetails = async (formData) => {
    try{
        const card_details_url = cardDetailsUrl();
        const response = await apiPostCall(card_details_url, formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getOrderSummaryDetails = async (QueryParams, apiToken="") => {
    try{
        const order_summary_url = orderSummaryUrl();
        const response = await apiGetCall(order_summary_url + QueryParams, apiToken);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const makePayment = async (body, apiToken="") => {
    try{
        const order_summary_url = orderSummaryUrl();
        const response = await apiPostCall(order_summary_url, body, apiToken);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
} 

export const getStoreProducts = async () => {
    try{
        const store_product_url = storeProductUrl();
        const response = await apiGetCall(store_product_url);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const toggleSubscription = async (id, formData={}) => {
    try {
        const addon_subscription_url = subscriptionUrl(id);
        const response = await apiPutCall(addon_subscription_url, formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const postAddOnSurvey = async (id, formData) => {
    try {
        const addon_survey_url = `${addonSurveyResponseUrl()}${id}/`;
        const response = await apiPostCall(addon_survey_url, formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const postPlanSurvey = async (id, formData) => {
    try {
        const plan_survey_url = `${planSurveyResponseUrl()}${id}/`;
        const response = await apiPostCall(plan_survey_url, formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getStoreProductByID = async (id) => {
    try{
        const store_product_url = storeProductUrl();
        const response = await apiGetCall(`${store_product_url}${id}/`);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getPaymentHistory = async (limit, offset) => {
    try{
        const payment_history_url = paymentHistoryUrl();
        const response = await apiGetCall(`${payment_history_url}?limit=${limit}&offset=${offset}`);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getBillingVerificationStatus = async (apiToken="") => {
    try{
        const billing_verification_status_url = billingVerificationStatusUrl();
        const response = await apiGetCall(billing_verification_status_url, apiToken);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const sendVerificationEmail = async (body) => {
    try{
        const verify_email_url = verifyEmailUrl();
        const response = await apiPostCall(verify_email_url, body);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getDowngradePlanDetails = async (baseProductId, billingPeriod, isLite) => {
    try{
        const downgrade_details_url = downgradePlanUrl();
        const response = await apiGetCall(`${downgrade_details_url}?base_product_id=${baseProductId}${!isLite ? `&billing_period=${billingPeriod}` : ""}`)
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}


export const getReceiptData = async (receiptId) => {
    try{
        const receipt_data_url = billingReceiptUrl(receiptId);
        const response = await apiGetCall(receipt_data_url);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const downgradePlan = async (formData) => {
    try{
        const downgrade_details_url = downgradePlanUrl();
        const response = await apiPostCall(downgrade_details_url, formData);
        return response.data;
    }
    catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getBulkRechargeDetails = async () => {
    try{
        const bulk_recharge_detail_url = bulkRechargeDetailsUrl();
        const response = await apiGetCall(bulk_recharge_detail_url);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const isSamePlan = (plan1, plan2) => {
    return plan1 === plan2 
    || plan1.toLowerCase() === plan2.toLowerCase() 
    || `${plan1.toLowerCase()} plan` === plan2.toLowerCase()
    || plan1.toLowerCase() === `${plan2.toLowerCase()} plan`
    || `${plan1.toLowerCase().replace(" ", "-")}` === plan2.toLowerCase()
    || plan1.toLowerCase() === `${plan2.toLowerCase().replace(" ", "-")}`
    || `${plan1.toLowerCase().replace("-", " ")}` === plan2.toLowerCase()
    || plan1.toLowerCase() === `${plan2.toLowerCase().replace("-", " ")}`
}


export const getDunningMessagesForAddons = async () => {
    try{
        const addons_dunning_message_url = addonsDunningMessageUrl();
        const response = await apiGetCall(addons_dunning_message_url);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const removeDunningMessage = async (body) => {
    try{
        const remove_dunning_message_url = removeDunningMessageUrl();
        const response = await apiPostCall(remove_dunning_message_url, body);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}

export const getAssistance = async (url) => {
    try{
        const response = await apiGetCall(url);
        return response.data;
    }catch(error){
        return {
            error : error.response.data, 
            status: error.response.status
        };
    }
}


export const getStripeTokenPromise = (card) => {
    return new Promise ((resolve, reject) => {
        Stripe.createToken(card, (status, response) => {
            if(response.error){
                return reject(response.error);
            }else{
                return resolve(response);
            }
        });
    })
}

export const getStripeToken = async (card) => {
    try{
        const token = await getStripeTokenPromise(card);
        return token;
    }catch(error){
        return {
            error: error
        };
    }
}

export const postKycStatus = async (data,apiToken) => {
    try{
        const validateKycURL = validateKycUrl();
        const response = await apiPostCall(validateKycURL,data,apiToken);
        return response.data;
    }catch(error){
        return {
            error: error
        };
    }
}

export const checkKycStatus = async (apiToken) => {
    try{
        const validateKycURL = validateKycUrl();
        const response = await apiGetCall(validateKycURL,apiToken);
        return response.data;
    }catch(error){
        return {
            error: error
        };
    }
}

export const postCustomerKycDetails = async (data,apiToken) => {
    try{
        const customerKycURL = customerKycUrl();
        const response = await apiPostCall(customerKycURL,data,apiToken);
        return response.data;
    }catch(error){
        return {
            error: error.response.data
        };
    }
}

export const getUsageTableData = async () => {
    try{
        const usageTableURL = usageTableUrl();
        const response = await apiGetCall(usageTableURL);
        return response;
    }catch(error){
        throw error;
    }
}

export const getInvoiceData = async (month, year) => {
    try{
        const invoiceDataURL = invoiceDataUrl(month, year);
        const response = await apiGetCall(invoiceDataURL);
        return response;
    }catch(error){
        throw error;
    }
}

export const sendLoginLink = async(data) =>{
    try{
        const magic_link_url = magicLinkUrl();
        const formData = new URLSearchParams(data).toString();
        const response = await apiPostCall(magic_link_url,formData,apiToken);
        return response;
    }catch(error){
        return {
            error: error
        };
    }
}