import React, { useState, useEffect } from 'react'
import "./ProductCard.scss";
import {getEarlyAccess, postEarlyAccess} from "../../../utilities/earlyRequestUtilities";
import { EARLY_ACCESS_REQUEST_SENT } from "../../../constants/earlyAccess";
import {trackSegementEvents} from "../SegmentEventsConstant/SegmentEventsConstant";

function ProductCard(props) {

    const { product, index } = props;

    const [showDescription, setShowDescription] = useState(false);
    const [requireEarlyAccess, setRequireEarlyAccess] = useState(true)

  useEffect(() => {
    getEarlyAccessData()
  }, [])

  const sendSegments = () => {
        if(window && window.analytics){
            let segment_traits = {
                'userid' : product?.userId,
                'emailid' : product?.userEmail,
            };
            trackSegementEvents("RO-early-access-requested", segment_traits);
        }
    }

  const getEarlyAccessData = async() => {
      if(product.earlyAccess) {
        const response = await getEarlyAccess({[product.addVarsKey] : 0})
        if(response?.data.relational_early_access == EARLY_ACCESS_REQUEST_SENT) {
          setRequireEarlyAccess(false)
        }
      }
  }

  const earlyAccessHandler = async() => {
      const response = await postEarlyAccess({[product.addVarsKey] : 1})
      if(response?.data.relational_early_access == EARLY_ACCESS_REQUEST_SENT) {
          setRequireEarlyAccess(false)
          sendSegments()
      }

  }

  return (
    <div className='product-card'>
        <div className='card-body'>
            {/* <div className='info-ico-wrapper'>
                <a>
                    <i className="icon-info-sign info-icon-blue-col"></i>
                </a>
            </div> */}
            <div className='product-card-details'>
                <div className="margin-10 product-icon-wrapper">
                    {/* <i class="icon-comments product-icon p2pIcoBg"></i> */}
                    {product.icon}
                </div>
                <div className="margin-10">
                    <div className='product-title-wrapper'>
                        <div className='title-section'>
                            <h3>{product.name}</h3>
                            {product.promoText && 
                                <span className='promo-text'>{product.promoText}</span>
                            }
                        </div>
                        {product.fullDescription &&
                            <a
                                className='hint-ico'
                                tabIndex={index}
                                onBlur={() => setShowDescription(false)}
                                onClick={() => setShowDescription(true)}
                            >
                                <i className="icon-info-sign info-icon-blue-col"></i>
                                {showDescription &&
                                    <div className='desc-popover'>
                                        <div className='triangle-up'></div>
                                        <div className='desc-popover-body'>
                                            <div></div>
                                            <pre className='full-desc-content' dangerouslySetInnerHTML={{__html : product.fullDescription}}>

                                            </pre>
                                        </div>
                                    </div>
                                }
                            </a>
                        }
                        {product.newTag &&
                          <div className='new-product-tag'>
                            NEW
                          </div>
                        }
                    </div>
                    <div className='product-short-description'>
                        <p>{product.description}</p>
                    </div>
                </div>
            </div>
            <div className='product-create-wrapper'>
                {product.earlyAccess ?
                  <button
                    className={`create-camp-btn early-access-btn ${!requireEarlyAccess && 'early-access-btn-disabled'}`}
                    onClick={earlyAccessHandler}>
                      {requireEarlyAccess ? 'Request Early Access' : 'Request Sent'}
                  </button>
                  :
                  <a
                    className='create-camp-btn'
                    href={product.link}
                    id={product.id}
                  >
                    Create
                  </a>
                }
            </div>
        </div>
    </div>
  )
}

export default ProductCard;