const prefix = '/wfv1/';

export const getWorkflowListUrl = (limit,offset, query, filterByTrigger, filterByStatus, sortBy, orderBy) => {
    let url = `${prefix}workflow_automation_list/?limit=${limit}&offset=${offset}`;
    if(query){
        url += `&search=${query}`;
    }
    if(filterByTrigger){
        url += `&trigger_event_name=${filterByTrigger}`;
    }
    if(filterByStatus){
        url += `&status=${filterByStatus}`;
    }
    if(sortBy){
        url += `&sort_by=${sortBy}`
    }
    if(orderBy){
        url += `&sort_order=${orderBy == 'ascend' ? 'asc' : 'desc'}`
    }
    return url;
}

export const postWorkflowDataUrl = (workflowId=null) => {
    if(workflowId){
        return `${prefix}workflow_automation/${workflowId}/`;
    }
    return `${prefix}workflow_automation/`;
}

export const getWorkflowDataUrl = (workflowId) => {
    return `${prefix}workflow_automation/${workflowId}/`;
}

export const getWorkflowStatusUrl = (workflowId) => {
    return `${prefix}workflow_automation/status/${workflowId}/`;
}

export const workflowStatusUpdaterUrl = (workflowStatus) => {
    return `${prefix}workflow_automation/status/${workflowStatus}/`;
}

export const getWorkflowStatsUrl = (workflowId) => {
    return `${prefix}workflow_automation/dashboard_statistics/${workflowId}/`;
}

export const getNodeStatusUrl = (workflowId, nodeId) => {
    return `${prefix}workflow_automation/node_statistics/${workflowId}/${nodeId}/`;
}

export const duplicateWorkflowUrl = (workflowId) => {
    return `${prefix}workflow_automation/duplicate/${workflowId}/`;
}

export const getAvailableConfigurationsUrl = () => {
    return `${prefix}workflow_automation/available_configurations/`
}

export const nodeCreateUrl = (object_type,campaign_type, search, selected) => {
    let url = `${prefix}workflow_node_create_data/`;
    if(object_type){
        url += `?object_type=${object_type}`;
    }
    if(campaign_type){
        url += `&campaign_type=${campaign_type}`;
    }
    if(search){
        url += `&search_term=${search}`;
    }
    if(selected){
        url += `&selected=${selected}`;
    }
    return url;
}

export const deleteWorkflowUrl = (workflow_id) => {
    return `${prefix}workflow_automation/${workflow_id}/`;
}

export const getWorkflowReportsUrl = (workflow_id, limit, offset, query, sortBy, orderBy) => {
    let url = `${prefix}workflow_automation/report/${workflow_id}/?limit=${limit}&offset=${offset}`;
    if(query){
        url += `&search=${query}`;
    }
    if(sortBy){
        url += `&sort_by=${sortBy}`
    }
    if(orderBy){
        url += `&sort_order=${orderBy == 'ascend' ? 'asc' : 'desc'}`
    }
    return url;
}

export const getNodeReportsUrl = (workflow_id, node_id, limit, offset, query, sortBy, orderBy) => {
    let url = `${prefix}workflow_automation/report/${workflow_id}/${node_id}/?limit=${limit}&offset=${offset}`;
    if(query){
        url += `&search=${query}`;
    }
    if(sortBy){
        url += `&sort_by=${sortBy}`
    }
    if(orderBy){
        url += `&sort_order=${orderBy == 'ascend' ? 'asc' : 'desc'}`
    }
    return url;
}

export const formatNodeDataUrl = () => {
    return `${prefix}workflow_automation/format_node_details/`;
}

export const getAvailableFiltersUrl = () => {
    return `${prefix}workflow_automation/available_filters/`;
}

export const getWorkflowSettingsUrl = () => {
    return `${prefix}workflow_automation/settings/`;
}

export const postExportUrl = (workflow_id, node_id) => {
    let url = `${prefix}workflow_automation/report_export/${workflow_id}/`;
    if(node_id){
        url += `${node_id}/`;
    }
    return url;
}

export const getUserMergeTagsUrl = () => {
    return `/v2/email-merge-tags/`;
}

export const getWebhookTestUrl = () => {
    return `${prefix}workflow_automation/webhook/test/`;
}

export const getWebhookStatsUrl = (workflow_id, node_id) => {
    return `${prefix}workflow_automation/webhook/status/${workflow_id}/${node_id}/`;
}

export const unpublishedWorkflowsMessageUrl = () => {
    return `${prefix}workflow_automation/unpublished_wf_stats/`
}