import React,{ useEffect, useState } from "react";
import MobileAppPromoImage from "../../../assets/images/mobile-app-promo.png";
import CancelIcon from "../../../assets/svg/CancelIcon";
import GooglePlayIcon from "../../../assets/svg/GooglePlayIcon";
import IStoreIcon from "../../../assets/svg/IStoreIcon";
import "./MobileDiscoverability.scss";
import { mobileDiscoverabilityStatus } from "../../configuration/mobileDiscoverConfig"
import {postMobileDiscoverabilityStatus} from "../../utilities/mobileDiscoverUtilities"
import P2pMobileImage from "../../../assets/images/P2pMobileImage.png"


export const MobileDiscoverBanner = (props)=>{
    const [bannerShow,setBannerShow] = useState(true);
    const BANNER_STATUS = {
        NOT_SEEN : 0,
        SEEN_IN_CAMPAIGN_FLOW : 1,
        SEEN_OUTSIDE_CAMPAIGN_FLOW : 2,
        SEEN : 3
    };
    const  get_segmentTrait = (segementTrait)=>{
        try {
            if(account_data_segment){
                segementTrait = {
                    ...segementTrait,
                    ...account_data_segment
                }
            }
        } catch (error) {
            console.log(error)
        }
        return segementTrait;
    }
    const handleBanner = () =>{
        setBannerShow(false);
        let data;
        let url = mobileDiscoverabilityStatus();
        if(props.componentData.Key == "InsideCampaign"){
            if(props.componentData.campaignType == 'p2p'){
                data={p2p_banner_status : BANNER_STATUS.SEEN}
            }else{
                if(props.componentData.existingStatus == BANNER_STATUS.SEEN_OUTSIDE_CAMPAIGN_FLOW){
                    data = {mobile_discover_status : BANNER_STATUS.SEEN};
                }
                else{
                    data = {mobile_discover_status : BANNER_STATUS.SEEN_IN_CAMPAIGN_FLOW};
                }
            }
        }
        else{
            if(props.componentData.existingStatus == BANNER_STATUS.SEEN_IN_CAMPAIGN_FLOW){
                data = {mobile_discover_status : BANNER_STATUS.SEEN};
            }
            else{
                data = {mobile_discover_status : BANNER_STATUS.SEEN_OUTSIDE_CAMPAIGN_FLOW};
            }
        }
        postMobileDiscoverabilityStatus(url,data,props.componentData.apiToken);
        if(window && window.analytics){
            let segementTrait = {
                'userId': props.componentData.userId,
                'email' : props.componentData.email,
                'source' : props.componentData.Page,
            }
            segementTrait = get_segmentTrait(segementTrait);
            window.analytics.track("manager-mobile-banner-closed", segementTrait);
        }
    }
    const handleSegments = (clicktype) => {
        if(window && window.analytics){
            let segementTrait = {
                'userId': props.componentData.userId,
                'email' : props.componentData.email,
                'source' : props.componentData.Page,
                'download Type' : clicktype
            }
            segementTrait = get_segmentTrait(segementTrait);
            window.analytics.track("manager-mobile-banner-clicked", segementTrait);
        }
    }
    return (
        (bannerShow ? <div className={props.componentData.campaignType !='p2p' ? "banner" : "p2pbanner"}>
            {props.componentData.campaignType !='p2p' ? <div className="bannerStrip"></div> : null}
            <div className="mobileImage">
                <img alt="mobileImage" src={props.componentData.campaignType !='p2p' ? MobileAppPromoImage : P2pMobileImage} width="100%"/>
            </div>
            {props.componentData.campaignType !='p2p' ? <div className="bannerContent">
                <p>CallHub Mobile App for Agents </p>
                <p>Save cost and increase efficiency by letting agents call and text from their smartphones</p>
            </div> :<div className="p2pbannerContent">
                <p>Introducing Peer to Peer texting in CallHub mobile app</p>
                <p>Increase agent’s efficiency 2x by letting them text on the go from their smartphones</p>
                </div>}
            <div className="bannerIcons" style={{right : props.componentData.Key == "InsideCampaign" ? "55px" : "70px"}}>
                <a aria-label="iphone app link" href="https://membr.us/CH-iPhoneApp/" target="_blank" onClick={()=>handleSegments("appstore")}><IStoreIcon /></a>
                <a aria-label="android app link" href="https://membr.us/CH-AndroidApp/" target="_blank" onClick={()=>handleSegments("playstore")}><GooglePlayIcon /></a>
            </div>
            <div className="bannerClose" onClick={handleBanner}>
                <CancelIcon />
            </div>
        </div> : <></>)
    )
}