import React, { useEffect, useState } from "react";
import { message, Modal, Spin } from 'antd';
import {ConnectPdiIntegration} from './Apis/apis';
import "./pdiIntegration.scss";

const PdiIntegrationConnectionModal = ()=>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [apiToken , setApiToken] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = async() => {
        try{
            if(!apiToken.length || !userName.length || !password.length){
                error('All fields are mandatory')
                return;
            }
            setShowLoading(true);
            let data = {
                api_token: apiToken,
                username: userName,
                password: password,
            }

            let response = await ConnectPdiIntegration(data);
            if(response.status === 200){
                let responseData = response.data.data.job_id
                localStorage.setItem('sync_pdi_data', JSON.stringify(responseData));
                window.location.href = '/pdi/import/';
            }else if(response.status === 400){
                error(response.error.error.message)
            }

        }catch(err){
            error('Oops something went wrong')
        }
      setIsModalOpen(false);
      setShowLoading(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleApiTokenChange = (event)=>{
        let value = event.target.value;
        setApiToken(value);

    }

    const handleUserNameChange = (event)=>{
        let value = event.target.value;
        setUserName(value);
    }

    const handlePasswordChange = (event)=>{
        let value = event.target.value;
        setPassword(value)
    }
    const error = (err) => {
        messageApi.open({
          type: 'error',
          content: err,
        });
    };

    useEffect(()=>{
        const button = document.getElementById("pdiIntegrationImport");
        button.addEventListener("click", function() {
            showModal();
        });
    },[])

    return(
        <div id='pdiConnectionModalContainer'>
            {contextHolder}
            <Modal title='Connect to PDI' open={isModalOpen} okText={'Connect'} onOk={handleOk} onCancel={handleCancel} className='pdiIntegrationModal' maskClosable={false} centered={true}>
                <div className='pdiIntegrationModalContainer'>
                    <div className="pdiIntegrationInputSections">
                        <div className="pdiUserNameSection">
                            <div className="pdiUserNameTitle">User name</div>
                            <input id='pdiUserNameInput' onChange={handleUserNameChange} value={userName}></input>
                        </div>
                        <div className="pdiPasswordSection">
                            <div className="pdiPasswordTitle">Password</div>
                            <input type="password" id='pdiPasswordInput' onChange={handlePasswordChange} value={password}></input>
                        </div>
                        <div className="pdiApiKeySection">
                            <div className="pdiApiKeyTitle">Api Token</div>
                            <input id='pdiIntegrationApiInput' onChange={handleApiTokenChange} value={apiToken}></input>
                        </div>
                        
                    </div>
                </div>
                {showLoading && <Spin size="large" />}
            </Modal>
        </div>
    )
}

export default PdiIntegrationConnectionModal