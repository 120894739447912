import React from 'react'

function ContactListRange2KycIcon(props) {
    return (
        <svg
            width={26}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M17.727 7.286c1.962 0 3.534-1.627 3.534-3.643S19.689 0 17.727 0c-1.962 0-3.545 1.627-3.545 3.643s1.583 3.643 3.545 3.643Zm-9.454 0c1.962 0 3.533-1.627 3.533-3.643S10.235 0 8.273 0 4.727 1.627 4.727 3.643s1.584 3.643 3.546 3.643Zm0 2.428C5.519 9.714 0 11.135 0 13.964V17h16.546v-3.036c0-2.829-5.52-4.25-8.273-4.25Zm9.454 0c-.343 0-.733.025-1.146.061 1.37 1.02 2.328 2.392 2.328 4.19V17H26v-3.036c0-2.829-5.52-4.25-8.273-4.25Z"
            fill="#F07635"
            />
        </svg>
    )
}

export default ContactListRange2KycIcon
