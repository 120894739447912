import { apiGetCall, apiPostCall, apiPutCall , apiDeleteCall} from '../../../../../src/utilities/utilities';
import {pdiQuestionUrl, pdiConnectUrl} from './config';

export const generatePdiQuestion = async(data, campaignId='',campaign_type="cc")=>{

    try{
        const pdi_question_url = pdiQuestionUrl(campaignId,campaign_type);
        const host = window.location.hostname;
        const response = await apiPostCall(`https://${host}/${pdi_question_url}`, data);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const ConnectPdiIntegration = async(data)=>{

    try{
        const pdi_connection_url = pdiConnectUrl();
        const host = window.location.hostname;
        const response = await apiPostCall(`https://${host}/${pdi_connection_url}`, data);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}

export const PdiQuestionType = async()=>{

    try{
        const pdi_question_url = pdiQuestionUrl();
        const host = window.location.hostname;
        const response = await apiGetCall(`https://${host}/${pdi_question_url}`);
        return response;
    }
    catch(error){
        return {
            error : error.response.data,
            status: error.response.status
        };
    }
}