import React, {useEffect, useState} from "react";
import { useRecoilState } from "recoil";
import { IsSortOrderToggledAtom } from "../../../RecoilSharedState/SortOrder/SortOrder";

import './Switch.scss';

const Switch = ({ sortOrderSwitchId, mainDivclassName, inputFieldName, switchClassName, jqueryComponentData }) => {
    const[isToggled, setIsToggled] = useRecoilState(IsSortOrderToggledAtom)

    const { isSortOrderToggled = true, action = 'add' } = jqueryComponentData
    useEffect(() => {
        setIsToggled(isSortOrderToggled)
    }, [isSortOrderToggled])


    return (
        <div 
            className={`toggle-switch ${mainDivclassName} ${(action == 'edit' && isToggled == true) ? 'switch-dis-none' : ''}`} 
            onClick={() => setIsToggled(!isToggled)}
        >
            <input 
                id = {sortOrderSwitchId}
                type="checkbox" 
                checked={isToggled}
                name={inputFieldName}
                onChange = {() => setIsToggled(!isToggled)}
            />
            <span className={`switch ${switchClassName}`} />
        </div>
    )
}

export default Switch