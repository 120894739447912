import React from "react";
import Switch from "../../../../components/common/Switch/Switch";
import InfoIcon from "../../../../../assets/svg/InfoIcon";
import DiscoverabilityButton from "../../../../components/common/DiscoverabilityButton/DiscoverabilityButton"
import { NEW_BUTTON_TEXT } from "../../../../constants/SortOrder";
import InfoToolTip from "../../../../components/common/InfoTooltip/InfoTooltip";
import { useRecoilValue } from "recoil";
import { P2PComponentDataAtom, IsSortOrderToggledAtom } from "../../../../RecoilSharedState/SortOrder/SortOrder";

import './Heading.scss';

const Heading = ({ sortOrderSwitchId,inputFieldName, label, tooltiptext }) => {

    const jqueryComponentData = useRecoilValue(P2PComponentDataAtom)
    const isSwitchToggled = useRecoilValue(IsSortOrderToggledAtom)
    const { action = 'add' } = jqueryComponentData

    return (
        <div className={`sort-order-container-display m-top-16 ${(action == 'edit' &&  !isSwitchToggled) ? 'container-display-none' : ''}`}>
            {/* <Switch 
                mainDivclassName=''
                inputFieldName={inputFieldName} 
                switchClassName = ''
                sortOrderSwitchId = {sortOrderSwitchId}
                jqueryComponentData = {jqueryComponentData}
            /> */}
            <div className="sort-order-container-display">
                <label className="sort-order-label">{label}</label>
                <InfoToolTip 
                    tooltiptext = {tooltiptext}
                    alignmentClasses = 'margin-right-14'
                    tooltiptextClasses = 'information-tooltip-text'
                >
                    <InfoIcon 
                        width={16} 
                        height={16} 
                        color={'#8C8C8C'}
                    />
                </InfoToolTip>
                
            </div>
           
        </div>
    )
}

export default Heading