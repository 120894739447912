import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard'
import "./GuidePageProductList.scss";
import RelationalDashboardIcon  from "../../../../assets/svg/RelationalDashBoardIcon";

function GuidePageProductList(props) {


    const { componentData } = props;
    const {
            apiToken,
            open_group_user,
            sms_opt_in_campaign_permission,
            snowflake_brandname,
            tcpa_permission,
            text_broadcast_campaign_permission,
            voice_broadcast_campaign_permission,
            email_campaign_permission,
            disable_product_p2p,
            disable_product_cc,
            disable_product_email,
            disable_product_tb,
            disable_product_sms_opt_in,
            disable_product_vb,
            has_relational_permission,
            user_email,
            user_id
        } = componentData;

    const [productDetailsConfig, setProductDetailsConfig] = useState([]);

    useEffect(() => {
        let productDetails = []

        if(!open_group_user) {
            
            const p2pDetail = (!disable_product_p2p)
                            ?
                        {
                            name: snowflake_brandname || "Peer to Peer Texting",
                            icon: <i className="icon-comments product-icon p2pIcoBg"></i>,
                            link: '/collective_texting/add/',
                            description: 'Engage people in one-to-one text message conversations at scale.',
                            earlyAccess: false,
                            id: 'p2p',
                            fullDescription: (!tcpa_permission) 
                                                    ?
                                            `Send batches of templated text messages, with volunteers having real conversations with the recipients, and record data based on their responses. Text message people who haven’t opted in without breaking regulations. Import surveys, event RSVPs and tags from your CRM.\n\nUse Peer to Peer Texting for GOTV, volunteer recruitment, event invites, fundraising or supporter engagement.`
                                                    :
                                            `Send personalized 1 to 1 texts to your list with the help of agents. A single can handle hundreds of conversations at a time. And since each text is manually initiated by a human, we help maintain regulatory compliance. Connect to CRMs like NationBuilder, NGPVAN or Action Network to sync your surveys and events.`,
                        }
                            :
                        null

            const ccDetail = (!disable_product_cc)
                            ?

                        {
                            name: "Call Center",
                            icon: <i className="icon-headphones product-icon ccIcoBg"></i>,
                            link: '/power_campaign/add/',
                            description: 'Create calling campaigns with multiple automated dialing options.',
                            earlyAccess: false,
                            id: 'cc',
                            fullDescription: (!tcpa_permission) 
                                                ? 
                                            `Call Center Campaigns will help you go through a list of people You can follow a script, take notes about the contacts or fill out surveys\n\nHistory of all past interactions, other people in their household, and other details about the person are displayed on the screen.\n\nLeave a pre-recorded message, if an answering machine answers the phone. All calls could be recorded for review`
                                                :
                                            `Call through lists of cellphones and landlines. Calls are manually initiated to help ensure regulatory compliance. Stressed for time? Assign an agent to click and queue calls for caller agents. Caller agents spend zero time dialing. Couldn’t reach a contact? Send a text follow-up. All surveys and events sync with CRMs like NationBuilder, NGPVAN or Action Network.`,
                        
                        }
                            :
                        null

            const vbDetail = ((!tcpa_permission || voice_broadcast_campaign_permission) && (!disable_product_vb)) 
                                ?
                            {
                                name: "Voice Broadcast",
                                icon: <i className="icon-bullhorn product-icon vbIcoBg"></i>,
                                link: '/voice_campaign/add/',
                                description: 'Send pre-recorded calls to large groups of people and collect responses.',
                                fullDescription: `Voice broadcasting campaign will call a list of contacts and play your message when the person answers the phone. You can provide key press options like transfer or do not call.\n\nYou can choose to play a different message when an answering machine answers the phone.`,
                                earlyAccess: false,
                                id: 'vb',
                            }
                                :
                            null;
            
            const smsDetail = ((!tcpa_permission || text_broadcast_campaign_permission) && (!disable_product_tb))
                            ?
                        {
                            name: "Text Broadcast",
                            icon: <i className="icon-comment product-icon tbIcoBg"></i>,
                            link: '/smsbroadcast/create/',
                            description: 'Text large groups in one go and handle responses automatically.',
                            fullDescription: `Text broadcast campaign will send text messages to your list of contacts. You can respond to every reply individually or setup automatic responses based on keywords, like RSVP.\n\nUse tags to personalize and address people by their first name or send custom URLs to contacts.`,
                            earlyAccess: false,
                            id: 'tb',
                        }
                            :
                        null;

            const smsOptDetail = ((!tcpa_permission || sms_opt_in_campaign_permission) && (!disable_product_sms_opt_in))
                            ?
                        {
                            name: "Text to Join",
                            icon: <i className="icon-thumbs-up product-icon smsOptInBg"></i>,
                            link: '/sms_campaign/signup/add/',
                            description: 'Build and grow your list by collecting opt-ins and user details.',
                            fullDescription: `Publish a phone number at your event and ask people to text to that number. Text to Join campaign will automatically get their name and email. It will then create their contact profile for you.\n\nPeople who attend your events are your biggest supporters. Do not miss a single person`,
                            earlyAccess: false,
                            id: 'smsoptin',
                        }
                            :
                        null;

            const emailDetail = (email_campaign_permission && (!disable_product_email))
                            ?
                        {
                            name: "Email",
                            icon: <i className="icon-envelope product-icon ecIcoBg"></i>,
                            link: '/product/email/campaign/create/',
                            description: 'Create email templates and reach large groups of people quickly.',
                            fullDescription: null,
                            promoText: "Free 100K emails",
                            earlyAccess: false,
                            id: 'email',
                        }
                            :
                        null;
            const relationalDetail = (has_relational_permission)
                            ?
                        {
                            name: "Relational Organizing",
                            icon: <div className="product-icon roIcoBg"><RelationalDashboardIcon /></div>,
                            link: '/relational-organizing/create/',
                            id: 'ro',
                            description: 'Utilize agent’s personal contacts for your campaign outreach',
                            fullDescription: "The contact list you upload is matched with your agent's phone contacts to find common contacts that form a network. To do the outreach in their network, agents use their own phone number and the initial scripts added by you. They fill out surveys to make progress in the campaign.\n\nUse this relational organizing effort to mobilize supporters, increase fundraising impact, and organize communities.",
                        }
                            :
                        null
            productDetails = [
                ccDetail,
                vbDetail,
                smsOptDetail,
                p2pDetail,
                smsDetail,
            ]
        } else {
            productDetails = [
                {
                    name: "Collective Calling",
                    icon: <i className="icon-headphones product-icon ccIcoBg"></i>,
                    link: '/power_campaign/add/',
                    description: 'Create calling campaigns with multiple automated dialing options.',
                    fullDescription: `Volunteers use on-screen contact information to manually dial contacts on their landlines, and cell phones as well.\n\nConversations remain on the phone while contact data is displayed and collected through Collective Calling`,
                    earlyAccess: false,
                    id: 'cc',
                },
                {
                    name: "Peer to Peer Texting",
                    icon: <i className="icon-comments product-icon p2pIcoBg"></i>,
                    link: '/collective_texting/add/',
                    description: 'Engage people in one-to-one text message conversations at scale.',
                    fullDescription: `Send batches of templated text messages, with volunteers having real conversations with the recipients, and record data based on their responses. Text message people who haven’t opted in without breaking regulations. Import surveys, event RSVPs and tags from your CRM.\n\nUse Peer to Peer Texting for GOTV, volunteer recruitment, event invites, fundraising or supporter engagement.`,
                    earlyAccess: false,
                    id: 'p2p',
                },
                {
                    name: "Text Broadcast",
                    icon: <i className="icon-comment product-icon tbIcoBg"></i>,
                    link: '/smsbroadcast/create/',
                    description: 'Text large groups in one go and handle responses automatically.',
                    fullDescription: `Text broadcast campaign will send text messages to your list of contacts. You can respond to every reply individually or setup automatic responses based on keywords, like RSVP.\n\nIt is easy to personalize your message for each contact. Use tags to address people by their first name or send custom urls for people to click.`,
                    earlyAccess: false,
                    id: 'tb',
                },
                {
                    name: "SMS Opt-in",
                    icon: <i className="icon-thumbs-up product-icon smsOptInBg"></i>,
                    link: '/sms_campaign/signup/add/',
                    description: 'Grow your mobile list by collecting opt-ins through texts.',
                    fullDescription: `Publish a phone number at your event and ask people to text to that number. SMS opt-in campaign will automatically get their name and email. It will then create their contact profile for you.\n\nPeople who attend your events are your biggest supporters. Do not miss a single person`,
                    earlyAccess: false,
                    id: 'smsoptin',
                },
            ]
        }

        setProductDetailsConfig(productDetails);
    }, []);

    return (
        <div className='guide-products-wrapper'>
            {productDetailsConfig.map((product, index) => {
                if(product === null) return null;
                return (
                    <ProductCard
                        key={product.name}
                        product={product}
                        index={index}
                    />
                )    
            })}
        </div>
    )
}

export default GuidePageProductList