import React, {useState, useEffect} from 'react';
import CommonSideModal from '../common/SideModalWrapper'
import DetailsContent from './detailsContent'
import CloseButton from "../../../../assets/svg/CloseIcon";
import "../common/SideModalWrapper.scss";
import PersonaliseTagIcon from '../../../../assets/svg/PersonaliseTagIcon';
import DetailsIcon from "../../../../assets/svg/DetailsIcon"



function DetailsWrapper(props){
    return (
        <CommonSideModal
            noOverlay = {true}
            id="edit_UTM_params_modal"
            modalClass={`sideModal animate-right ` + (props.isModalClosed ? "hide" : "show")}
            modalHeader={
                <>
                    <button
                        type="button"
                        className="close panel-modal-close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={props.closeDetailsModal}><CloseButton width="16" height="16" /></button>
                    <div className="side-modal-header panel-modal-header-title panel-modal-header-title-padding">
                    {
                        <>
                            <div className="heading-icon details-Heading-icon"><DetailsIcon/></div>
                                <div className="heading-text">
                                    <h3>URL Details</h3>
                                    <h5>View all insights and URL configurations</h5>
                                </div>
                        </>
                    }        
                    </div>
                </>
            }
            modalDialogClass = 'hp100'
            modalBodyId="side-modal-body"
            activeModalScreen = {
                <DetailsContent 
                    addToScript ={props.addToScript} 
                    shortUrlDetails={props.shortUrlDetails} 
                    click_data = {props.click_data}
                    campaign_names = {props.campaign_names}
                    addToScriptButton = {props.addToScriptButton}
                    duplicateShortenUrl={props.duplicateShortenUrl}/>
            }
            footerClassName={"panel-modal-footer m-0 p-0 side-modal-footer"}
            footerContent = {
                <>
                    <a className="btn btn-default mr-5 modal-button-padding common-modal-button-margin" id="details-footer"
                    data-dismiss="modal" onClick={props.closeDetailsModal}>Cancel</a>
                    
                </>
            }
        />
    )
}

export default DetailsWrapper;
