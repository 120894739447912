import React, { useEffect, useState } from "react";
import {PROFANVE_MESSAGE_TITLE, PROFANVE_MESSAGE_DETAILS, CONTACTS_SENDING_PROFANE, CONTACTS_SENDING_PROFANE_DETAILS} from './AgentProfanityConstants';
import {trackSegementEvents} from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant'

const AgentProfanityIsEnabled = ({profaneData, campaignId, user_name, user_email}) =>{

    const handleSeeContacts = () =>{
        trackSegementEvents('pf-click-see-contacts',{
            'username':user_name,
            'email': user_email,
        })
    }

    const handleViewMessages = () =>{
        trackSegementEvents('pf-click-view-message',{
            'username':user_name,
            'email': user_email,
        })
    }

    return(
        <div className="agent-profanity-is-enabled-container">
            <div className="profanity-message-section">
                <div className="profanity-message-details-title">
                    {PROFANVE_MESSAGE_TITLE}
                </div>
                <div className="profanity-message-details-count">{profaneData.profane_messages}</div>
                <div className="profanity-message-details">{profaneData.unique_agents} agents have been protected against {profaneData.profane_messages} profane messages <a href={`/collective_texting/details/${campaignId}/result/?id_status=7`} target="_blank" onClick={handleViewMessages}>View messages</a></div>
            </div>
            <div className="profanity-message-section">
                <div className="profanity-message-details-title">
                    {CONTACTS_SENDING_PROFANE}
                </div>
                <div className="profanity-message-details-count">{profaneData.unique_contacts}</div>
                <div className="profanity-message-details">{CONTACTS_SENDING_PROFANE_DETAILS} <a href={`/collective_texting/details/${campaignId}/result/?id_status=7`} target="_blank" onClick={handleSeeContacts}>See contacts</a></div>
            </div>
        </div>
    )
}

export default AgentProfanityIsEnabled