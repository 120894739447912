import React, {useState, useEffect} from 'react';
import CommonSideModal from '../common/SideModalWrapper'
import EditPersonalizeContent from './editPersonalizedTagsContent'
import "../common/SideModalWrapper.scss";
import PersonaliseTagIcon from '../../../../assets/svg/PersonaliseTagIcon';


function EditPersonalisedParams(props){
    //add function for active screen
    let personalizeParamsStr = "";
    const {personalizeParams,  setPeronsonaliseParams} = props;
    const [errorMessage, setErrorMessage] =  useState("");
    const [errorSelectMessage, setErrorSelectMessage] =  useState("");
    const [errorMessageIndex, setInputErrorMessageIndex] = useState([]);
    const [selectErrorMessageIndex, setSelectErrorMessageIndex] = useState([]);

    let errInputMessage = false, errSelectMessage = false
    function personaliseParamsObject(personaliseParamsObj){
        setErrorMessage('')
        setInputErrorMessageIndex([]);
        setErrorSelectMessage('')
        setSelectErrorMessageIndex([]);
        for(let i = 0; i < personaliseParamsObj.length;i++){
            if(personaliseParamsObj[i].paramName !== "" && personaliseParamsObj[i].paramValue !== ""){
                if(i === 0){
                    personalizeParamsStr += `${personaliseParamsObj[i].paramName}=${personaliseParamsObj[i].paramValue}`
                }else{
                    personalizeParamsStr += `&${personaliseParamsObj[i].paramName}=${personaliseParamsObj[i].paramValue}`
                }
            } else {
                if(personaliseParamsObj[i].paramName === "") {
                    setErrorMessage("Provide a valid value. eg: Name")
                    setInputErrorMessageIndex(errorMessageIndex => [...errorMessageIndex, i])
                    errInputMessage = true;
                } 
                if(personaliseParamsObj[i].paramValue === "") {
                    setErrorSelectMessage("Provide a valid value. eg: {first_name}")
                    setSelectErrorMessageIndex(selectErrorMessageIndex => [...selectErrorMessageIndex, i])
                    errSelectMessage=true;
                }
            }

        }
    }
    const addMergeField = () => {
        personaliseParamsObject(personalizeParams);
        if(errInputMessage === false && errSelectMessage === false) {
            props.setPeronsonaliseString(personalizeParams);
            props.closeEditPersonalizeParamsModal();
        }
        
    }
    
    return (
        <CommonSideModal
            noOverlay = {true}
            id="edit_UTM_params_modal"
            modalClass={`sideModal animate-right personlize-params ` + (props.isModalClosed ? "hide" : "show")}
            modalHeader={
                <div className="side-modal-header  panel-modal-header-title panel-modal-header-title-padding">
                     {
                        <>
                            <div className="heading-icon" id="shorten_url_personaliseTag"><PersonaliseTagIcon width={24} height={24}/></div>
                                <div className="heading-text">
                                    <h3>Merge Fields</h3>
                                    <h5>Personalise your message by adding merge fields</h5>
                                </div>
                        </>
                    }
                   
                </div>
            }
            sideModalWrapper="personlizeSideModalWrapper"
            modalDialogClass = 'hp100'
            modalBodyId="side-modal-body"
            activeModalScreen = {
                <EditPersonalizeContent 
                    personaliseTagsList = {props.personaliseTagsList}
                    personaliseParamsObject = {setPeronsonaliseParams}
                    personalizeParams = {personalizeParams}
                    errorMessage = {errorMessage}
                    errorMessageIndex = {errorMessageIndex}
                    selectErrorMessageIndex = {selectErrorMessageIndex}
                    errorSelectMessage = {errorSelectMessage}
                    setErrorMessage = {setErrorMessage}
                    setErrorSelectMessage = {setErrorSelectMessage}
                />
            }
            footerClassName={"panel-modal-footer m-0 p-0 side-modal-footer"}
            footerContent = {
                <>
                    <a className="btn btn-default mr-5 modal-button-padding common-modal-button-margin"
                    data-dismiss="modal" onClick={props.closeEditPersonalizeParamsModal}>Cancel</a>
                    <a id="modal-discard-btn"
                    className={`btn common-modal-button-margin mr-20 w146`} onClick={addMergeField}>Add merge fields</a>
                    
                </>
            }
        />
    )
}

export default EditPersonalisedParams;
