import React, { useEffect, useState } from "react";
import OverAllSentiment from './OverAllSentiment/OverAllSentiment';
import SentimentOvertime from './SentimentOvertime/SentimentOvertime';
import CallSentiments from './CallSentiments/CallSentiments';
import ContactCategories from './ContactCategories/ContactCategories';

const CcAiPowerd = ({ componentData }) => {

    useEffect(()=>{
        handleSegmentTrackEvent('testing-segment')
    },[])
 
    return (
        <div id='ccAiPowerdContainer'>
            {componentData.has_smart_insight_permission && 
             <>
                <CallSentiments
                    campaign_id = {componentData.campaign_id}
                />
                <hr></hr>
                <OverAllSentiment></OverAllSentiment>
                <hr></hr>
                <SentimentOvertime/>
             </>
            }
            {componentData.has_auto_tag_permission &&
                <ContactCategories></ContactCategories>
            }
        </div>
    )
}

export default CcAiPowerd