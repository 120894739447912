import Tag  from "antd/lib/tag";
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntTag.scss";
import React, {useEffect, useState, useRef} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Loading from "../../../assets/svg/Loading";
import { audioPrimaryKeysState, inboundCallingDataState, isDynamicCallerIdCheckedState, isInboundDataPresent, isValidatedNumberCheckedSatate, loaderState, mediaAudioFileState, showInboundFormState, stepperCurrentState } from "../../RecoilSharedState/InboundCalling/InboundCallingRecoile";
import { StepperAnt } from "../common/CustomAntDesignComponents/StepperAnt/StepperAnt";
import AddHoldMusic from "./AddHoldMusic";
import CallerIdNudge from "./CallerIdNudge";
import InboundCallingNudge from "./InboundCallingNudge";
import "./InboundCallingStyles.scss";
import WelcomeMessage from "./WelcomeMessage";
import CheckBox from 'antd/lib/checkbox'
import "../../screens/react_library_for_jquery/AntDesignCustomStyles/AntCheckbox.scss";


function InboundCallingContainer({componentData}) {
    const [current, setCurrent] = useRecoilState(stepperCurrentState);
    const [inboundCallingData, setInboundCallingData] = useRecoilState(inboundCallingDataState);
    const [showInboundForm, setShowInboundForm] = useRecoilState(showInboundFormState);
    const [isDataPresent, setIsDataPresent] = useRecoilState(isInboundDataPresent);
    const [audioFiles, setAudioFiles] = useRecoilState(mediaAudioFileState)
    const stepsCount = useRef(0);
    const showForm = useRef(false);
    showForm.current = showInboundForm;
    stepsCount.current = current;
    const [audioPrimaryKeys, setAudioPrimaryKeys] = useRecoilState(audioPrimaryKeysState)
    const [isDynamicCallerIdChecked, setIsDynamicCallerIdChecked] = useRecoilState(isDynamicCallerIdCheckedState)
    const [isValidatedNumberChecked, setIsValidatedNumberChecked] = useRecoilState(isValidatedNumberCheckedSatate)
    const isLoading = useRecoilValue(loaderState);
    const [inboundTextError, setInboundTextError] = useState(false);
    const [thankyouMessageTextError, setThankyouMessageTextError] = useState(false)
    const welcomeMessageErrorr = useRef(false);
    welcomeMessageErrorr.current = inboundTextError
    const welcomeAudioSelected = useRef(false);
    welcomeAudioSelected.current = inboundCallingData.welcomeAudioSelected;
    const thankyouMessageError = useRef(false);
    thankyouMessageError.current = thankyouMessageTextError;
    const thankyouMessageAudioSelected = useRef(false);
    thankyouMessageAudioSelected.current = inboundCallingData.thankyoumessageAudioSelected

    const hanldeStepschange = (currentStepValue) => {
        setCurrent(currentStepValue)
    }


    let renderSteps = [
        {
            title : "Welcome message",
            component : <WelcomeMessage componentData={componentData} inboundTextError={inboundTextError} setInboundTextError={setInboundTextError} />
        },
        {
            title : "Add hold music",
            component : <AddHoldMusic componentData={componentData} thankyouMessageTextError={thankyouMessageTextError} setThankyouMessageTextError={setThankyouMessageTextError}/>
        }
    ]

    

    const handleCheckIsDataPresent = () => {
        const nextButton = document.getElementById("wizard-next")
        nextButton.addEventListener('click', function() {
            if (showForm.current) {
                const isInboundCallingDataPresent = JSON.parse(sessionStorage.getItem("isInboundCallingDataPresent"));
                if((welcomeMessageErrorr.current) && (!welcomeAudioSelected.current)) {
                    setCurrent(0);
                } else if ((thankyouMessageError.current) && (!thankyouMessageAudioSelected.current)){
                    setCurrent(1);
                } else if (isInboundCallingDataPresent && isInboundCallingDataPresent.isPresent == false) {
                    if (stepsCount.current === 0) {
                        setCurrent(current + 1)
                    }
                    if ((stepsCount.current === 2) || (stepsCount.current > 1)) {
                        setCurrent(1)
                    }
                    setIsDataPresent(false);
                }
            }
        })
    }

    useEffect(() => {
        handleCheckIsDataPresent()
        const sessionInboundData = JSON.parse(sessionStorage.getItem("inboundCallingData"));
        if(sessionInboundData !==  null) {
            setInboundCallingData({
                ...inboundCallingData,
                welcomeText: sessionInboundData.welcomeText,
                maximumHoldSeconds: sessionInboundData.maximumHoldSeconds,
                ThankyouMessageText: sessionInboundData.ThankyouMessageText === "" ? inboundCallingData.ThankyouMessageText : sessionInboundData.ThankyouMessageText,
                welcomeAudioFileName: sessionInboundData.welcomeAudioFileName,
                holdMusicAudioFileName: sessionInboundData.holdMusicAudioFileName,
                thnakyouMessageAudioFileName: sessionInboundData.thnakyouMessageAudioFileName,
                welcomeAudio: sessionInboundData.welcomeAudio === "DEFAULT" ? "https://static.callhub.io/brand/callhub/samples/welcome_message_default_inbound_calling.mp3" : sessionInboundData.welcomeAudioUrl,
                holdMusic : sessionInboundData.holdMusic === "DEFAULT" ? "https://static.callhub.io/brand/callhub/samples/valse-gymnopedie-hold-music.mp3" : sessionInboundData.holdMusicUrl,
                ThankyouMessageAudio: sessionInboundData.ThankyouMessageAudio === "DEFAULT" ? "https://static.callhub.io/brand/callhub/samples/contact_exceeds_default_wait_time_default_inbound_calling.mp3" : sessionInboundData.ThankyouMessageAudioUrl,
                welcomeAudioSelected: sessionInboundData.welcomeAudioSelected,
                thankyoumessageAudioSelected: sessionInboundData.thankyoumessageAudioSelected,
                OutsideCampaignSubscriberAllowed: sessionInboundData.OutsideCampaignSubscriberAllowed
            })
            setShowInboundForm(sessionInboundData.showInboundForm)
            const mediaFiles = JSON.parse(sessionStorage.getItem("mediaAudioFiles"));
            setAudioFiles(mediaFiles);
            sessionStorage.setItem('isInboundSelected', JSON.stringify(true))
        } else {
            setInboundCallingData({ 
                ...inboundCallingData,
                welcomeText: componentData.inboundData.inbound_welcome_text !== "" ? componentData.inboundData.inbound_welcome_text : inboundCallingData.welcomeText,
                maximumHoldSeconds: componentData.inboundData.inbound_max_wait_seconds !== "" ? componentData.inboundData.inbound_max_wait_seconds : inboundCallingData.maximumHoldSeconds,
                ThankyouMessageText: componentData.inboundData.inbound_thankyou_message_text !== "" ? componentData.inboundData.inbound_thankyou_message_text : inboundCallingData.ThankyouMessageText,
                welcomeAudioFileName: componentData.inboundData.inbound_welcome_audio_file_name !== "" ? componentData.inboundData.inbound_welcome_audio_file_name : "",
                holdMusicAudioFileName: componentData.inboundData.inbound_hold_music_audio_file_name !== "" ? componentData.inboundData.inbound_hold_music_audio_file_name  : "",
                thnakyouMessageAudioFileName: componentData.inboundData.inbound_thankyou_message_audio_file_name !== "" ? componentData.inboundData.inbound_thankyou_message_audio_file_name : "",
                welcomeAudio: componentData.inboundData.inbound_welcome_audio !== "" ? componentData.inboundData.inbound_welcome_audio : inboundCallingData.welcomeAudio,
                holdMusic: componentData.inboundData.inbound_hold_music_audio !== "" ? componentData.inboundData.inbound_hold_music_audio : inboundCallingData.holdMusic,
                ThankyouMessageAudio: componentData.inboundData.inbound_thankyou_message_audio !== "" ? componentData.inboundData.inbound_thankyou_message_audio : inboundCallingData.ThankyouMessageAudio,
                welcomeAudioSelected: componentData.inboundData.inbound_max_wait_seconds !== "" ? componentData.inboundData.inbound_welcome_audio_selected === "True" ? true : false : inboundCallingData.welcomeAudioSelected,
                thankyoumessageAudioSelected: componentData.inboundData.inbound_max_wait_seconds !== "" ? componentData.inboundData.inbound_thankyou_audio_selected === "True"? true : false : inboundCallingData.thankyoumessageAudioSelected,
                welcomeAudioUrl: componentData.inboundData.inbound_welcome_audio,
                holdMusicUrl: componentData.inboundData.inbound_hold_music_audio,
                ThankyouMessageAudioUrl: componentData.inboundData.inbound_thankyou_message_audio,
                OutsideCampaignSubscriberAllowed: componentData.inboundData.is_outside_campaign_subscriber_allowed
            })
            setShowInboundForm(componentData.inboundData.show_inbound_form)
            setAudioPrimaryKeys({...audioPrimaryKeys, 
                welcomeAudioKey: componentData.inboundData.inbound_welcome_audio_pk !== "" ? componentData.inboundData.inbound_welcome_audio_pk : "",
                holdAudioKey: componentData.inboundData.inbound_hold_music_audio_pk !== "" ? componentData.inboundData.inbound_hold_music_audio_pk : "",
                thankyouAudioKey: componentData.inboundData.inbound_thankyou_audio_pk !== "" ? componentData.inboundData.inbound_thankyou_audio_pk : ""
            })
            if(componentData.inboundData.show_inbound_form) {
                sessionStorage.setItem('isInboundSelected', JSON.stringify(true))
            } else {
                sessionStorage.removeItem("isInboundSelected")
            }
            
        }
     }, [])
 
     

    useEffect(() => {
    }, [inboundCallingData])

   



    return (
        <div id="inbound-calling-container">
            <InboundCallingNudge componentData={componentData}/> 
            {isLoading ? <div className="inbound-loading-wrapper"><Loading size={100}/></div> : showInboundForm ? isValidatedNumberChecked ? <CallerIdNudge componentData={componentData} /> :
            // 
             <><Tag color="default">DURING OPERATIONAL HOURS</Tag>
            <div id='inbound-calling-steps'>
                <StepperAnt 
                    direction="vertical"
                    current={current}
                    renderSteps={renderSteps}
                    progressDot={true}
                    handleOnchange={hanldeStepschange}
                />
            </div></> : null
            }
        </div>
    )
}

export default InboundCallingContainer;