import * as React from "react"

const ProfileIcon = (props) => (
  <svg
    width={props.width}
    height={props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.414 10.931a5.843 5.843 0 0 0-1.26-1.867 5.869 5.869 0 0 0-1.866-1.26l-.02-.007a3.874 3.874 0 1 0-4.537.002l-.018.007c-.7.296-1.329.719-1.868 1.26a5.87 5.87 0 0 0-1.72 4.025.125.125 0 0 0 .125.128h.938a.125.125 0 0 0 .125-.122 4.658 4.658 0 0 1 1.371-3.192A4.657 4.657 0 0 1 6 8.53c1.253 0 2.43.488 3.316 1.374a4.658 4.658 0 0 1 1.371 3.192.124.124 0 0 0 .126.122h.937a.125.125 0 0 0 .125-.128 5.823 5.823 0 0 0-.46-2.16ZM6 7.344a2.67 2.67 0 0 1-1.9-.788 2.67 2.67 0 0 1-.787-1.9c0-.717.28-1.392.787-1.9A2.67 2.67 0 0 1 6 1.97c.717 0 1.392.28 1.9.787a2.67 2.67 0 0 1 .788 1.9 2.67 2.67 0 0 1-.788 1.9 2.67 2.67 0 0 1-1.9.788Z"
      fill={props.fill}
      fillOpacity={0.88}
    />
  </svg>
)

export default ProfileIcon