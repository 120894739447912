import React, { useEffect, useState } from "react";
import ExclamationCircleIcon from "../../../../assets/svg/ExclamationCircleIcon";

import "./NotificationSwitch.scss";

const NotificationSwitch = ({
  switchId,
  inputFieldName,
  jqueryComponentData,
  warningMessage,
}) => {
  const [isToggled, setIsToggled] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const { defaultValue = true } = jqueryComponentData;
  useEffect(() => {
    setIsToggled(defaultValue);
  }, [defaultValue]);

  const handleOnClick = () => {
    // setIsToggled(prev => !prev);
    if(isToggled){
        setModalVisible((prev) => !prev);
    }else{
        setIsToggled(prev => true);
        // pass
    }    
  };

  const cancelNotification = () => {
    // setIsToggled(prev => !prev);
    setModalVisible((prev) => !prev);
    setIsToggled(prev => false)
    
  };

  useEffect(() => {
    document.body.classList.toggle('lock-background-scroll', modalVisible);
  },[modalVisible])

  return (
    <>
      <div
        className={`notification-toggle-switch`}
        onClick={handleOnClick}
      >
        <input
          id={switchId}
          type="checkbox"
          checked={isToggled}
          name={inputFieldName}
          onChange={() => {}}
        />
        <span className={`switch`} />
      </div>
      {modalVisible && (
        <div
          className="notification-warning-modal-overlay"
          onClick={(event) => setModalVisible((prev) => !prev)}
        >
          <div
            className="notification-warning-modal"
            onClick={(event) => event.stopPropagation()}
          >
            <ExclamationCircleIcon />
            <p className="notification-warning-text">{warningMessage}</p>

            <p> </p>
            <div className="notification-warning-modal-btn-container">
              <button
                className="notification-warning-modal-cancel-btn"
                onClick={() => setModalVisible((prev) => !prev)}
              >
                Cancel
              </button>
              <button className="notification-warning-modal-accept-btn"
                onClick={cancelNotification}
                type="button"
              >
                Yes, don’t notify
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationSwitch;
