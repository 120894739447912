import React from 'react'

const CloseIcon = props => (
  <svg
    overflow="visible"
    preserveAspectRatio="none"
    viewBox="0 0 514 514"
    width={props.size}
    height={props.size}
    {...props}
  >
    <path
      fill={props.color ? props.color : "#999"}  //"#999"
      className="close-icon"
      d="M257.08 200.242L412.486 44.836c15.61-15.609 40.917-15.609 56.526 0 15.61 15.61 15.61 40.917 0 56.526L313.606 256.768l155.687 155.687c15.61 15.61 15.61 40.917 0 56.526-15.61 15.61-40.917 15.61-56.526 0L257.08 313.294 101.233 469.142c-15.61 15.609-40.917 15.609-56.526 0-15.61-15.61-15.61-40.917 0-56.526l155.847-155.848L45.02 101.233c-15.61-15.61-15.61-40.917 0-56.526 15.61-15.61 40.917-15.61 56.526 0L257.08 200.242z"
      vectorEffect="non-scaling-stroke"
      fillRule="evenodd"
    />
  </svg>
)

export default CloseIcon;