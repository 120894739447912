import React, { useEffect,useState } from 'react';
import "./ChooseContactList.scss";
import "./TrailGiftContainer.scss";

import ContactListRange1KycIcon from '../../../../assets/svg/ContactListRange1KycIcon';
import ContactListRange2KycIcon from '../../../../assets/svg/ContactListRange2KycIcon';
import ContactListRange3KycIcon from '../../../../assets/svg/ContactListRange3KycIcon';
import ContactListRange4KycIcon from '../../../../assets/svg/ContactListRange4KycIcon';
import ContactListRange5KycIcon from '../../../../assets/svg/ContactListRange5KycIcon';
import {ContactListRangeData} from './KycConstants';
import Form from 'antd/lib/form'
import Radio from 'antd/lib/radio'

import "../AntDesignCustomStyles/AntForm.scss";
import "../AntDesignCustomStyles/AntInput.scss";
import "../AntDesignCustomStyles/AntCheckbox.scss";
import "../AntDesignCustomStyles/AntRadio.scss";
import "../AntDesignCustomStyles/AntMessage.scss";

export function ChooseContactList(props){
    let [form] = Form.useForm()
    let [contactListValue , setContactListValue] = useState([]);

    useEffect(() => {
        const data = ContactListRangeData();
        setContactListValue(data);
    },[])

    return(
        <div className="third_slide">
            <div className="slide_three_heading">How big is your contact list?</div>
            <div className="mobile-slide_three_heading">
                <p className="heading">Contact list size?</p>
                <p className='sub-heading'>Your choices won’t limit what you can do in {props.getBrandName}</p>
            </div>
            <div className='contact-list-form'>
                <Form
                    layout="vertical" 
                    className="contact_list_form"
                    requiredMark="optional"
                    form={form}
                    name="kyc-form-3"
                    scrollToFirstError={true}
                >
                    <Form.Item
                        required={true}
                        rules={[{required:true,message:"Select at least one option"}]}
                        name="contactListRange"
                    >
                        <Radio.Group className='kyc-contactList-radioGroup'>
                            <Radio.Button value={contactListValue.one}>
                                <div className="contact_container">
                                    <ContactListRange1KycIcon></ContactListRange1KycIcon>
                                    <p> Less than 5,000 </p>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                            <Radio.Button  value={contactListValue.two} >
                                <div className="contact_container">
                                    <ContactListRange2KycIcon></ContactListRange2KycIcon>
                                    <p> 5000 - 10,000</p>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                            <Radio.Button value={contactListValue.three} >
                            
                                <div className="contact_container">
                                    <ContactListRange3KycIcon></ContactListRange3KycIcon>
                                    <p> 10,000 - 50,000</p>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                            <Radio.Button value={contactListValue.four} >
                                <div className="contact_container">
                                    <ContactListRange5KycIcon></ContactListRange5KycIcon>
                                    <p> More than 50,000</p>
                                    <div className="abs-radio">
                                        <div className="outer-circle">
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </Radio.Button>
                        </Radio.Group>

                    </Form.Item>
                    <Form.Item>
                        <button className='kyc-continue-button' type="submit" onClick={()=>{props.handleContinue(4 , form.getFieldValue(), form)}}>Next</button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}