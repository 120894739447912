import React from "react";
import { FilterIcon } from "../../../../assets/svg/FilterIcon";
import { SingleUserIcon } from "../../../../assets/svg/SingleUserIcon";
import {TwoArrowIcon} from "../../../../assets/svg/TwoArrowIcon"
export const metaTitleAPI = "/url_shortener/url_title/";
export const availableDomainsAPI = "/url_shortener/available_domains/";
export const createShortURLAPI = '/url_shortener/create/';
export const searchShortenURL = "/url_shortener/search_filter/?campaign_creation=";
export const mergeTagsAPI = '/v2/merge-tags/';
export const urlDetailsAPI = '/url_shortener/short_url_details/';
export const generateIdentifierAPI = "/url_shortener/generate_identifier/";
export const urlValidationAPI = "/url_shortener/validate_url/";
export const validateFixedPrefixAPI = "/url_shortener/validate_fixed_prefix/";
export const validateTitleAPI = "/url_shortener/validate_title/";
export const validateUrlAndGetTitleApi = "/url_shortener/validate_url_and_get_title/";

export const shortenUrlPopupConstants = (charSaved=0) => {

    let constants = [
      {
        'title': `Save ${charSaved} characters`,
        'icon': <TwoArrowIcon/>,
      },
      {
        'title': 'Track contacts who click',
        'icon': <SingleUserIcon/>,
      },
      {
        'title': 'Prevent carrier filtering',
        'icon': <FilterIcon/>,
      }
    ]
    if(charSaved < 1){
        constants.shift()
    }
    return constants
  
  } 