import React from 'react'
import PlanCard from "./PlanCard";
import "./AllPlanTypeCards.scss";

function AllPlanTypeCards(props) {
    const {
        width,
        allPlancardData,
        planType,
        buttonStates,
        currentPlanBillingPeriod,
        currentPlanName,
        billingRankings,
        trialData,
        handleDowngrade,
        minTextCallCost,
        apiToken,
        daysLeft,
        isEmailVerified,
        upgradeButtonClicked
    } = props;

    const getIsCurrentPlanCard = (plan, currentPlanName) => {
        return plan.toLowerCase() === currentPlanName.toLowerCase()
        || `${plan.toLowerCase()} plan` === currentPlanName.toLowerCase()
        || plan.toLowerCase() === `${currentPlanName.toLowerCase()} plan`;
    } 

    const getIsCurrentPlan = (currentPlanBillingPeriod, planType) => {
        return currentPlanBillingPeriod.toLowerCase().replace(" ", "-") === planType.toLowerCase().replace(" ", "-")
    }

    const isLitePlan = (planName) => {
        return planName.toLowerCase() === "lite" || planName.toLowerCase().replace(" ", "-") === "lite-plan";
    }

    const isEnterPrisePlan = (planName) => {
        return planName.toLowerCase() === "enterprise" || planName.toLowerCase().replace(" ", "-") === "enterprise-plan";
    }

    return (
        <div className="disp-flex" style={{width: width}}>

            {
                allPlancardData.map((cardData, index) => {
                    let isCurrentPlanCard = getIsCurrentPlanCard(cardData.planName, currentPlanName);
                    let isCurrentBillingPeriod = getIsCurrentPlan(currentPlanBillingPeriod, planType);
                    let isCurrentPlan = isCurrentBillingPeriod && isCurrentPlanCard;
                    let buttonText = buttonStates[index];
                    let forceDisablePlanSelectButton = (cardData.planName.toLowerCase() === 'lite' && (currentPlanName.toLowerCase() === 'lite plus' || currentPlanName.toLowerCase() === 'lite plus plan'));
                    if(isCurrentPlanCard) {
                        let rankingIndexCurrentPlan = billingRankings.indexOf(currentPlanBillingPeriod.toLowerCase());
                        let planTypeIndex = billingRankings.indexOf(planType.toLowerCase());
                        if(planTypeIndex < rankingIndexCurrentPlan) {
                            buttonText = "Downgrade";
                        }
                    }
                    if((isLitePlan(cardData.planName) && getIsCurrentPlanCard("lite", currentPlanName))
                    || (isEnterPrisePlan(cardData.planName) && getIsCurrentPlanCard("enterprise", currentPlanName))) {
                        buttonText = buttonStates[index];
                        isCurrentPlan = true;
                    }
                    if(trialData.isTrial || trialData.isAllPlansPostTrial) {
                        buttonText = "Choose";
                        isCurrentPlan = false;
                    }
                    return <PlanCard 
                        multipleCards={true} 
                        rightBorder={index < allPlancardData.length - 1 ? true : false} 
                        width={224} 
                        key={index}
                        cardData={cardData}  
                        planType={planType} 
                        buttonText={buttonText}
                        isCurrentPlan={isCurrentPlan}
                        handleDowngrade={handleDowngrade}
                        trialData={trialData}
                        minTextCallCost={minTextCallCost}
                        apiToken={apiToken}
                        daysLeft={daysLeft}
                        isEmailVerified={isEmailVerified}
                        upgradeButtonClicked={upgradeButtonClicked}
                        forceDisablePlanSelectButton={forceDisablePlanSelectButton}
                    />
                })
            }
        </div>
    )
}

export default AllPlanTypeCards
