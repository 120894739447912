import React, { useEffect } from "react";
import CallSentimentCreation from './CallSentimentCreation/CallSentimentCreation';
import ContactCategorization from './ContactCategorization/ContactCategorization';


const SmartInsight = ({ componentData }) => {
 
    return (
        <div id='SmartInsightContainer'>
            <CallSentimentCreation
                campaign_id={componentData.campaign_id}
                campaign_goal={componentData.campaign_goal}
                campaign_action = { componentData.campaign_action}
                sentiment_enabled={componentData.sentiment_enabled}
                survey_id = {componentData.survey_id}
                is_campaign_live = {componentData.is_campaign_live}
            />
            <hr></hr>
            {componentData.auto_tag_permission &&
            <ContactCategorization
                auto_tags_enabled={componentData.auto_tags_enabled}
                selected_auto_tags={componentData.selected_auto_tags}
                survey_id = {componentData.survey_id}
                campaign_action = { componentData.campaign_action}
                is_campaign_live = {componentData.is_campaign_live}
            />}
        </div>
    )
}

export default SmartInsight