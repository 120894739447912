import React from 'react'

const AgentProfanityIcon = props => (
    <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={17} height={17} rx={1} fill="#7D72DC" />
    <path
      d="M8 0H16C16.5523 0 17 0.447715 17 1V16C17 16.5523 16.5523 17 16 17H8V0Z"
      fill="#494190"
    />
    <path
      d="M8.6718 12L8.11995 10.1882H5.34505L4.79319 12H3.05432L5.74072 4.35731H7.71386L10.4107 12H8.6718ZM7.73469 8.83464C7.22448 7.19295 6.93641 6.26451 6.87046 6.04932C6.80799 5.83413 6.76287 5.66406 6.7351 5.53912C6.62056 5.98338 6.29257 7.08188 5.75113 8.83464H7.73469ZM11.3738 12V4.38855H12.9877V12H11.3738Z"
      fill="#FDFDFD"
    />
    <path
      d="M8 0H16C16.5523 0 17 0.447715 17 1V16C17 16.5523 16.5523 17 16 17H8V0Z"
      fill="black"
      fillOpacity={0.16}
    />
  </svg>
)

export default AgentProfanityIcon;