import React, { useEffect, useState } from "react";
import {CALL_SENTIMENTS_TITLE, SMART_INSIGHT_BETA_POPOVER} from '../../../../constants/CcAiPowerdConstant';
import AgentFilter from '../AgentFilter/AgentFilter';
import {getCampaignAgents ,applyingSmartFilter} from '../apis/CcAiPowedApi';
import { useRecoilValue, useSetRecoilState } from "recoil";
import {CallSentimentDataAtom, SentimentGraphDataAtom, ContactCategoriesDataAtom} from '../../../../RecoilSharedState/CcAiPowed/CcAiPowerdState';
import { DatePicker, message, Popover } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
import './CallSentiment.scss';

const CallSentiments = ({campaign_id}) => {

    const dateFormat = 'DD/MM/YYYY';
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    const [startDate , setStartDate] = useState(dayjs());
    const [endDate , setEndDate] = useState(dayjs());
    const [filterData, setFilterData] = useState({agent_ids:[]});
    const setSentimentData = useSetRecoilState(CallSentimentDataAtom);
    const setGraphData = useSetRecoilState(SentimentGraphDataAtom);
    const setContactCategories = useSetRecoilState(ContactCategoriesDataAtom);
    const [messageApi, messageContextHolder] = message.useMessage();

    const handleDateChange = (dates, dateString)=>{
        // let startDate = dates[0].format(dateFormat);
        let startDate = dates[0];
        let endDate = dates[1];
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const applyFilter = async()=>{
        let data = {
            'start_date':startDate,
            'end_date': endDate,
            'agents':filterData.agent_ids
        }
        let response = await applyingSmartFilter(campaign_id , data);
        if(response.status==200){
            let sentiment_data = response.data.sentiment_data;
            let graph_data = response.data.sentiment_graph;
            setSentimentData(sentiment_data);
            setGraphData(graph_data);
            if(response.data?.autotagging_stats){
                let contact_categories_chart = response.data.autotagging_stats;
                setContactCategories(contact_categories_chart);
            }
        }else{
            error();
        }
    }

    const getAgentListAndDate = async() => {
        const response = await getCampaignAgents(campaign_id)
        if(response.status == 200) {
            let startDate = dayjs.utc(response.data.start_date , dateFormat);
            let endDate = dayjs.utc(response.data.end_date , dateFormat);

            setStartDate(startDate);
            setEndDate(endDate);
        }else{
            error();
        }
    }

    const error = () => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong, please try again',
        });
    };

    useEffect(()=>{
        applyFilter();
        getAgentListAndDate()
    },[])

    useEffect(()=>{
        applyFilter();
    },[startDate, endDate])

    return (
        <>
            {messageContextHolder}
            <div id='callSentimentsContainer'>
                <div class='call-sentiment-title-section'>
                    <div className='call-sentiment-title'>{CALL_SENTIMENTS_TITLE}</div>
                    <Popover placement="top" content={SMART_INSIGHT_BETA_POPOVER} overlayClassName="smart-insight-beta-popover">
                        <p className='smart-insight-beta-version'>Beta</p>
                    </Popover>
                </div>
                <div className="call-sentiment-filter">
                    <AgentFilter
                        campaign_id = {campaign_id}
                        getCampaignAgents={getCampaignAgents}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        showFooterButton={true}
                        componentRenderdIn={'callSentiment'}
                        applyFilter={applyFilter}
                    />  
                    <RangePicker 
                        onChange={handleDateChange}
                        defaultValue={[startDate, endDate]}
                        value={[startDate, endDate]}
                        className='smartInsight-date-picker'
                        dropdownClassName='smartInsight-date-dropdown'
                    />
                </div>
            </div>
        </>
    )
}

export default CallSentiments