import React, { useState } from "react";

import CustomSelect from "./CustomSelect";
import './CustomSelect.scss';


const CustomSelectForCampaignResult =  ({componentData}) => {

    const [showCallStatusDropDown, setShowCallStatusDropDown] = useState(false)

    return (
        <div className="inbound_result_filters">
            <CustomSelect componentData={componentData.call_direction_dropdown} setShowCallStatusDropDown={setShowCallStatusDropDown}/>

            {
                showCallStatusDropDown && 
                <CustomSelect componentData={componentData.incoming_call_status_dropdown}/>    
            }
        </div>
        
    )
}

export default CustomSelectForCampaignResult