import React, { useState } from 'react'
import WorkflowDiscoverabilityBanner from '../../DiscoverabilityBanners/WorkflowDiscoverabilityBanner';
import "./DiscoverabilityBannerCommon.scss";

function DiscoverabilityBannerCommon({componentData, ...restProps}) {

    const {
        show = false,
        className = '',
        bannerId = "",
    } = componentData;

    if(!show) return null;

    const [showContent, setShowContent] = useState(true);

    const getContent = () => {
        switch(bannerId){
            case 'workflow-banner-cc':
                return <WorkflowDiscoverabilityBanner 
                            type="cc" 
                            setShowContent={setShowContent} 
                            userDetails={{
                                userEmail: componentData?.userEmail,
                                username: componentData?.username,
                                userId: componentData?.userId,
                            }}
                            token={componentData?.token}
                        />
            case 'workflow-banner-p2p':
                return <WorkflowDiscoverabilityBanner 
                            type="p2p"
                            setShowContent={setShowContent} 
                            userDetails={{
                                userEmail: componentData?.userEmail,
                                username: componentData?.username,
                                userId: componentData?.userId,
                            }}
                            token={componentData?.token}
                        />
        }
    }

    return (
        <section className={`discoverability-common-banner ${className}`}>
            {showContent &&
                getContent()
            }
        </section>
    )
}

export default DiscoverabilityBannerCommon;