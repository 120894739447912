import { apiGetCall, apiPostCall, apiPutCall, apiDeleteCall } from "../../../utilities/utilities";

import {EnableAgentProfanityUrl, AgentProfanityDataUrl} from './AgentProfanityConfigs';

export const EnableAgentProfanitySheild = async (campaignId) =>{
    try {
        const agent_profanity_url = EnableAgentProfanityUrl(campaignId);
        const host = window.location.hostname;
        const response = await apiPostCall(
          `https://${host}/${agent_profanity_url}`
        );
        console.log("testing api call response data: ", response);
        return response;
      } catch (error) {
        return {
          error: error.response.data,
          status: error.response.status,
        };
      }
}

export const GetProfanityData = async (campaignId) =>{
    try {
        const agent_profanity_data_url = AgentProfanityDataUrl(campaignId);
        const host = window.location.hostname;
        const response = await apiGetCall(
          `https://${host}/${agent_profanity_data_url}`
        );
        console.log("testing api call response data: ", response);
        return response;
      } catch (error) {
        return {
          error: error.response.data,
          status: error.response.status,
        };
      }
}