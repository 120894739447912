import React, {useState, useEffect} from "react";
import NotificationCloseIcon from "../../../assets/svg/NotificationCloseIcon";
import ReferralGiftIcon from "../../../assets/svg/ReferralGiftIcon";
import moment from 'moment';
import "./ReactCustomNotificationStyles.scss";

function ReactCustomNotification() {
    const [showNotification, setShowNotification] = useState(false);
    
    const handleRedirectToReferral = () => {
        window.location.replace('/referral/')
    }

    let last_showed_time = JSON.parse(localStorage.getItem("lastReferralNotificationDate"));

    const handleShowNotification = () => {
        let data = {...last_showed_time, isShowNotification: false}
        localStorage.setItem("lastReferralNotificationDate", JSON.stringify(data))
        setShowNotification(false);
    }

    const handleShowReferralNotification = () => {
        if (last_showed_time === null) {
            let currentDate = moment().startOf('day');
            let data = {
                lastUpdatedDdate : currentDate,
                isShowNotification: true,
            }
            localStorage.setItem("lastReferralNotificationDate", JSON.stringify(data))
            setShowNotification(true);
       
        } else {
            if(last_showed_time.isShowNotification) {
                setShowNotification(true)
             
            } else {
                let currentDate = moment().startOf('day');
                let lastUpdatedDdate = last_showed_time.lastUpdatedDdate
                let days = moment.duration(currentDate.diff(lastUpdatedDdate)).asDays();
                if (days >= 7) {
                    let data = {
                        lastUpdatedDdate : currentDate,
                        isShowNotification: true,
                    }
                    localStorage.setItem("lastReferralNotificationDate", JSON.stringify(data))
                    setShowNotification(true);
                }
            }
        }
    }


    useEffect(() => {
        handleShowReferralNotification();
    }, [])


    return ( showNotification && 
        <div id="react-notification-card">
            <div><ReferralGiftIcon /></div>
                <div>
                    <div className="referral-notification-card-heading">Enjoying {getBrandName}? <br/> Get free credits by referring your friends</div>
                    <div className="referral-notification-card-message">You get $25 {getBrandName} credits and referred friend gets FLAT 25% OFF up to $125 when they complete the first payment.</div>
                    <div className="notification-card-button-wrapper">
                        <button onClick={handleRedirectToReferral} className="refer-a-friend-button">Refer a friend</button>
                        <button onClick={handleShowNotification} className="remind-me-later-button">Remind me later</button>
                    </div>
                    <div onClick={handleShowNotification} className="referral-notification-close-wrapper"><NotificationCloseIcon /></div>
                </div>
        </div>
    )
}

export default ReactCustomNotification;