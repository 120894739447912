import { InfoCircleFilled } from "@ant-design/icons";
import { Popover } from "antd";
import React from "react";
import "./HeaderWithPopover.scss";

const HeaderWithPopover = ({title, popoverText, popoverTitle}) => {
    return (
      <div className="column-title-container">
        <p>{title}</p>
        <Popover 
          content={popoverText} 
          title={popoverTitle} 
          trigger={'hover'} 
          overlayClassName="column-title-popover"
        >
          <InfoCircleFilled style={{color: "rgba(0, 0, 0, 0.45)"}} />
        </Popover>
      </div>
    )
}

export default HeaderWithPopover;