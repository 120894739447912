import React, { useEffect, useState } from "react";
import OverAllSentimentChart from './OverAllSentimentChart';
import {OVERALL_SENTIMENT_TITLE, CALL_SENTIMENTS_DETAIL_DATA} from '../../../../constants/CcAiPowerdConstant';
import OverAllSentimentDetail from './OverAllSentimentDetail';
import { useRecoilValue } from "recoil";
import {CallSentimentDataAtom} from '../../../../RecoilSharedState/CcAiPowed/CcAiPowerdState';
import './OverAllSentiment.scss';

const OverAllSentiment = (props) =>{

    const sentimentChartData = useRecoilValue(CallSentimentDataAtom);
    const [overallSentimentDetails, setOverallSentimentDetails] = useState(CALL_SENTIMENTS_DETAIL_DATA);

    useEffect(()=>{
        const updatedOverallSentimentDetails = overallSentimentDetails.map((item) => {
            const dataItem = sentimentChartData.find((dataItem) => dataItem.type === item.type);
            if (dataItem) {
              return { ...item, number: dataItem.value };
            }
            return { ...item, number: 0 };;
        });
        setOverallSentimentDetails(updatedOverallSentimentDetails);
    },[sentimentChartData])
    
    return(
        <div id="overallSentimentContainer">
            <div className="overall-sentiment-title">{OVERALL_SENTIMENT_TITLE}</div>
            <div className="overall-sentiment-data-container">
                <OverAllSentimentChart></OverAllSentimentChart>
                <div className="call-sentiment-detail-section">
                    {overallSentimentDetails?.map((value,key)=>(
                        <OverAllSentimentDetail 
                            title = {value.title}
                            number = {value.number}
                            increase = {value.increase}
                            subhead = {value.subhead}
                            percentage = {value.percentage}
                            type = {value.type}
                            index = {key}
                            hidePercentage={true}
                        />

                    ))}
                </div>
            </div>
        </div>
    )
}

export default OverAllSentiment;