import React from 'react'

function ContactListRange5KycIcon(props) {
    return (
        <svg
            width={56}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M37.538 7.108a3.548 3.548 0 0 0 3.559-3.554A3.548 3.548 0 0 0 37.537 0a3.558 3.558 0 0 0-3.57 3.554 3.558 3.558 0 0 0 3.57 3.554Zm0 2.37c-.345 0-.738.023-1.155.059 1.381.995 2.345 2.333 2.345 4.087v2.961h7.142v-2.961c0-2.76-5.559-4.147-8.332-4.147ZM47.571 7.108a3.548 3.548 0 0 0 3.56-3.554A3.548 3.548 0 0 0 47.57 0a3.558 3.558 0 0 0-3.57 3.554 3.558 3.558 0 0 0 3.57 3.554Zm0 2.37c-.345 0-.738.023-1.154.059 1.38.995 2.345 2.333 2.345 4.087v2.961h7.142v-2.961c0-2.76-5.56-4.147-8.333-4.147Z"
            fill="#F07635"
            />
            <path
            d="M37.855 7.108a3.548 3.548 0 0 0 3.56-3.554A3.548 3.548 0 0 0 37.854 0a3.558 3.558 0 0 0-3.57 3.554 3.558 3.558 0 0 0 3.57 3.554Zm-9.522 0a3.548 3.548 0 0 0 3.559-3.554A3.548 3.548 0 0 0 28.332 0a3.558 3.558 0 0 0-3.57 3.554 3.558 3.558 0 0 0 3.57 3.554Zm0 2.37c-2.774 0-8.333 1.385-8.333 4.146v2.961h16.665v-2.961c0-2.76-5.559-4.147-8.332-4.147Zm9.522 0c-.345 0-.738.023-1.154.059 1.38.995 2.344 2.333 2.344 4.087v2.961h7.142v-2.961c0-2.76-5.558-4.147-8.332-4.147ZM18.366 7.108a3.548 3.548 0 0 1-3.56-3.554A3.548 3.548 0 0 1 18.367 0a3.558 3.558 0 0 1 3.57 3.554 3.558 3.558 0 0 1-3.57 3.554Zm0 2.37c.345 0 .738.023 1.154.059-1.38.995-2.345 2.333-2.345 4.087v2.961h-7.142v-2.961c0-2.76 5.56-4.147 8.333-4.147ZM8.332 7.108a3.548 3.548 0 0 1-3.559-3.554A3.548 3.548 0 0 1 8.333 0a3.558 3.558 0 0 1 3.57 3.554 3.558 3.558 0 0 1-3.57 3.554Zm0 2.37c.346 0 .738.023 1.155.059-1.38.995-2.345 2.333-2.345 4.087v2.961H0v-2.961c0-2.76 5.559-4.147 8.332-4.147Z"
            fill="#F07635"
            />
        </svg>
    )
}

export default ContactListRange5KycIcon
