import React, {useEffect} from "react";
import { Typography } from "antd";
import OtpVerifyIcon from "../../../../assets/svg/OtpVerifyIcon"

const OtpSuccess = ({ mobileNumber}) => {
  return (
    <div className="otp-success">
      <OtpVerifyIcon width={84} height={84} status="success" />
      <h3 className="contact-creation-otp-title">Verified</h3>
      <p className="otp-verified-message">
          {mobileNumber} has been verified
      </p>
    </div>
  );
};

export default OtpSuccess;
