import React, {useState, useEffect} from 'react';
import CommonSideModal from '../common/SideModalWrapper'
import "./closeScreen.scss";
import "../common/SideModalWrapper.scss";
import AlertIcon from '../../../../assets/svg/AlertIcon'
import ShortUrlIcon from '../../../../assets/svg/ShortenURLIcon.js'


function DetailsWrapper(props){

    return (
        <CommonSideModal
            noOverlay = {true}
            id="edit_UTM_params_modal"
            modalClass={`sideModal animate-right ` + (props.isModalClosed ? "hide" : "show")}
            modalHeader={
                <div className="side-modal-header panel-modal-header-title panel-modal-header-title-padding">
                    <div className="heading-icon"><ShortUrlIcon/></div>
                    <div className="heading-text">
                        <h3>Shorten URL</h3>
                        <h5>Convert your long URL into customized short URL</h5>
                    </div>
                </div>
            }
            modalDialogClass = 'hp100'
            modalBodyId="side-modal-body"
            activeModalScreen = {
                <div className={'closeScreenBody'}>
                    <AlertIcon width="32" height="28"/>
                    <div>
                        <h3>{props.heading}</h3>
                        <div>{props.paraText}</div>
                    </div>
                    <div className="closeScreenFooter">
                        <a className="btn btn-default mr-5 modal-button-padding common-modal-button-margin"
                        data-dismiss="modal" onClick={props.closeURLShortenerMainModal}>Yes</a>
                        <a className="btn btn-default mr-5 modal-button-padding common-modal-button-margin"
                        data-dismiss="modal" onClick={props.closeCloseConfirmModal}>No</a>
                    </div>
                </div>
            }
        />
    )
}

export default DetailsWrapper;
