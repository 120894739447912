import React from 'react'

const VoiceBroadcastKycIcon = (props) => (
    <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 443.78 443.78"
    width="30px"
    height="30px"
    {...props}
  >
    <defs>
      <clipPath id="clippath">
        <circle
          cx={221.89}
          cy={221.89}
          r={221.89}
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>
        {".cls-6{fill:#ffc255}.cls-7{fill:#faebe8}.cls-9{fill:#556575}"}
      </style>
    </defs>
    <g id="Layer_1-2">
      <g id="Layer_2-2">
        <circle className="cls-6" cx={221.89} cy={221.89} r={221.89} />
        <g
          style={{
            clipPath: "url(#clippath)",
          }}
        >
          <path
            d="M288.66 365.3s-13.73 11.09-20.54 16.68c0 0-4.4 5.8-10.43-.86 0 0-4.65-5.24 3.05-12.13 0 0 14.75-11.41 18.88-16.32 0 0 4.4-4.14 10.64 2.68 0 0 2.81 4.51-1.59 9.94Z"
            style={{
              fill: "#f7bc93",
            }}
          />
          <g
            style={{
              opacity: 0.3,
            }}
          >
            <path
              d="M257.68 381.12c4.26 4.71 7.01 2.78 9.08 1.73-3.76.27-6.08-.06-6.99-4.46-1.31-6.31 4.37-7.6 7.79-11.56 3.23-3.76 14.87-13.47 20.07-11.15 2.62 1.17 2.87 4.1 2.9 6.43 1.33-3.64-.28-6.75-.28-6.75-6.24-6.82-10.64-2.68-10.64-2.68-4.14 4.91-18.88 16.32-18.88 16.32-7.69 6.89-3.05 12.13-3.05 12.13Z"
              style={{
                fill: "#e5a478",
                isolation: "isolate",
                opacity: 0.5,
              }}
            />
          </g>
          <path
            className="cls-9"
            d="M181.65 299.94s16.5 63.14 81.77 86.15c0 0 21.32 1.47 36.69-22.92 0 0 3.16-11.57-16.77-15.9 0 0-45.37-18.15-33.04-74.17l-68.66 26.83Z"
          />
          <path
            className="cls-9"
            d="M251.91 355.9c-4.82-4.98-9.87-9.92-14.38-15.18-8.87-10.33-16.27-24.15-15.08-38.2.86-10.12 5.88-17.96 13.21-23.7l-54 21.1s16.5 63.14 81.77 86.15c0 0 21.32 1.47 36.69-22.92 0 0 .55-2.02-.27-4.69"
          />
          <path
            d="M112.54 356.29c10.8 9.06 21.61-.43 21.61-.43 112.46-89.99 213.9-85.02 213.9-85.02l-57.2-98.94-76.09-85.27s-24.51 91.11-147.64 176.6c0 0-12.39 7.32-7.17 20.4 0 0 10.49 37.9 52.58 72.66Z"
            style={{
              fill: "#eef0f2",
            }}
          />
          <path
            className="cls-9"
            d="M190.16 317.69c-18.05 10.31-36.86 22.86-56 38.17 0 0-10.82 9.48-21.61.43-42.09-34.76-52.58-72.66-52.58-72.66-5.23-13.08 7.17-20.4 7.17-20.4 20.26-14.07 37.81-28.29 53.06-42.23 0 0 5.96 58.53 69.96 96.69Z"
          />
          <path
            className="cls-9"
            d="M121.39 335.5c-9.25-9.72-18.39-21.06-22.86-33.9-3.92-11.23-2.63-22.14 6.12-30.7 7.52-7.35 16.78-12.27 25.59-17.84-8.64-18.4-10.05-32.06-10.05-32.06-15.25 13.94-32.79 28.16-53.06 42.23 0 0-12.39 7.32-7.17 20.4 0 0 10.49 37.9 52.58 72.66 10.8 9.06 21.61-.43 21.61-.43 1.93-1.54 3.84-3.04 5.76-4.52-6.85-4.49-13.08-10.11-18.53-15.85Z"
          />
          <path
            d="M343.67 131.79c40.07 55.36 52.66 114.62 28.15 132.37-24.51 17.74-76.87-12.75-116.93-68.11-40.06-55.35-52.66-114.62-28.14-132.36 24.51-17.74 76.87 12.75 116.92 68.11Z"
            style={{
              fill: "#909ead",
            }}
          />
          <path
            className="cls-7"
            d="M336.74 136.81c36.31 50.18 48.98 102.99 28.28 117.97-14.45 10.45-41.37-.33-68.67-24.89-11.77-10.61-23.6-23.75-34.52-38.86-10.35-14.3-18.78-28.8-25.05-42.59-15.77-34.6-18.03-64.67-3.25-75.37 20.69-14.97 66.91 13.56 103.22 63.74Z"
          />
          <path
            className="cls-7"
            d="M233.53 73.06c-3.36 2.43-5.83 5.88-7.47 10.13 23.49-1.19 60.84 25.64 91.26 67.67 30.41 42.03 44.21 85.89 35.74 107.84 4.56-.23 8.61-1.49 11.96-3.93 20.69-14.97 8.04-67.78-28.28-117.97-36.31-50.18-82.53-78.72-103.22-63.74Z"
          />
          <path
            d="m289.14 138.26 31.64 43.72-24.43 47.9c-11.77-10.61-23.6-23.75-34.52-38.86-10.35-14.3-18.78-28.8-25.05-42.59l52.37-10.17Z"
            style={{
              fill: "#e59335",
            }}
          />
          <path
            className="cls-6"
            d="M313.55 153.92c8.73 12.08 11.99 24.65 7.26 28.08-4.74 3.43-15.65-3.6-24.39-15.68-8.74-12.07-12-24.65-7.26-28.07 4.74-3.43 15.66 3.59 24.4 15.66Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default VoiceBroadcastKycIcon