import Button from "antd/lib/button"
import Form from "antd/lib/form"
import "../../../../screens/react_library_for_jquery/AntDesignCustomStyles/AntButton.scss";
import "../../../../screens/react_library_for_jquery/AntDesignCustomStyles/AntForm.scss";
import React from "react";
import "./ButtonAnt.scss";
export function ButtonAnt({ text="", type, onClick, buttonType, className="" ,buttonClass=""}) {
  //buttonClass
  let buttonAnt;
  switch (type) {
    case "primary":
      buttonAnt = (
        <button
          onClick={onClick && onClick}
          className={`primaryBtnAnt commonButtonAntStyle ${buttonClass}`}
          type={buttonType && buttonType}
        >
          {text && text}
        </button>
      );
      break;
    case "secondary":
      buttonAnt = (
        <button
          onClick={onClick && onClick}
          className={`secondaryBtnAnt commonButtonAntStyle ${buttonClass}`}
          type={buttonType && buttonType}
        >
          {text && text}
        </button>
      );
      break;
    default:
      buttonAnt = <button className={`${buttonClass}`} onClick={onClick && onClick}>{text}</button>;
      break;
  }

  return <Form.Item className={className}>{buttonAnt}</Form.Item>;
}
