import React from 'react';

const DisabledContactTracking = () => {
    return (
        <svg 
            version="1.2" 
            overflow="visible" 
            preserveAspectRatio="none" 
            viewBox="0 0 22 22" 
            width="20" 
            height="20"
        >
        <g transform="translate(1, 1)">
        <g  id="Page-1" 
            stroke="none" 
            strokeWidth="1" 
            fill="none" 
            fillRule="evenodd"
        >
        <g  id="Campaign-Overview" 
            transform="translate(-139.390000, -730.810000)" 
            fill="rgba(68, 68, 68, 60%)" 
            fillRule="nonzero"
        >
        <g id="Group-4" 
           transform="translate(118.000000, 583.000000)"
        >
        <g id="person_off_black_24dp" transform="translate(20.000000, 145.000000)">
        <path 
            d="M20,17.17 L16.63,13.79 C17.27,14.01 17.86,14.27 18.4,14.55 C19.37,15.06 19.98,16.07 20,17.17 Z M21.19,21.19 L19.78,22.6 L17.17,20 L4,20 L4,17.22 C4,16.1 4.61,15.07 5.61,14.56 C6.9,13.9 8.48,13.34 10.28,13.11 L1.39,4.22 L2.8,2.81 L21.19,21.19 Z M15.17,18 L12.17,15 C12.11,15 12.06,15 12,15 C9.63,15 7.71,15.73 6.52,16.34 C6.2,16.5 6,16.84 6,17.22 L6,18 L15.17,18 Z M12,6 C13.1,6 14,6.9 14,8 C14,8.86 13.46,9.59 12.7,9.87 L14.18,11.35 C15.28,10.64 16,9.4 16,8 C16,5.79 14.21,4 12,4 C10.6,4 9.36,4.72 8.65,5.82 L10.13,7.3 C10.41,6.54 11.14,6 12,6 Z" 
            id="Shape" 
            vectorEffect="non-scaling-stroke"
        />
        </g>
        </g>
        </g>
        </g>
        </g>
        </svg>
    )
}

export default DisabledContactTracking