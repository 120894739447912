import React from 'react'

function ContactListRange3KycIcon(props) {
    return (
        <svg
            width={36}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
            d="M17.538 7.108a3.548 3.548 0 0 0 3.559-3.554A3.548 3.548 0 0 0 17.537 0a3.558 3.558 0 0 0-3.57 3.554 3.558 3.558 0 0 0 3.57 3.554Zm0 2.37c-.346 0-.739.023-1.155.059 1.38.995 2.345 2.333 2.345 4.087v2.961h7.142v-2.961c0-2.76-5.559-4.147-8.332-4.147ZM27.571 7.108a3.548 3.548 0 0 0 3.56-3.554A3.548 3.548 0 0 0 27.57 0 3.558 3.558 0 0 0 24 3.554a3.558 3.558 0 0 0 3.571 3.554Zm0 2.37c-.345 0-.738.023-1.155.059 1.381.995 2.345 2.333 2.345 4.087v2.961h7.142v-2.961c0-2.76-5.558-4.147-8.332-4.147Z"
            fill="#F07635"
            />
            <path
            d="M17.855 7.108a3.548 3.548 0 0 0 3.56-3.554A3.548 3.548 0 0 0 17.854 0a3.558 3.558 0 0 0-3.571 3.554 3.558 3.558 0 0 0 3.571 3.554Zm-9.523 0a3.548 3.548 0 0 0 3.56-3.554A3.548 3.548 0 0 0 8.331 0a3.558 3.558 0 0 0-3.57 3.554 3.558 3.558 0 0 0 3.57 3.554Zm0 2.37C5.56 9.477 0 10.862 0 13.623v2.961h16.665v-2.961c0-2.76-5.56-4.147-8.333-4.147Zm9.523 0c-.345 0-.738.023-1.155.059 1.381.995 2.345 2.333 2.345 4.087v2.961h7.142v-2.961c0-2.76-5.559-4.147-8.332-4.147Z"
            fill="#F07635"
            />
        </svg>
    )
}

export default ContactListRange3KycIcon
