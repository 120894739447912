import React from 'react';
import TipIcon from './../../../assets/svg/TipIcon';

function Tips(props) {
    return(
        <div className={"tip " + (props.size == "big" ? "big" : "short")}>
            {
                props.heading ? 
                    <div className="tip-heading">
                        <TipIcon /> Tip
                    </div>
                :
                    <></>
            }
            <div className="tip-content">
                {props.children}
            </div>
        </div>
    )
}

export default Tips;