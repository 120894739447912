const upsellFixtures = {
  "User Roles": {
    title_text: "Upgrade to collaborate with your teammates",
    legacy_title_text:
      "Buy User Roles add-on to collaborate with your teammates",
    display_text:
      "Get User roles for <span style='font-weight: 600'> free with the Scale plan </span> along with $500 credits or you can buy the add-on.",
    plan_name: "Scale",
    width: 390,
    height: 'auto',
    legacy_width: 380,
    legacy_height: 'auto',
    features: ["User Roles"],
    included_in: ["Scale"],
  },
  "Sub-accounts": {
    title_text: "Upgrade to manage multiple accounts under one roof",
    legacy_title_text:
      "Manage multiple accounts and their budgets under one roof with the Sub Accounts add-on",
    display_text:
      "Get Sub Accounts for <span style='font-weight: 600'> free with the Scale plan </span> along with $500 credits or you can buy the add-on. ",
    plan_name: "Scale",
    width: 390,
    height: 'auto',
    legacy_width: 380,
    legacy_height: 'auto',
    features: ["Sub-accounts"],
    included_in: ["Scale"],
  },
  "Predictive and Auto Dialer": {
    title_text:
      "Upgrade to reduce the connection time by upto 3x with Auto (2:1, 3:1) & Predictive Dialer",
    legacy_title_text:
      "Buy Auto (2:1, 3:1) & Predictive Dialer add-on to reduce the connection time by upto 3x",
    display_text:
      "Get Auto (2:1, 3:1) & Predictive Dialer for free with any subscription plan or you can buy the add-on.",
    plan_name: "Essentials",
    width: 390,
    height: 'auto',
    legacy_width: 380,
    legacy_height: 'auto',
    features: ["Auto Dialer (2:1, 3:1)", "Predictive Dialer"],
    included_in: ["Essentials", "Scale"],
  },
};

export default upsellFixtures;
