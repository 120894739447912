import React from 'react'

const ProfanitySubHeadingIcon = props => (
    <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={8} fill="#FAAD14" />
    <path
      d="M6.93262 11.8286C6.93262 12.0863 7.03496 12.3334 7.21714 12.5155C7.39932 12.6977 7.64641 12.8001 7.90405 12.8001C8.16168 12.8001 8.40877 12.6977 8.59095 12.5155C8.77313 12.3334 8.87547 12.0863 8.87547 11.8286C8.87547 11.571 8.77313 11.3239 8.59095 11.1417C8.40877 10.9596 8.16168 10.8572 7.90405 10.8572C7.64641 10.8572 7.39932 10.9596 7.21714 11.1417C7.03496 11.3239 6.93262 11.571 6.93262 11.8286ZM8.38976 9.56197C8.47881 9.56197 8.55167 9.48911 8.55167 9.40006V3.8953C8.55167 3.80626 8.47881 3.7334 8.38976 3.7334H7.41833C7.32928 3.7334 7.25643 3.80626 7.25643 3.8953V9.40006C7.25643 9.48911 7.32928 9.56197 7.41833 9.56197H8.38976Z"
      fill="white"
    />
  </svg>
)

export default ProfanitySubHeadingIcon;