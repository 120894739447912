import axios from 'axios';
import {
    phonebooksUrl,
    integrationsUrl,
    shortCodeKeywordUrl,
    rentedAndValidatedNumbersUrl,
    numberRentRatesUrl,
    editCampaignUrl,
    mergeTagsUrl,
    addBlackbaudUrl,
    timezoneListUrl
} from '../../configuration/Config';


export const getPhoneBookList = async() => { 
    const phonebook_list_url = phonebooksUrl()
    const res = await axios.get(phonebook_list_url, {
    headers: {
        'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const getTimezoneList = async() => { 
    const timezone_list_url = timezoneListUrl()
    const res = await axios.get(timezone_list_url, {
    headers: {
        'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const getIntegrationDetails = async() => { 
    const integrations_url = integrationsUrl()
    const res = await axios.get(integrations_url, {
    headers: {
        'Authorization': 'Token ' + token
        },
    params: {
        get_integration_data : true
    },
    }) 
    return res
}

export const getShortcode = async() => { 
    const shortcode_url = shortCodeKeywordUrl()
    const res = await axios.get(shortcode_url, {
    headers: {
        'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const previousRentedNumbers = async() => { 
    const previousRentedNumbers_url = rentedAndValidatedNumbersUrl()
    const res = await axios.get(previousRentedNumbers_url, {
    headers: {
        'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const automaticallyRentedNumbers = async() => { 
    const automaticallyRentedNumbers_url = numberRentRatesUrl()
    const res = await axios.get(automaticallyRentedNumbers_url, {
    headers: {
        'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const addBlackBaudDetails = async(blackbaudIntegrationId, blackbaudIntegrationType) => {
    let body = {
        interaction_type_name: blackbaudIntegrationType.value,
        interaction_type_id: blackbaudIntegrationId.value
    } 
    const addBlackBaudDetails_url = addBlackbaudUrl()
    const res = await axios.post(addBlackBaudDetails_url, body, {
        headers: {
            'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const getEditCampaignDetails = async(campaign_id) => { 
    const editCampaignUrl_url = editCampaignUrl(campaign_id)
    const res = await axios.get(editCampaignUrl_url, {
    headers: {
        'Authorization': 'Token ' + token
        }
    }) 
    return res
}

export const mergeTags = async(apiToken) => {
    const mergeTags_url = mergeTagsUrl()
    let tokenForAPI = apiToken ? apiToken : token
    const res = await axios.get(mergeTags_url, {
        headers: {
            'Authorization': 'Token ' + tokenForAPI
        }
    })
    return res;
}

export const RSVPDetails = {
    "data": {        
        "nb_detail": {
            "nb_sites_and_events": [
                {
                    "site_slug": "callhub",
                    "site_id": "2240567200261869594",
                    "events": [
                        {
                            "event_id": "2240567388812611752",
                            "event_name": "cleanblrlakes"
                        },
                        {
                            "event_id": "2240567388745502886",
                            "event_name": "nb_practice_event"
                        },
                        {
                            "event_id": "2240567388779057319",
                            "event_name": "sales_demo"
                        },
                        {
                            "event_id": "2240567388678394021",
                            "event_name": "sales_practice"
                        }
                    ]
                }
            ],
            "contact_type_choices": [
                [
                    13,
                    "Demo SMS campaign"
                ],
                [
                    77,
                    "Ecanvasser"
                ],
                [
                    68,
                    "Friday Campaign Kickoff"
                ],
                [
                    69,
                    "Friday Campaign Kickoff 07/06/14 11:40:45"
                ],
            ],
            "nb_enabled": true,
        }
    }
};