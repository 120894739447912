import React,{ useState,useEffect,useRef } from 'react';
import CloseButton from "../../../assets/svg/CloseIcon";
import Loading from "../../../assets/svg/Loading";
import { AccountsCard } from './AcccountsCard';
import {SwitchAccountsUrl} from "../../configuration/SwitchAccountsConfig";
import {getApiCall} from "../../utilities/SwitchAccountUtilities"
import './SwitchAccounts.scss';

export const SwitchAccounts = (props) =>{
    const [show,setShow] = useState(props.show || props.componentData.show);
    const [accountsData,setAccountsData] = useState([]);
    const [page,setPage] = useState(0);
    const [currentUser,setCurrentUser] = useState({});
    const containerRef = useRef();
    const [loading,setLoading] = useState(true);
    const [nextExist,setNextExist] = useState(false);

    useEffect(() => {
        getUserData();        
    },[page])

    useEffect(() => {
        document.body.classList.toggle('lock-background-scroll', show);
        if(!show && !props.component){
            props.unMountTheComponent(false);
        }
    },[show]);

    const handleCloseModal = () => {
        setShow(false);
        props.handleShowModal && props.handleShowModal();
    }

    const getUserData = async () => {
        !page && setLoading(true);      // !page indicates setting state only in the initial stages
        let responseData = await getApiCall(SwitchAccountsUrl(),{offset:page});
        !page && setLoading(false);
        !page && setCurrentUser(responseData.results.login_user);
        setAccountsData([...accountsData,...responseData.results.enterprise_users]);
        setNextExist(responseData.next);
    }
    const handleScroll = ()=> {
        if(containerRef.current.scrollTop + containerRef.current.clientHeight > containerRef.current.scrollHeight-5){
            if(nextExist){
                setPage(accountsData.length+1); // we are considering 1 count for login user
            }
        }
    }
    
    return (
        <>
        {show && <div className="switch-account-container">
            <div className="modal-overlay"></div>
            <div className={`modal switch-accounts-modal ${props.component == 'react' ? 'react-modal': ""}`}>
                <div className="modal-header">
                    <p className="panel-modal-header-title">
                        Switch accounts
                    </p>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={handleCloseModal}
                    >
                        <CloseButton width="11" height="12" />
                    </button>                
                </div>
                {loading ? <div className='loader'><Loading size={50}/></div> : <div className='modal-body' id="modal-body">
                    <div className='currentAccount'>
                        <div>
                            <img src={currentUser.image} alt='profile'/>
                        </div>
                        <div>
                            <p>{currentUser?.fullName}{currentUser.isParent && <span>MAIN ACCOUNT</span>}</p>
                            <p>{currentUser.email}</p>
                        </div>
                        <div>
                            <p>Current account</p>
                        </div>
                    </div>
                    <div className='accountsSection'>
                        <p>Switch to:</p>
                        <div className='subAccountsContainer' ref={containerRef} onScroll={(e)=>handleScroll(e)}>
                            {accountsData.map((accountData)=>{
                                return <AccountsCard {...accountData} key={accountData.user_id} />
                            })}
                        </div>
                    </div>
                </div>}
            </div>
            </div>}
        </>
    )
}