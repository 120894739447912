import React from "react"

function OtpVerifyIcon({width,height,status}) {
    const color =  status == 'success' ? '#3FC59F' : '#3690E6'
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 84 84" fill="none">
          <circle cx="42" cy="42" r="42" fill={color}/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M22.3987 25.0309C25.9705 25.4948 34.7912 24.9381 41.5 19C48.2088 24.9381 57.0295 25.4948 60.6013 25.0309C63.583 53.9794 49.1095 63.0722 41.5 64C33.8905 63.0722 19.417 53.9794 22.3987 25.0309Z"
                fill="white"/>
          <path d="M33 42.3829L38.5849 47.6098L50 36" stroke={color} stroke-width="4"/>
      </svg>
  )
}

export default OtpVerifyIcon;