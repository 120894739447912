import React, { useState, useEffect } from 'react';
import SingleTransfer from '../../../../../src/shared/SingleTransfer/SingleTransfer';
import {useRecoilState, useSetRecoilState} from "recoil";
// import {getCampaignAgents} from '../apis/CcAiPowedApi';
// import {campaignContactFilterData} from '../../../RecoilSharedState/Relational/RelationalRecoil';

import _ from 'lodash';

const SingleTransferAgentWrapper = ({campaignId, setDropDownOpen, getCampaignAgents , filterData, setFilterData, showFooterButton, componentRenderdIn, applyFilter})=>{
    // const [contactFilterData , setContactFilterData] = useRecoilState(campaignContactFilterData)
    // const [contactFilterData , setContactFilterData] = useState({agent_ids:[]})
    const [dropdownData, setdropdownData] = useState({list: [], totalCount: 0})
    const [loading, setLoading] = useState(true)
    const ITEMS_PER_PAGE = 50
    const LIMIT = 50

    const getAgentList = async(search_term) => {
      const response = await getCampaignAgents(campaignId, LIMIT, 0, search_term)
      setLoading(false)
      if(response.status == 200) {
        setdropdownData({...dropdownData, list: response.data.data, totalCount: response.data.agent_count})
      }
    }

    useEffect(() => {
        getAgentList('')
    }, [])

    const debouncedSearch = _.debounce((searchTerm) => {
        getAgentList(searchTerm)
    }, 1000);

    const handleSearch = (dir, value) => {
        debouncedSearch(value);
    };

    const handleSelectChange = (selectedAgents) => {
        // event.stopPropagation();
        setFilterData({...filterData, agent_ids: selectedAgents})
    };
    const loadData = async (currentPage) => {
        const offset = (currentPage - 1) * ITEMS_PER_PAGE;
        const response = await getCampaignAgents(campaignId, LIMIT, offset)
        const newList = dropdownData.list.concat(response.data.data)
        setLoading(false)
        if(response.status == 200) {
          setdropdownData({...dropdownData, list: newList, totalCount: response.data.agent_count})
        }
    }

    const onScrollHandler = (direction, event) => {
        if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
          const totalPages = Math.ceil(dropdownData.totalCount / ITEMS_PER_PAGE);
          const currentPage = Math.ceil(dropdownData.list.length / ITEMS_PER_PAGE);
          if (currentPage < totalPages) {
            loadData(currentPage + 1);
          }
        }
    }

    const onClickHandler = ()=>{
        setDropDownOpen(false);

        switch (componentRenderdIn){

            case 'callSentiment':
                applyFilter();
                break;
            default:
                break;
        }
    }
 
    return(
        <SingleTransfer
            data={dropdownData.list}
            selectedValues={filterData.agent_ids}
            handleSearch={handleSearch}
            handleSelectChange={handleSelectChange}
            onClickHandler={onClickHandler}
            handleScroll = {onScrollHandler}
            loading={loading}
            removeFooter = {!showFooterButton}
        />
    )
}

export default SingleTransferAgentWrapper