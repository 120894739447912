export const getOrdinalNumber = (valueToCompare) => {
  switch (valueToCompare) {
    case 1:
      return "2nd";
    case 2:
      return "3rd";
    case 3:
      return "4th";
    case 4:
      return "5th";
    default:
      return "1st";
  }
};
