import React, { useState, useContext, useEffect } from 'react'
import  Button from "./Button";
import GreenTickIcon from "../../../../assets/svg/GreenTickIcon";
import EnterpiseIcon from '../../../../assets/svg/EnterpriseIcon';
import Popover from "./Popover";
import { getOrderSummaryDetails, makePayment } from "../../../utilities/subscriptionUtilities";
import CalendlyTextPopUp from "../../react_library_for_jquery/common/CalendlyTextPopUp";
import { getCalendlyTalkToAnExpertUrl } from "../../../configuration/subscriptionConfig";
import * as Constant from '../../../components/common/SegmentEventsConstant/SegmentEventsConstant';
import "./PlanCard.scss";
import { ExperimentContext, UpSellContext } from "../../../context/subscriptionContext";
import _ from "lodash";
import { GreenPlusIcon } from '../../../../assets/svg/GreenPlusIcon';

const allPlansBillingPeriod = {
    "Monthly": " / month",
    "Quarterly": " / quarter",
    "Half yearly": " / 6 months",
    "Yearly": " / year",
};

function Benefits(props) {
    return (
        <div className="disp-flex align-center mb-10px">
            <GreenTickIcon height={6} width={8}/>
            <p className={`mb-0px ml-12px text-size-13 text-4b4b4b ${
                props.isUnderlined ? "underlined-text" : ""
              }`}
            >
              {props.benefit}
            </p>
        </div>
    );
}

function BenefitsCategory(props){
    return <p className="mb-10px text-444444 font-weight-500 text-size-12">{props.category}</p>
}


function PlanCard(props) {

    const { plan_name, features, included_in } = useContext(UpSellContext) || {};

    const {user_bucket} = useContext(ExperimentContext) || {}

    const {
        width,
        multipleCards,
        rightBorder,
        cardData,
        planType,
        buttonText,
        isCurrentPlan,
        handleDowngrade,
        trialData,
        minTextCallCost,
        apiToken,
        isEmailVerified,
        upgradeButtonClicked,
        forceDisablePlanSelectButton
    } = props;

    function kFormatter(num) {
        if(num > 999)
            return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
        return num;
    }

    const [showLitePopover, setShowLitePopover] = useState(false);
    const [litePlanPopoverLoading, setLitePlanPopoverLoading] = useState(false);
    const [showVariantDesign, setShowVariantDesign] = useState(false);
    const [isPopularPlan, setIsPopularPlan] = useState(false)

    useEffect(() => {
        if(user_bucket && cardData){
            setShowVariantDesign(prev => {
                return user_bucket === 'Variant'
            })
        }
    }, [user_bucket, cardData])

    useEffect(() => {
       if(cardData){
           setIsPopularPlan(prev => cardData.is_popular ?? false)
       }
    }, [cardData])



    const getPlanDetails = () => {
        return cardData[planType] ? cardData[planType] : "";
    }

    const getCredits = () => {
        let planDetails = getPlanDetails();
        return !_.isEmpty(planDetails) && !_.isEmpty(planDetails.credits) ? planDetails.credits : "";
    }

    const getCallAndTextCredits = () => {
        let credits = getCredits();
        return credits !== "" && credits.cost ? credits.cost : "";
    }

    const getCreditsComments = () => {
        let creditsComment;
        let planDetails = getPlanDetails();
        let callAndTextCredits = getCallAndTextCredits();
        if(planDetails["credits"]) {
            let minMinutes = kFormatter(Math.round(callAndTextCredits / minTextCallCost.minimum_call_cost));
            let minTexts = kFormatter(Math.round(callAndTextCredits / minTextCallCost.minimum_text_cost));
            creditsComment = `up to ${minMinutes} mins or ${minTexts} texts`;
        }
        return creditsComment;
    }

    const getPrice = () => {
        let mainPrice, subPrice;
        let planDetails = getPlanDetails();
        if(planDetails["sub_plan_cost"]) {
            [ mainPrice, subPrice ] = planDetails["sub_plan_cost"].toString().split(".");
        }else {
            mainPrice = "0";
            subPrice = "";
        }
        return { mainPrice, subPrice };
    }

    const getSave = () => {
        let planDetails = getPlanDetails();
        return planDetails["savings"] ? planDetails["savings"] : "";
    }

    const getTagLine = () => {
        return !_.isEmpty(cardData.prevPlan) ? `Everything in ${cardData.prevPlan} plus:` : "";
    }

    const getCategorizedFeatures = () => {
        const {categorized_features} = cardData;

        const benefit_list = []
        let counter = 0;
    
        if(categorized_features){
            for(const [key, value] of Object.entries(categorized_features)){
                counter += 1
                benefit_list.push(<BenefitsCategory category={`${key}:`} key={counter}/>)
                
                const temp = value.map(benefit => {
                    counter += 1 
                    const isUnderlined = features && features.length > 0 && features.includes(benefit) ? true : false;
                    return <Benefits benefit={benefit} isUnderlined={isUnderlined} key={counter}/>
                })
                benefit_list.push(...temp)
            }

        }

        return benefit_list

    }

    const getCardBenefits = () => {
        return cardData["benefits"].map((benefit, index) => {
          const isUnderlined = features && features.length > 0 && features.includes(benefit) ? true : false;
          return <Benefits benefit={benefit} isUnderlined={isUnderlined} key={index}/>
        });
    }

    const getSavings = () => {
        let savings;
        let save = getSave();
        if(cardData.planName === "Enterprise" || cardData.planName === "Enterprise Plan" ) {
            savings = <p className="text-size-13 text-44444470 m-0">Custom pricing</p>;
        }else if(cardData.planName === "Lite" || cardData.planName === "Lite Plan") {
            savings = <p className="text-size-13 text-44444470 m-0">Pay as you go</p>;
        }else {
            savings = <p className="text-1ab394 text-size-13 m-0 font-weight-600">{save && save !== "" ? `SAVE $${save}` : <span>&nbsp;</span>}</p>;
        }
        return savings;
    }

    const getCurrentPlanBillingText = () => {
        if(planType === 'Monthly' && user_bucket === 'Variant'){
            return " / monthly"
        }
        return allPlansBillingPeriod[planType];
    }

    const callAndTextCredits = getCallAndTextCredits();
    const creditsComment = getCreditsComments();
    const { mainPrice, subPrice } = getPrice();
    const tagLine = getTagLine();


    const cardBenifits = showVariantDesign && isPopularPlan ? getCategorizedFeatures() : getCardBenefits();

    const savings = getSavings();
    const currentPlanBillingText = getCurrentPlanBillingText();

    const handleCardBtnClicked = (cardData, subscriptionBilling, buttonText) => {
        if((trialData.isTrial || trialData.isAllPlansPostTrial) && (cardData.planName.toLowerCase() === "lite" || cardData.planName.toLowerCase() === "lite plan")) {
            if(trialData.daysLeft <= 0) {
                handleTrialLiteSubmit();
                return;
            }
            setShowLitePopover(true);
        }
        else {
            if(buttonText === "Downgrade") {
                handleDowngrade(cardData, subscriptionBilling);
                return;
            }
            let plan = {
                type: "plan",
                base_product_id: cardData.id, 
                billing_period: _.capitalize(subscriptionBilling).replace(" ", "-")
            }
            if((cardData.planName.toLowerCase() !== "lite" || cardData.planName.toLowerCase() !== "lite plan") && upgradeButtonClicked){
                const planNameForSegmentEvent = cardData.planName.toLowerCase() 
                Constant.trackSegementEvents(Constant.segmentEventNamesForUpgradePlans[planNameForSegmentEvent],"Adoption rate");
            }
            if(JSON.parse(sessionStorage.getItem("post_trail"))){
                const planNameForSegmentEvent = cardData.planName.toLowerCase() 
                Constant.trackSegementEvents(Constant.segmentEventNamesForUpgradePlans[planNameForSegmentEvent],"Conversion rate");
            }
            sessionStorage.setItem("billing", JSON.stringify(plan));
            if(isEmailVerified){
                window.location = "/payment/";
                let upgradePlanSegmentDetails = {
                    upgradeButtonClicked: true,
                    planName: cardData.planName.toLowerCase(), 
                }
                sessionStorage.setItem("upgradePlan", JSON.stringify(upgradePlanSegmentDetails));
            }else{
                window.location = "/billing/recharge/";
            }
        }
    }

    const handleTrialLiteSubmit = async () => {
        setLitePlanPopoverLoading(true);
        let queryParameter = `?base_product_id=${cardData.id}&billing_period=${_.capitalize(planType).replace(" ", "-")}`;
        let orderSummaryResonse = await getOrderSummaryDetails(queryParameter, apiToken);
        if(orderSummaryResonse.error) {
            setLitePlanPopoverLoading(false);
            if(orderSummaryResonse.error.detail === "Already bought this Product") {
                Constant.trackSegementEvents("chose-payg","Conversion rate");
                redirectToSubscriptionsPage("Successfully subscribed to Lite plan");
            }
            return;
        }
        let makePaymentResponse = await makePayment({ product_id: orderSummaryResonse.product.id }, apiToken);
        if(makePaymentResponse.error) {
            setLitePlanPopoverLoading(false);
            return;
        }
        setLitePlanPopoverLoading(false);
        Constant.trackSegementEvents("chose-payg","Conversion rate");
        redirectToSubscriptionsPage("Successfully subscribed to Lite plan");
    }

    const redirectToSubscriptionsPage = (toasterText) => {
        sessionStorage.setItem("subscription-page-notification", toasterText);
        window.location.pathname === "/my/subscriptions/" ? window.location.reload() : window.location = "/my/subscriptions/"
    }


    return (
        <>
            <div 
                style={{width: width}} 
                className={`plan-card-container 
                            ${multipleCards && rightBorder && "card-right-border"}
                            ${!multipleCards && "card-border"} 
                            ${plan_name && plan_name === cardData.planName
                              ? "plan-card-highlighted"
                              : ""
                          }`}
            >
                <div className="plan-card-title">
                    <div className={`text-size-18 font-weight-600 text-444444 disp-flex
                        ${cardData.planName === "Enterprise" || cardData.planName === "Enterprise Plan" 
                        ? "mb-17px" 
                        : "mb-10px"}`}>
                            {cardData.planName}
                        
                        { showVariantDesign && isPopularPlan && <span className="popular-tag">Popular</span>}
                    </div>
                        
                    { cardData.planName === "Enterprise" || cardData.planName === "Enterprise Plan"
                        ? <div className="mb-4px">
                            <EnterpiseIcon height={32} width={32} />
                        </div>
                        : <p style={{ lineHeight: 'normal' }} className={`text-size-32 text-444444 font-weight-600 mb-0px`}> 
                            ${mainPrice}
                            <span className="text-size-13 font-weight-regular">{subPrice && subPrice !== ""  && `.${subPrice}`}{ mainPrice !== "0" && !_.isEmpty(currentPlanBillingText) ? currentPlanBillingText : ""}</span>
                        </p>
                    }

                    { savings }
                </div>
                <div style={{ height: 210 }} className="pl-20px">
                    {
                        tagLine && tagLine !== "" && 
                        <div>
                            <p className="text-4b4b4b mb-10px text-size-12 font-weight-600 tag-line text-no-wrap">{tagLine}</p>
                        </div>
                    }
                    { cardBenifits }
                </div>
                <div className={`pt-10px pb-10px pl-20px pr-1 mb-20px call-text-credits disp-flex align-center  ${ isPopularPlan ? "popular-highlight" : "bg-f7f7f7"}`}>
                    {callAndTextCredits && callAndTextCredits !== "" ? <div style={{width:'16px', alignSelf:'start'}}><GreenPlusIcon width={8} height={8} /> </div>: <></>}
                    <p className="text-size-13 font-weight-600 text-4b4b4b mr-0 mt-0 mb-0 ml-12px">
                        {callAndTextCredits && callAndTextCredits !== "" ? (
                            <span
                            className={`font-weight-600 ${
                                isPopularPlan ? "text-3b84df" : "text-818181"
                            }`}
                            >
                            {
                                `$${callAndTextCredits} free credits ${allPlansBillingPeriod[planType]} `
                            }
                            <span className="font-weight-regular">{creditsComment}</span>
                            </span>
                        ) : (
                            <span style={{ visibility: "hidden" }}>
                            &nbsp;
                            <br />
                            &nbsp;
                            </span>
                        )}
                    </p>
                </div>
                <div className="disp-flex justify-center">
                    {
                        cardData.planName.toLowerCase() === "enterprise" || cardData.planName.toLowerCase() === "enterprise plan" && !isCurrentPlan 
                        ? <CalendlyTextPopUp 
                            className="calendely-btn-talktoexpert"
                            trackSegementEvents={Constant.trackSegementEvents}
                            planName={cardData.planName.toLowerCase()}
                            upgradeButtonClicked={upgradeButtonClicked}
                            url={getCalendlyTalkToAnExpertUrl()}
                            text="Talk to sales"
                        />
                        : <Button
                            name={
                              isCurrentPlan
                                ? "Current plan"
                                : plan_name
                                ? "Choose"
                                : `${!_.isEmpty(buttonText) ? buttonText : ""}`
                            }
                            handleClick={() =>
                              handleCardBtnClicked(cardData, planType, buttonText)
                            }
                            type="primary"
                            customStyles={{ width: 128, height: 40, fontSize: 16 }}
                            className="plan-card-btn"
                            disabled={
                              isCurrentPlan ||
                              (included_in
                                ? included_in.length > 0 &&
                                  included_in.includes(cardData.planName)
                                  ? false
                                  : true
                                : forceDisablePlanSelectButton)
                            }
                          />
                    }
                    {
                        showLitePopover &&
                        <Popover 
                            className="plan-card-lite-popover"
                            buttonCustomStyles={{ height:30, fontSize: 14, padding: "0 12px" }}
                            areButtonLoading={false}
                            handleClickWarningBtn={handleTrialLiteSubmit}
                            handleClickLightBtn={() => setShowLitePopover(false)}
                            buttonTexts={{ light: "Cancel", warning: "Confirm Lite plan" }}
                            footerHeight={60}
                            padding={20}
                            dimensions={{ height: 160 ,width: 336 }}
                            areButtonLoading={litePlanPopoverLoading}
                        >
                            <p className="mb-0px text-size-14 text-444444 lh-20">You will lose the {trialData.daysLeft} days remaining on Scale plan free trial. Are you sure you want to continue with Lite plan?</p>
                        </Popover>
                    }
                </div>
            </div>
        </>
    )
}

export default PlanCard
