import React, { Component, createRef } from "react";
import { getAPIData, postApiData } from "./shortenURLUtils.js";
import DetailsWrapper from './detailsWrapper.js'
import { searchShortenURL, urlDetailsAPI } from "./constant";

// Importing Components
import _ from "lodash";

//css
import "../commonStyle.scss";
import "./searchBox.scss";


class SearchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchListData : "",
			searchTerm : "",
			showSearchFooter : false,
			showSearchList : false,
			shortUrlDetails : {},
			click_data : {},
			campaign_names : [],
		}
	}
	getSearchListData = async (searchTerm) => {
		if(searchTerm !== undefined) {
			let url = searchShortenURL + 'true&search_term=' + searchTerm;
			let searchListData = await getAPIData(url, this.props.token);
			if(searchListData.data !== undefined) {
				this.setState({searchListData : searchListData.data.data.url_page_data});
			}
		}
	}

	loadSearchData = _.debounce((searchTerm) => {
		searchTerm = searchTerm.trim();
		this.getSearchListData(searchTerm);
		searchTerm = searchTerm.trim()
		this.props.getSearchTerm(searchTerm);
		this.props.validateLongURL(searchTerm);
	}, 200);

	shortenUrlDropdown = (searchTerm) => {
		if(searchTerm.length !== 0 ){
			document.querySelector(`${this.props.modalId ? `#${this.props.modalId} .createShortURLWrapper` : '.createShortURLWrapper'}`).style.display = "block";
			this.setState({
				showSearchList: true
			})
			this.loadSearchData(searchTerm);
		}else{
			this.setState({
				showSearchList: false
			})
			document.querySelector(`${this.props.modalId ? `#${this.props.modalId} .createShortURLWrapper`: '.createShortURLWrapper'} `).style.display = "none";
		}
		this.setState({searchTerm : searchTerm})
	}

	getDetails = async(title) => {
		let data = {title : title,};
		let urlDetailsData = await postApiData(urlDetailsAPI, data, this.props.token)
		if(urlDetailsData.data !== undefined){
			let urlData = urlDetailsData.data.data;
			this.setState({
				shortUrlDetails: urlData.short_url,
				click_data : urlData.click_data,
				campaign_names : urlData.campaign_names,
			})
		}
	}


	renderDetailModal = (title) => {
		if(title !== ""){
			this.getDetails(title);
			this.props.openDetailsModal();
		}
	}

	removeSearchList = () => {
		if(this.state.searchTerm === ""){
			this.setState({
				showSearchList: false
			})
		}
		this.setState({
			searchTerm : this.state.searchTerm,
		})
	}

	duplicateShortenUrl = (title, shortenURl) => {
		this.props.getTitleFromExistingURL(title, shortenURl);
		this.props.handleNextScreen();
		this.props.getSearchTerm(shortenURl);
	}
	handleAddToScriptBtn = (title, id, short_url_preview, contact_tracking_type) => {
		let url_type = ""
		switch(contact_tracking_type){
			case 1:
				url_type = "default-back-half-no-tracking"
				break;
			case 2:
				url_type = "custom-back-half-yes-tracking"
				break;
			case 3:
				url_type = "default-back-half-yes-tracking"
				break;
		}
		//this.props.setSegmentUrlType(url_type);
		this.props.addTitleToScript(title, id, `${short_url_preview}`, url_type);
		this.shortenUrlDropdown("");
	}
	render() {
		const {searchListData, searchTerm, showSearchList, shortUrlDetails, click_data, campaign_names} = this.state;
		return (
			<div>
				<input maxLength={2048} className="searchBox" type="text" placeholder="Shorten URL or search existing" value={this.state.searchTerm} onFocus={() => (this.state.searchTerm === "") ? this.shortenUrlDropdown(" ") : {}} onBlur={this.removeSearchList} onChange={(e) => this.shortenUrlDropdown(e.target.value)}/>
				<div className={"searchList " + (showSearchList ? 'show' : 'hide')}>
					{	
						searchListData.totalcount > 0 &&
						searchListData.base_short_url_data.map((url_data, index) => 
							<div key={index} className="searchListItem">
								<div onClick={() => this.renderDetailModal(url_data.title)}>
									<h5 className="m-b-0 textOverflowEllipsis mainHeading">{url_data.title}</h5>
									<div className="textOverflowEllipsis secondaryParagraph">{url_data.target_url}</div>
									<div className="textOverflowEllipsis secondaryParagraph">{url_data.short_url_preview}</div>
								</div>
								<div>
									{this.props.addToScriptButton === undefined && <button onClick={(e) => {e.preventDefault(); this.handleAddToScriptBtn(url_data.title, url_data.id, `${url_data.short_url_preview}`, url_data.contact_tracking_type)}}>Add to Script</button>}
									<button onClick={() => this.duplicateShortenUrl(url_data.title, url_data.target_url)}>Duplicate</button>
								</div>
							</div>
							
						)
					}
					{
						this.props.isDetailedModalOpen &&
							<DetailsWrapper
							isModalClosed = {this.props.isModalClosed}
							closeDetailsModal = {this.props.closeDetailsModal}
							shortUrlDetails = {shortUrlDetails}
							click_data = {click_data}
							campaign_names = {campaign_names}
							duplicateShortenUrl = {this.duplicateShortenUrl}
							addToScript = {this.props.addTitleToScript}
							addToScriptButton  ={this.props.addToScriptButton}/>
						
					}
				</div>
			</div>
		);
	}
}
export default SearchBox;
