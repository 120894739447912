import * as React from "react"

const RelationalDashboardIcon = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32} height={32} rx={16} fill="#13C2C2" />
    <path
      d="M10.387 9a2.437 2.437 0 110 4.875 2.437 2.437 0 010-4.875zM21.6 9a2.437 2.437 0 110 4.875A2.437 2.437 0 0121.6 9zM6 18.101a3.252 3.252 0 013.251-3.251h1.301c.485 0 .945.107 1.359.296a3.901 3.901 0 001.261 3.604H6.65A.651.651 0 016 18.101zm12.349.649h-.021a3.89 3.89 0 001.319-2.925 4.18 4.18 0 00-.058-.68 3.222 3.222 0 011.359-.295h1.301a3.252 3.252 0 013.251 3.251.65.65 0 01-.649.649h-6.502zm-5.524-2.925a2.925 2.925 0 115.85 0 2.925 2.925 0 01-5.85 0zM9.9 23.787a4.062 4.062 0 014.061-4.062h3.577a4.062 4.062 0 014.062 4.061.813.813 0 01-.814.814H10.713a.813.813 0 01-.813-.814z"
      fill="#fff"
    />
  </svg>
)

export default RelationalDashboardIcon
