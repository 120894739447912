import React,{ useEffect, useState} from 'react';
import {Exclamation} from "../../../assets/svg/Exclamation";
import {TickMark} from "../../../assets/svg/TickMark";
import { ProgressbarSlider } from './ProgressbarSlider';
import "./ProgressBar.scss";
import {EnableSpamDetection} from "./EnableSpamDetection";
// import "../../styles/commonStyles.scss"

export const ProgressBar = (props)=>{
    const [stateColor,setStateColor] = useState("");
    const [progressData,setProgressData] = useState(0);
    const [show,setShow] = useState(true);
    const [callsData,setCallsData] = useState(0);
    const [doReload,setDoReload] = useState(false);
    const {color,progress,calls,closeModal,userId,email,is_whitelabel,key,campaignId,apiToken} = props.componentData;
    useEffect(() => {
        getCallHealth();
    },[]);
    const  get_segmentTrait = (segementTrait)=>{
        try {
            if(account_data_segment){
                segementTrait = {
                    ...segementTrait,
                    ...account_data_segment
                }
            }
        } catch (error) {
            console.log(error)
        }
        return segementTrait;
    }

    const getCallHealth = () => {
        setStateColor(color);
        setProgressData(progress);
        setCallsData(calls);
    };

    const closePopup = (viewstats) => {
        setShow(false);
        closeModal(viewstats);
        if(doReload){
            sessionStorage.setItem("ShowSpamEnableMessage",true);
            if(viewstats){
                sessionStorage.setItem("viewStats",true);
            }else{
                sessionStorage.setItem("viewStats",false);
            }
            window.location.reload(false);
        }
        props.unMountTheComponent(false);
    }

    const handleReload = (status) =>{
        if(status){
            if(window && window.analytics){
                let segementTrait = {
                    'userId': props.componentData.userId,
                    'email' : props.componentData.email,
                }
                segementTrait = get_segmentTrait(segementTrait);
                let event_name = key == "voice_campaign" ? "enabled-prevent-spam-labelling-from-vb-overview" : "enabled-prevent-spam-labelling-from-cc-overview";
                window.analytics.track(event_name, segementTrait);
            }
        }
        setDoReload(status);
    }

    return (
        show ? <div className="progressbar">
            <div>
                {doReload ? (
                <div className="safe-header">
                    <TickMark/>
                    <p>Spam label shield activated!</p>
                    <p onClick={() =>closePopup(true)}>view stats</p>
                </div>):(
                <div className="danger-header">
                    <Exclamation/>
                    <p>Answer rate has dropped below recommended level!</p>
                </div>
                )}
            </div>
            <hr/>
            {callsData > 0 ? (
            <div>
                <p className="calls-analysed">Calls analysed: {callsData}</p>
                <ProgressbarSlider status={progressData} color={stateColor} />
                <p className="calls-answered-info">% of calls made that were picked up</p>
            </div>) : <div className="noCalls"><p>No calls made in the last 7 days. {!is_whitelabel && <a className='tutorialLinks' href='https://support.callhub.io/hc/en-us/articles/8379153475225-How-is-the-Campaign-health-calculated-for-calling-campaigns-' target="_blank">Learn how we calculate call health.</a>}</p></div>} 
            <div>
                <p className="recommend-action">We recommend turning on spam label shield</p>
                <EnableSpamDetection campaign_id = {campaignId} apiToken={apiToken} handleReload={handleReload} is_whitelabel={is_whitelabel} />
                <hr/>
                <div className="buttons">
                    <div>
                        <button onClick={() => closePopup()} className="cancel">Cancel</button>
                        <button onClick={() =>closePopup()} className="done" >Done</button>
                    </div>
                </div>
            </div>
        </div> : null
    )
}