import React from "react";
import URLShortenerSideModal from "../ShortenedUrl/URLShortenerSideModal";

// Importing Components
import _ from "lodash";

//css
import "../commonStyle.scss";

const CreateShortenedUrl = props => {

	const modalPosition = (props.componentData.textareaId) ? `#${props.componentData.textareaId}` : ".panel-url-shortener textarea"

	return (
		<div>
			<URLShortenerSideModal modalPosition={modalPosition} componentData={props.componentData}/>
		</div>
	);
};

export default CreateShortenedUrl;
