import React, { useEffect, useState } from "react";
import ProfanitySubHeadingIcon from '../../../../assets/svg/ProfanitySubHeadingIcon';
import {PROFANITY_NOT_ENABLED_TITLE, PROFANITY_NOT_ENABLED_SUBHEADING, ENABLE_PROFANITY_BUTTON} from './AgentProfanityConstants';
import { Button } from 'antd';

const AgentProfanityNotEnabled = ({handleEnableProfanity})=>{

    return(
        <div className="agent-profanity-not-enabled-container">
            <div className="ai-not-enabled-title-section">
                <ProfanitySubHeadingIcon/>
                <div className="ai-not-enabled-title">
                    {PROFANITY_NOT_ENABLED_TITLE}
                </div>
            </div>
            <div className="ai-not-enabled-subheading">
                {PROFANITY_NOT_ENABLED_SUBHEADING}
            </div>
            <div className='enable-profanity-button'>
                <Button type="primary" onClick={handleEnableProfanity}>{ENABLE_PROFANITY_BUTTON}</Button>
            </div>
        </div>
    )
}

export default AgentProfanityNotEnabled