import React from "react";
import { Form, Input } from "antd";

const ContactForm = ({ form }) => {


  return (
    <Form form={form} preserve={true} layout="vertical" className="contact-creation-with-otp-form">
      <Form.Item
        label="First name"
        name="firstName"
      >
        <Input placeholder="First name" maxLength={100} />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="lastName"
      >
        <Input placeholder="Last name" maxLength={100} />
      </Form.Item>
      <Form.Item
        label="Mobile number"
        name="mobileNumber"
        rules={[
          { required: true, message: "Phone number is required" },
        ]}
        className="contact-creation-mobileNumber"
      >
        <Input placeholder="Enter phone number with country code" />
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
